import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CrmService {
  constructor(private httpClient: HttpClient) { }

  create_lead_url = environment.host + "lead";

  createLead(leadData: any) {
    return this.httpClient.post(this.create_lead_url, leadData);
  }
  updateLead(leadData: any) {
    return this.httpClient.patch(this.create_lead_url, leadData);
  }

  uploadLead(formData: FormData) {
    return this.httpClient.post(
      this.create_lead_url + "/upload-leads",
      formData
    );
  }
  getAllLead(option) {
    console.log({ option });
    const getall_leads_url =
      environment.host +
      `lead?page=${option.pageNum}&limit=${option.limit}${option.startDate === undefined || option.startDate === null
        ? ""
        : `&startDate=${option.startDate}`
      }${option.endDate === undefined || option.endDate === null
        ? ""
        : `&endDate=${option.endDate}`
      } ${option.leadStatus === undefined || option.leadStatus === null
        ? ""
        : `&leadStatus=${option.leadStatus}`
      }${option.representatorId === undefined || option.representatorId === null
        ? ""
        : `&representatorId=${option.representatorId}`
      }`;

    return this.httpClient.get(getall_leads_url);
  }

  getAllSearchedLead(option) {
    const get_search_leads_url =
      environment.host +
      `lead/search?search=${option.search}&page=${option.pageNum}&limit=${option.limit
      }${option.startDate === undefined || option.startDate === ""
        ? ""
        : `&startDate=${option.startDate}`
      }${option.endDate === undefined || option.endDate === ""
        ? ""
        : `&endDate=${option.endDate}`
      } `;
    return this.httpClient.get(get_search_leads_url);
  }


  getLeadDetail(id) {
    return this.httpClient.get(this.create_lead_url + "/" + id);
  }

  deleteLeadById(id) {
    return this.httpClient.delete(this.create_lead_url + "/" + id);
  }

  openLeadsData(option) {
    const get_open_leads_url =
      environment.host +
      `analytics/lead/status?status=${option.startDate === undefined || option.startDate === ""
        ? ""
        : `&startDate=${option.startDate}`
      }${option.endDate === undefined || option.endDate === ""
        ? ""
        : `&endDate=${option.endDate}`
      }${option.representatorId === undefined || option.representatorId === null
        ? ""
        : `&representatorId=${option.representatorId}`
      }`;
    return this.httpClient.get(get_open_leads_url);
  }

  getFollowups(option) {
    const get_follow_ups_url =
      environment.host +
      `analytics/lead/follow-ups?page=${option.pageNumber}&limit=${option.limitCount}`;
    return this.httpClient.get(get_follow_ups_url);
  }

  // lead Tracking API's

  getAnyUsers(department: string) {
    const get_bd_users = environment.host + `users?department=${department}`;
    return this.httpClient.get(get_bd_users);
  }

  shareAccess(body: any) {
    const share_lead_url = environment.host + "org-lead/access";
    return this.httpClient.post(share_lead_url, body);
  }

  CheckLeadAccess(id: string) {
    const check_access_url = environment.host + "users" + "/" + id;
    return this.httpClient.get(check_access_url);
  }
  assignLead(body: any) {
    const assign_lead_url = environment.host + "org-lead/assign";
    return this.httpClient.post(assign_lead_url, body);
  }

  // getLeadListing(option) {
  //   const get_leads_listing_url =
  //     environment.host +
  //     `org-lead?page=${option.pageNum}&limit=${option.limit}${
  //       option.startDate === undefined || option.startDate === null
  //         ? ""
  //         : `&startDate=${option.startDate}`
  //     }${
  //       option.endDate === undefined || option.endDate === null
  //         ? ""
  //         : `&endDate=${option.endDate}`
  //     }`;
  //   return this.httpClient.get(get_leads_listing_url);
  // }

  // searchLeadList(option) {
  //   let apiUrl = environment.host + "org-lead";

  //   const queryParams = [];

  //   if (
  //     option.project ||
  //     option.name ||
  //     option.startDate ||
  //     option.endDate ||
  //     option.projectTab
  //   ) {
  //     apiUrl += "/search";

  //     if (option.project) {
  //       queryParams.push(`project=${option.project}`);
  //     }

  //     if (option.name) {
  //       queryParams.push(`name=${option.name}`);
  //     }

  //     if (option.startDate) {
  //       queryParams.push(`startDate=${option.startDate}`);
  //     }

  //     if (option.endDate) {
  //       queryParams.push(`endDate=${option.endDate}`);
  //     }

  //     if (option.projectTab) {
  //       queryParams.push(`projectTab=${option.projectTab}`);
  //     }
  //   }

  //   queryParams.push(`page=${option.pageNum}`);
  //   queryParams.push(`limit=${option.limit}`);

  //   // Join all query parameters with '&'
  //   apiUrl += "?" + queryParams.join("&");

  //   return this.httpClient.get(apiUrl);
  // }

  searchLeadList(type) {
    console.log(type, "Type");
    const search_lead_list_url =
      environment.host +
      `org-lead/search?project=${type.project}${type.startDate === undefined || type.startDate === ""
        ? ""
        : `&startDate=${type.startDate}`
      }${type.endDate === undefined || type.endDate === ""
        ? ""
        : `&endDate=${type.endDate}`
      }&page=${type.pageNum}&limit=${type.limit}&projectTab=${type.projectTab}`;
    return this.httpClient.get(search_lead_list_url);
  }

  //localhost:3000/org-lead/export?project=V-ESTATE&projectTab=Contact Us&startDate=2024-05-27T07:29:32.111&endDate=2024-05-27T07:29:32.111&name=Aniket

  exportExcel(opt) {
    const export_lead_url =
      environment.host +
      `org-lead/export?project=${opt.project}&projectTab=${opt.projectTab}${opt.startDate === undefined || opt.startDate === ""
        ? ""
        : `&startDate=${opt.startDate}`
      }${opt.endDate === undefined || opt.endDate === ""
        ? ""
        : `&endDate=${opt.endDate}`
      }`;
    return this.httpClient.get(export_lead_url);
  }

  updateLeadStatus(body: any) {
    const update_status_url = environment.host + "org-lead";
    return this.httpClient.patch(update_status_url, body);
  }
}
