<!-- <div class="container-fluid">
<div class="row">
  <div class="col-lg-5 d-flex flex-column justify-content-center" id="left-area">
    <img src="../../../assets/Group 502.png" class="img-fluid" alt="login-logo" id="login-logo">
  </div>
  <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 d-flex flex-column justify-content-center" id="right-area">
    <div style="text-align: center;">

      <img src="../../../assets/IIC logo-02-copy.png" class="img-fluid" id="company-logo" alt="Company-logo">
    </div>
    <div class="form-area ">
      <form class="d-flex flex-column justify-content-center" [formGroup]="loginForm">
        <h4>SIGN IN</h4>
        <div class="form-group">
          <input formControlName="username" type="text" id="userID" aria-describedby="emailHelp"
            placeholder="Enter Username">
          </div>
          <div class="form-group">
            <input formControlName="password" type="password" id="userPassword" placeholder="Enter Password">
          </div>
          <div class="form-group">
            <button class="login-button" id="login-button" (click)="clickLogin1()"> <span id="log-in-text"> Log in
            </span>
            <img class="hide" id="preloader" src="../../../assets/loader.gif" alt="">
          </button>
        </div>

      </form>
    </div>
  </div>
</div>
<div class="hide" id="preloader">
  <div id="loader"></div>
</div>

</div>-->


<div class="container-fluid">

  <div class="row">
    <div class="col-lg-5 d-flex flex-column justify-content-center" id="left-area">
      <img src="../../../assets/Group 502.png" class="img-fluid" alt="login-logo" id="login-logo">
    </div>
    <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 d-flex flex-column justify-content-center" id="right-area">
      <div style="text-align: center;">

        <img src="../../../assets/IIC logo-02-copy.png" class="img-fluid" id="company-logo" alt="Company-logo">
      </div>
      <div class="form-area ">
        <form class="d-flex flex-column justify-content-center" [formGroup]="loginForm">
          <h4>SIGN IN</h4>
          <div class="form-group">
            <input formControlName="username" type="text" id="userID" aria-describedby="emailHelp"
              placeholder="Enter Username"  [ngModel]="loginForm.value.username"  (click)="onInputClick()">
            </div>
            <div class="form-group">
              <input formControlName="password" type="password" id="userPassword" placeholder="Enter Password" [ngModel]="loginForm.value.password" (click)="onInputClick()">
            </div>
            <div class="form-group">
              <button class="login-button" id="login-button" (click)="onLogin()"> <span id="log-in-text"> Log in
              </span>
            </button>
          </div>

          @if (errorMessage) {
            <div class="text-danger">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17316C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM15.6389 14.1213C15.7326 14.2142 15.807 14.3248 15.8578 14.4467C15.9086 14.5685 15.9347 14.6992 15.9347 14.8313C15.9347 14.9633 15.9086 15.094 15.8578 15.2158C15.807 15.3377 15.7326 15.4483 15.6389 15.5413C15.5459 15.635 15.4353 15.7094 15.3135 15.7601C15.1916 15.8109 15.0609 15.8371 14.9289 15.8371C14.7969 15.8371 14.6662 15.8109 14.5443 15.7601C14.4225 15.7094 14.3119 15.635 14.2189 15.5413L12 13.41L9.7158 15.42C9.62284 15.5137 9.51224 15.5881 9.39038 15.6389C9.26852 15.6897 9.13781 15.7158 9.0058 15.7158C8.87379 15.7158 8.74308 15.6897 8.62122 15.6389C8.49936 15.5881 8.38876 15.5137 8.2958 15.42C8.20207 15.327 8.12768 15.2164 8.07691 15.0946C8.02614 14.9727 8 14.842 8 14.71C8 14.578 8.02614 14.4473 8.07691 14.3254C8.12768 14.2036 8.20207 14.093 8.2958 14L10.59 12L8.58 9.88725C8.3917 9.69895 8.28591 9.44355 8.28591 9.17725C8.28591 8.91095 8.3917 8.65556 8.58 8.46725C8.76831 8.27895 9.0237 8.17316 9.29 8.17316C9.5563 8.17316 9.8117 8.27895 10 8.46725L12 10.59L14.2189 8.46725C14.4072 8.27895 14.6626 8.17316 14.9289 8.17316C15.1952 8.17316 15.4506 8.27895 15.6389 8.46725C15.8272 8.65556 15.933 8.91095 15.933 9.17725C15.933 9.44356 15.8272 9.69895 15.6389 9.88725L13.41 12L15.6389 14.1213Z" fill="#F11E11"/>
              </svg>
              {{ errorMessage }}
            </div>
          }
        </form>
      </div>
    </div>
  </div>

</div>