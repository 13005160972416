import { Component } from "@angular/core";
import { HeaderComponent } from "../header/header.component";
import { RouterOutlet, Router } from "@angular/router";
import { InventoryDashboardComponent } from "./inventory-dashboard/inventory-dashboard.component";
import { WorkstationComponent } from "./workstation/workstation.component";
import { NgClass } from "@angular/common";
@Component({
  selector: "app-inventory",
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    InventoryDashboardComponent,
    WorkstationComponent,
    NgClass
  ],
  templateUrl: "./inventory.component.html",
  styleUrl: "./inventory.component.css",
})
export class InventoryComponent {


  isMenuExpanded = false;

  onMouseEnter() {
    this.isMenuExpanded = true;
  }

  onMouseLeave() {
    this.isMenuExpanded = false;
  }

  activeMenu: string = 'dashboard';

  constructor(private router: Router) { }

  navigateTo(menu: string, route: string) {
    this.activeMenu = menu;
    this.router.navigate([route]);
  }

  // selectInventory(inventory: string) {
  //   this.router.navigate(["/inventory/inventories"]);
  // }
  // selectWorkstation(workstation: string) {
  //   this.router.navigate(["/inventory/workstation"]);
  // }
  // selectServiceRequest(service: string) {
  //   this.router.navigate(["/inventory/service"]);
  // }
  // selectInventoryRequest(inventory: string) {
  //   this.router.navigate(["/inventory/inventory-request"]);
  // }
  // selectNewInventory(newInventory: string) {
  //   this.router.navigate(["/inventory/new-inventory"]);
  // }

}
