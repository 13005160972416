<div class="container-fluid w-96">
  <div (click)="backToCollection()" class="back-to-collection d-flex align-items-center cursorPointer">
    <svg id="back-to-collection" _ngcontent-ng-c892648083="" xmlns="http://www.w3.org/2000/svg" width="55" height="30"
      fill="currentColor" viewBox="0 0 16 16" class="bi bi-arrow-left cursorPointer fw">
      <path _ngcontent-ng-c892648083="" fill-rule="evenodd"
        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z">
      </path>
    </svg>
    <span> Back</span>
  </div>
  <div class="row">
    <div class="col-6 pb-2 pt-3">
      <div class="media">
        @if (!PlaybackAvailable) {
        <div>
          <p style="color: #000000; font-size: 2rem">
            File is still uploading...
          </p>
        </div>
        }
        <div class="videoHeight">
          <!-- <video
          [src]="videoUrl_lg"
          class="embed-responsive embed-responsive-16by9"
          id="video"
          *ngIf="videoUrl_lg && videoImageToggle"
          autoplay
          [controls]="true"
            style="
              width: 100%;
              height: 403px;
              object-fit: contain !important;
              background-color: black;
              object-fit: fill;
            "
          controlsList="nodownload"
          preload="auto"
        ></video> -->
          @if (videoUrl_lg && videoImageToggle) {
          <video class="embed-responsive embed-responsive-16by9" id="video" autoplay [controls]="true" style="
              width: 100%;
              height: 403px;
              object-fit: contain !important;
              background-color: black;
              object-fit: fill;
            " controlsList="nodownload" preload="auto">
            <source [src]="videoUrl_lg" />
          </video>
          } @if (!videoImageToggle && thumbnail?.[0]) {
          <img class="imgHeight" [src]="thumbnail[0]" width="100%" height="400px"
            style="object-fit: contain !important; background-color: black" />
          } @if (!videoImageToggle && videoUrl_lg) {
          <img ngClass="cursorPointer" (click)="videoPlay()" class="playCenter" src="../../../assets/play_btn_icon.svg"
            [style.pointer-events]="PlaybackAvailable ? 'auto' : 'none'" />
          }
          <!-- <button type="button" ngClass="cursorPointer" *ngIf="!videoImageToggle && videoUrl_lg" (click)="videoPlay()" [style.pointer-events]="videoPlaybackAvailable ? 'auto' : 'none'">Edit</button> -->
        </div>
        @if (!PlaybackAvailable) {
        <div class="lds-facebook mt-25" style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          ">
          <div></div>
          <div></div>
          <div></div>
        </div>
        } @if (image_Url) {
        <img class="imgHeight" id="image" class="img-thumbnail" [src]="image_Url"
          style="height: -webkit-fill-available; object-fit: contain !important" />
        }
        <!-- <ngx-doc-viewer
      id="preview-pdf"
      [url]="pdfUrl"
      viewer="google"
      height="560px"
      width="700px"
      style="width: 700px; height: 350px"
      [style.display]="pdfDisplay"
      >
    </ngx-doc-viewer> -->
        <div class="default-preview" style="margin-top: 80px">
          <img [src]="defaultThumbnail" />
        </div>
        <!-- <p class="d-flex fileTruncate">{{ fileNameBased }}</p> -->

        @if (videoUrl_lg) {
        <div id="download-file" (click)="removeDownload()">
          <!-- <div class="centerMedia">
        <h4>Download Media</h4>
        <div class="verticalLine"></div>
        <div
          class="d-flex justify-content-between"
          *ngIf="videoUrl_lg && !videoUrl_md"
          >
          <div
            class="resolution m-auto"
            *ngIf="videoUrl_lg"
            (click)="handleResolution('High', 'highDiv')"
                [ngStyle]="{
                  'background-color': activeDiv === 'highDiv' ? '#ffc107' : '',
                  height: '46px'
                }"
            #highDiv
            >
            <p class="high">Original Resolution</p>
          </div>
        </div>
        <div class="d-flex justify-content-around" *ngIf="videoUrl_md">
          <div
            class="cursorPointer"
            (click)="handleResolution('High', 'highDiv')"
                [ngStyle]="{
                  'background-color': activeDiv === 'highDiv' ? '#ffc107' : '',
                  height: '46px'
                }"
            #highDiv
            >
            <p class="high">Original Resolution</p>
          </div>
          <div
            class="cursorPointer"
            (click)="handleResolution('Low', 'lowDiv')"
                [ngStyle]="{
                  'background-color': activeDiv === 'lowDiv' ? '#ffc107' : '',
                  height: '46px'
                }"
            #lowDiv
            >
            <p class="whatsapp">Medium Resolution</p>
          </div>
        </div>
      </div> -->
        </div>
        }
        <!-- <div class="d-flex justify-content-between">
  <div class="d-flex mt-2" *ngIf="isResolutionVisible">
    <div
      style="cursor: pointer"
      (click)="watermark()"
              [ngStyle]="{
                'background-color':
                  activediv === 'watermarkDiv' ? '#ffc107' : '',
                height: activediv === 'watermarkDiv' ? '43px' : ''
              }"
      #watermarkDiv
      >
      <p class="watermark">Watermark</p>
    </div>
    <div
      style="cursor: pointer"
      (click)="Withoutwatermark()"
      #withoutWatermarkDiv
      >
      <p
        class="Withoutwatermark ml-2"
                [ngStyle]="{
                  'background-color':
                    activediv === 'withoutWatermarkDiv' ? '#ffc107' : '',
                  height: activediv === 'withoutWatermarkDiv' ? '43px' : ''
                }"
        >
        Without Watermark
      </p>
    </div>
  </div>
</div> -->
      </div>
      @if (isDownLoad) {
      <progress id="downloadProgress" value="0" max="100" style="width: inherit; margin-top: 20px"></progress>
      }

      <div id="progress-bar" style="display: none">
        <div class="progress-bar"></div>
      </div>
      @if (isDownloading) {
      <div>
        <p>Download in progress...</p>
        <div class="progress">
          <div id="progress-bar" class="progress-bar progress-bar-striped progress-bar-animated"
            [style.width]="downloadProgress + '%'">
            {{ downloadProgress }}%
          </div>
        </div>
      </div>
      }

      <div class="pt-2 pb-3">
        <div class="float-right" id="float-right">
          @if (userId === guestId) {
          <button type="button" class="cursorPointer Edit" [ngClass]="{ 'disable-div': !PlaybackAvailable }"
            [style.pointer-events]="PlaybackAvailable ? 'auto' : 'none'" (click)="edit()">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.73801 16.3199C5.17539 16.3199 3.61277 16.3199 2.05015 16.3199C1.00007 16.3199 0.106252 15.4948 0.100001 14.4447C0.0812496 11.2695 0.0812496 8.088 0.100001 4.91276C0.106252 3.89393 0.981319 3.03136 2.0189 3.01886C3.81279 2.99386 5.60042 3.00636 7.39431 3.01261C7.81309 3.01261 8.10062 3.31264 8.09437 3.69392C8.08811 4.0877 7.80059 4.34396 7.33806 4.35022C5.68168 4.35647 4.0253 4.35022 2.36893 4.35022C1.61887 4.35022 1.4251 4.54398 1.4251 5.29404C1.4251 8.21926 1.4251 11.1507 1.4251 14.076C1.4251 14.8073 1.62512 15.0135 2.34392 15.0135C5.3004 15.0135 8.26313 15.0135 11.2196 15.0135C11.8884 15.0135 12.0947 14.8073 12.0947 14.1385C12.0947 12.4633 12.0947 10.782 12.0947 9.10683C12.0947 8.61304 12.4134 8.30677 12.8447 8.36302C13.1948 8.40678 13.4198 8.66305 13.4198 9.05683C13.426 9.81939 13.4198 10.5757 13.4198 11.3383C13.4198 12.3383 13.426 13.3384 13.4198 14.3385C13.4135 15.2823 12.826 16.0386 11.9509 16.2824C11.8197 16.3199 11.6821 16.3199 11.5509 16.3199C9.94451 16.3199 8.33813 16.3199 6.73801 16.3199Z"
                fill="white" />
              <path
                d="M6.17586 10.6568C5.90709 10.663 5.74458 10.5068 5.78833 10.263C5.9321 9.47545 6.10086 8.69414 6.25712 7.90658C6.28212 7.78782 6.36963 7.71281 6.45089 7.63156C8.11351 5.96893 9.77614 4.3063 11.4388 2.64368C11.5763 2.49991 11.6575 2.48741 11.8075 2.63742C12.4701 3.31248 13.1389 3.98753 13.814 4.64383C13.9702 4.79384 13.939 4.8751 13.8014 5.00636C12.1451 6.65648 10.4887 8.31286 8.83232 9.96924C8.70106 10.1005 8.56355 10.1818 8.37603 10.2193C7.63223 10.363 6.88842 10.5193 6.17586 10.6568Z"
                fill="white" />
              <path
                d="M14.5634 0.349609C15.0697 0.35586 15.5759 0.643382 15.8885 1.21843C16.2072 1.80597 16.176 2.40602 15.7947 2.95606C15.5134 3.35609 15.1197 3.66236 14.7821 4.01864C14.6821 4.1249 14.6196 4.05614 14.5571 3.98739C13.8571 3.28733 13.1633 2.58728 12.4632 1.89348C12.382 1.81222 12.3257 1.74972 12.4382 1.64346C12.7632 1.33719 13.0695 1.00591 13.4008 0.712137C13.6821 0.455868 14.0321 0.35586 14.5634 0.349609Z"
                fill="white" />
            </svg>
            Edit
          </button>
          } @if (videoUrl_lg) {
          <img title="download files" class="cursorPointer" src="../../../assets/download_icon.svg"
            (click)="downloadOption('video')" [ngClass]="{ 'disable-div': !PlaybackAvailable }"
            [style.pointer-events]="PlaybackAvailable ? 'auto' : 'none'" style="margin-right: -18px" />
          } @if ( cardData && (cardData?.extension == '.xlsx' ||
          cardData?.extension == '.xls') ) {
          <img title="download files" class="cursorPointer" src="../../../assets/download_icon.svg"
            (click)="downloadOption('excel')" [ngClass]="{ 'disable-div': !PlaybackAvailable }"
            [style.pointer-events]="PlaybackAvailable ? 'auto' : 'none'" style="margin-right: -18px" />
          } @if (cardData && cardData?.extension == '.pdf') {
          <img title="download files" class="cursorPointer" src="../../../assets/download_icon.svg"
            (click)="downloadOption('pdf')" [ngClass]="{ 'disable-div': !PlaybackAvailable }"
            [style.pointer-events]="PlaybackAvailable ? 'auto' : 'none'" style="margin-right: -18px" />
          } @if (cardData && cardData?.extension == '.pptx') {
          <img title="download files" class="cursorPointer" src="../../../assets/download_icon.svg"
            (click)="downloadOption('pptx')" [ngClass]="{ 'disable-div': !PlaybackAvailable }"
            [style.pointer-events]="PlaybackAvailable ? 'auto' : 'none'" style="margin-right: -18px" />
          } @if ( cardData && (cardData?.extension == '.png' ||
          cardData?.extension == '.jpeg' || cardData?.extension == '.jpg') ) {
          <img title="download files" class="cursorPointer" src="../../../assets/download_icon.svg"
            (click)="downloadOption('image')" [ngClass]="{ 'disable-div': !PlaybackAvailable }"
            [style.pointer-events]="PlaybackAvailable ? 'auto' : 'none'" style="margin-right: -18px" />
          }
          <!-- <img
          *ngIf="
          cardData && (
            (!videoUrl_lg && (cardData?.extension === '.png' || cardData?.extension === '.jpeg' || cardData?.extension === '.jpg'))
          )"
          class="cursorPointer"
          src="../../../assets/download_icon.svg"
          (click)="downloadOption('image')"
          [ngClass]="{ 'disable-div': !PlaybackAvailable }"
          [style.pointer-events]="PlaybackAvailable ? 'auto' : 'none'"
          /> -->
          @if ( cardData && (cardData?.extension === '.doc' ||
          cardData?.extension === '.docx') ) {
          <img title="download files" class="cursorPointer" src="../../../assets/download_icon.svg"
            (click)="downloadOption('doc')" [ngClass]="{ 'disable-div': !PlaybackAvailable }"
            [style.pointer-events]="PlaybackAvailable ? 'auto' : 'none'" style="margin-right: -18px" />
          } @if (userId === guestId) {
          <img title="delete media file" class="cursorPointer" src="../../../assets/icons 6/delete_icon.svg"
            (click)="deleteMedia(id)" [ngClass]="{ 'disable-div': !PlaybackAvailable }"
            [style.pointer-events]="PlaybackAvailable ? 'auto' : 'none'" style="width: 84px" />
          }
        </div>
        @if (download$ | async; as download) {
        <mat-progress-bar [mode]="download.state == 'PENDING' ? 'buffer' : 'determinate'" [value]="download.progress">
        </mat-progress-bar>
        }
      </div>
    </div>
    <div class="col-6 pt-3">
      <div class="detail-area">
        <div class="white-background p-3 pt-4">
          <div class="project-title d-flex justify-content-between text-work">
            <h3 [title]="title">{{ title }}</h3>
            <p class="d-flex">
              <span class="description">Client:</span>
              <span [title]="cardData?.clientName" class="text-bold pl-1 client-text">{{ cardData?.clientName }}</span>
            </p>
          </div>
          <p class="description">{{ description }}</p>
        </div>
        <div class="tags-container">
          <div class="heightTags">
            <div class="tags d-flex flex-wrap">
              @for (tag of tags; track tag) {
              <div class="tag-element px-2">
                <span class="px-3">{{ tag }} </span>
              </div>
              }
            </div>
          </div>
          <div class="BottomDetails">
            <div class="pb-2">
              <div class="d-flex align-items-center">
                <div>
                  <!-- <p class="pt-3 mr-2 description11">In Collections: </p> -->
                </div>
                <div class="tag-section d-flex flex-wrap">
                  <!-- <div class="tag-elementCollection px-4"
                  *ngFor="let tag of ['Public Collection','Works 2023'];let i=index">
                  {{tag}}
                </div> -->
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <p class="pr-2 description11">Uploaded By:</p>
                <!-- <img class="uploadedBy" src="../../../assets/Group 212.png"> -->
                @if (cardData?.profileImg) {
                <img class="uploadedBy" src="{{ cardData?.profileImg }}" />
                } @if (!cardData?.profileImg) {
                <img src="../../../assets/add-people.png" width="20px" height="20px" />
                }

                <p class="text-bold pl-2" style="font-size: 14.54px">
                  {{ cardData?.uploadedBy }}
                </p>
              </div>
              <div class="d-flex align-content-center">
                <span class="pt-0 pr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                    class="bi bi-clock colorCLock" viewBox="0 0 16 16">
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                  </svg>
                </span>
                <p class="description21 align-baseline">
                  {{ cardData?.createdAt | date }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      @if (imageUrls) {
      <div class="downloadZip">
        <button class="downloadALL float-right" (click)="downloadZip()">
          Download All
        </button>
      </div>
      }
    </div>
  </div>

  <div class="row justify-content-center">
    @if (filesUrlLen) {
    <div class="col-12 img-show mb-5 pb-3">
      <div class="swiper-container1 carousel pt-2 pb-2">
        <div class="swiper-wrapper swiper-wrapper1">
          @for (file of additionalFiles; track file; let i = $index) { @if
          (isImage(file.fileUrl)) {

          <div class="swiper-slide slide">
            <img src="{{ file.fileUrl }}" class="img-row-show thumbnail img-thumbnail mx-2"
              (click)="onClickImage(file.fileUrl, i)" width="100%" height="100%" data-toggle="modal"
              data-target="#exampleModalCenter" />
          </div>
          } @if (isDocument(file.fileUrl)) {
          <div class="swiper-slide slide">
            <img id="edit-doc-content" height="100px" class="img-preview"
              [src]="fileTypeImages[file.fileUrl.split('.')[5]]" (click)="onClickImage(file.fileUrl, i)"
              data-toggle="modal" data-target="#exampleModalCenter" />
          </div>
          } @if (isVideo(file.fileUrl)) {
          <div class="swiper-slide slide">
            <video id="vidoeCheck" class="mx-2 cursorPointer" height="100%" width="100%" [controls]="true"
              preload="none" [muted]="true" controls muted (click)="onClickImage(file.fileUrl, i)" data-toggle="modal"
              data-target="#exampleModalCenter">
              <source [src]="file.fileUrl" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          } }
        </div>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
    }

    <!-- Modal -->
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backtrack="static">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Image Preview
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" (click)="pauseVideo()">&times;</span>
            </button>
          </div>
          <div class="modal-body modalImage p-0">
            @if (additionalFiles ) {
            <div class="swiper-container carousel-container">
              <div class="swiper-wrapper">
                @for (url of additionalFiles; track url; let i = $index) {
                @if (isImage(url.fileUrl)) {

                <div class="swiper-slide slide slider1">
                  <div>
                    <img id="imageAll" src="{{ url.fileUrl }}" class="modal-image img-thumbnail" width="100%"
                      height="100%" />
                  </div>
                </div>

                }
                @if (isDocument(url.fileUrl)) {
                <div class="swiper-slide slide slider1">
                  <img id="imageAll" src="{{ fileTypeImages[url.fileUrl.split('.')[5]] }}"
                    class="modal-image img-thumbnail" width="100%" height="100%" />
                </div>
                }
                @if (isVideo(url.fileUrl)) {
                <div class="swiper-slide slide slider1">
                  <div>
                    <video id="video" class="ratio ratio-16x9" id="videoSelected" height="100%" width="100%"
                      [controls]="true" [muted]="true" (click)="playVideo()">
                      <source [src]="url.fileUrl" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                }


                }
              </div>
            </div>
            }
          </div>
          <div class="d-flex justify-content-between">
            <div class="pt4" style="font-size: 20px; font-weight: 600">
              <p>{{ fileName }}</p>
            </div>
            <div class="d-flex">
              @if (selectedImageUrl) {
              <p class="d-none">{{ selectedImageUrl }}</p>
              @if (isImage(selectedImageUrl)) {
              <span class="cursorPointer" (click)="download('image')"><img
                  src="../../../assets/download_icon.svg" /></span>
              } @if (isDocument(selectedImageUrl)) {
              <span class="cursorPointer" (click)="download('document')"><img
                  src="../../../assets/download_icon.svg" /></span>
              } @if (isVideo(selectedImageUrl)) {
              <span class="cursorPointer" (click)="download('video')">
                <img src="../../../assets/download_icon.svg" />
              </span>
              } }
            </div>

            <!-- <div class="m-auto w-auto">
                <button class="btn btn-primary mb-5" (click)="downloadZip()">Download All</button>
              </div> -->
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade exampleModal12" id="exampleModal12" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          @for (user of allUsers; track user; let i = $index) {
          <div class="d-flex justify-content-between shareDiv mb-1">
            <div>{{ i }}</div>
            <div>{{ user.username }}</div>
            <div>{{ user.phone }}</div>
            <div>
              @if (videoUrl_lg) {
              <button class="btn btn-primary" (click)="shareFile(user.phone, videoUrl_lg)">
                Send
              </button>
              } @if (!videoUrl_lg && cardData?.extension == '.xlsx') {
              <button class="btn btn-primary" (click)="shareFile(user.phone, cardData.og_file_url)">
                Send
              </button>
              } @if (!videoUrl_lg && cardData?.extension == '.pdf') {
              <button class="btn btn-primary" (click)="shareFile(user.phone, cardData.og_file_url)">
                Send
              </button>
              } @if (!videoUrl_lg && cardData?.extension == '.pptx') {
              <button class="btn btn-primary" (click)="shareFile(user.phone, cardData.og_file_url)">
                Send
              </button>
              } @if (!videoUrl_lg && cardData?.extension == '.png') {
              <button class="btn btn-primary" (click)="shareFile(user.phone, cardData.og_file_url)">
                Send
              </button>
              }
            </div>
          </div>
          }
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>