import { createReducer, on } from "@ngrx/store";
import { uploadAction } from "./counter.actions";
import { loginSuccess, loginFailure } from "./counter.actions";
import { initialLoginState } from "./counter.state";
import { increment, decrement, reset } from "./counter.actions";
import { updateLeadSearchUser, updateLeadSearchStatus } from './counter.actions';
import { LeadsState, initialLeadsState } from './counter.state';




export const uploadFormDetailsInitialState = {
  body: Object,
} as any;

export const uploadReducer = createReducer(
  uploadFormDetailsInitialState,
  on(uploadAction, (state, action) => {
    console.log({ action });
    return {
      ...state,
      body: action,
    };
  })
);

export const LoginState = {
  userData: Object,
} as any;

// export const loginReducer = createReducer(
//     LoginState,
//     on(loginSuccess, (state,  action ) => {
//         console.log({action})
//         return{
//              ...state, userData: action
//         }
//          }),
//   );

export const loginReducer = createReducer(
  initialLoginState,
  on(loginSuccess, (state, { body }) => ({
    ...state,
    body,
    // userData: jwtResponse.data,
    // jwtResponse: jwtResponse,
    errorMessage: null,
  })),
  on(loginFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage: errorMessage,
  }))
);

export const initialState = 0;

export const counterReducer = createReducer(
  initialState,
  on(increment, (state) => state + 1),
  on(decrement, (state) => state - 1),
  on(reset, (state) => 0)
);







export const leadsReducer = createReducer(
  initialLeadsState,
  on(updateLeadSearchUser, (state, { user }) => {
    console.log('user first', user);
    return{
      ...state,
      selectedSearchIICUser: typeof user === 'string' ? user : user.username,
    selectedSearchUserId: typeof user === 'string' ? null : user.id,
    };
    }),
  on(updateLeadSearchStatus, (state, { searchStatus }) => {
    console.log("status first", searchStatus);
    return{
      ...state,
      selectedSearchStatus: searchStatus,
    }
  })
);