import { AfterViewChecked, Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LiveProjectService } from "src/app/service/live-project.service";
import { UserService } from "src/app/service/user.service";
import { FooterComponent } from "../footer/footer.component";

import { HeaderComponent } from "../header/header.component";

declare var $: any;

@Component({
    selector: "app-create-new-project",
    templateUrl: "./create-new-project.component.html",
    styleUrls: ["./create-new-project.component.css"],
    standalone: true,
    imports: [
    HeaderComponent,
    ReactiveFormsModule,
    FooterComponent
],
})
export class CreateNewProjectComponent implements OnInit {
  projectForm: FormGroup;
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private liveProjectService: LiveProjectService,
    private userService: UserService,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
    });

    this.projectForm = this.fb.group({
      title: "",
      description: "",
      notion: "",
      slack: "",
    });

    // if(this.id != -1){
    //   this.editMode=true;
    //   this.userService.getAllUsers().subscribe(resp=>{
    //     console.log(resp['data']);
    //     this.allUsers=resp['data'];
    //   });
    //   this.liveProjectService.getSingleLiveProjectByID(this.id).subscribe(resp=>{
    //     console.log(resp['data']);
    //     this.projectForm.setValue({
    //       title:resp['data']['title'],
    //       description:resp['data']['description'],
    //       notion:resp['data']['notion'],
    //       slack:resp['data']['slack'],
    //       selectedDate:''
    //   })
    //   })
    // }else{
    //   this.editMode=false;
    // }
  }

  // projectForm = new FormGroup({
  //   title: new FormControl(''),
  //   description: new FormControl(''),
  //   notion: new FormControl(''),
  //   slack: new FormControl('')
  // })

  allUsers = [];

  threeD = [];
  twoD = [];
  sales = [];
  account = [];
  hr = [];
  it = [];
  admin_dept = [];
  ops = [];
  id;
  editMode = false;
  // config={};
  meetings = [];
  // meet={start:"",end:"",participants:[]};
  meet = {
    room_name: "",
    room_uid: "",
    usersids: [],
    meetingType: "scheduled",
    creator: "",
    description: "",
    st: "",
    ed: "",
  };

  selectedDate;

  ngOnInit(): void {
    if (this.id != -1) {
      this.editMode = true;

      this.userService.getAllUsers().subscribe((resp) => {
        this.userService.allUsersData = resp["data"];
        this.allUsers = this.userService.allUsersData;
        this.allUsers = this.allUsers.filter(
          (x) =>
            x._id != localStorage.getItem("guestID") && x.username != "admin"
        );
        this.allUsers.sort(this.sortFunction);

        console.log("users", this.allUsers);
        for (let i = 0; i < this.allUsers.length; i++) {
          switch (this.allUsers[i].department) {
            case "3D Department":
              this.threeD.push(this.allUsers[i]);
              break;
            case "2D Department":
              this.twoD.push(this.allUsers[i]);
              break;
            case "Sales & Marketing Department":
              this.sales.push(this.allUsers[i]);
              break;
            case "Account Department":
              this.account.push(this.allUsers[i]);
              break;
            case "Human Resource Department":
              this.hr.push(this.allUsers[i]);
              break;
            case "IT Department":
              this.it.push(this.allUsers[i]);
              break;
            case "Admin Department":
              this.admin_dept.push(this.allUsers[i]);
              break;
            case "Operation Department":
              this.ops.push(this.allUsers[i]);
              break;
          }
        }

        setTimeout(() => {
          $(".selectpicker").selectpicker("refresh");
        }, 150);

        setTimeout(() => {
          $(function () {
            $(".list-group.checked-list-box .list-group-item").each(
              function () {
                // Settings
                var $widget = $(this),
                  $checkbox = $('<input type="checkbox" class="hidden" />'),
                  color = $widget.data("color")
                    ? $widget.data("color")
                    : "primary",
                  style =
                    $widget.data("style") == "button"
                      ? "btn-"
                      : "list-group-item-",
                  settings = {
                    on: {
                      icon: "glyphicon glyphicon-check",
                    },
                    off: {
                      icon: "glyphicon glyphicon-unchecked",
                    },
                  };

                $widget.css("cursor", "pointer");
                $widget.append($checkbox);

                // Event Handlers
                $widget.on("click", function () {
                  $checkbox.prop("checked", !$checkbox.is(":checked"));
                  $checkbox.triggerHandler("change");
                  updateDisplay();
                });
                $checkbox.on("change", function () {
                  updateDisplay();
                });

                // Actions
                function updateDisplay() {
                  var isChecked = $checkbox.is(":checked");

                  // Set the button's state
                  $widget.data("state", isChecked ? "on" : "off");

                  // Set the button's icon
                  $widget
                    .find(".state-icon")
                    .removeClass()
                    .addClass(
                      "state-icon " + settings[$widget.data("state")].icon
                    );

                  // Update the button's color
                  if (isChecked) {
                    $widget.addClass(style + color + " active");
                  } else {
                    $widget.removeClass(style + color + " active");
                  }
                }

                // Initialization
                function init() {
                  if ($widget.data("checked") == true) {
                    $checkbox.prop("checked", !$checkbox.is(":checked"));
                  }

                  updateDisplay();

                  // Inject the icon if applicable
                  if ($widget.find(".state-icon").length == 0) {
                    $widget.prepend(
                      '<span class="state-icon ' +
                        settings[$widget.data("state")].icon +
                        '"></span>'
                    );
                  }
                }
                init();
              }
            );

            $("#get-checked-data").on("click", function (event) {
              event.preventDefault();
              var checkedItems = {},
                counter = 0;
              $("#check-list-box li.active").each(function (idx, li) {
                checkedItems[counter] = $(li).text();
                counter++;
              });
              $("#display-json").html(JSON.stringify(checkedItems, null, "\t"));
            });
          });
        }, 150);
      });

      this.liveProjectService
        .getSingleLiveProjectByID(this.id)
        .subscribe((resp) => {
          console.log(resp["data"]);
          this.projectForm.setValue({
            title: resp["data"]["title"],
            description: resp["data"]["description"],
            notion: resp["data"]["notion"],
            slack: resp["data"]["slack"],
          });
        });
    } else {
      this.editMode = false;
    }
  }

  addparticipant() {
    // var participantArray=this.projectForm.get('projectParticipants') as FormArray;
    // participantArray.push(this.fb.control(''));
  }

  onClickCreateButton() {
    var body = {
      title: this.projectForm.value["title"],
      description: this.projectForm.value["description"],
      notion: this.projectForm.value["notion"],
      slack: this.projectForm.value["slack"],
    };
    this.liveProjectService.createNewProject(body).subscribe((resp) => {
      console.log("response", resp);
      this.router.navigate(["live-project-list/"]);
    });
  }

  onClickSaveButton() {
    const projectData = this.projectForm.value;
    this.liveProjectService
      .updateProject(this.projectForm.value, this.id)
      .subscribe((resp) => {
        console.log("response", resp);
      });
  }

  startTimeChange(e) {
    // console.log(e.target.value);
    // this.meet['start'] = e.target.value;
    // console.log(this.meet);
  }

  endTimeChange(e) {
    // console.log(e.target.value);
    // this.meet['end'] = e.target.value;
    // console.log(this.meet);
  }
  onClickScheduleButton() {
    let participants = [];
    $.each($("input[type='checkbox']:checked"), function () {
      participants.push($(this).val());
    });
    console.log(participants);
    let arrtest = [];
    let intersection = this.allUsers.filter((x) =>
      participants.includes(x.username)
    );
    for (let i = 0; i < intersection.length; i++) {
      arrtest.push(intersection[i]._id);
    }
    arrtest.push(localStorage.getItem("guestID"));

    // console.log((<any>document.getElementById("meet-title")).value);
    // console.log((<any>document.getElementById("start-time")).value);
    // console.log((<any>document.getElementById("start-date")).value);
    // console.log((<any>document.getElementById("end-time")).value);
    // console.log((<any>document.getElementById("end-date")).value);
    // console.log((<any>document.getElementById("meet-description")).value);

    let startTime = new Date(
      (<any>document.getElementById("start-date")).value +
        "T" +
        (<any>document.getElementById("start-time")).value +
        ":00"
    ).toISOString();
    let endTime = new Date(
      (<any>document.getElementById("end-date")).value +
        "T" +
        (<any>document.getElementById("end-time")).value +
        ":00"
    ).toISOString();
    console.log("start time--->", startTime);
    console.log("end time --->", endTime);
    this.meet = {
      room_name: (<any>document.getElementById("meet-title")).value,
      room_uid: Math.trunc(Math.random() * 100000 + 1).toString(),
      usersids: arrtest,
      meetingType: "scheduled",
      creator: localStorage.getItem("username"),
      description: (<any>document.getElementById("meet-description")).value,
      st: startTime,
      ed: endTime,
    };

    console.log(this.meet);
    this.liveProjectService
      .createScheduledMeeting(this.meet)
      .subscribe((resp) => {
        console.log("scheduled meeting ", resp);
      });

    console.log(this.meetings);
    (<any>document.getElementById("start-time")).value = null;
    (<any>document.getElementById("end-time")).value = null;
    (<any>document.getElementById("start-date")).value = null;
    (<any>document.getElementById("end-date")).value = null;
  }

  onChangeSelect(e) {
    var values = $("#participants").val();
    console.log(values);

    //  var val=(<any>document.getElementsByClassName("filter-option-inner-inner"))[0];
    // //  console.log(val);
    //  var temp=$("#participant-group button").attr("title");
    //  $("#participant-group button")
    //  console.log(temp);
  }

  sortFunction(a, b) {
    let nameA = a.username.toUpperCase();
    let nameB = b.username.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }
}
