<div class="container"></div>
<div class="logo-wrapper" (click)="showChat()">
  <img class="rounded-circle" src="assets/samurais.png" alt="" />
</div>
@if (showChatBox) {
  <div class="chatbox-holder">
    <div class="chatbox group-chat chatbox1">
      <div class="chatbox-top chatbox-top1">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div><h5 class="m-0">Samurais</h5></div>
          <div (click)="showChatBox = !showChatBox" style="cursor: pointer">
            <img style="width: 15px" src="assets/close1.png" alt="" />
          </div>
        </div>
      </div>
      <hr />
      <!-- <div class="p-2">
      <hr>
        <input type="text" class="form-control" placeholder="search in chats">
      </div> -->
      <div id="chat-messages" class="chat-messages">
        @for (msg of groupedMessages; track msg) {
          <div style="display: contents">
            <div class="message-date d-flex justify-content-center w-100">
              <p>{{ msg.date | date : "MMM d, yyyy" }}</p>
            </div>
            @for (message of msg.messages; track message) {
              <div style="display: contents">
                @if (userId === message.sender_id) {
                  <div class="message-box-holder">
                    @if (isDifferentTimestamp(message)) {
                      <div
                        class="d-flex justify-content-end"
                        >
                        <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                      </div>
                    }
                    @if (message.message) {
                      <div class="message-box">
                        {{ message.message }}
                      </div>
                    }
                    <!-- <ng-container *ngIf="message.fileName">
                    <div class="message-box">
                      File Name : {{ message.fileName }}
                      <div class="message-time">
                        <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                      </div>
                    </div></ng-container
                    > -->
                    @if (message.url && isImage(message.url)) {
                      <div class="image-box-holder message-box">
                        <div class="image-box">
                          <img [src]="message.url" class="img-fluid" alt="Image" />
                        </div>
                      </div>
                    }
                    @if (message.url && isVideo(message.url)) {
                      <div class="video-box-holder message-box">
                        <div class="video-box">
                          <video
                            [src]="message.url"
                            class="embed-responsive embed-responsive-16by9"
                            controls
                          ></video>
                          <div class="message-time">
                            <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                          </div>
                        </div>
                      </div>
                    }
                    @if (message.url && isDocument(message.url)) {
                      <div class="document-box-holder message-box">
                        <div class="document-box download-doc">
                          <p>{{ message.url }}</p>
                          <a [href]="message.url">
                            <img src="../../../assets/dwnload-icon.svg" alt="" />
                          </a>
                        </div>
                        <div class="message-time">
                          <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                        </div>
                      </div>
                    }
                  </div>
                }
                @if (userId !== message.sender_id) {
                  <div class="message-box-holder">
                    @if (isDifferentSender(message)) {
                      <div
                        class="message-sender d-flex justify-content-between align-items-center"
                        >
                        <div class="d-flex align-items-center">
                          <img
                            [src]="message.profileImage"
                            class="rounded-circle"
                            alt=""
                            width="40px"
                            />
                          <p class="senderName text-capitalize ml-2 ">{{ message.name }}</p>
                        </div>
                        <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                      </div>
                    }
                    @if (message.message) {
                      <div class="message-box message-partner">
                        {{ message.message }}
                        <!-- <div class="d-flex justify-content-end">
                        <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                      </div> -->
                    </div>
                  }
                  @if (message.url && isImage(message.url)) {
                    <div class="image-box-holder message-box message-partner">
                      <div class="image-box">
                        <img [src]="message.url" class="img-fluid" alt="Image" />
                        <div class="message-time">
                          <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                        </div>
                      </div>
                    </div>
                  }
                  @if (message.url && isVideo(message.url)) {
                    <div class="video-box-holder message-box message-partner">
                      <div class="video-box">
                        <video
                          [src]="message.url"
                          class="embed-responsive embed-responsive-16by9"
                          controls
                        ></video>
                        <div class="message-time">
                          <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                        </div>
                      </div>
                    </div>
                  }
                  @if (message.url && isDocument(message.url)) {
                    <div class="document-box-holder message-box message-partner">
                      <div class="mess-box download-doc">
                        <p>{{ message.url }}</p>
                        <a [href]="message.url">
                          <img src="../../../assets/dwnload-icon.svg" />
                        </a>
                      </div>
                      <div class="message-time">
                        <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      }
      @if (isLoading) {
        <div
          class="d-flex justify-content-center flex-column align-items-center w-100"
          >
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <span class="uploading-progress">Uploading...</span>
        </div>
      }
    </div>
    <div>
      <div class="chat-input-holder">
        <textarea
          type="text"
          class="chat-input"
          [(ngModel)]="newMessage"
          (keydown.enter)="sendMessage()"
          (keydown)="handelKeyDown($event)"
          placeholder="Type a message"
        ></textarea>
        <div class="position-relative mx-3">
          <input
            type="file"
            (change)="handleFileInput($event)"
            (keydown.enter)="sendMessage()"
            /><img
            class="img-fluid img-icon"
            src="assets/upload-pic.png"
            alt=""
            />
        </div>
        <div class="cursorPointer">
          <img
            src="../../../assets/sendMessage.png"
            class="message-send message-send1"
            (click)="sendMessage()"
            />
        </div>
      </div>
    </div>
    <!-- <div class="attachment-panel">
    <a href="#" class="fa fa-thumbs-up"></a>
    <a href="#" class="fa fa-camera"></a>
    <a href="#" class="fa fa-video-camera"></a>
    <a href="#" class="fa fa-image"></a>
    <a href="#" class="fa fa-paperclip"></a>
    <a href="#" class="fa fa-link"></a>
    <a href="#" class="fa fa-trash-o"></a>
    <a href="#" class="fa fa-search"></a>
  </div> -->
</div>
</div>
}
