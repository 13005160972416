import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGaurdGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      localStorage.getItem("guestToken") &&
      localStorage.getItem("guestToken") != ""
    ) {
      return true;
    } else {
      console.log(
        "token not found --> returning to login --> authguard",
        localStorage.getItem("guestToken"),
        localStorage.getItem("fcm_token")
      );
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
