<div class="dashboard-banner" id="dashboardBanner">
  <div class="overview-content">
    <div class="overview-head">
      <h1>Overview</h1>
    </div>
    <div class="sales-month-filter">
      <div class="dropdown all-sales-name">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="all-sales-button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Select

          <svg
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.6"
              d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
              stroke="#092C4C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <div class="dropdown-menu" aria-labelledby="all-sales-button">
          <a class="dropdown-item"> About </a>
        </div>
      </div>
      <div class="dropdown all-month-sales">
        <div class="dashboard-month-type">
          <mat-form-field
            class="example-form-field"
            style="
              --mat-form-field-filled-with-label-container-padding-bottom: 0px;
              --mat-form-field-container-height: 0px;
              --mat-form-field-filled-with-label-container-padding-top: 0px;
              --mdc-filled-text-field-container-color: #fff;
              --mat-form-field-container-vertical-padding: 10px;
            "
          >
            <!-- <mat-label>Enter Date</mat-label> -->
            <mat-date-range-input [rangePicker]="rangePicker">
              <input matStartDate placeholder="Start date" />
              <input matEndDate placeholder="End date" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="rangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>Reset</button>
                <button
                  style="
                    --mdc-protected-button-container-color: #fecc28;
                    --mdc-protected-button-label-text-color: #000;
                  "
                  mat-raised-button
                  color="primary"
                  matDateRangePickerApply
                >
                  Apply
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="leads-overview-card">
    <div class="open-leads">
      <div class="open-leads-left">
        <h2>Open Leads</h2>
        <p class="open-leads-count">
          318<span class="percentage-count"
            ><svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.03142 0.212329C4.86454 0.211521 4.6974 0.279833 4.57735 0.417229L0.389659 5.20998C0.171955 5.45914 0.197455 5.83761 0.446614 6.05531C0.695774 6.27302 1.07424 6.24752 1.29194 5.99836L4.43521 2.40093L4.43521 10.3969C4.43521 10.7278 4.70344 10.996 5.03431 10.996C5.36518 10.996 5.6334 10.7278 5.6334 10.3969L5.6334 2.41081L8.77754 5.999C8.99559 6.24785 9.3741 6.27281 9.62295 6.05476C9.8718 5.8367 9.89676 5.4582 9.67871 5.20935L5.52956 0.474204C5.4217 0.31611 5.24013 0.212322 5.03431 0.212322C5.03334 0.212322 5.03238 0.212324 5.03142 0.212329Z"
                fill="#19BC55"
              />
            </svg>

            20.50%
          </span>
        </p>
        <p class="comparison-para">Compared to (271 last month)</p>
      </div>
      <div class="open-leads-right">
        <img src="/assets/crm/lead-overview-Icon.svg" alt="open leads" />
      </div>
    </div>
    <div class="open-leads">
      <div class="open-leads-left">
        <h2>New Leads Generated</h2>
        <p class="open-leads-count">
          318<span class="percentage-count"
            ><svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.03142 0.212329C4.86454 0.211521 4.6974 0.279833 4.57735 0.417229L0.389659 5.20998C0.171955 5.45914 0.197455 5.83761 0.446614 6.05531C0.695774 6.27302 1.07424 6.24752 1.29194 5.99836L4.43521 2.40093L4.43521 10.3969C4.43521 10.7278 4.70344 10.996 5.03431 10.996C5.36518 10.996 5.6334 10.7278 5.6334 10.3969L5.6334 2.41081L8.77754 5.999C8.99559 6.24785 9.3741 6.27281 9.62295 6.05476C9.8718 5.8367 9.89676 5.4582 9.67871 5.20935L5.52956 0.474204C5.4217 0.31611 5.24013 0.212322 5.03431 0.212322C5.03334 0.212322 5.03238 0.212324 5.03142 0.212329Z"
                fill="#19BC55"
              />
            </svg>

            20.50%
          </span>
        </p>
        <p class="comparison-para">Compared to (271 last month)</p>
      </div>
      <div class="open-leads-right">
        <img src="/assets/crm/lead-overview-Icon.svg" alt="open leads" />
      </div>
    </div>
    <div class="open-leads">
      <div class="open-leads-left">
        <h2>Recurring Leads</h2>
        <p class="open-leads-count">
          318<span class="percentage-count"
            ><svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.03142 0.212329C4.86454 0.211521 4.6974 0.279833 4.57735 0.417229L0.389659 5.20998C0.171955 5.45914 0.197455 5.83761 0.446614 6.05531C0.695774 6.27302 1.07424 6.24752 1.29194 5.99836L4.43521 2.40093L4.43521 10.3969C4.43521 10.7278 4.70344 10.996 5.03431 10.996C5.36518 10.996 5.6334 10.7278 5.6334 10.3969L5.6334 2.41081L8.77754 5.999C8.99559 6.24785 9.3741 6.27281 9.62295 6.05476C9.8718 5.8367 9.89676 5.4582 9.67871 5.20935L5.52956 0.474204C5.4217 0.31611 5.24013 0.212322 5.03431 0.212322C5.03334 0.212322 5.03238 0.212324 5.03142 0.212329Z"
                fill="#19BC55"
              />
            </svg>

            20.50%
          </span>
        </p>
        <p class="comparison-para">Compared to (271 last month)</p>
      </div>
      <div class="open-leads-right">
        <img src="/assets/crm/lead-overview-Icon.svg" alt="open leads" />
      </div>
    </div>
    <div class="open-leads">
      <div class="open-leads-left">
        <h2>Total Leads</h2>
        <p class="open-leads-count">
          318<span class="percentage-count"
            ><svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.03142 0.212329C4.86454 0.211521 4.6974 0.279833 4.57735 0.417229L0.389659 5.20998C0.171955 5.45914 0.197455 5.83761 0.446614 6.05531C0.695774 6.27302 1.07424 6.24752 1.29194 5.99836L4.43521 2.40093L4.43521 10.3969C4.43521 10.7278 4.70344 10.996 5.03431 10.996C5.36518 10.996 5.6334 10.7278 5.6334 10.3969L5.6334 2.41081L8.77754 5.999C8.99559 6.24785 9.3741 6.27281 9.62295 6.05476C9.8718 5.8367 9.89676 5.4582 9.67871 5.20935L5.52956 0.474204C5.4217 0.31611 5.24013 0.212322 5.03431 0.212322C5.03334 0.212322 5.03238 0.212324 5.03142 0.212329Z"
                fill="#19BC55"
              />
            </svg>

            20.50%
          </span>
        </p>
        <p class="comparison-para">Compared to (271 last month)</p>
      </div>
      <div class="open-leads-right">
        <img src="/assets/crm/lead-overview-Icon.svg" alt="open leads" />
      </div>
    </div>
  </div>

  <div class="follow-ups-banner">
    <div class="today-followup-content">
      <div class="common-follow">
        <h2>Today’s Follow Ups</h2>
        <ul>
          <li>Meeting with X-labs<span>12:30 PM - 01:00 PM</span></li>
          <li>UI UX Presentation<span>02:30 PM - 03:30 PM</span></li>
          <li>Project Understanding<span>04:00 PM - 04:30 PM</span></li>
          <li>Project Understanding<span>06:00 PM - 06:30 PM</span></li>
        </ul>
      </div>
    </div>
    <div class="closed-leads-container">
      <div class="closed-lead-head">
        <h2>Leads Closed this Month</h2>
        <p>View All</p>
      </div>
      <div class="closed-lead-detail">
        <div class="closing-lead">
          <div class="closing-date">
            <img src="/assets/crm/closed-time.svg" alt="closed-time" />
            <p>Closing:<span>Feb 05</span></p>
          </div>
          <p class="lead-name">Xlabs UI Project</p>
        </div>
        <div class="closed-customer-name">
          <p>Customer: <span>John Doe</span></p>
        </div>
        <div class="lead-creator-name">
          <p>Shruti</p>
        </div>
        <div class="closed-lead-status">
          <p>Qualified</p>
        </div>
      </div>
      <div class="closed-lead-detail">
        <div class="closing-lead">
          <div class="closing-date">
            <img src="/assets/crm/closed-time.svg" alt="closed-time" />
            <p>Closing:<span>Feb 05</span></p>
          </div>
          <p class="lead-name">Xlabs UI Project</p>
        </div>
        <div class="closed-customer-name">
          <p>Customer: <span>John Doe</span></p>
        </div>
        <div class="lead-creator-name">
          <p>Shruti</p>
        </div>
        <div class="closed-lead-status">
          <p>Qualified</p>
        </div>
      </div>
      <div class="closed-lead-detail">
        <div class="closing-lead">
          <div class="closing-date">
            <img src="/assets/crm/closed-time.svg" alt="closed-time" />
            <p>Closing:<span>Feb 05</span></p>
          </div>
          <p class="lead-name">Xlabs UI Project</p>
        </div>
        <div class="closed-customer-name">
          <p>Customer: <span>John Doe</span></p>
        </div>
        <div class="lead-creator-name">
          <p>Shruti</p>
        </div>
        <div class="closed-lead-status">
          <p>Qualified</p>
        </div>
      </div>
      <div class="closed-lead-detail">
        <div class="closing-lead">
          <div class="closing-date">
            <img src="/assets/crm/closed-time.svg" alt="closed-time" />
            <p>Closing:<span>Feb 05</span></p>
          </div>
          <p class="lead-name">Xlabs UI Project</p>
        </div>
        <div class="closed-customer-name">
          <p>Customer: <span>John Doe</span></p>
        </div>
        <div class="lead-creator-name">
          <p>Shruti</p>
        </div>
        <div class="closed-lead-status">
          <p>Qualified</p>
        </div>
      </div>
    </div>
  </div>

  <div class="lead-chart-banner">
    <div class="bar-chart-container">
      <div style="text-align: center">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [title]="chartOptions.title"
          [dataLabels]="chartOptions.dataLabels"
          [plotOptions]="chartOptions.plotOptions"
          [yaxis]="chartOptions.yaxis"
          [xaxis]="chartOptions.xaxis"
          [legend]="chartOptions.legend"
          [colors]="chartOptions.colors"
          [grid]="chartOptions.grid"
        ></apx-chart>
      </div>
    </div>
    <div class="customers-list-container">
      <div class="customer-head">
        <h2>Customers</h2>
        <p>View All</p>
      </div>
      <div class="common-customer">
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
      </div>
      <div class="common-customer">
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
      </div>
      <div class="common-customer">
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
      </div>
      <div class="common-customer">
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
      </div>
      <div class="common-customer">
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="call-meeting-followup-banner">
    <div class="new-call-container">
      <div class="new-call-head">
        <h2>Number of New Calls</h2>
        <div class="dropdown call-meeting-calendar" style="width: 37%">
          <mat-form-field
            class="example-form-field"
            style="
              --mat-form-field-filled-with-label-container-padding-bottom: 0px;
              --mat-form-field-container-height: 0px;
              --mat-form-field-filled-with-label-container-padding-top: 0px;
              --mdc-filled-text-field-container-color: #fff;
              --mat-form-field-container-vertical-padding: 10px;
            "
          >
            <!-- <mat-label>Enter Date</mat-label> -->
            <mat-date-range-input [rangePicker]="rangePicker">
              <input matStartDate placeholder="Start date" />
              <input matEndDate placeholder="End date" />
            </mat-date-range-input>

            <mat-datepicker-toggle
              matIconSuffix
              [for]="rangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>Reset</button>
                <button
                  style="
                    --mdc-protected-button-container-color: #fecc28;
                    --mdc-protected-button-label-text-color: #000;
                  "
                  mat-raised-button
                  color="primary"
                  matDateRangePickerApply
                >
                  Apply
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>

      <p class="calls-count">
        125
        <span class="percentage-count"
          ><svg
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.03142 0.212329C4.86454 0.211521 4.6974 0.279833 4.57735 0.417229L0.389659 5.20998C0.171955 5.45914 0.197455 5.83761 0.446614 6.05531C0.695774 6.27302 1.07424 6.24752 1.29194 5.99836L4.43521 2.40093L4.43521 10.3969C4.43521 10.7278 4.70344 10.996 5.03431 10.996C5.36518 10.996 5.6334 10.7278 5.6334 10.3969L5.6334 2.41081L8.77754 5.999C8.99559 6.24785 9.3741 6.27281 9.62295 6.05476C9.8718 5.8367 9.89676 5.4582 9.67871 5.20935L5.52956 0.474204C5.4217 0.31611 5.24013 0.212322 5.03431 0.212322C5.03334 0.212322 5.03238 0.212324 5.03142 0.212329Z"
              fill="#19BC55"
            />
          </svg>

          20.50%
        </span>
      </p>
      <div class="call-title">
        <p>BRANDS/CLIENTS</p>
      </div>
      <div class="common-customer">
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="new-call-container">
      <div class="new-call-head">
        <h2>New 1 On 1 Meeting</h2>
        <div class="dropdown call-meeting-calendar" style="width: 37%">
          <mat-form-field
            class="example-form-field"
            style="
              --mat-form-field-filled-with-label-container-padding-bottom: 0px;
              --mat-form-field-container-height: 0px;
              --mat-form-field-filled-with-label-container-padding-top: 0px;
              --mdc-filled-text-field-container-color: #fff;
              --mat-form-field-container-vertical-padding: 10px;
            "
          >
            <!-- <mat-label>Enter Date</mat-label> -->
            <mat-date-range-input [rangePicker]="rangePicker">
              <input matStartDate placeholder="Start date" />
              <input matEndDate placeholder="End date" />
            </mat-date-range-input>

            <mat-datepicker-toggle
              matIconSuffix
              [for]="rangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>Reset</button>
                <button
                  style="
                    --mdc-protected-button-container-color: #fecc28;
                    --mdc-protected-button-label-text-color: #000;
                  "
                  mat-raised-button
                  color="primary"
                  matDateRangePickerApply
                >
                  Apply
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>

      <p class="calls-count">
        125
        <span class="percentage-count"
          ><svg
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.03142 0.212329C4.86454 0.211521 4.6974 0.279833 4.57735 0.417229L0.389659 5.20998C0.171955 5.45914 0.197455 5.83761 0.446614 6.05531C0.695774 6.27302 1.07424 6.24752 1.29194 5.99836L4.43521 2.40093L4.43521 10.3969C4.43521 10.7278 4.70344 10.996 5.03431 10.996C5.36518 10.996 5.6334 10.7278 5.6334 10.3969L5.6334 2.41081L8.77754 5.999C8.99559 6.24785 9.3741 6.27281 9.62295 6.05476C9.8718 5.8367 9.89676 5.4582 9.67871 5.20935L5.52956 0.474204C5.4217 0.31611 5.24013 0.212322 5.03431 0.212322C5.03334 0.212322 5.03238 0.212324 5.03142 0.212329Z"
              fill="#19BC55"
            />
          </svg>

          20.50%
        </span>
      </p>
      <div class="call-title">
        <p>BRANDS/CLIENTS</p>
      </div>
      <div class="common-customer">
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="new-call-container">
      <div class="new-call-head">
        <h2>Follow Ups</h2>
        <div class="dropdown call-meeting-calendar" style="width: 37%">
          <mat-form-field
            class="example-form-field"
            style="
              --mat-form-field-filled-with-label-container-padding-bottom: 0px;
              --mat-form-field-container-height: 0px;
              --mat-form-field-filled-with-label-container-padding-top: 0px;
              --mdc-filled-text-field-container-color: #fff;
              --mat-form-field-container-vertical-padding: 10px;
            "
          >
            <!-- <mat-label>Enter Date</mat-label> -->
            <mat-date-range-input [rangePicker]="rangePicker">
              <input matStartDate placeholder="Start date" />
              <input matEndDate placeholder="End date" />
            </mat-date-range-input>

            <mat-datepicker-toggle
              matIconSuffix
              [for]="rangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>Reset</button>
                <button
                  style="
                    --mdc-protected-button-container-color: #fecc28;
                    --mdc-protected-button-label-text-color: #000;
                  "
                  mat-raised-button
                  color="primary"
                  matDateRangePickerApply
                >
                  Apply
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>

      <p class="calls-count">
        125
        <span class="percentage-count"
          ><svg
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.03142 0.212329C4.86454 0.211521 4.6974 0.279833 4.57735 0.417229L0.389659 5.20998C0.171955 5.45914 0.197455 5.83761 0.446614 6.05531C0.695774 6.27302 1.07424 6.24752 1.29194 5.99836L4.43521 2.40093L4.43521 10.3969C4.43521 10.7278 4.70344 10.996 5.03431 10.996C5.36518 10.996 5.6334 10.7278 5.6334 10.3969L5.6334 2.41081L8.77754 5.999C8.99559 6.24785 9.3741 6.27281 9.62295 6.05476C9.8718 5.8367 9.89676 5.4582 9.67871 5.20935L5.52956 0.474204C5.4217 0.31611 5.24013 0.212322 5.03431 0.212322C5.03334 0.212322 5.03238 0.212324 5.03142 0.212329Z"
              fill="#19BC55"
            />
          </svg>

          20.50%
        </span>
      </p>
      <div class="call-title">
        <p>BRANDS/CLIENTS</p>
      </div>
      <div class="common-customer">
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
        <div class="contact-content">
          <div class="customer-title">
            <p>Coen Janssen</p>
          </div>
          <div class="customer-contact">
            <p>
              <img src="../../../assets/crm/email_icon.svg" alt="email" />
              mailabc.com
            </p>
            <p>
              <img src="../../../assets/crm/phone_icon.svg" alt="email" />(91)
              123 456 7890
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="emails-proposal-banner">
    <div class="emails-sent-container">
      <div class="new-call-head">
        <h2>Emails / Proposals sent</h2>
        <div class="dropdown call-meeting-calendar" style="width: 24%">
          <mat-form-field
            class="example-form-field"
            style="
              --mat-form-field-filled-with-label-container-padding-bottom: 0px;
              --mat-form-field-container-height: 0px;
              --mat-form-field-filled-with-label-container-padding-top: 0px;
              --mdc-filled-text-field-container-color: #fff;
              --mat-form-field-container-vertical-padding: 10px;
            "
          >
            <!-- <mat-label>Enter Date</mat-label> -->
            <mat-date-range-input [rangePicker]="rangePicker">
              <input matStartDate placeholder="Start date" />
              <input matEndDate placeholder="End date" />
            </mat-date-range-input>

            <mat-datepicker-toggle
              matIconSuffix
              [for]="rangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>Reset</button>
                <button
                  style="
                    --mdc-protected-button-container-color: #fecc28;
                    --mdc-protected-button-label-text-color: #000;
                  "
                  mat-raised-button
                  color="primary"
                  matDateRangePickerApply
                >
                  Apply
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>
      <p class="calls-count">
        68
        <span class="percentage-count"
          ><svg
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.03142 0.212329C4.86454 0.211521 4.6974 0.279833 4.57735 0.417229L0.389659 5.20998C0.171955 5.45914 0.197455 5.83761 0.446614 6.05531C0.695774 6.27302 1.07424 6.24752 1.29194 5.99836L4.43521 2.40093L4.43521 10.3969C4.43521 10.7278 4.70344 10.996 5.03431 10.996C5.36518 10.996 5.6334 10.7278 5.6334 10.3969L5.6334 2.41081L8.77754 5.999C8.99559 6.24785 9.3741 6.27281 9.62295 6.05476C9.8718 5.8367 9.89676 5.4582 9.67871 5.20935L5.52956 0.474204C5.4217 0.31611 5.24013 0.212322 5.03431 0.212322C5.03334 0.212322 5.03238 0.212324 5.03142 0.212329Z"
              fill="#19BC55"
            />
          </svg>

          20.50%
        </span>
      </p>
      <div class="emails-content-list">
        <div class="email-detail">
          <h2>Jack & Jones India</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt
          </p>
        </div>
        <div class="email-detail">
          <h2>Jack & Jones India</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt
          </p>
        </div>
        <div class="email-detail">
          <h2>Jack & Jones India</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt
          </p>
        </div>
      </div>
    </div>
    <div class="emails-sent-container">
      <div class="new-call-head">
        <h2>New Pitch Created</h2>
        <div class="dropdown call-meeting-calendar" style="width: 24%">
          <mat-form-field
            class="example-form-field"
            style="
              --mat-form-field-filled-with-label-container-padding-bottom: 0px;
              --mat-form-field-container-height: 0px;
              --mat-form-field-filled-with-label-container-padding-top: 0px;
              --mdc-filled-text-field-container-color: #fff;
              --mat-form-field-container-vertical-padding: 10px;
            "
          >
            <!-- <mat-label>Enter Date</mat-label> -->
            <mat-date-range-input [rangePicker]="rangePicker">
              <input matStartDate placeholder="Start date" />
              <input matEndDate placeholder="End date" />
            </mat-date-range-input>

            <mat-datepicker-toggle
              matIconSuffix
              [for]="rangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>Reset</button>
                <button
                  style="
                    --mdc-protected-button-container-color: #fecc28;
                    --mdc-protected-button-label-text-color: #000;
                  "
                  mat-raised-button
                  color="primary"
                  matDateRangePickerApply
                >
                  Apply
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>
      <p class="calls-count">
        79
        <span class="percentage-count"
          ><svg
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.03142 0.212329C4.86454 0.211521 4.6974 0.279833 4.57735 0.417229L0.389659 5.20998C0.171955 5.45914 0.197455 5.83761 0.446614 6.05531C0.695774 6.27302 1.07424 6.24752 1.29194 5.99836L4.43521 2.40093L4.43521 10.3969C4.43521 10.7278 4.70344 10.996 5.03431 10.996C5.36518 10.996 5.6334 10.7278 5.6334 10.3969L5.6334 2.41081L8.77754 5.999C8.99559 6.24785 9.3741 6.27281 9.62295 6.05476C9.8718 5.8367 9.89676 5.4582 9.67871 5.20935L5.52956 0.474204C5.4217 0.31611 5.24013 0.212322 5.03431 0.212322C5.03334 0.212322 5.03238 0.212324 5.03142 0.212329Z"
              fill="#19BC55"
            />
          </svg>

          20.50%
        </span>
      </p>
      <div class="emails-content-list">
        <div class="email-detail">
          <h2>Jack & Jones India</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt
          </p>
        </div>
        <div class="email-detail">
          <h2>Jack & Jones India</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt
          </p>
        </div>
        <div class="email-detail">
          <h2>Jack & Jones India</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
