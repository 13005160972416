import { Component } from "@angular/core";
import { HeaderComponent } from "../header/header.component";
import { LeadsComponent } from "./leads/leads.component";
import { RouterOutlet, Router } from "@angular/router";

import { DashboardComponent } from "./dashboard/dashboard.component";
@Component({
  selector: "app-crm",
  templateUrl: "./crm.component.html",
  styleUrls: ["./crm.component.css"],
  standalone: true,
  providers: [],
  imports: [HeaderComponent, LeadsComponent, DashboardComponent, RouterOutlet],
})
export class CrmComponent {
  constructor(private router: Router) {}
  selectMenu(menu: string) {
    this.router.navigate(["/crm/dashboard"]);
  }
  selectLead(lead: string) {
    this.router.navigate(["/crm/leads"]);
  }
}
