import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveProjectService {

  constructor(private httpClient:HttpClient) { }

  // instantMeetingLink;
  // instantMeetingID;
  notifications;
  activeInstantMeeting;


  post_create_live_project_url=environment.host+"meetings";
  get_live_project_list_url=environment.host+"meetings";
  get_single_live_project_by_id_url=environment.host + "meetings/" // "meetings/id"
  put_update_live_project_by_id_url=environment.host + "meetings/" // "meetings/id"
  post_live_conference_url=environment.host+"conference";
  get_notifications_url=environment.host+"instant";
  get_instant_notification_url=environment.host+"inst?";
  get_scheduled_notification_url=environment.host+"sched?";
  post_create_scheduled_meeting=environment.host+"schedconference"

  createNewProject(bodyData:{title:string,description:string,notion:string,slack:string}){
    var body={
      user_id:localStorage.getItem("guestID"),
      title:bodyData.title,
      description:bodyData.description,
      notion:bodyData.notion,
      slack:bodyData.slack
    }
    console.log("service body",body);
    return this.httpClient.post(this.post_create_live_project_url,body);
  }

  updateProject(bodyData:{title:string,description:string,notion:string,slack:string},id){
    var body={
      title:bodyData.title,
      description:bodyData.description,
      notion:bodyData.notion,
      slack:bodyData.slack
    }
    console.log("service body",body);
    return this.httpClient.put(this.put_update_live_project_by_id_url+id,body);
  }



  getAllLiveProjects(){
    return this.httpClient.get(this.get_live_project_list_url);
  }

  getSingleLiveProjectByID(id){
    return this.httpClient.get(this.get_single_live_project_by_id_url+id);
  }


  createInstantMeeting(bodyData:{creator:string,room_name:string,meetingType:string,room_uid:string,users:{_id:string}[]}){
    console.log(bodyData);
    return this.httpClient.post(this.post_live_conference_url,bodyData);

  }

  addToInstantMeeting(bodyData){
    console.log(bodyData);
    return this.httpClient.post(this.post_live_conference_url,bodyData);

  }

  createScheduledMeeting(bodyData:
    {
      room_name:string,
      room_uid:string,
      usersids:string[],
      meetingType:string,
      creator:string,
      description:string,
      st:string,
      ed:string
  }
  ){
    console.log(bodyData);
    return this.httpClient.post(this.post_create_scheduled_meeting,bodyData); 
  }

  getNotifications(){
    return this.httpClient.get(this.get_notifications_url);
  }

  getInstantNotificationsAsync(limit:number=10,page:number=1){
    return new Promise(async (resolve, reject) => {
      
   resolve(this.httpClient.get(this.get_instant_notification_url+`limit=${limit}&page=${page}`).toPromise())
  });
  }

  getInstantNotifications(limit:number=10,page:number=1){
      return this.httpClient.get(this.get_instant_notification_url+`limit=${limit}&page=${page}`)
  }

  getScheduledNotifications(limit:number=10,page:number=1){
    return this.httpClient.get(this.get_scheduled_notification_url+`limit=${limit}&page=${page}`);
  }


}
