import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SearchHistoryService {
  private readonly STORAGE_KEY = "search_history";

  constructor() {}

  getSearchHistory(): string[] {
    const history = localStorage.getItem(this.STORAGE_KEY);
    return history ? JSON.parse(history) : [];
  }

  addToHistory(query: string): void {
    const history = this.getSearchHistory();
    history.push(query);
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(history));
  }

  clearHistory(): void {
    localStorage.removeItem(this.STORAGE_KEY);
  }

  getMostRecentSearch(): string | null {
    const history = this.getSearchHistory();
    return history.length > 0 ? history[history.length - 1] : null;
  }
  updateHistory(updatedHistory: string[]): void {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(updatedHistory));
  }

  getRecentFiveTags(): string[] {
    const history = this.getSearchHistory();
    const startIndex = Math.max(history.length - 5, 0);
    return history.slice(startIndex);
  }
}
