import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { MediaService } from "src/app/service/media.service";
import { saveAs } from "file-saver";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpEventType } from "@angular/common/http";
import * as JSZip from "jszip";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs/operators";

import {
  alertModalDelete,
  alertModal,
  alertModalWithoutConfirm,
} from "../helpers/alert";

import { Inject } from "@angular/core";
import { DownloadService } from "../../download.service";
import { Download } from "../../download";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { DOCUMENT, NgClass, AsyncPipe, DatePipe } from "@angular/common";
import { UserService } from "../../service/user.service";
import { DataFilterService } from "src/app/data-filter.service";
import { FooterComponent } from "../footer/footer.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";

declare let Swiper: any;
declare var $: any; // Declare jQuery

@Component({
  selector: "app-single-project",
  templateUrl: "./single-project.component.html",
  styleUrls: ["./single-project.component.css"],
  standalone: true,
  imports: [
    NgClass,
    MatProgressBarModule,
    FooterComponent,
    AsyncPipe,
    DatePipe,
  ],
})
export class SingleProjectComponent implements OnInit {
  swiper: any;
  mediaForm: FormGroup;
  id: string;
  cardData: any;
  tags;
  title;
  description;
  videoUrl_lg;
  image_Url;

  videoUrl_md;
  videoUrl_md_wm;
  videoUrl_sm;
  videoUrl_sm_wm;
  filesUrl;
  additionalFiles = [];
  filesUrlLen;
  fileNameBased: any;
  pdfDisplay: string = "none";
  pdfUrl: string = "";
  pdf_Url: boolean = false;
  excel_Url: boolean = false;
  pptx_Url: boolean = false;
  imageUrls: any;
  Resolution: string;
  isResolutionVisible: boolean = false;
  thumbnail: string;
  videoImageToggle: boolean = false;
  createdAt: any;
  clientName: string;
  @ViewChild(SlickCarouselComponent, { static: false })
  slickModal: SlickCarouselComponent;
  httpClient: any;
  // videoPlaybackAvailable: any;
  videoUrl_lg_wm;
  PlaybackAvailable: any;
  defaultThumbnail = "";
  docUrl = "";
  constructor(
    private mediaService: MediaService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private downloads: DownloadService,
    private UserService: UserService,
    private dataFilter: DataFilterService,

    @Inject(DOCUMENT) private document: Document
  ) {}

  playAccess: boolean = false;
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    // this.getOne();
    this.getAllUserList();
    this.muteVideoOnScroll();
    this.getAllPlaylist();
    $(".modal-backdrop").remove();

    // let getLink = localStorage.getItem("guestLink");
    // if (getLink) {
    //   console.log("now testing with best ");
    //   let floatRight = document.getElementById("float-right");
    //   floatRight.style.display = "none";
    //   let nav = document.getElementById("navbarMenu");
    //   nav.style.setProperty("display", "none", "important");
    //   let form = document.getElementById("formDis");
    //   form.style.setProperty("display", "none", "important");
    //   let online = document.getElementById("onlineUser");
    //   online.style.setProperty("display", "none", "important");
    // }

    // this.dataFilter.disableUser.subscribe((item) => {
    //   console.log("===========++++++++++++++====================", item);
    //   if (item == "playlist-user-login") {
    //     this.playAccess = true;
    //     console.log("pr", item);
    //   }
    // });
  }
  // filee: any;
  // isPptx(filee: string): boolean {
  //   const fileExtension = filee.split(".").pop()?.toLowerCase();
  //   return fileExtension === ".pptx";
  // }
  // isExcel(filee: string): boolean {
  //   const fileExtension = filee.split(".").pop()?.toLowerCase();
  //   return fileExtension === ".xlsx";
  // }

  swiperInstance: any;
  ngAfterViewInit() {
    setTimeout(() => {
      this.swiper = new Swiper(".swiper-container1", {
        slidesPerView: "auto",
        spaceBetween: 10,
        freeMode: false,
        loop: true,
        centerInsufficientSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          dynamicBullets: false,
        },
      });
    }, 1000);
  }
  backToCollection() {
    history.back();
  }
  allUsers: any = [];
  getAllUserList() {
    this.UserService.guestList().subscribe(
      (res) => {
        let data = res["data"];
        this.getOne();
        data.map((user) => {
          this.allUsers.push({ username: user.username, phone: user.phoneNo });
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  downloadOptionAvailable = false;
  selectedImageUrl;
  slideConfig = { slidesToShow: 7, slidesToScroll: 1 };
  slideConfig1 = { slidesToShow: 1, slidesToScroll: 1, initialSlide: 0 };

  slickInit(e) {
    this.id = this.route.snapshot.paramMap.get("id");
  }

  guestId = localStorage.getItem("guestID");
  userId: string;
  getOne() {
    this.mediaService.getOneByMediaId(this.id).subscribe(
      (res: any) => {
        console.log("Response:", res);
        if (!res.data.PlaybackAvailable) {
          console.log("data", res.data.PlaybackAvailable);
          this.PlaybackAvailable = false;
        } else {
          this.PlaybackAvailable = true;
        }
        console.log(res.data);
        this.additionalFiles = res.data["addtionalFiles"];
        console.log("files for the extras", this.additionalFiles, res);
        if (res.data.addtionalFiles) {
          const processedUrls = res.data.addtionalFiles.map(({ fileUrl }) => {
            console.log({ fileUrl });
            if (!fileUrl.startsWith("https://")) {
              return "https://" + fileUrl;
            }
            return fileUrl;
          });
          this.imageUrls = processedUrls;
        }
        this.cardData = res.data;
        if (this.cardData["tags"]) {
          this.tags = this.cardData["tags"].filter((tag) => tag !== "");
        }
        this.title = this.cardData["title"];
        this.userId = this.cardData["userId"];
        this.description = this.cardData["description"];
        if (res.data["PlaybackAvailable"]) {
          console.log("video play back", res.data.PlaybackAvailable);
          this.PlaybackAvailable = this.cardData["PlaybackAvailable"];
          console.log(this.PlaybackAvailable);
        } else {
          this.PlaybackAvailable = true;
        }

        if (this.cardData["addtionalFiles"]) {
          const processedUrls = this.cardData["addtionalFiles"].map((url) => {
            if (!url.fileUrl.startsWith("https://")) {
              return "https://" + url;
            }
            return url.fileUrl;
          });
          this.filesUrl = processedUrls;
        }
        this.thumbnail = this.cardData["thumbnails"];
        this.filesUrlLen = this.filesUrl.length > 0;
        console.log(this.cardData.extension, "exttt");
        if (
          this.cardData.extension == ".mp4" ||
          this.cardData.extension == ".webm" ||
          this.cardData.extension == ".mov" ||
          this.cardData.extension == ".mkv"
        ) {
          // this.defaultThumbnail =
          //   "../../../assets/Archive_files/video-icon.png";
          this.videoUrl_lg = this.cardData["highQuality"];
          var video = document.getElementById("video");
          // this.fileNameBased = this.videoUrl_lg.split("/").pop();
          // video.setAttribute("src", this.videoUrl_lg);
          if (this.videoUrl_lg) {
            this.fileNameBased = this.videoUrl_lg.substring(
              this.videoUrl_lg.lastIndexOf("/") + 1
            );
            if (video) video.setAttribute("src", this.videoUrl_lg);
          }
        }

        if (
          this.cardData.extension == ".jpeg" ||
          this.cardData.extension == ".png" ||
          this.cardData.extension == ".jpg"
        ) {
          this.image_Url = this.cardData["ogFileUrl"];
          console.log(this.cardData["ogFileUrl"]);
          this.fileNameBased = this.image_Url.substring(
            this.image_Url.lastIndexOf("/") + 1
          );
        }

        if (
          this.cardData.extension == ".pdf" ||
          this.cardData.extension == ".js" ||
          this.cardData.extension == ".git" ||
          this.cardData.extension == ".html" ||
          this.cardData.extension == ".angular"
        ) {
          this.pdfUrl = this.cardData.ogFileUrl;
          this.fileNameBased = this.pdfUrl.substring(
            this.pdfUrl.lastIndexOf("/") + 1
          );

          if (this.cardData.extension == ".js") {
            this.pdfUrl = this.cardData.ogFileUrl.replace(/^https?:\/\//, "");
            this.fileNameBased = this.pdfUrl.substring(
              this.pdfUrl.lastIndexOf("/") + 1
            );
          }
          this.defaultThumbnail = "../../../assets/Archive_files/pdf.png";
          this.pdfDisplay = this.pdfDisplay === "none" ? "inline" : "none";
          this.pdf_Url = true;
        }

        if ([".xlsx", ".xls"].includes(this.cardData.extension)) {
          this.defaultThumbnail =
            "../../../assets/Archive_files/excel-icon.png";
          this.pdfUrl = this.cardData.ogFileUrl;
          this.excel_Url = true;
          this.pdfDisplay = this.pdfDisplay === "none" ? "inline" : "none";
          this.fileNameBased = this.pdfUrl.substring(
            this.pdfUrl.lastIndexOf("/") + 1
          );
        }

        if (this.cardData.extension == ".pptx") {
          this.defaultThumbnail = "../../../assets/Archive_files/ppt-icon.png";
          this.pdfUrl = this.cardData.ogFileUrl;
          this.pptx_Url = true;
          this.pdfDisplay = this.pdfDisplay === "none" ? "inline" : "none";
          this.fileNameBased = this.pdfUrl.substring(
            this.pdfUrl.lastIndexOf("/") + 1
          );
        }
        if ([".doc", ".docx"].includes(this.cardData.extension)) {
          this.defaultThumbnail = "../../../assets/docx-img.png";
          this.pdfUrl = this.cardData.ogFileUrl;
          this.pptx_Url = true;
          this.pdfDisplay = this.pdfDisplay === "none" ? "inline" : "none";
          this.fileNameBased = this.pdfUrl.substring(
            this.pdfUrl.lastIndexOf("/") + 1
          );
        }

        this.videoUrl_lg_wm = this.cardData["highQualityWM"];
        this.videoUrl_md = this.cardData["md"];
        // this.videoUrl_md_wm = this.cardData["md_wm"];
        // this.videoUrl_sm = this.cardData["sm"];
        // this.videoUrl_sm_wm = this.cardData["sm_wm"];
        this.filesUrl = this.cardData["addtionalFiles"];
        this.filesUrlLen = this.filesUrl.length > 0;
      },

      (error: any) => {
        console.error("Error:", error);
      }
    );
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  afterChange(e) {
    console.log("afterChange");
  }

  beforeChange(e) {
    console.log("beforeChange");
  }
  activeDiv: string | null = null;
  downloadFile(link, name, res = "1080p") {
    this.startDownload(link, name, res);
  }
  isDownLoad: boolean = false;
  startDownload(link, name, res) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";

    xhr.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        this.isDownLoad = true;
        const percentage = (event.loaded / event.total) * 100;
        const downloadProgress = document.getElementById(
          "downloadProgress"
        ) as HTMLProgressElement;
        downloadProgress.value = percentage;
      }
    });

    xhr.addEventListener("load", () => {
      if (xhr.status === 200) {
        const blob = new Blob([xhr.response], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        this.isDownLoad = false;
        const a = document.createElement("a");
        a.href = url;
        a.download = name + "_" + res + ".mp4";
        a.click();

        window.URL.revokeObjectURL(url);
      }
    });

    xhr.open("GET", link, true);
    xhr.send();
  }

  downloadOption(type) {
    if (type === "video") {
      const a = document.createElement("a");
      a.href = this.cardData["ogFileUrl"];
      a.download = this.cardData["ogFileUrl"];
      console.log(this.cardData["ogFileUrl"]);
      a.click();
    } else if (type === "image") {
      const imageElement = document.getElementById("image") as HTMLImageElement;
      const imageUrl = imageElement.src;
      const a = document.createElement("a");
      a.href = imageUrl;
      a.download = imageUrl;
      a.click();
    } else if (type === "pdf") {
      const a = document.createElement("a");
      a.href = this.cardData["ogFileUrl"];
      a.download = this.cardData["ogFileUrl"];
      console.log(this.cardData["ogFileUrl"]);
      a.click();
    } else if (type === "excel") {
      const a = document.createElement("a");
      a.href = this.cardData["ogFileUrl"];
      a.download = this.cardData["ogFileUrl"];
      a.click();
    } else if (type === "pptx") {
      const a = document.createElement("a");
      a.href = this.cardData["ogFileUrl"];
      a.download = this.cardData["ogFileUrl"];
      a.click();
    } else if (type === "doc") {
      const a = document.createElement("a");
      a.href = this.cardData["ogFileUrl"];
      a.download = this.cardData["ogFileUrl"];
      a.click();
    }
    this.downloadOptionAvailable = !this.downloadOptionAvailable;
  }
  imageSin: boolean = true;
  fileName: any;
  onClickImage(url: string, index: number): void {
    this.swiperInstance = new Swiper(".swiper-container", {
      pagination: ".swiper-pagination",
      paginationClickable: true,
      slidesPerView: 1,
      autoplay: 3000,
      spaceBetween: 30,
      direction: "horizontal",
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        960: {
          slidesPerView: 1,
        },
      },
    });
    this.selectedImageUrl = url;
    const filename = url.substring(url.lastIndexOf("/") + 1);
    let url1 = this.imageUrls[index];
    this.fileName = filename;
    console.log(url, "DOCUMENT");
    // this.openModal(index);
    this.selectedImageUrl = url;
    if (this.selectedImageUrl) {
      let video = document.getElementById("vidoeCheck") as HTMLVideoElement;
    }

    setTimeout(() => {
      this.swiperInstance.slideTo(index);
    }, 400);
  }

  details: any;
  deleteMedia(id: string) {
    alertModalDelete("You won't be able to revert this!").then(
      (isConfirmed) => {
        if (isConfirmed) {
          this.mediaService.deleteOnyById(id).subscribe(
            (res) => {
              console.log(res);
              setTimeout(() => {
                this.router.navigate(["/collection"]);
              }, 2000);
            },
            (error) => {
              console.error(error);
              const message = error.error.message || "Something went wrong!";
              alertModalDelete(message);
            }
          );
        } else {
          alertModalDelete("Deletion cancelled");
        }
      }
    );
  }

  downloadImagesAll: any;

  // async downloadFileAll(): Promise<File[]> {
  //   console.log("=========: ", this.cardData.addtionalFiles)
  //   const downloadPromises = this.cardData.addtionalFiles.map(async ({ fileUrl }) => {
  //     console.log({fileUrl})
  //     const response = await fetch(fileUrl);
  //     const blob = await response.blob();
  //     const filename = fileUrl.split("/").pop();
  //     return new File([blob], filename, { type: blob.type });
  //   });
  //   return Promise.all(downloadPromises);
  // }

  // async downloadZip() {
  //   console.log("download zip file");
  //   const filesArray = await this.downloadFileAll();
  //   const zip = new JSZip();
  //   const imageFolder = zip.folder("images");
  //   filesArray.forEach((file) => {
  //     imageFolder.file(file.name, file);
  //   });
  //   zip.generateAsync({ type: "blob" }).then((content) => {
  //     saveAs(content, "images.zip");
  //   });
  // }

  async downloadFileAll() {
    console.log({ allCardData: this.cardData?.addtionalFiles });
    console.log({ type: typeof this.cardData.addtionalFiles });

    const { fileUrl } = this.cardData?.addtionalFiles[0];
    // const response = await fetch(fileUrl);
    // const blob = await response.blob();
    // const filename = fileUrl.split("/").pop();
    // return [{filename, blob}]
    console.log(this.cardData?.addtionalFiles);
    // this.cardData?.addtionalFiles.forEach(({ fileUrl }) => {
    //   window.open(fileUrl);
    // });

    // if(this.cardData?.addtionalFiles){
    //   console.log(this.cardData?.addtionalFiles)
    //   const downloadResouce = this.cardData?.addtionalFiles.map(async ({fileUrl}, idx)=>{
    //     console.log({fileUrl})
    //     const response = await fetch(fileUrl);
    //     console.log(response + "kkkkkkkkkkkkkkkkkkkkkkkkk")
    //       const blob = await response.blob();
    //       console.log(blob)
    //       const filename = fileUrl.split("/").pop();
    //       console.log(filename)
    //       return { filename, blob };
    //   })
    // return downloadResouce;

    // }
    return this.cardData?.addtionalFiles;
  }
  async downloadZip() {
    console.log("download zip file");
    try {
      const filesArray = await this.downloadFileAll();
      console.log("Files Array:", filesArray); // Log filesArray to inspect its structure

      const zip = new JSZip();
      const imageFolder = zip.folder("images");

      for (const file of filesArray) {
        try {
          const response = await fetch(file.fileUrl);
          if (!response.ok) {
            throw new Error(`Failed to fetch ${file.fileUrl}`);
          }
          const blob = await response.blob();
          console.log("File:", file); // Log the file object to check its structure
          console.log("Blob:", blob); // Log the blob object to check its structure
          if (blob) {
            // Check file type by its extension
            const fileExtension = file.fileUrl.split(".").pop().toLowerCase();
            const supportedTypes = [
              "jpg",
              "jpeg",
              "png",
              "gif",
              "bmp",
              "tiff",
              "doc",
              "docx",
              "pdf",
              "txt",
            ];
            if (supportedTypes.includes(fileExtension)) {
              imageFolder.file(file.fileUrl, blob);
            } else {
              console.warn("Unsupported file type:", file.fileUrl);
            }
          } else {
            console.warn("Invalid blob object:", blob);
          }
        } catch (error) {
          console.error("Error processing file:", error);
        }
      }

      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "images.zip");
      });
    } catch (error) {
      console.error("Error downloading files and creating zip:", error);
    }
  }

  isImage(url: any): boolean {
    let newUrl = "";
    if (typeof url === "string") {
      newUrl = url;
    } else {
      newUrl = url && url.fileUrl ? url.fileUrl : "";
    }
    return (
      newUrl.endsWith(".jpg") ||
      newUrl.endsWith(".png") ||
      newUrl.endsWith(".gif") ||
      newUrl.endsWith(".jpeg")
    );
  }

  fileTypeImages: { [key: string]: string } = {
    doc: "../../../assets/docx-img.png",
    docx: "../../../assets/docx-img.png",
    xls: "../../../assets/excl-img.jpg",
    xlsx: "../../../assets/excl-img.jpg",
    pdf: "../../../assets/Archive_files/pdf.png",
    pptx: "../../../assets/ppt-file-img.png",
  };

  isDocument(url: any): boolean {
    let newUrl = "";
    this.docUrl = url;
    if (typeof url === "string") {
      newUrl = url;
    } else {
      newUrl = url && url.fileUrl ? url.fileUrl : "";
    }
    return (
      newUrl.endsWith(".doc") ||
      newUrl.endsWith(".docx") ||
      newUrl.endsWith(".xls") ||
      newUrl.endsWith(".xlsx") ||
      newUrl.endsWith(".pdf") ||
      newUrl.endsWith(".pptx")
    );
  }

  isVideo(url: any): boolean {
    let newUrl = "";
    if (typeof url === "string") {
      newUrl = url;
    } else {
      newUrl = url && url.fileUrl ? url.fileUrl : "";
    }
    return (
      newUrl.endsWith(".mp4") ||
      newUrl.endsWith(".avi") ||
      newUrl.endsWith(".webm") ||
      newUrl.endsWith(".mov") ||
      newUrl.endsWith(".mkv")
    );
  }

  get sortedImageUrls(): string[] {
    if (!this.selectedImageUrl) {
      return this.imageUrls;
    }
    const index = this.imageUrls.indexOf(this.selectedImageUrl);
    if (index !== -1) {
      const sortedUrls = [...this.imageUrls];
      sortedUrls.splice(index, 1);
      sortedUrls.unshift(this.selectedImageUrl);
      return sortedUrls;
    }
    return this.imageUrls;
  }

  download(type: any) {
    if (type === "video" && this.isVideo(this.selectedImageUrl)) {
      let videoUrl = this.selectedImageUrl;
      fetch(videoUrl)
        .then((response) => response.blob())
        .then((blob) => {
          saveAs(blob, "video.mp4");
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        });
    } else if (type === "image") {
      const imageUrl = this.selectedImageUrl;
      const a = document.createElement("a");
      a.href = imageUrl;
      a.download = imageUrl;
      a.click();
    } else if (type === "document") {
      const imageUrl = this.docUrl;
      const a = document.createElement("a");
      a.href = imageUrl;
      a.download = imageUrl;
      a.click();
    }
  }

  onCarouselItemClick(index: number): void {
    const url = this.imageUrls[index];
    this.selectedImageUrl = url;
    this.slickModal.slickGoTo(index);
  }
  handleResolution(resolution: string, divId: string) {
    this.activeDiv = divId;
    this.Resolution = resolution;
    this.isResolutionVisible = true;
  }
  activediv: any = false;
  handleWatermark(div) {
    this.activediv = div;
  }
  handleWithoutWatermark(div) {
    this.activediv = div;
  }

  isDownloading: boolean = false;
  downloadProgress: number = 0;

  downloadWatermark() {
    this.isDownloading = true;
    const interval = setInterval(() => {
      if (this.downloadProgress < 100) {
        this.downloadProgress += 10;
      } else {
        clearInterval(interval);
        this.isDownloading = false;
      }
    }, 1000);
  }
  watermark() {
    this.handleWatermark("watermarkDiv");
    if (this.Resolution == "Whatsapp") {
      this.downloadFile(this.videoUrl_sm_wm, this.title, "sm_wm");
      // this.downloadWatermark();
    }
    if (this.Resolution == "High") {
      this.downloadFile(this.videoUrl_lg_wm, this.title, "lg_wm");
      // this.downloadWatermark();
    }
    if (this.Resolution == "Low") {
      this.downloadFile(this.videoUrl_md_wm, this.title, "md_wm");
      // this.downloadWatermark();
    }
  }

  Withoutwatermark() {
    this.handleWithoutWatermark("withoutWatermarkDiv");
    if (this.Resolution == "Whatsapp") {
      this.downloadFile(this.videoUrl_sm, this.title, "sm");
    }
    if (this.Resolution == "High") {
      this.downloadFile(this.videoUrl_lg, this.title, "lg");
    }
    if (this.Resolution == "Low") {
      this.downloadFile(this.videoUrl_md, this.title, "md");
    }
  }

  videoPlay() {
    this.videoImageToggle = true;
    let videoY = document.getElementsByTagName("video")[0];
    videoY.addEventListener("play", this.handleFirstPlay, false);
  }

  played: boolean = false;
  handleFirstPlay(event) {
    if (!this.played) {
      this.played = true;
      const vid = event.target;
      vid.removeEventListener("play", this.handleFirstPlay);
    }
  }

  saveChanges() {}
  edit() {
    let id = this.cardData._id;
    this.router.navigateByUrl(`edit/${id}`);
  }

  download$: Observable<Download>;
  slides = {
    name: "Mobile Cross-Platform from a Progressive Perspective",
    url: "https://nils-mehlhorn.de/slides/mobile_cp_progessive_mehlhorn_pottjs.pdf",
  };

  shareFile(phone: any, link: string) {
    this.mediaService.fileShare(phone, link).subscribe(
      (res) => {
        console.log("share", res);
      },
      (err) => {
        console.log("error", err);
      }
    );
    console.log("share file", link);
  }

  muteVideoOnScroll() {
    const video = document.getElementById("video") as HTMLVideoElement;
    if (video) {
      video.muted = true;
    }
  }

  pauseVideo() {
    const video = document.getElementById("videoSelected") as HTMLVideoElement;
    if (video) {
      video.muted = true;
      video.pause();
    }
  }

  playVideo() {
    const video = document.getElementById("videoSelected") as HTMLVideoElement;
    if (video) {
      video.muted = false;
      video.play();
    }
  }
  removeDownload() {
    let down = document.getElementById("download-file");
    down.style.display = "none";
  }

  isDropdownOpen: number | null = null;
  isDropdownOpen1: number | null = null;

  showDropdown(index: number) {
    console.log("dropdown open", index);
    index = index - 3;
    if (this.isDropdownOpen === index) {
      // Clicked on the same dropdown, close it
      this.isDropdownOpen = null;
    } else {
      // Clicked on a different dropdown, open it
      this.isDropdownOpen = index;
    }
  }

  closeDropdown(event: Event) {
    if (
      !(event.target instanceof HTMLElement) ||
      !event.target.matches(".dropbtn")
    ) {
      this.isDropdownOpen = null;
    }
  }

  AllPlayList: any;
  PlayListNames: any = [];
  collectionTags: string = "";
  collectionType: string = "";
  playList = [];

  addCollectionType(string: any) {
    this.collectionType = string;
    console.log(this.collectionType);
  }
  getAllPlaylist() {
    this.mediaService.playLists().subscribe(
      (res) => {
        console.log("res play", res["data"]);
        this.AllPlayList = res["data"];
        this.AllPlayList.map((list) => {
          console.log(list);
          list.play_list.forEach((list) => {
            const name = list.name;
            const type = list.type;
            this.PlayListNames.push({
              name: name,
              type: type,
            });
          });
        });
        console.log(this.PlayListNames);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  createPlayLists(body) {
    let name = body.name;
    let type = body.type;
    body = {
      play_list: { name: name, type: type, data: this.cardData },
    };
    console.log(body);
    this.mediaService.createPlayList(body).subscribe(
      (res) => {
        console.log("res create", res);
      },
      (err) => [console.log(err)]
    );
  }

  createCollection() {
    if (this.collectionTags && this.collectionType) {
      this.playList.push({
        name: this.collectionTags,
        type: this.collectionType,
      });
    }

    console.log(this.playList);
    this.createPlayLists(this.playList[0]);
  }
  selectedCollection = "";

  selectedPlayList() {
    console.log(this.collectionTags);
    console.log(this.collectionTags, "selected");
    const selectedIndex = this.PlayListNames.findIndex(
      (card) => card.name === this.collectionTags
    );

    if (selectedIndex !== -1) {
      // Access the selected item based on the index
      const selectedCard = this.PlayListNames[selectedIndex];
      // Now you can work with the selectedCard details as needed
      console.log(selectedCard);
    }
    // this.PlayListNames[index];
    let data = this.PlayListNames.filter(
      (item) => item.name == this.collectionTags
    );
    console.log(data);
    // console.log(this.PlayListNames[index]);
    this.collectionType = data[0].type;
    this.collectionTags = data[0].name;
    this.selectedCollection = this.collectionTags;
  }
}
