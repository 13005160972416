import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Socket, io } from "socket.io-client";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class OnlineUsersService {
  private socket: Socket;
  public onlineUsers: Subject<any> = new Subject();
  constructor(private httpClient: HttpClient, private router: Router) {
    const url = environment.host;
    this.socket = io(url, {
      query: { token: localStorage.getItem("guestToken") },
      transports: ["websocket"],
    });

    this.socket.on("connect", () => {
      console.log("Socket connected.");
    });

    this.socket.on("error", (error) => {
      this.router.navigate["/home"];
    });

    this.socket.on("ONLINE-USERS", (data) => {
      this.onlineUsers.next(data);
    });
  }

  getUsers() {
    return this.onlineUsers;
  }
  listen(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }
  private file: File | null = null;
  handleSubmit(event) {
    this.file = event;
    const data = {
      name: event.name,
      size: event.size,
    };
    console.log(data, this.socket);
    this.socket.emit("ARCHIVE", data);
  }

  isUrlValid(body: any) {
    var headers = this.get_auth_token();
    return this.httpClient.post(environment.host + "/chats/tags", body, {
      ...headers,
    });
  }

  isUrlValidSave(body: any) {
    var headers = this.get_auth_token();
    return this.httpClient.post(environment.host + "/chats/tags/save", body, {
      ...headers,
    });
  }

  get_auth_token() {
    var token = localStorage.getItem("guestToken");
    var headers = {
      Authorization: "Bearer " + token,
    };
    return { headers: headers };
  }
}
