import { Component, ElementRef, ViewChild } from "@angular/core";
import { GeocoderAutocomplete } from "@geoapify/geocoder-autocomplete";
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormBuilder,
  FormControl,
  FormArray,
} from "@angular/forms";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { provideNativeDateAdapter } from "@angular/material/core";
import { UserService } from "../../../service/user.service";
import { CrmService } from "src/app/service/crm.service";
import { NgClass } from "@angular/common";
import { HeaderComponent } from "../../header/header.component";
import { PaginationService } from "src/app/service/pagination.service";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import {
  alertModalDelete,
  alertModalWithoutConfirm,
} from "src/app/component/helpers/alert";
import { alertModalWithConfirm } from "src/app/component/helpers/alert";
import { HttpClient } from "@angular/common/http";
import * as bootstrap from "bootstrap";
import { Router, ActivatedRoute } from "@angular/router";
import {
  updateLeadSearchUser,
  updateLeadSearchStatus,
} from "src/app/store/counter.actions";
import { Observable, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import {
  getSelectedSearchIICUser,
  getSelectedSearchStatus,
} from "src/app/store/counter.selectors";
@Component({
  selector: "app-leads",
  templateUrl: "./leads.component.html",
  styleUrls: ["./leads.component.css"],
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    InfiniteScrollModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    HeaderComponent,
    NgClass,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
  ],
})
export class LeadsComponent {
  selectedSearchIICUser$: Observable<string | null>;
  selectedSearchUserId$: Observable<string | null>;
  selectedSearchStatus$: Observable<string | null>;

  @ViewChild("newLeadModal", { static: true }) modal: any;
  @ViewChild("editLeadModal", { static: true }) editmodal: any;
  @ViewChild("leadDetailModal", { static: true }) detailmodal: any;
  @ViewChild("searchModal") searchModal: ElementRef;
  @ViewChild("infiniteScroller") infiniteScroller: ElementRef;

  ngOnInit() {
    this.getRepresetativeUsers();
    this.getCreatedLeadsData();
    this.selectedSearchStatus$.subscribe(() => this.getCreatedLeadsData());
    this.selectedSearchIICUser$.subscribe(() => this.getCreatedLeadsData());
  }

  ngAfterViewInit() {
    this.addField();
    // modals initialization
    this.leadDetailModalInstance = new (window as any).bootstrap.Modal(
      this.detailmodal.nativeElement
    );
    this.createLeadModalInstance = new (window as any).bootstrap.Modal(
      this.modal.nativeElement
    );
    // this.leadDetailModalInstance.show();
    console.log(
      "lead modal init",
      this.leadDetailModalInstance,
      this.detailmodal
    );
    try {
      this.editDetailModalInstance = new (window as any).bootstrap.Modal(
        this.editmodal.nativeElement
      );
    } catch (error) {}
    setTimeout(() => {
      // this.CreateLeadForm.controls.customerInformation[0].patchValue({
      //   countryCode: "+91",
      // });
      console.log("LFP", this.CreateLeadForm);
    }, 2000);
  }

  constructor(
    private userService: UserService,
    private crmService: CrmService,
    private formBuilder: FormBuilder,
    private router: Router,
    private paginationService: PaginationService,
    private http: HttpClient,
    private store: Store
  ) {
    this.selectedSearchIICUser$ = this.store.select(getSelectedSearchIICUser);
    this.selectedSearchStatus$ = this.store.select(getSelectedSearchStatus);
  }

  //location
  firstRunLocation: boolean = true;
  firstRunEditLocation: boolean = true;
  //shimmer loader
  isShimmerLoading: boolean = true;
  shimmerLoadingValue = ([] = [1, 2, 3, 4, 5, 6, 7, 8]);

  formSubmitted = false;
  id: any;
  editLeadId: string;
  leadDetailModalInstance: any;
  editDetailModalInstance: any;
  Name: any;
  countries: any;
  selectedIndustry: string = "Select";
  iicIndusties: string[] = [
    "Education",
    "Construction",
    "Manufacturing",
    "Agriculture",
    "Mining",
    "Health Care",
    "Primary Industry",
    "Secondary Industry",
    "Tertiary Industry",
    "Agency/Events",
    "Automobile",
    "Computer Industry",
    "Entertainment",
    "Food industry",
    "Technology",
    "Energy",
    "Hospitality",
    "Retail",
    "Transportation",
    "Aviation",
    "Real Estate",
    "Finance/Banking",
    "Tourism",
    "Goverment",
    "Lifestyle",
    "FMCG",
    "Consulting",
    "Finance",
  ];
  createLeadModalInstance: any;
  // country list
  countryList = {
    "+93": "af",
    "+358": "fi",
    "+355": "al",
    "+213": "dz",
    "+1684": "as",
    "+376": "ad",
    "+244": "ao",
    "+1264": "ai",
    "+672": "nf",
    "+1268": "ag",
    "+54": "ar",
    "+374": "am",
    "+297": "aw",
    "+61": "cc",
    "+43": "at",
    "+994": "az",
    "+1242": "bs",
    "+973": "bh",
    "+880": "bd",
    "+1246": "bb",
    "+375": "by",
    "+32": "be",
    "+501": "bz",
    "+229": "bj",
    "+1441": "bm",
    "+975": "bt",
    "+591": "bo",
    "+387": "ba",
    "+267": "bw",
    "+55": "br",
    "+246": "io",
    "+673": "bn",
    "+359": "bg",
    "+226": "bf",
    "+257": "bi",
    "+855": "kh",
    "+237": "cm",
    "+1": "us",
    "+238": "cv",
    "+ 345": "ky",
    "+236": "cf",
    "+235": "td",
    "+56": "cl",
    "+86": "cn",
    "+57": "co",
    "+269": "km",
    "+242": "cg",
    "+243": "cd",
    "+682": "ck",
    "+506": "cr",
    "+225": "ci",
    "+385": "hr",
    "+53": "cu",
    "+357": "cy",
    "+420": "cz",
    "+45": "dk",
    "+253": "dj",
    "+1767": "dm",
    "+1849": "do",
    "+593": "ec",
    "+20": "eg",
    "+503": "sv",
    "+240": "gq",
    "+291": "er",
    "+372": "ee",
    "+251": "et",
    "+500": "gs",
    "+298": "fo",
    "+679": "fj",
    "+33": "fr",
    "+594": "gf",
    "+689": "pf",
    "+241": "ga",
    "+220": "gm",
    "+995": "ge",
    "+49": "de",
    "+233": "gh",
    "+350": "gi",
    "+30": "gr",
    "+299": "gl",
    "+1473": "gd",
    "+590": "mf",
    "+1671": "gu",
    "+502": "gt",
    "+44": "gb",
    "+224": "gn",
    "+245": "gw",
    "+595": "py",
    "+509": "ht",
    "+379": "va",
    "+504": "hn",
    "+852": "hk",
    "+36": "hu",
    "+354": "is",
    "+91": "in",
    "+62": "id",
    "+98": "ir",
    "+964": "iq",
    "+353": "ie",
    "+972": "il",
    "+39": "it",
    "+1876": "jm",
    "+81": "jp",
    "+962": "jo",
    "+77": "kz",
    "+254": "ke",
    "+686": "ki",
    "+850": "kp",
    "+82": "kr",
    "+965": "kw",
    "+996": "kg",
    "+856": "la",
    "+371": "lv",
    "+961": "lb",
    "+266": "ls",
    "+231": "lr",
    "+218": "ly",
    "+423": "li",
    "+370": "lt",
    "+352": "lu",
    "+853": "mo",
    "+389": "mk",
    "+261": "mg",
    "+265": "mw",
    "+60": "my",
    "+960": "mv",
    "+223": "ml",
    "+356": "mt",
    "+692": "mh",
    "+596": "mq",
    "+222": "mr",
    "+230": "mu",
    "+262": "re",
    "+52": "mx",
    "+691": "fm",
    "+373": "md",
    "+377": "mc",
    "+976": "mn",
    "+382": "me",
    "+1664": "ms",
    "+212": "ma",
    "+258": "mz",
    "+95": "mm",
    "+264": "na",
    "+674": "nr",
    "+977": "np",
    "+31": "nl",
    "+599": "an",
    "+687": "nc",
    "+64": "nz",
    "+505": "ni",
    "+227": "ne",
    "+234": "ng",
    "+683": "nu",
    "+1670": "mp",
    "+47": "sj",
    "+968": "om",
    "+92": "pk",
    "+680": "pw",
    "+970": "ps",
    "+507": "pa",
    "+675": "pg",
    "+51": "pe",
    "+63": "ph",
    "+872": "pn",
    "+48": "pl",
    "+351": "pt",
    "+1939": "pr",
    "+974": "qa",
    "+40": "ro",
    "+7": "ru",
    "+250": "rw",
    "+290": "sh",
    "+1869": "kn",
    "+1758": "lc",
    "+508": "pm",
    "+1784": "vc",
    "+685": "ws",
    "+378": "sm",
    "+239": "st",
    "+966": "sa",
    "+221": "sn",
    "+381": "rs",
    "+248": "sc",
    "+232": "sl",
    "+65": "sg",
    "+421": "sk",
    "+386": "si",
    "+677": "sb",
    "+252": "so",
    "+27": "za",
    "+211": "ss",
    "+34": "es",
    "+94": "lk",
    "+249": "sd",
    "+597": "sr",
    "+268": "sz",
    "+46": "se",
    "+41": "ch",
    "+963": "sy",
    "+886": "tw",
    "+992": "tj",
    "+255": "tz",
    "+66": "th",
    "+670": "tl",
    "+228": "tg",
    "+690": "tk",
    "+676": "to",
    "+1868": "tt",
    "+216": "tn",
    "+90": "tr",
    "+993": "tm",
    "+1649": "tc",
    "+688": "tv",
    "+256": "ug",
    "+380": "ua",
    "+971": "ae",
    "+598": "uy",
    "+998": "uz",
    "+678": "vu",
    "+58": "ve",
    "+84": "vn",
    "+1284": "vg",
    "+1340": "vi",
    "+681": "wf",
    "+967": "ye",
    "+260": "zm",
    "+263": "zw",
  };
  countryNames = {
    ad: "Andorra",
    ae: "United Arab Emirates",
    af: "Afghanistan",
    ag: "Antigua and Barbuda",
    ai: "Anguilla",
    al: "Albania",
    am: "Armenia",
    ao: "Angola",
    aq: "Antarctica",
    ar: "Argentina",
    as: "American Samoa",
    at: "Austria",
    au: "Australia",
    aw: "Aruba",
    ax: "\u00c5land Islands",
    az: "Azerbaijan",
    ba: "Bosnia and Herzegovina",
    bb: "Barbados",
    bd: "Bangladesh",
    be: "Belgium",
    bf: "Burkina Faso",
    bg: "Bulgaria",
    bh: "Bahrain",
    bi: "Burundi",
    bj: "Benin",
    bl: "Saint Barthélemy",
    bm: "Bermuda",
    bn: "Brunei Darussalam",
    bo: "Bolivia, Plurinational State of",
    bq: "Caribbean Netherlands",
    br: "Brazil",
    bs: "Bahamas",
    bt: "Bhutan",
    bv: "Bouvet Island",
    bw: "Botswana",
    by: "Belarus",
    bz: "Belize",
    ca: "Canada",
    cc: "Cocos (Keeling) Islands",
    cd: "Congo, the Democratic Republic of the",
    cf: "Central African Republic",
    cg: "Republic of the Congo",
    ch: "Switzerland",
    ci: "C\u00f4te d'Ivoire",
    ck: "Cook Islands",
    cl: "Chile",
    cm: "Cameroon",
    cn: "China (People's Republic of China)",
    co: "Colombia",
    cr: "Costa Rica",
    cu: "Cuba",
    cv: "Cape Verde",
    cw: "Cura\u00e7ao",
    cx: "Christmas Island",
    cy: "Cyprus",
    cz: "Czech Republic",
    de: "Germany",
    dj: "Djibouti",
    dk: "Denmark",
    dm: "Dominica",
    do: "Dominican Republic",
    dz: "Algeria",
    ec: "Ecuador",
    ee: "Estonia",
    eg: "Egypt",
    eh: "Western Sahara",
    er: "Eritrea",
    es: "Spain",
    et: "Ethiopia",
    eu: "Europe",
    fi: "Finland",
    fj: "Fiji",
    fk: "Falkland Islands (Malvinas)",
    fm: "Micronesia, Federated States of",
    fo: "Faroe Islands",
    fr: "France",
    ga: "Gabon",
    gb: "United Kingdom",
    gd: "Grenada",
    ge: "Georgia",
    gf: "French Guiana",
    gg: "Guernsey",
    gh: "Ghana",
    gi: "Gibraltar",
    gl: "Greenland",
    gm: "Gambia",
    gn: "Guinea",
    gp: "Guadeloupe",
    gq: "Equatorial Guinea",
    gr: "Greece",
    gs: "South Georgia and the South Sandwich Islands",
    gt: "Guatemala",
    gu: "Guam",
    gw: "Guinea-Bissau",
    gy: "Guyana",
    hk: "Hong Kong",
    hm: "Heard Island and McDonald Islands",
    hn: "Honduras",
    hr: "Croatia",
    ht: "Haiti",
    hu: "Hungary",
    id: "Indonesia",
    ie: "Ireland",
    il: "Israel",
    im: "Isle of Man",
    in: "India",
    io: "British Indian Ocean Territory",
    iq: "Iraq",
    ir: "Iran, Islamic Republic of",
    is: "Iceland",
    it: "Italy",
    je: "Jersey",
    jm: "Jamaica",
    jo: "Jordan",
    jp: "Japan",
    ke: "Kenya",
    kg: "Kyrgyzstan",
    kh: "Cambodia",
    ki: "Kiribati",
    km: "Comoros",
    kn: "Saint Kitts and Nevis",
    kp: "Korea, Democratic People's Republic of",
    kr: "Korea, Republic of",
    kw: "Kuwait",
    ky: "Cayman Islands",
    kz: "Kazakhstan",
    la: "Laos (Lao People's Democratic Republic)",
    lb: "Lebanon",
    lc: "Saint Lucia",
    li: "Liechtenstein",
    lk: "Sri Lanka",
    lr: "Liberia",
    ls: "Lesotho",
    lt: "Lithuania",
    lu: "Luxembourg",
    lv: "Latvia",
    ly: "Libya",
    ma: "Morocco",
    mc: "Monaco",
    md: "Moldova, Republic of",
    me: "Montenegro",
    mf: "Saint Martin",
    mg: "Madagascar",
    mh: "Marshall Islands",
    mk: "North Macedonia",
    ml: "Mali",
    mm: "Myanmar",
    mn: "Mongolia",
    mo: "Macao",
    mp: "Northern Mariana Islands",
    mq: "Martinique",
    mr: "Mauritania",
    ms: "Montserrat",
    mt: "Malta",
    mu: "Mauritius",
    mv: "Maldives",
    mw: "Malawi",
    mx: "Mexico",
    my: "Malaysia",
    mz: "Mozambique",
    na: "Namibia",
    nc: "New Caledonia",
    ne: "Niger",
    nf: "Norfolk Island",
    ng: "Nigeria",
    ni: "Nicaragua",
    nl: "Netherlands",
    no: "Norway",
    np: "Nepal",
    nr: "Nauru",
    nu: "Niue",
    nz: "New Zealand",
    om: "Oman",
    pa: "Panama",
    pe: "Peru",
    pf: "French Polynesia",
    pg: "Papua New Guinea",
    ph: "Philippines",
    pk: "Pakistan",
    pl: "Poland",
    pm: "Saint Pierre and Miquelon",
    pn: "Pitcairn",
    pr: "Puerto Rico",
    ps: "Palestine",
    pt: "Portugal",
    pw: "Palau",
    py: "Paraguay",
    qa: "Qatar",
    re: "Réunion",
    ro: "Romania",
    rs: "Serbia",
    ru: "Russian Federation",
    rw: "Rwanda",
    sa: "Saudi Arabia",
    sb: "Solomon Islands",
    sc: "Seychelles",
    sd: "Sudan",
    se: "Sweden",
    sg: "Singapore",
    sh: "Saint Helena, Ascension and Tristan da Cunha",
    si: "Slovenia",
    sj: "Svalbard and Jan Mayen Islands",
    sk: "Slovakia",
    sl: "Sierra Leone",
    sm: "San Marino",
    sn: "Senegal",
    so: "Somalia",
    sr: "Suriname",
    ss: "South Sudan",
    st: "Sao Tome and Principe",
    sv: "El Salvador",
    sx: "Sint Maarten (Dutch part)",
    sy: "Syrian Arab Republic",
    sz: "Swaziland",
    tc: "Turks and Caicos Islands",
    td: "Chad",
    tf: "French Southern Territories",
    tg: "Togo",
    th: "Thailand",
    tj: "Tajikistan",
    tk: "Tokelau",
    tl: "Timor-Leste",
    tm: "Turkmenistan",
    tn: "Tunisia",
    to: "Tonga",
    tr: "Turkey",
    tt: "Trinidad and Tobago",
    tv: "Tuvalu",
    tw: "Taiwan (Republic of China)",
    tz: "Tanzania, United Republic of",
    ua: "Ukraine",
    ug: "Uganda",
    um: "US Minor Outlying Islands",
    us: "United States",
    uy: "Uruguay",
    uz: "Uzbekistan",
    va: "Holy See (Vatican City State)",
    vc: "Saint Vincent and the Grenadines",
    ve: "Venezuela, Bolivarian Republic of",
    vg: "Virgin Islands, British",
    vi: "Virgin Islands, U.S.",
    vn: "Vietnam",
    vu: "Vanuatu",
    wf: "Wallis and Futuna Islands",
    ws: "Samoa",
    xk: "Kosovo",
    ye: "Yemen",
    yt: "Mayotte",
    za: "South Africa",
    zm: "Zambia",
    zw: "Zimbabwe",
  };

  countryKeys = Object.keys(this.countryList);

  //create Lead Form
  CreateLeadForm: FormGroup = this.formBuilder.group({
    companyName: [""],
    representatorName: [""],
    representatorId: [""],
    location: ["", Validators.required],
    customerInformation: this.formBuilder.array([]),
    jobTitle: [""],
    companyIndustry: [""],
    leadType: ["", Validators.required],
    leadSource: ["", Validators.required],
    leadStatus: ["", Validators.required],
    leadScoring: ["", Validators.required],
    notes: [""],
    followUpMedium: ["", Validators.required],
    followUpDate: [""],
    firstContactDate: [""],
    interestedIn: ["", Validators.required],
    categoryLead: ["", Validators.required],
    // workaround for category lead input
    editAccess: [""],
  });

  notFoundResult: boolean = false;
  flagUrl: string | null = null;

  //Country code and Flag
  async fetchCountryFlag(countryCode: string) {
    const result = await fetch(
      `https://flagcdn.com/${countryCode.toLowerCase()}.svg`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "no-cors",
      }
    );
    console.log(result);
  }

  // Getting UserList in iic Sales Representative and Edit Access
  iicUsers: any = [];
  isEditable: boolean = false;
  isEdit = false;
  represetativeUsersId: string = "";
  selectedIICUser: { username: string; id: string } | null = null;
  getRepresetativeUsers() {
    const loggedInUsername = localStorage.getItem("username");
    const loggedInUserId = localStorage.getItem("guestID");
    this.userService.guestList().subscribe(
      (res) => {
        let data = res["data"];
        this.iicUsers = data.map((user) => {
          // this.represetativeUsersId = user._id;
          return {
            username: user.userName,
            id: user._id,
          };
        });
        if (loggedInUsername) {
          this.iicUsers.push({
            username: loggedInUsername,
            id: loggedInUserId,
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  //IIC User
  selectUser(user: { username: string; id: string }) {
    this.selectedIICUser = user;
    this.Name = user.username;
    this.CreateLeadForm.patchValue({
      representatorName: user.username,
    });
  }

  //Search IIC User
  selectedSearchIICUser;
  selectedSearchUserId;
  // updateSearchUser(user: string | { username: string; id: string }) {
  //   // this.store.dispatch(updateLeadSearchUser({ user }));

  //   this.getCreatedLeadsData();
  // }

  updateSearchUser(user: string | { username: string; id: string }) {
    if (typeof user === "string") {
      this.selectedSearchIICUser = user;
      this.selectedSearchUserId = null;
    } else {
      this.selectedSearchIICUser = user.username;
      this.selectedSearchUserId = user.id;
    }
    this.store.dispatch(updateLeadSearchUser({ user }));

    this.getCreatedLeadsData();
    this.store
      .select(getSelectedSearchIICUser)
      .pipe()
      .subscribe((state: any) => {
        console.log({ state });
      });

    // console.log({
    //   selectedSearchStatus: this.store.select(getSelectedSearchIICUser)
    //  });
  }

  //Industry select update
  selectIndustry(industry: string) {
    this.CreateLeadForm.patchValue({
      companyIndustry: industry,
    });
    this.selectedIndustry = industry;
  }

  // Edit Access User
  selectedUsers: { username: string; id: string }[] = [];
  selectUserDisplay(user: { username: string; id: string }) {
    console.log("selecting users", this.selectedUsers);
    if (
      !this.selectedUsers.some((u) => u.id === user.id) &&
      this.selectedUsers.length < 3
    ) {
      this.selectedUsers.push(user);
      console.log("Selected user:", user);
    }
  }

  //remove selected Username
  removeUser(user: { username: string; id: string }) {
    this.selectedUsers = this.selectedUsers.filter((u) => u.id !== user.id);
  }

  // Interested Service
  serviceInterest: string[] = [];
  newService: string = "";
  editService: string;
  addService() {
    if (this.newService.trim() !== "") {
      this.serviceInterest.push(this.newService);
      this.newService = "";
    }
  }

  addEditService() {
    if (this.editService.trim() !== "") {
      this.serviceInterest.push(this.editService);
      this.editService = "";
    }
  }

  //Remove service
  removeServiceInt(index: number) {
    this.serviceInterest.splice(index, 1);
    if (!this.serviceInterest.length) {
      this.CreateLeadForm.patchValue({
        interestedIn: "",
      });
      console.log(this.CreateLeadForm);
    }
  }

  // Lead Status select
  selectedStatus: string;
  updateStatus(status: string) {
    this.selectedStatus = status;
    this.CreateLeadForm.patchValue({
      leadStatus: status,
    });
  }

  //Search By Lead Status
  selectedSearchStatus: string;
  UpdateSearchStatus(searchStatus: string) {
    console.log({ searchStatus });
    this.store.dispatch(updateLeadSearchStatus({ searchStatus }));
    // this.selectedSearchStatus = searchStatus;
    this.getCreatedLeadsData();
  }

  //Medium select update
  selectedMedium: string;
  updateMedium(mediumStatus: string) {
    this.selectedMedium = mediumStatus;
    this.CreateLeadForm.patchValue({
      followUpMedium: mediumStatus,
    });
    console.log("Medium", this.selectedMedium);
  }

  //Category lead select update
  selectedCategory: string;
  updateCategory(categoryStatus: string) {
    this.selectedCategory = categoryStatus;
    this.CreateLeadForm.patchValue({
      categoryLead: [categoryStatus],
    });
  }

  //Lead type select update
  selectedLeadType: string;
  updateLeadType(leadTypeStatus: string) {
    this.selectedLeadType = leadTypeStatus;
    this.CreateLeadForm.patchValue({
      leadType: leadTypeStatus,
    });
  }

  //Source lead select update
  selectedSourceLead: string;
  updateSourceLead(sourceLeadStatus: string) {
    this.selectedSourceLead = sourceLeadStatus;
    this.CreateLeadForm.patchValue({
      leadSource: sourceLeadStatus,
    });
  }

  //Country select update
  selectedCountryCode = ["+91"];
  selectCountry(country: string, i: number) {
    this.selectedCountryCode[i] = country || "+91";
    // @ts-ignore
    this.CreateLeadForm.controls.customerInformation.at(i).patchValue({
      countryCode: this.selectedCountryCode[i] || "+91",
    });
    console.log("country3", this.CreateLeadForm);
  }

  //create Lead Form Submit
  onSubmit() {
    this.formSubmitted = true;
    console.log("lead form", this.CreateLeadForm, this.selectedIICUser);
    // const selectedCountryCode = this.selectedCountry;

    this.CreateLeadForm.value.interestedIn = this.serviceInterest;
    this.CreateLeadForm.value.representatorName =
      this.selectedIICUser?.username || "";
    (this.CreateLeadForm.value.representatorId =
      this.selectedIICUser?.id || localStorage.getItem("guestID")),
      (this.CreateLeadForm.value.editAccess = this.selectedUsers.map(
        (user) => ({
          userName: user.username,
          userId: user.id,
        })
      ));
    const updatedcustomerInformation =
      this.CreateLeadForm.value.customerInformation.map((contact, i) => {
        return contact;
      });
    console.log(this.CreateLeadForm.value);
    const leadData = {
      representatorId:
        this.selectedIICUser?.id || localStorage.getItem("guestID"),
      representatorName: this.selectedIICUser?.username || "",

      interestedIn: this.serviceInterest,
      editAccess: this.selectedUsers.map((user) => ({
        userName: user.username,
        userId: user.id,
      })),
      // contact: [...this.CreateLeadForm.value.customerInformation],
    };
    console.log("constructed lead data: ", leadData);

    let isValid = true;
    Object.values(leadData).map((value) => {
      if (!value) {
        isValid = false;
      }
    });

    if (!isValid) {
      if (this.CreateLeadForm.invalid) {
        return alertModalWithoutConfirm(
          "warning",
          "Please fill in all required fields before uploading."
        );
      }
      return;
    } else {
      if (!this.isEdit) {
        this.crmService.createLead(this.CreateLeadForm.value).subscribe(
          (response) => {
            console.log("Lead created successfully:", response);
            this.closeCreateLeadmodal();
            this.resetClose();

            alertModalWithoutConfirm("success", " Successfully Created!");
            // location.reload();
            this.getCreatedLeadsData();
          },
          (error) => {
            console.error("Error creating lead:", error);
          }
        );
      } else {
        console.log("lead data", this.editLeadId, this.CreateLeadForm.value);
        this.CreateLeadForm.addControl(
          "leadId",
          this.formBuilder.control(this.editLeadId)
        );

        this.CreateLeadForm.addControl(
          "creatorName",
          this.formBuilder.control(this.singleLeads.creatorName)
        );
        this.CreateLeadForm.addControl(
          "creatorId",
          this.formBuilder.control(this.singleLeads.creatorId)
        );

        console.log(this.editLeadId);
        this.CreateLeadForm.value.representatorId =
          this.selectedIICUser?.id || localStorage.getItem("guestID");
        const updatedcustomerInformation =
          this.CreateLeadForm.value.customerInformation.map((contact, i) => {
            if (contact.phoneNo.trim() !== "") {
              const phoneNumberWithCountryCode = contact.phoneNo;
              return { ...contact, phoneNo: phoneNumberWithCountryCode };
            } else {
              return contact;
            }
          });

        this.CreateLeadForm.value.interestedIn = this.serviceInterest;
        this.CreateLeadForm.value.customerInformation =
          updatedcustomerInformation;
        console.log("lead data 2", this.editLeadId, this.CreateLeadForm.value);

        this.crmService.updateLead(this.CreateLeadForm.value).subscribe(
          (response: any) => {
            console.log("Lead data updated successfully:", response);
            this.closeCreateLeadmodal();
            this.resetClose();
            this.CreateLeadForm.removeControl("leadId"); // location.reload();
            alertModalWithoutConfirm("success", " Successfully Created!");
          },
          (error: any) => {
            console.error("Error updating lead data:", error);
          }
        );
      }
    }
  }

  closeCreateLeadmodal() {
    this.createLeadModalInstance.hide();
    this.getCreatedLeadsData();
  }

  openCreateLeadModal() {
    this.createLeadModalInstance.show();
  }

  //Reset form on Close
  resetClose() {
    this.isEdit = false;

    this.formSubmitted = false;
    this.selectedIndustry = "Select";
    this.serviceInterest = [];
    this.selectedMedium = "Select";
    this.selectedLeadType = "Select";
    this.selectedSourceLead = "Select";
    this.selectedCategory = "Select";
    // set the current created user
    this.createdUsername = localStorage.getItem("username");
    this.selectedIICUser = null;
    this.Name = "";
    this.selectedStatus = "";
    this.selectedUsers = [];
    this.CreateLeadForm.reset();
    (
      this.CreateLeadForm.controls.customerInformation as FormArray
    ).controls[0].patchValue({
      countryCode: "+91",
    });

    if (this.firstRunLocation) {
      const autocomplete = new GeocoderAutocomplete(
        document.getElementById("autocomplete"),
        "57aa3ef5b49d4679a55a5b597bc17bda",
        {
          /* Geocoder options */
        }
      );

      autocomplete.on("select", (location) => {
        // check selected location here
        console.log("location selected", location);
        this.CreateLeadForm.patchValue({
          location: location?.properties.formatted,
        });
        console.log(
          "created lead form should be updated",
          this.CreateLeadForm,
          location.properties.formatted,
          location.properties.formatted
        );
      });
      autocomplete.on("suggestions", (suggestions) => {
        // process suggestions here
        console.log("location suggestions", suggestions);
        if (suggestions.length === 0) {
          // this.CreateLeadForm.patchValue({
          //   location_name: "",
          // });
        }
      });
      this.firstRunLocation = false;
    }
    document.querySelector(
      "#autocomplete .geoapify-autocomplete-input"
      // @ts-ignore
    ).value = "";
  }

  // Create Lead Form Validation

  get f() {
    return this.CreateLeadForm.controls;
  }

  get companyName() {
    return this.CreateLeadForm.get("companyName");
  }
  get firstContactDate() {
    return this.CreateLeadForm.get(" firstContactDate");
  }
  get followUpDate() {
    return this.CreateLeadForm.get(" followUpDate");
  }
  get location() {
    return this.CreateLeadForm.get("location");
  }
  get category_lead_input() {
    return this.CreateLeadForm.get("category_lead_input");
  }
  get service_int_input() {
    return this.CreateLeadForm.get("service_int_input");
  }
  get name() {
    return this.CreateLeadForm.get("name");
  }
  get phone_no() {
    return this.CreateLeadForm.get("phone_no");
  }
  get email_id() {
    return this.CreateLeadForm.get("email_id");
  }
  get jobTitle() {
    return this.CreateLeadForm.get("jobTitle");
  }
  get companyIndustry() {
    return this.CreateLeadForm.get("companyIndustry");
  }
  get leadType() {
    return this.CreateLeadForm.get("leadType");
  }
  get leadSource() {
    return this.CreateLeadForm.get("leadSource");
  }
  get leadStatus() {
    return this.CreateLeadForm.get("leadStatus");
  }
  get leadScore() {
    return this.CreateLeadForm.get("leadScore");
  }
  get notes() {
    return this.CreateLeadForm.get("notes");
  }
  get followUpMedium() {
    return this.CreateLeadForm.get("followUpMedium");
  }
  get interestedIn() {
    return this.CreateLeadForm.get("interestedIn");
  }
  get categoryLead() {
    return this.CreateLeadForm.get("categoryLead");
  }
  get editAccess() {
    return this.CreateLeadForm.get("editAccess");
  }

  //getting currently loggedIn Username on Created By
  createdUsername = "";

  //Created Leads Data
  leads: any[] = [];
  totalLeads: number;
  searchedLeadsResult: [];
  isdeletable: boolean = false;
  creatorId: any;
  getCreatedLeadsData() {
    const option = {
      pageNum: 1,
      limit: 20,
      startDate: this.startDate,
      endDate: this.endDate,
      leadStatus:
        this.selectedSearchStatus === "All"
          ? null
          : this.selectedSearchStatus || null,
      representatorId: this.selectedSearchUserId || null,
    };
    this.crmService.getAllLead(option).subscribe(
      (response: any) => {
        this.leads = response.data.leads;
        this.totalLeads = response.data.totalLeads;
        this.searchedLeadsResult = response["data"].leads;
        this.isShimmerLoading = false;
        this.notFoundResult = this.searchedLeadsResult.length === 0;
      },
      (error) => {
        console.error("Error fetching leads data:", error);
      }
    );
  }

  // single Lead Data
  singleLeads: any;
  getSingleLeadData(id: any) {
    console.log("clicked", id);
    this.leadDetailModalInstance.show();

    this.editLeadId = id;
    this.crmService.getLeadDetail(id).subscribe(
      (res: any) => {
        this.singleLeads = res.data;
        console.log("Single Leads", res.data);
        this.singleLeads._id = id;
        const creatorId = this.singleLeads.creatorId;
        const creatorName = this.singleLeads.creatorName;
        this.createdUsername = this.singleLeads.creatorName;
        const name = this.singleLeads.representatorName;
        console.log(this.singleLeads);
        const guestID = localStorage.getItem("guestID");
        console.log({ creatoreID: this.singleLeads });
        console.log({ guestID });
        let editAccessIds = this.singleLeads.editAccess.map(
          (user) => user.userId
        );
        if (guestID === creatorId || editAccessIds.includes(guestID)) {
          console.log({ guestID }, "GETTING");
          console.log("check");
          this.isEditable = true;
        } else {
          this.isEditable = false;
        }
      },
      (error: any) => {
        console.error("Error fetching lead details:", error);
      }
    );
  }

  //Delete Lead
  guestID = localStorage.getItem("guestID");
  deleteId: any;
  deleteLead(id: any) {
    alertModalDelete("You won't be able to revert this!").then(
      (isConfirmed) => {
        if (isConfirmed) {
          this.crmService.deleteLeadById(id).subscribe(
            (res) => {
              // window.location.reload();
              this.getCreatedLeadsData();
              // this.totalLeads -= 1;

              console.log("Delete Response", res);
              setTimeout(() => {
                this.router.navigate(["/crm/leads"]);
              }, 2000);
            },
            (error) => {
              console.error(error);
              const message = error.error.message || "Something went wrong!";
              alertModalDelete(message);
            }
          );
        } else {
          alertModalDelete("Deletion cancelled");
        }
      }
    );
  }

  // Leads data in Edit Lead
  editLead() {
    this.leadDetailModal();
    this.createLeadModalInstance.show();
    this.isEdit = true;
    const numberOfFieldsToAdd = this.singleLeads.customerInformation.length - 1;
    this.serviceInterest = [];
    this.newService = "";
    this.removeField(1);

    // Add fields
    for (let i = 0; i < numberOfFieldsToAdd; i++) {
      this.addField();
    }
    this.CreateLeadForm.patchValue(this.singleLeads);
    this.singleLeads.interestedIn.map((res) => {
      this.serviceInterest.push(res);
      // this.newService = "";
    });
    const followUpDate = new Date(this.singleLeads.followUpDate);
    const firstContactDate = new Date(this.singleLeads.firstContactDate);
    this.CreateLeadForm.patchValue({
      followUpDate: followUpDate.toISOString().split("T")[0],
    });

    this.CreateLeadForm.patchValue({
      firstContactDate: firstContactDate.toISOString().split("T")[0],
    });
    this.selectedLeadType = this.singleLeads.leadType;
    this.selectedCategory = this.singleLeads.categoryLead;
    this.selectedIndustry = this.singleLeads.companyIndustry;
    this.selectedMedium = this.singleLeads.followUpMedium;
    this.selectedSourceLead = this.singleLeads.leadSource;
    this.selectedStatus = this.singleLeads.leadStatus;
    this.Name = this.singleLeads.representatorName;
    // console.log( this.Name)
    this.selectedIICUser = {
      username: this.Name,
      id: this.singleLeads.representatorId,
    };

    this.CreateLeadForm.patchValue({
      representatorName: this.Name,
    });
    console.log(this.Name, "Name");
    this.singleLeads.customerInformation.map((res, index) => {
      // this.selectedCountryCode[index] = code;
      const contactFormGroup = this.contactForms.at(index);
      contactFormGroup.patchValue({
        name: res.name,
        phoneNo: res.phoneNo,
        countryCode: res.countryCode || "+91",
        emailId: res.emailId,
      });
      this.selectCountry(res.countryCode || "+91", index);
    });
    console.log(this.singleLeads);

    // workaround for edit location
    if (this.firstRunLocation) {
      const autocomplete = new GeocoderAutocomplete(
        document.getElementById("autocomplete"),
        "57aa3ef5b49d4679a55a5b597bc17bda",
        {
          /* Geocoder options */
        }
      );

      autocomplete.on("select", (location) => {
        // check selected location here
        console.log("location selected", location);
        this.CreateLeadForm.patchValue({
          location: this.singleLeads.location,
        });
        console.log("created lead form should be updated", this.CreateLeadForm);
      });
      this.firstRunLocation = false;
    }

    document.querySelector(
      "#autocomplete .geoapify-autocomplete-input"
      // @ts-ignore
    ).value = this.singleLeads.location;

    this.leadDetailModal();
  }

  leadDetailModal() {
    this.leadDetailModalInstance.hide();
    console.log(this.leadDetailModalInstance);
  }
  closeEditLeadModal() {
    this.editDetailModalInstance.hide();
    this.getCreatedLeadsData();
  }

  // Search Leads By Date Range
  startDate: Date;
  endDate: Date;
  searchedLeads: any[] = [];
  startDateStr: string;
  endDateStr: string;
  searchLeadsByDateRange() {
    if (this.startDate && this.endDate) {
      const startDateStr = this.formatDate(this.startDate);
      this.startDateStr = startDateStr;
      const endDateStr = this.formatDate(this.endDate);
      this.endDateStr = endDateStr;

      this.crmService
        .getAllLead({
          pageNum: 1,
          limit: 100,
          startDate: startDateStr,
          endDate: endDateStr,
        })
        .subscribe(
          (response) => {
            this.searchedLeads = response["data"].leads;
            this.leads = this.searchedLeads;
            console.log(this.searchedLeads, "search Items");
            this.totalLeads = response["data"].totalLeads;
            this.notFoundResult = this.searchedLeads.length === 0;
          },
          (error) => {
            console.error("Error fetching leads:", error);
          }
        );
    }
  }
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // Reset Calendar
  resetCalendar() {
    console.log("clear");
    this.startDate = undefined;
    this.endDate = undefined;
    this.getCreatedLeadsData();
  }

  // Search Input leads
  searchQuery: string;
  searchInputLeads: any[] = [];
  onSearchInputChange() {
    const searchOptions = {
      search: this.searchQuery,
      pageNum: 1,
      limit: 20,
      startDate: this.startDateStr,
      endDate: this.endDateStr,
    };
    if (this.searchQuery === "") {
      this.clearSearch();
    } else {
      this.crmService.getAllSearchedLead(searchOptions).subscribe(
        (response: any) => {
          this.searchInputLeads = response["data"].leads;
          this.leads = this.searchInputLeads;
          this.totalLeads = response["data"].totalLeads;
          this.notFoundResult = this.searchInputLeads.length === 0;
          console.log("Search result:", response);
        },
        (error) => {
          console.error("Error searching leads:", error);
        }
      );
    }
  }

  clearSearch() {
    this.searchQuery = "";
    this.startDate = undefined;
    this.endDate = undefined;
    this.getCreatedLeadsData();
  }

  // Scroll Pagination
  // items: string[] = [];
  // isLoading = false;
  // pageLimit: number = 100;
  // toggleLoading = () => (this.isLoading = !this.isLoading);
  // loadData = () => {
  //   this.toggleLoading();
  //   this.paginationService.getItems(this.pageLimit, this.pageLimit).subscribe({
  //     next: (response) => (this.items = response),
  //     error: (err) => console.log(err),
  //     complete: () => this.toggleLoading(),
  //   });
  // };
  // PageNum: number = 1;
  // currentPageNume: number = 100;
  // appendData = () => {
  //   this.toggleLoading();
  //   const option = {
  //     pageNum: 1,
  //     limit: 100,
  //     startDate: this.startDate,
  //     endDate: this.endDate,
  //   };

  //   this.crmService.getAllLead(option).subscribe({
  //     next: (response) => {
  //       console.log({ response });
  //       this.leads = [...this.leads, response["data"].leads];

  //       // (this.cardData = [...this.cardData, ...response["data"].medias]),
  //       // (this.allDataForFilter = [...this.cardData])
  //     },
  //     error: (err) => console.log(err),
  //     complete: () => this.toggleLoading(),
  //   });
  // };

  // onScroll = () => {
  //   if (this.leads.length === 10) {
  //     console.log(this.leads.length, "Length");
  //     this.currentPageNume++;
  //     this.appendData();
  //   }
  // };

  //  Button Pagination
  currentPage = 1;
  itemsPerPage = 20;

  getPaginatedLeads() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.leads.slice(startIndex, endIndex);
  }

  // Function to handle page change
  pageChanged(page: number) {
    this.currentPage = page;
    console.log(page, "PAGE");
    const option = {
      pageNum: page,
      limit: 20,
      startDate: this.startDate,
      endDate: this.endDate,
      leadStatus:
        this.selectedSearchStatus === "All"
          ? null
          : this.selectedSearchStatus || null,
      representatorId: this.selectedSearchUserId || null,
    };
    this.crmService.getAllLead(option).subscribe(
      (response: any) => {
        this.leads = response.data.leads;
        this.totalLeads = response.data.totalLeads;
        this.searchedLeadsResult = response["data"].leads;
        this.isShimmerLoading = false;
        this.notFoundResult = this.searchedLeadsResult.length === 0;
      },
      (error) => {
        console.error("Error fetching leads data:", error);
      }
    );
    this.notFoundResult = false;
  }

  get totalPages(): number {
    return Math.ceil(this.totalLeads / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  trackByFn(index: number, item: any): any {
    return item._id;
  }

  get visiblePages(): number[] {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2)
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  }

  isPageVisible(page: number): boolean {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2)
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return page <= 3 || (page >= startPage && page <= endPage);
  }

  toggleRightView: boolean = true;
  //Multiple-field for customer name , phone , Email
  createContact(): FormGroup {
    console.log("form");
    this.selectedCountryCode.push("+91");
    // this.selectedCountry.push("in");

    return this.formBuilder.group({
      name: ["", Validators.required],
      phoneNo: ["", Validators.required],
      countryCode: ["+91"],
      emailId: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
          ),
        ],
      ],
    });
  }

  get contactForms() {
    return this.CreateLeadForm.get("customerInformation") as FormArray;
  }

  addField() {
    this.contactForms.push(this.createContact());
    console.log("created lead form should be updated", this.CreateLeadForm);
  }

  removeField(index: number) {
    this.contactForms.removeAt(index);
  }

  showDashboard() {
    document.getElementById("containerBanner").style.display = "none";
    document.getElementById("dashboardBanner").style.display = "block";
  }
  showLeads() {
    document.getElementById("dashboardBanner").style.display = "none";
    document.getElementById("containerBanner").style.display = "block";
  }

  isSpinnerLoader: boolean = false;
  uploadFile(file: File) {
    console.log("Uploading file:", file);
    this.isSpinnerLoader = true;
    const formData = new FormData();
    formData.append("file", file);
    this.crmService.uploadLead(formData).subscribe(
      (response: any) => {
        this.isSpinnerLoader = false;
        this.getCreatedLeadsData();
        if (response.data.rows.length === 0) {
          console.log("Upload successful:", response);
          alertModalWithoutConfirm("success", " Successfully Imported!");
        } else {
          alertModalWithConfirm(
            "success",
            "Successfully Imported!",
            response.data.rows
          );
        }
      },
      (error) => {
        this.isSpinnerLoader = false;
        console.error("Upload failed:", error);
        alertModalWithoutConfirm(
          "warning",
          "Please select correct file format"
        );
      }
    );
  }

  importData() {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".xlsx";

    fileInput.addEventListener("change", (event) => {
      const target = event.target as HTMLInputElement;

      if (target.files && target.files.length > 0) {
        const file: File = target.files[0];

        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();

        if (fileExtension === "xlsx") {
          this.uploadFile(file);
        } else {
          alert("Please select a .xlsx file.");
        }
      } else {
        alert("Please select a file.");
      }
    });

    fileInput.click();
  }

  // Status Tooltip
  getTooltipText(leadStatus: string): string {
    switch (leadStatus) {
      case "Outreach Done":
        return "Outreach Done";
      case "Introduction Meeting":
        return "Introduction Meeting";
      case "Initial Deck Presentation":
        return "Initial deck presentation ";
      case "Second round Deck Presentation":
        return "Second round deck presentation ";
      case "Cost Sheet Sent":
        return "Cost Sheet Sent";
      case "Qualified":
        return "Qualified";
      case "Following up":
        return "Following up";
      case "On Hold":
        return "On Hold";
      case "Lead Lost - To Competitors":
        return "Lead Lost - To Competitors";
      case "Lead Lost - Cost":
        return "Lead Lost - Cost";
      case "Lead Lost - Idea":
        return "Lead Lost - Idea";
      case "Lead Lost - Project Cancelled":
        return "Lead Lost - Project Cancelled";
      default:
        return ""; // Default tooltip text
    }
  }
}
