import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InventoryService } from 'src/app/service/inventory.service';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass } from '@angular/common';
import { CrmService } from 'src/app/service/crm.service';

interface Urgency {
  value: string;
  viewValue: string;
}

interface Request {
  value: string;
  viewValue: string;
}
interface newRequest {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-new-inventory',
  standalone: true,
  imports: [MatInputModule, MatSelectModule, MatFormFieldModule, ReactiveFormsModule, FormsModule, NgClass],
  templateUrl: './new-inventory.component.html',
  styleUrls: ["./new-inventory.component.css", "../styles/inventory.css"]
})
export class NewInventoryComponent {

  newInventoryForm: FormGroup;
  forwardRequestForm: FormGroup;
  users: { value: string, viewValue: string, id: string }[] = [];

  urgencies: Urgency[] = [
    { value: 'High', viewValue: 'High' },
    { value: 'Medium', viewValue: 'Medium' },
    { value: 'Low', viewValue: 'Low' },
  ];

  requests: Request[] = [
    { value: 'New Inventory Request', viewValue: 'Forwarded Request' },
  ]

  newReq: newRequest[] = [
    { value: 'New Request', viewValue: 'New Inventory Request' }
  ]

  constructor(private fb: FormBuilder, private inventoryService: InventoryService, private crmService: CrmService) {

    this.newInventoryForm = this.fb.group({
      assetCategory: ['', Validators.required],
      projectName: ['', Validators.required],
      startDate: ['', Validators.required],
      link1: ['', Validators.required],
      link2: ['', Validators.required],
      link3: ['', Validators.required],
      urgency: ['', Validators.required],
      approverName: ['', Validators.required]
    })

    this.forwardRequestForm = this.fb.group({
      maximumCost: ['', Validators.required],
      comment: ['', Validators.required],
      link1: ['', Validators.required],
      link2: ['', Validators.required],
      link3: ['', Validators.required],
      selectedAdmin: ['', Validators.required]
    })
  }

  userDepartment: string | null = null;
  showForwardButton = false;
  ngOnInit() {
    this.getAllUsers();
    this.getNewInventory();
    this.getAdminUsersList();
    this.userDepartment = localStorage.getItem('department');
    this.showForwardButton = this.userDepartment === 'HR';
  }


  showForwardedReqTable: boolean = false;
  showServiceRequestTable: boolean = true;
  onRequestSelect(value: string): void {
    console.log("Selected value:", value);
    this.filterForwardReq(value);
  }

  forwardedReq = []
  totalForwarded: any;
  filterForwardReq(requestType: string) {
    const getForwardReqValue: any = {
      requestType: requestType,

    };
    this.inventoryService.getAllForwardedReq(getForwardReqValue).subscribe(response => {
      console.log("Forward Response", response);
      this.forwardedReq = response['data'].records;
      this.totalForwarded = response['data'].totalCounts;
      this.showForwardedReqTable = true;
      this.showServiceRequestTable = false;
    });
  }



  showForwardSuccess = false;
  onSubmitForwardReq(newInventory: any) {
    console.log({ newInventory })
    console.log('REQUEST ID', newInventory.requestId)
    console.log(this.forwardRequestForm.value)
    const forwardReqValues = {
      ...this.forwardRequestForm.value
    };

    const linksArray = [
      forwardReqValues.link1,
      forwardReqValues.link2,
      forwardReqValues.link3
    ].filter(link => link);


    const getForwardRequestFormValue: any = {
      maximumCost: String(forwardReqValues.maximumCost),
      comment: String(forwardReqValues.comment),
      links: linksArray,
      requestId: newInventory.requestId,
      adminId: forwardReqValues.selectedAdmin
    };
    console.log(newInventory?.requestId, 'REQUEST ID')
    this.inventoryService.forwardRequest(getForwardRequestFormValue).subscribe(response => {
      console.log('submitted ', response);
      this.showForwardSuccess = true
    });
  }



  adminUsers: { value: string, viewValue: string, id: string }[] = [];
  getAdminUsersList() {
    const department = "ADMIN";
    const loggedInUsername = localStorage.getItem("username");
    const loggedInUserId = localStorage.getItem("guestID");
    this.crmService.getAnyUsers(department).subscribe(
      (res) => {
        let data = res["data"];
        this.adminUsers = data.map((adminUser) => {
          return {
            value: adminUser.userName,
            viewValue: adminUser.userName,
            id: adminUser._id
          };
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllUsers(): void {
    this.inventoryService.getInvUsers().subscribe(res => {
      let data = res["data"];
      this.users = data.map((user: any) => ({
        value: user.userName,
        viewValue: user.userName,
        id: user._id
      }));
      console.log("users", this.users);

    });
  }





  //Formatted Start DATE
  formatStartDate(date: Date): { day: number; month: number; year: number } {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };
  }
  formSubmitted = false;
  onSubmitForm() {
    console.log(this.newInventoryForm.value);
    const newInventoryValues = {
      ...this.newInventoryForm.value
    };

    const startDate = new Date(newInventoryValues.startDate);
    const formattedStartDate = this.formatStartDate(startDate);

    const approver = this.users.find(user => user.value === newInventoryValues.approverName);
    console.log('approver:', approver);
    const approverId = approver ? approver.id : "null";
    console.log({ approverId });

    const linksArray = [
      newInventoryValues.link1,
      newInventoryValues.link2,
      newInventoryValues.link3
    ].filter(link => link);

    const getNewInventoryFormValue: any = {
      assetCategory: String(newInventoryValues.assetCategory),
      projectName: String(newInventoryValues.projectName),
      startDate: {
        day: startDate.getDate(),
        month: startDate.getMonth() + 1,
        year: startDate.getFullYear()
      },
      links: linksArray,
      urgency: newInventoryValues.urgency,
      approverName: String(newInventoryValues.approverName),
      approverId: approverId
    };


    this.inventoryService.newInventoryRequest(getNewInventoryFormValue).subscribe(response => {
      console.log('submitted ', response);
      this.formSubmitted = true;
      this.showForwardedReqTable = false;
      this.showServiceRequestTable = true;
      this.getNewInventory()

    }, error => {
      console.log('Error', error);
    });
  }



  selectedInventory: any = {};
  newInventories = []
  totalNewInventory: any;
  getNewInventory() {
    console.log("loggged")
    this.inventoryService.getNewInventoryRequest().subscribe(response => {
      console.log("Response", response);
      this.newInventories = response['data'].records
      this.totalNewInventory = response['data'].totalCounts
      console.log(this.newInventories)
      this.showForwardedReqTable = false;
      this.showServiceRequestTable = true;
    })
  }


  acceptRequest(requestId: any) {
    const acceptRequest = {
      action: 'Accept',
      requestId: requestId,
      requestType: 'New Inventory Request',
    }
    this.inventoryService.adminAction(acceptRequest).subscribe(response => {
      console.log("called");
      // toast.success('Request has been accepted')
      this.getNewInventory();
    })
  }

  declineRequest(requestId: any) {
    const declineRequest = {
      action: 'Rejected',
      requestId: requestId,
      requestType: 'New Inventory Request',
    }
    this.inventoryService.adminAction(declineRequest).subscribe(response => {
      console.log("called")
      this.getNewInventory();
    })
  }

}






