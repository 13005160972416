import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LiveProjectService } from 'src/app/service/live-project.service';

import { HeaderComponent } from '../header/header.component';

@Component({
    selector: 'app-live-project-list',
    templateUrl: './live-project-list.component.html',
    styleUrls: ['./live-project-list.component.css'],
    standalone: true,
    imports: [HeaderComponent]
})
export class LiveProjectListComponent implements OnInit {

  constructor(private router:Router,private liveProjectService:LiveProjectService) { }
projectList=[];
  ngOnInit(): void {
this.liveProjectService.getAllLiveProjects().subscribe(resp=>{
  console.log(resp['data']);
  this.projectList=resp['data'];
})
  }


  onClickProject(project){
    this.router.navigate(["create-project/"+project._id]);
  }



//   projectList=[
//     {name:"project 100",description:"Some description about the project abcd"},
//     {name:"project 2",description:"Some333 description about the project"},
//     {name:"project 3",description:"Some description about the project"},
//     {name:"project 1",description:"Some  444description about the project"},
//     {name:"project 0",description:"Some description about the project"},
//     {name:"project 66",description:"Some f f fdescription about the project"},
//     {name:"project 7",description:"Some description about the project"},
//     {name:"project 1",description:"Some  fffdescription about the project"},
//     {name:"project 4",description:"Some bbdescription about the project"},
//     {name:"project 41",description:"Some bbdescription about the project"},
//     {name:"project 4",description:"Some bbdescription about the project"},
//     {name:"project 4",description:"Some bbdescription about the project"},
//     {name:"project2",description:"Some xyz about the project"},
//     {name:"project 4",description:"Some bbdescription about the project"},
//     {name:"project3",description:"Some bbdescription abcdse about the project"},
//     {name:"project 4",description:"Some bbdescription  hhh about the project"},
//     {name:"project ABC",description:"Some bbdescription about the project"},
//     {name:"project 4",description:"Some bbdescription  k l kl kabout the project"}
// ]
}
