import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  HostListener,
} from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd, RouterLink, RouterOutlet } from "@angular/router";
import { DataFilterService } from "src/app/data-filter.service";
import { MediaService } from "src/app/service/media.service";
import { alertModal, alertModalWithoutConfirm } from "../helpers/alert";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { NgClass, DatePipe } from "@angular/common";

@Component({
    selector: "app-play-list",
    templateUrl: "./play-list.component.html",
    styleUrls: ["./play-list.component.css"],
    standalone: true,
    imports: [
    RouterLink,
    NgClass,
    NgxDocViewerModule,
    ReactiveFormsModule,
    FormsModule,
    RouterOutlet,
    DatePipe
],
})
export class PlayListComponent implements AfterViewInit {
  AllPlayList: any = [];
  selectPlayList: boolean = false;
  notFoundResult: boolean = false;
  pdfDisplay: string = "none";
  pdfUrl: string = "";
  excelUrl: string = "";
  pptxUrl: string = "";
  docsUrl: string = "";
  jsUrl: string = "";
  PdfDetails = [];
  isHovered = false;
  searchTerm: string;
  filteredName: string;
  searchHistory: string[];
  mostRecentSearch: string | null;
  recentFiveTags: string[];
  client_names: any[];
  uploader_names = [];
  completePath: any;
  cardData;
  orginalFileName: string = "";
  orginal: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mediaService: MediaService,
    private el: ElementRef,
    private dataFilter: DataFilterService
  ) {}
  ngOnInit() {
    history.pushState(null, document.title, location.href);
    let loc = window.location;
    if (loc.origin == "http://localhost:4200") {
      const newURL = window.location.href.replace(
        "http://localhost:4200",
        "https://link-test.iiclab.com/#/collection/"
      );
      this.urlLink = newURL;
    } else {
      this.urlLink = loc.href;
    }

    if (localStorage.getItem("department") == "admin") {
      this.mediaService.getAdminCollection().subscribe((resp) => {
        console.log("admin collections", resp);
        this.mediaService.mediaData = resp["data"];
        this.cardData = this.mediaService.mediaData;
        console.log(this.cardData, "cardData");

        this.cardData?.map((card) => {
          this.uploader_names.push(card);
          if (
            card.extension === ".pptx" ||
            card.extension === ".pdf" ||
            card.extension === ".docx" ||
            card.extension === ".xlsx" ||
            card.extension == ".js" ||
            card.extension == ".git" ||
            card.extension == ".html"
          ) {
            let url = card.ogFileUrl;
            var filename = url.substring(url.lastIndexOf("/") + 1);
            this.orginal = true;
            this.orginalFileName = filename;
            this.PdfDetails.push({
              filename: filename,
              orginal: card.ogFileUrl,
            });

            if (card.extension === ".xlsx") {
              this.excelUrl = card.ogFileUrl.replace(/^https?:\/\//, "");
            }
            if (card.extension === ".pptx") {
              this.pptxUrl = card.ogFileUrl;
            }
            if (card.extension === ".pdf") {
              this.pdfUrl = card.ogFileUrl;
            }
            if (card.extension === ".docx") {
              this.docsUrl = card.ogFileUrl;
            }
            if (card.extension === ".js") {
              this.jsUrl = card.ogFileUrl.replace(/^https?:\/\//, "");
            }
            this.pdfDisplay = this.pdfDisplay === "none" ? "inline" : "none";
          }
        });
      });
    } else {
      this.mediaService.getUserCollection([]).subscribe((resp) => {
        console.log("user collections", resp);
        this.mediaService.mediaData = resp["data"].media;
        this.cardData = this.mediaService.mediaData;
        console.log("cadr", this.cardData);
        this.cardData.map((card) => {
          this.uploader_names.push(card);

          if (
            card.extension === ".pptx" ||
            card.extension === ".pdf" ||
            card.extension === ".docx" ||
            card.extension === ".xlsx" ||
            card.extension == ".js" ||
            card.extension == ".git" ||
            card.extension == ".html"
          ) {
            let url = card.ogFileUrl;

            var filename = url.substring(url.lastIndexOf("/") + 1);
            this.orginal = true;
            this.orginalFileName = filename;

            let excel = document.getElementById("thumbnail");

            this.PdfDetails.push({
              filename: filename,
              orginal: card.ogFileUrl,
            });

            if (card.extension === ".xlsx") {
              this.excelUrl = card.ogFileUrl.replace(/^https?:\/\//, "");
            }
            if (card.extension === ".pptx") {
              this.pptxUrl = card.ogFileUrl;
            }
            if (card.extension === ".pdf") {
              this.pdfUrl = card.ogFileUrl;
            }
            if (card.extension === ".docx") {
              this.docsUrl = card.ogFileUrl;
            }
            if (card.extension === ".js") {
              this.jsUrl = card.ogFileUrl.replace(/^https?:\/\//, "");
            }
            this.pdfDisplay = this.pdfDisplay === "none" ? "inline" : "none";
          }
        });
      });
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentPath = this.router.url;
        console.log("Current path:", currentPath);
      }
    });
  }

  @HostListener("window:popstate", ["$event"]) onClickBack(
    event: PopStateEvent
  ) {
    history.pushState(null, document.title, location.href);
  }

  @HostListener("window:beforeunload", ["$event"]) onBeforeUnload(
    event: BeforeUnloadEvent
  ) {
    history.pushState(null, document.title, location.href);
  }

  showPlayList: any = [];
  urlLink: any;
  // displayPlayList(id: any) {
  //   this.selectPlayList = true;
  //   console.log(id);
  //   this.showPlayList = this.AllPlayList.filter((item) => item._id == id);
  //   console.log(this.showPlayList, "PLAYLIST");

  //   this.showPlayList = this.showPlayList[0].media;
  //   console.log(this.showPlayList);
  // }

  currentCardData: any;
  filteredList: any = [];
  isPreviewFile: boolean = false;
  displayPlayList(data: any) {
    // this.selectPlayList = true;
    console.log({ data });
    this.showPlayList = data;
    this.dataFilter.dataPass(this.filteredList);
    this.router.navigate(["single-playlist", data]);
    this.mediaService.selectedMedia = this.cardData[data];
    const url = `/single-playlist/${data}`;

    // Navigate to the URL
    // this.router.navigateByUrl(url);
    // console.log(this.AllPlayList, "CARDdata========");
    // this.filteredList = this.AllPlayList.filter((cards) => cards._id === id);
    // console.log(this.filteredList, "Filtered=====================");
    // if (this.filteredList.length > 0) {
    //   const card = this.filteredList[0];
    //   this.mediaService.mediaData = card;
    //   this.uploader_names.push(card);

    //   if (
    //     card.extension === ".pptx" ||
    //     card.extension === ".pdf" ||
    //     card.extension === ".docx" ||
    //     card.extension === ".doc" ||
    //     card.extension === ".xlsx" ||
    //     card.extension === ".xls" ||
    //     card.extension == ".js" ||
    //     card.extension == ".git" ||
    //     card.extension == ".html"
    //   ) {
    //     let url = card.ogFileUrl;
    //     var filename = url.substring(url.lastIndexOf("/") + 1);
    //     this.orginal = true;
    //     this.orginalFileName = filename;

    //     this.PdfDetails.push({
    //       filename: filename,
    //       orginal: card.ogFileUrl,
    //     });

    //     if (card.extension === ".xlsx" || card.extension === ".xls") {
    //       this.excelUrl = card.ogFileUrl.replace(/^https?:\/\//, "");
    //     }
    //     if (card.extension === ".pptx") {
    //       this.pptxUrl = card.ogFileUrl;
    //     }
    //     if (card.extension === ".pdf") {
    //       this.pdfUrl = card.ogFileUrl;
    //     }
    //     if (card.extension === ".docx" || card.extension === ".doc") {
    //       this.docsUrl = card.ogFileUrl;
    //     }
    //     if (card.extension === ".js") {
    //       this.jsUrl = card.ogFileUrl.replace(/^https?:\/\//, "");
    //     }
    //     this.pdfDisplay = this.pdfDisplay === "none" ? "inline" : "none";
    //   }
    // }

    // this.PdfDetails = this.PdfDetails.filter(
    //   (detail, index, self) =>
    //     index === self.findIndex((d) => d.filename === detail.filename)
    // );
  }

  selectedCollection = "";
  collectionType = "";
  collectionTags = "";
  PlayListNames: any = [];

  selectedPlayList() {
    console.log(this.collectionTags);
    console.log(this.collectionTags, "selected");
    // this.PlayListNames[index];
    let data = this.PlayListNames.filter(
      (item) => item.name == this.collectionTags
    );
    console.log(data);
    // console.log(this.PlayListNames[index]);
    this.collectionType = data[0].type;
    this.collectionTags = data[0].name;
    this.selectedCollection = this.collectionTags;
  }
  password: string = "";
  errorMessage: string = "";
  isFolderPreview: boolean = false;
  currentFolder: any;
  onSubmit() {
    this.isFolderPreview = true;
    console.log("Password submitted:", this.password);
    let pass = localStorage.getItem("tempPass");
    if (pass && !this.password) {
      this.password = pass;
    }
    let shareId = "";
    this.route.queryParams.subscribe((params) => {
      const id = params["id"];
      if (id) {
        localStorage.setItem("currentSecretPlaylistId", id);
        localStorage.setItem("currentSecretPlaylistShareId", params["shareId"]);
      }
      shareId = params["shareId"];
    });
    let body = { shareId: shareId, password: this.password };
    console.log(body);

    this.mediaService.accessPlayList(body).subscribe(
      (res) => {
        console.log(res, "after password access");
        this.currentFolder = res["data"];
        this.AllPlayList = res["data"].media;
        localStorage.setItem("secretData", JSON.stringify(res["data"]));

        this.closeModal();
        alertModalWithoutConfirm("success", " Successfully login");
        this.dataFilter.guestUserDisable("playlist-user-login");
        this.route.queryParams.subscribe((params) => {
          const id = params["id"];
          console.log("Query parameter id:", id);
        });
      },
      (err) => {
        console.log(err);
        this.errorMessage = err.error.message;
        localStorage.removeItem("guestLink");
        localStorage.removeItem("tempPass");
        alertModal("error", err.error.message);
      }
    );
    window.history.forward();
  }

  ngAfterViewInit() {
    let guestLink = localStorage.getItem("guestLink");
    this.route.queryParams.subscribe((params) => {
      console.log(params["id"]);
      if (params["id"]) {
        if (guestLink != this.urlLink) {
          this.el.nativeElement.querySelector("#modalPass").click();
        } else {
          this.onSubmit();
        }
      } else {
        let secretId = localStorage.getItem("currentSecretPlaylistId");
        let secretData = JSON.parse(localStorage.getItem("secretData"));
        this.currentFolder = secretData;
        this.AllPlayList = secretData.media;
        this.dataFilter.guestUserDisable("playlist-user-login");
        this.route.queryParams.subscribe((params) => {
          const id = secretId;
          console.log("Query parameter id:", id);
        });
      }
    });
  }

  closeModal() {
    const modalElement = document.getElementById("modalPass");
    const closeModal = document.getElementById("closeModal");
    const staticBackdrop = document.getElementById("staticBackdrop");
    const modalElement1 = document.querySelector(".modal.show");

    if (closeModal) {
      closeModal.click();
    }
    if (modalElement) {
      modalElement.classList.remove("show");
      modalElement.style.display = "none";
    }

    if (modalElement1) {
      modalElement.classList.remove("show");
      modalElement.style.display = "none";
    }

    if (staticBackdrop) {
      staticBackdrop.style.display = "none";
    }
  }

  working(id) {
    console.log("why ", id);

    var selectedCard = document.getElementById("card-" + id.toString());
    console.log(this.cardData);
    this.dataFilter.dataPass(this.filteredList);
    this.router.navigate(["single-playlist", id]);
    this.mediaService.selectedMedia = this.cardData[id];
    const url = `/single-playlist/${id}`;

    // Navigate to the URL
    this.router.navigateByUrl(url);
    console.log("===========+++++================");

    console.log("===========+++++================");
  }

  activeVideoIndex: number = -1;
  showControls(i) {
    this.activeVideoIndex = i;
    const videoElement = document.getElementsByTagName("video");
    const videoElements = document.getElementsByTagName("video");
    for (let i = 0; i < videoElements.length; i++) {
      const videoElement = videoElements[i];
      videoElement.addEventListener("mouseenter", function () {
        videoElement.play();
        const videoIndex = i;
        console.log("Hovered video index:", videoIndex);
      });
    }
    if (videoElement && this.activeVideoIndex == i) {
      // videoElement.play();
    }
  }

  hideControls(i) {
    this.activeVideoIndex = -1;
    const videoElement = document.getElementsByTagName("video");
    videoElement[i].pause();
    const videoElements = document.getElementsByTagName("video");
    for (let i = 0; i < videoElements.length; i++) {
      const videoElement = videoElements[i];
      videoElement.addEventListener("mouseout", function () {
        videoElement.pause();
        const videoIndex = i;
        console.log("Hovered video index:", videoIndex);
      });
    }

    if (videoElement && this.activeVideoIndex !== i) {
      // videoElement.pause();
    }
  }
}
