import {
  AfterViewInit,
  Component,
  DoCheck,
  Input,
  OnInit,
} from "@angular/core";
import {
  Router,
  NavigationStart,
  ActivatedRoute,
  RouterLink,
  RouterLinkActive,
} from "@angular/router";
import { UserService } from "src/app/service/user.service";
import { ChatService } from "src/app/service/chat.service";
import { DataFilterService } from "src/app/data-filter.service";
import { OnlineUsersService } from "src/app/service/online-users.service";
import { Store } from "@ngrx/store";
import { LoginState } from "src/app/store/counter.state";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgIf, NgFor, NgClass } from "@angular/common";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    ReactiveFormsModule,
    FormsModule,
    NgClass,
  ],
})
export class HeaderComponent implements OnInit {
  showOnlineUsers: boolean = false;
  onlineUserData: any[];
  playAccess: boolean = false;
  guestId: any;
  userInfo: any;
  // username: string = "";
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public chatService: ChatService,
    private userService: UserService,
    private dataFilter: DataFilterService,
    private onlineUser: OnlineUsersService,
    private store: Store<{ login: LoginState }>
  ) {}

  // hideHeader = false;
  @Input() activeLink: number = -1;
  username = localStorage.getItem("username");
  profileImg =
    localStorage.getItem("profileImg") != "undefined" &&
    localStorage.getItem("profileImg") != ""
      ? localStorage.getItem("profileImg")
      : "../../assets/default-profile.jpeg";

  ngOnInit(): void {
    this.isAdminAndSales();
    this.dataFilter.disableUser.subscribe((res) => {
      console.log("header play list checking =>", res);
      if (res.data == "playlist-user-login") {
        this.playAccess = true;
        let nav = document.getElementById("navbarMenu");
        nav.style.setProperty("display", "none", "important");
        let form = document.getElementById("formDis");
        form.style.setProperty("display", "none", "important");
        let online = document.getElementById("onlineUser");
        online.style.setProperty("display", "none", "important");
      }
    });

    let menu = document.getElementById("navbarMenu");
    let links = menu.querySelectorAll(".nav-link");
    links[this.activeLink]?.classList.add("nav-active");
  }

  ngAfterViewInit() {
    this.onlineUser.getUsers().subscribe((res) => {
      this.onlineUserData = res.data.filter(
        (user) => this.guestId !== user.userId
      );
    });

    this.guestId = localStorage.getItem("guestID");
  }
  getOnlineUsers() {
    this.showOnlineUsers = !this.showOnlineUsers;
  }
  guestToken: any = "";
  onClickLogout() {
    this.store.select("login").subscribe((info) => {
      this.guestToken = info;
    });

    localStorage.clear();

    this.router.navigate(["/login"]);
    try {
      this.userService
        .removeToken({
          id: localStorage.getItem("guestID"),
          token: localStorage.getItem("fcm_token"),
        })
        .subscribe(async (resp) => {
          localStorage.clear();
          this.router.navigate(["/login"]);
        });
    } catch (error) {}
  }

  onClickNavTab(eve) {
    // console.log(eve.target.outerText);
  }
  isAdminSales: boolean = true;
  isAdminAndSales() {
    const department = localStorage.getItem("department");
    if (department === "TESTER" || department === "EVENTS") {
      this.isAdminSales = false;
    } else {
      this.isAdminSales = true;
    }
  }
}
