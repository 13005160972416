<app-header [activeLink]=3 ></app-header>
<div class="container">
  <div class="row">
    <div class="col">
      <div style="display:flex ;">
        <h3 style="text-align: center; width:75%">Live projects</h3>
        <!-- <button>Add new Live project</button> -->
      </div>

      <div class="d-flex flex-wrap">
        @for (project of projectList; track project; let i = $index) {
<div (click)="onClickProject(project)" style="    box-sizing: border-box;
            padding: 1rem;" class=" col-md-3">
            <div class="project"><p><strong>{{project.title}}</strong></p>
            <p>{{project.description}}</p></div>
          </div>
        }
      </div>
    </div>
  </div>
</div>



