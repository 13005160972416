import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    standalone: true,
    imports: []
})
export class FooterComponent implements OnInit {

  version = localStorage.getItem('version')
  constructor(public router:Router) { }

  ngOnInit(): void {
  }

}
