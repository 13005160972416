<div class="container-banner" id="containerBanner">
  <div class="leads-count">
    <div class="import-btn-content">
      <h1>{{ totalLeads }} Leads</h1>
      <button
        class="btn btn-primary create-leads import-btn"
        type="button"
        (click)="importData()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M21 14a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-4a1 1 0 0 0-2 0v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1m-9.71 1.71a1 1 0 0 0 .33.21a.94.94 0 0 0 .76 0a1 1 0 0 0 .33-.21l4-4a1 1 0 0 0-1.42-1.42L13 12.59V3a1 1 0 0 0-2 0v9.59l-2.29-2.3a1 1 0 1 0-1.42 1.42Z"
          />
        </svg>
        Import File
      </button>
    </div>

    <div class="menu-field-container">
      <div class="search-box">
        <div class="search-input">
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.4">
              <circle
                cx="11.0795"
                cy="11.3768"
                r="7.62168"
                transform="rotate(-45 11.0795 11.3768)"
                stroke="#092C4C"
                stroke-width="1.72802"
                stroke-linecap="round"
              />
              <path
                d="M16.6416 16.9393L19.8465 20.1443"
                stroke="#092C4C"
                stroke-width="1.72802"
                stroke-linecap="round"
              />
            </g>
          </svg>
          <input
            type="search"
            placeholder="Please hit enter after typing..."
            [(ngModel)]="searchQuery"
            (keyup.enter)="onSearchInputChange()"
          />
          @if(searchQuery){
          <img
            class="search-close"
            style="cursor: pointer"
            src="../../../assets/closeIcon.png"
            alt=""
            (click)="clearSearch()"
          />}
        </div>
        <button
          class="btn btn-primary create-leads import"
          style="display: none"
          type="button"
          (click)="importData()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M21 14a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-4a1 1 0 0 0-2 0v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1m-9.71 1.71a1 1 0 0 0 .33.21a.94.94 0 0 0 .76 0a1 1 0 0 0 .33-.21l4-4a1 1 0 0 0-1.42-1.42L13 12.59V3a1 1 0 0 0-2 0v9.59l-2.29-2.3a1 1 0 1 0-1.42 1.42Z"
            />
          </svg>
          Import File
        </button>
      </div>

      <div class="menu-type-filter">
        <div class="month-type">
          <mat-form-field
            class="example-form-field"
            style="
              --mat-form-field-filled-with-label-container-padding-bottom: 0px;
              --mat-form-field-container-height: 0px;
              --mat-form-field-filled-with-label-container-padding-top: 0px;
              --mdc-filled-text-field-container-color: #fff;
              --mat-form-field-container-vertical-padding: 10px;
            "
          >
            <!-- <mat-label>Enter Date</mat-label> -->
            <mat-date-range-input [rangePicker]="rangePicker">
              <input
                matStartDate
                placeholder="Start date"
                [(ngModel)]="startDate"
              />
              <input matEndDate placeholder="End date" [(ngModel)]="endDate" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="rangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button
                  mat-button
                  matDateRangePickerCancel
                  (click)="resetCalendar()"
                >
                  Reset
                </button>
                <button
                  style="
                    --mdc-protected-button-container-color: #fecc28;
                    --mdc-protected-button-label-text-color: #000;
                  "
                  mat-raised-button
                  color="primary"
                  matDateRangePickerApply
                  (click)="searchLeadsByDateRange()"
                >
                  Apply
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
        </div>

        <button
          class="btn btn-primary create-leads"
          (click)="resetClose(); openCreateLeadModal()"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.4em"
            height="1.4em"
            viewBox="0 0 32 32"
          >
            <path fill="currentColor" d="M17 15V8h-2v7H8v2h7v7h2v-7h7v-2z" />
          </svg>
          Create leads
        </button>

        <div class="dropdown iic-sales-search">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="sales-rep-button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{
              (selectedSearchIICUser$ | async)
                ? (selectedSearchIICUser$ | async)
                : "Sales/CS"
            }}

            <svg
              width="11"
              height="7"
              viewBox="0 0 11 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.6"
                d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                stroke="#092C4C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <div class="dropdown-menu" aria-labelledby="sales-rep-button">
            <a class="dropdown-item" (click)="updateSearchUser('All')">All</a>
            @for (user of iicUsers; track user) {
            <a class="dropdown-item" (click)="updateSearchUser(user)">
              {{ user.username }}
            </a>
            }
          </div>
        </div>
        <div class="dropdown lead-status-search">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="leadStatusButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <p>
              {{
                (selectedSearchStatus$ | async)
                  ? (selectedSearchStatus$ | async)
                  : "Status"
              }}
            </p>
            <svg
              width="11"
              height="7"
              viewBox="0 0 11 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.6"
                d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                stroke="#092C4C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>

          <div class="dropdown-menu" aria-labelledby="leadStatusButton">
            <a class="dropdown-item" (click)="UpdateSearchStatus('All')">All</a>
            <a
              class="dropdown-item"
              (click)="UpdateSearchStatus('Outreach Done')"
              >Outreach Done</a
            >
            <a
              class="dropdown-item"
              (click)="UpdateSearchStatus('Introduction Meeting')"
              >Introduction Meeting</a
            >
            <a
              class="dropdown-item btn btn-secondary"
              data-toggle="tooltip"
              data-placement="left"
              title="Tooltip on left"
              (click)="UpdateSearchStatus('Initial Deck Presentation')"
              >Initial Deck Presentation</a
            >
            <a
              class="dropdown-item"
              (click)="UpdateSearchStatus('Second round Deck Presentation')"
              >Second round Deck Presentation</a
            >

            <a
              class="dropdown-item"
              (click)="UpdateSearchStatus('Cost Sheet Sent')"
              >Cost Sheet Sent</a
            >
            <a class="dropdown-item" (click)="UpdateSearchStatus('Qualified')"
              >Qualified</a
            >
            <a
              class="dropdown-item"
              (click)="UpdateSearchStatus('Following up')"
              >Following up</a
            >
            <a class="dropdown-item" (click)="UpdateSearchStatus('On Hold')"
              >On Hold</a
            >
            <a
              class="dropdown-item"
              (click)="UpdateSearchStatus('Lead Lost - To Competitors')"
              >Lead Lost - To Competitors</a
            >
            <a
              class="dropdown-item"
              (click)="UpdateSearchStatus('Lead Lost - Cost')"
              >Lead Lost - Cost</a
            >
            <a
              class="dropdown-item"
              (click)="UpdateSearchStatus('Lead Lost - Idea')"
              >Lead Lost - Idea</a
            >
            <a
              class="dropdown-item"
              (click)="UpdateSearchStatus('Lead Lost - Project Cancelled')"
              >Lead Lost - Project Cancelled</a
            >
          </div>
        </div>

        <div class="toggle-btn-content">
          <div
            class="left-toggle"
            (click)="toggleRightView = !toggleRightView"
            [ngClass]="toggleRightView ? 'toggle-bg ' : ''"
          >
            <svg
              class="left"
              width="15"
              height="15"
              viewBox="0 0 23 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.21143 14.2109L21.8853 14.2109"
                stroke="#030303"
                stroke-width="1.94175"
                stroke-linecap="round"
              />
              <path
                d="M6.21143 1.9174L21.8853 1.9174"
                stroke="#030303"
                stroke-width="1.94175"
                stroke-linecap="round"
              />
              <path
                d="M6.21143 8.06459L21.8853 8.06459"
                stroke="#030303"
                stroke-width="1.94175"
                stroke-linecap="round"
              />
              <path
                d="M1.88879 1.9174L1.88586 1.91741"
                stroke="#030303"
                stroke-width="2.28695"
                stroke-linecap="round"
              />
              <path
                d="M1.88879 8.06459L1.88586 8.0646"
                stroke="#030303"
                stroke-width="2.28695"
                stroke-linecap="round"
              />
              <path
                d="M1.88879 14.2109L1.88586 14.2109"
                stroke="#030303"
                stroke-width="2.28695"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div
            class="right-toggle"
            (click)="toggleRightView = !toggleRightView"
            [ngClass]="!toggleRightView ? 'toggle-bg ' : ''"
          >
            <svg
              class="right"
              width="15"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <rect
                  x="1.15088"
                  y="1.10098"
                  width="7.05637"
                  height="5.01948"
                  rx="0.803109"
                  stroke="#030303"
                  stroke-width="1.67281"
                />
                <rect
                  x="1.15088"
                  y="9.57101"
                  width="7.05637"
                  height="9.457"
                  rx="0.803109"
                  stroke="#030303"
                  stroke-width="1.67281"
                />
                <rect
                  x="18.7144"
                  y="19.0274"
                  width="7.05637"
                  height="5.01948"
                  rx="0.803109"
                  transform="rotate(-180 18.7144 19.0274)"
                  stroke="#030303"
                  stroke-width="1.67281"
                />
                <rect
                  x="18.7144"
                  y="10.5574"
                  width="7.05637"
                  height="9.457"
                  rx="0.803109"
                  transform="rotate(-180 18.7144 10.5574)"
                  stroke="#030303"
                  stroke-width="1.67281"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  @if(isSpinnerLoader){
  <div class="loader-wrapper">
    <span class="loader"></span>
    <h3 class="loader-head">Uploading...</h3>
  </div>
  } @if (leads.length > 0) { @if(toggleRightView){
  <div class="leads-banner">
    @if (!isShimmerLoading){
    <table class="table leads-content">
      <thead>
        <tr>
          <th scope="col">Company</th>
          <th scope="col">Customer Name</th>
          <th scope="col">Contact</th>
          <th scope="col">Status</th>
          <th scope="col" style="width: 160px">Next Follow-up</th>
          <th scope="col" style="width: 160px">Source</th>
          <th scope="col" style="width: 160px">Representative</th>
        </tr>
      </thead>
      <tbody>
        @for (lead of leads; track lead) {
        <tr>
          <td (click)="getSingleLeadData(lead._id)">
            <p class="leads-company">{{ lead.companyName }}</p>
            <span class="date-time">{{ lead.creationTime }}</span>
          </td>
          <td class="customer-name" (click)="getSingleLeadData(lead._id)">
            <p>{{ lead.customerInformation[0]?.name }}</p>
          </td>

          <td (click)="getSingleLeadData(lead._id)">
            <p class="leads-email">
              <svg
                class="em-svg"
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.03577 14.5187C7.51957 14.5187 6.0107 14.526 4.49451 14.5187C2.09937 14.5113 0.392735 12.8047 0.385411 10.4096C0.378086 8.49052 0.370762 6.57148 0.385411 4.65243C0.40006 2.27926 2.12134 0.579956 4.49451 0.579956C7.54887 0.579956 10.5959 0.579956 13.6503 0.579956C16.0234 0.579956 17.7447 2.27926 17.7594 4.65243C17.774 6.5788 17.774 8.5125 17.7594 10.4389C17.7447 12.812 16.0308 14.504 13.6503 14.5113C12.1121 14.5187 10.5739 14.5187 9.03577 14.5187ZM9.05774 13.4127C10.5959 13.4127 12.1341 13.4127 13.6722 13.4127C15.4008 13.4127 16.6314 12.2041 16.646 10.4755C16.6534 8.53447 16.646 6.59345 16.646 4.65243C16.646 4.23493 16.5728 3.83208 16.4263 3.43655C16.3091 3.12892 16.2139 3.14357 15.9941 3.3633C14.5658 4.80625 13.1375 6.23455 11.6946 7.66285C10.1931 9.14974 7.95173 9.14241 6.44286 7.65552C5.68842 6.90841 4.94131 6.15398 4.18688 5.40687C3.49104 4.71103 2.78056 4.01519 2.09204 3.30471C1.90893 3.11427 1.83568 3.15822 1.74779 3.37063C1.572 3.78081 1.49875 4.20563 1.49875 4.65243C1.49875 6.5788 1.49143 8.5125 1.49875 10.4389C1.50607 12.1894 2.72928 13.4053 4.47254 13.4053C6.00338 13.4127 7.5269 13.4127 9.05774 13.4127ZM9.07239 1.68597C7.51225 1.68597 5.94478 1.68597 4.38464 1.68597C3.8353 1.68597 3.3299 1.83979 2.8538 2.11812C2.65604 2.23532 2.66336 2.31589 2.81718 2.4697C4.32605 3.96392 5.81294 5.48744 7.32913 6.96701C8.30331 7.91188 9.83415 7.91188 10.8083 6.96701C12.3318 5.48744 13.8187 3.97125 15.3203 2.4697C15.4741 2.31589 15.4741 2.22799 15.2837 2.11812C14.8076 1.83979 14.3022 1.68597 13.7528 1.6933C12.1927 1.68597 10.6325 1.68597 9.07239 1.68597Z"
                  fill="#030303"
                />
              </svg>
              <span> {{ lead.customerInformation[0]?.emailId }}</span>
            </p>
            <p class="leads-contact-num">
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M3.63129 0.325115C4.13268 0.325115 4.63407 0.31843 5.13547 0.325115C5.9778 0.338485 6.53936 0.886672 6.5661 1.72232C6.59284 2.71842 6.72654 3.69446 7.02738 4.64375C7.20788 5.20531 7.1076 5.70002 6.71986 6.16798C6.27195 6.70948 5.87752 7.28441 5.44298 7.83928C5.32265 7.99304 5.34939 8.1 5.42961 8.25376C6.46582 10.1858 7.94993 11.6565 9.87527 12.6927C10.0424 12.7796 10.1427 12.7663 10.2898 12.6593C10.8714 12.2114 11.4663 11.7769 12.0413 11.3223C12.4357 11.0148 12.8636 10.9212 13.3248 11.0615C14.341 11.3691 15.3772 11.5362 16.4468 11.5629C17.2223 11.583 17.7772 12.1646 17.7906 12.9468C17.8039 13.983 17.8039 15.0192 17.7906 16.0554C17.7772 16.8777 17.1822 17.4526 16.3465 17.4392C11.9878 17.3724 8.25077 15.8014 5.18895 12.7061C2.78227 10.2727 1.35164 7.33121 0.843566 3.94849C0.736603 3.21981 0.676436 2.48443 0.683121 1.74906C0.689806 0.920098 1.26473 0.34517 2.0937 0.3318C2.60846 0.31843 3.11653 0.325115 3.63129 0.325115ZM16.8479 14.5111C16.8479 14.0298 16.8479 13.5551 16.8479 13.0738C16.8413 12.666 16.6942 12.5056 16.2797 12.5056C15.2234 12.4989 14.1939 12.3317 13.1911 12.0108C12.9371 11.9306 12.7499 11.9841 12.5494 12.1379C11.8875 12.6526 11.2123 13.1406 10.5572 13.6554C10.2898 13.8626 10.0558 13.8626 9.76162 13.7156C7.40175 12.5122 5.6168 10.7407 4.41346 8.38078C4.24633 8.05321 4.26639 7.80585 4.487 7.52508C4.99508 6.87661 5.47641 6.20809 5.98449 5.55963C6.15162 5.3457 6.18504 5.13846 6.10482 4.87774C5.7973 3.90838 5.63686 2.91229 5.62348 1.88945C5.6168 1.39475 5.4831 1.26773 4.97502 1.26773C4.07252 1.26773 3.17002 1.26773 2.26751 1.26773C1.75275 1.26773 1.61905 1.40812 1.62573 1.92288C1.67253 4.02872 2.08033 6.0677 3.02963 7.95293C5.78393 13.388 10.2162 16.2359 16.2998 16.4899C16.6808 16.5033 16.8479 16.3362 16.8546 15.9484C16.8546 15.4671 16.8479 14.9924 16.8479 14.5111Z"
                    fill="#030303"
                  />
                </g>
              </svg>
              <span> {{ lead.customerInformation[0]?.phoneNo }}</span>
            </p>
          </td>

          <td (click)="getSingleLeadData(lead._id)">
            <p
              class="status"
              [ngClass]="{
                blue: [
                  'Outreach Done',
                  'Introduction Meeting',
                  'Initial Deck Presentation',
                  'Second round Deck Presentation',
                  'Cost Sheet Sent'
                ].includes(lead.leadStatus),
                green: ['Qualified', 'Following up'].includes(lead.leadStatus),
                orange: lead.leadStatus === 'On Hold',
                red: [
                  'Lead Lost - To Competitors',
                  'Lead Lost - Cost',
                  'Lead Lost - Idea',
                  'Lead Lost - Project Cancelled'
                ].includes(lead.leadStatus)
              }"
              title="{{ getTooltipText(lead.leadStatus) }}"
            >
              {{ lead.leadStatus }}
            </p>
            <p></p>
          </td>

          <td (click)="getSingleLeadData(lead._id)">
            <p class="date-follow">{{ lead.followUpDate }}</p>
          </td>
          <td (click)="getSingleLeadData(lead._id)">
            <p class="source">{{ lead.leadSource }}</p>
          </td>

          <td class="d-flex justify-content-between align-items-center">
            <p class="sales-name mb-0">{{ lead.representatorName }}</p>
            @if (lead.creatorId === guestID ){
            <span (click)="deleteLead(lead._id)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="red"
                  d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z"
                />
              </svg>
            </span>
            }
          </td>
        </tr>
        }
      </tbody>
    </table>
    }
  </div>
  } } @if(isShimmerLoading) { @for (lead of shimmerLoadingValue; track lead; let
  i = $index) {
  <span class="d-flex">
    <div class="shimmer-card">
      <div class="shimmerBG media"></div>
      <div class="p-32">
        <div class="shimmerBG title-line"></div>
        <div class="shimmerBG title-line end"></div>

        <div class="shimmerBG content-line m-t-24"></div>
        <div class="shimmerBG content-line"></div>
        <div class="shimmerBG content-line"></div>
        <div class="shimmerBG content-line"></div>
        <div class="shimmerBG content-line end"></div>
      </div>
    </div>
  </span>
  } }
  <!-- GRID VIEW -->
  @if(!toggleRightView){
  <div class="grid-banner">
    @for (lead of leads; track lead) {
    <div class="grid-content" (click)="getSingleLeadData(lead._id)">
      <div class="company-time-col">
        <p class="leads-company">{{ lead.companyName }}</p>
        <span class="date-time">{{ lead.creationTime }}</span>
      </div>
      <div class="contact-email-col">
        <p class="leads-email">
          <svg
            width="18"
            height="15"
            viewBox="0 0 18 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.03577 14.5187C7.51957 14.5187 6.0107 14.526 4.49451 14.5187C2.09937 14.5113 0.392735 12.8047 0.385411 10.4096C0.378086 8.49052 0.370762 6.57148 0.385411 4.65243C0.40006 2.27926 2.12134 0.579956 4.49451 0.579956C7.54887 0.579956 10.5959 0.579956 13.6503 0.579956C16.0234 0.579956 17.7447 2.27926 17.7594 4.65243C17.774 6.5788 17.774 8.5125 17.7594 10.4389C17.7447 12.812 16.0308 14.504 13.6503 14.5113C12.1121 14.5187 10.5739 14.5187 9.03577 14.5187ZM9.05774 13.4127C10.5959 13.4127 12.1341 13.4127 13.6722 13.4127C15.4008 13.4127 16.6314 12.2041 16.646 10.4755C16.6534 8.53447 16.646 6.59345 16.646 4.65243C16.646 4.23493 16.5728 3.83208 16.4263 3.43655C16.3091 3.12892 16.2139 3.14357 15.9941 3.3633C14.5658 4.80625 13.1375 6.23455 11.6946 7.66285C10.1931 9.14974 7.95173 9.14241 6.44286 7.65552C5.68842 6.90841 4.94131 6.15398 4.18688 5.40687C3.49104 4.71103 2.78056 4.01519 2.09204 3.30471C1.90893 3.11427 1.83568 3.15822 1.74779 3.37063C1.572 3.78081 1.49875 4.20563 1.49875 4.65243C1.49875 6.5788 1.49143 8.5125 1.49875 10.4389C1.50607 12.1894 2.72928 13.4053 4.47254 13.4053C6.00338 13.4127 7.5269 13.4127 9.05774 13.4127ZM9.07239 1.68597C7.51225 1.68597 5.94478 1.68597 4.38464 1.68597C3.8353 1.68597 3.3299 1.83979 2.8538 2.11812C2.65604 2.23532 2.66336 2.31589 2.81718 2.4697C4.32605 3.96392 5.81294 5.48744 7.32913 6.96701C8.30331 7.91188 9.83415 7.91188 10.8083 6.96701C12.3318 5.48744 13.8187 3.97125 15.3203 2.4697C15.4741 2.31589 15.4741 2.22799 15.2837 2.11812C14.8076 1.83979 14.3022 1.68597 13.7528 1.6933C12.1927 1.68597 10.6325 1.68597 9.07239 1.68597Z"
              fill="#030303"
            />
          </svg>
          <span> {{ lead.customerInformation[0].emailId }}</span>
        </p>
        <p class="leads-contact-num">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M3.63129 0.325115C4.13268 0.325115 4.63407 0.31843 5.13547 0.325115C5.9778 0.338485 6.53936 0.886672 6.5661 1.72232C6.59284 2.71842 6.72654 3.69446 7.02738 4.64375C7.20788 5.20531 7.1076 5.70002 6.71986 6.16798C6.27195 6.70948 5.87752 7.28441 5.44298 7.83928C5.32265 7.99304 5.34939 8.1 5.42961 8.25376C6.46582 10.1858 7.94993 11.6565 9.87527 12.6927C10.0424 12.7796 10.1427 12.7663 10.2898 12.6593C10.8714 12.2114 11.4663 11.7769 12.0413 11.3223C12.4357 11.0148 12.8636 10.9212 13.3248 11.0615C14.341 11.3691 15.3772 11.5362 16.4468 11.5629C17.2223 11.583 17.7772 12.1646 17.7906 12.9468C17.8039 13.983 17.8039 15.0192 17.7906 16.0554C17.7772 16.8777 17.1822 17.4526 16.3465 17.4392C11.9878 17.3724 8.25077 15.8014 5.18895 12.7061C2.78227 10.2727 1.35164 7.33121 0.843566 3.94849C0.736603 3.21981 0.676436 2.48443 0.683121 1.74906C0.689806 0.920098 1.26473 0.34517 2.0937 0.3318C2.60846 0.31843 3.11653 0.325115 3.63129 0.325115ZM16.8479 14.5111C16.8479 14.0298 16.8479 13.5551 16.8479 13.0738C16.8413 12.666 16.6942 12.5056 16.2797 12.5056C15.2234 12.4989 14.1939 12.3317 13.1911 12.0108C12.9371 11.9306 12.7499 11.9841 12.5494 12.1379C11.8875 12.6526 11.2123 13.1406 10.5572 13.6554C10.2898 13.8626 10.0558 13.8626 9.76162 13.7156C7.40175 12.5122 5.6168 10.7407 4.41346 8.38078C4.24633 8.05321 4.26639 7.80585 4.487 7.52508C4.99508 6.87661 5.47641 6.20809 5.98449 5.55963C6.15162 5.3457 6.18504 5.13846 6.10482 4.87774C5.7973 3.90838 5.63686 2.91229 5.62348 1.88945C5.6168 1.39475 5.4831 1.26773 4.97502 1.26773C4.07252 1.26773 3.17002 1.26773 2.26751 1.26773C1.75275 1.26773 1.61905 1.40812 1.62573 1.92288C1.67253 4.02872 2.08033 6.0677 3.02963 7.95293C5.78393 13.388 10.2162 16.2359 16.2998 16.4899C16.6808 16.5033 16.8479 16.3362 16.8546 15.9484C16.8546 15.4671 16.8479 14.9924 16.8479 14.5111Z"
                fill="#030303"
              />
            </g>
          </svg>
          {{ lead.customerInformation[0].phoneNo }}
        </p>
      </div>
      <div class="status-col">
        <p
          class="status"
          [ngClass]="{
            blue: [
              'Outreach Done',
              'Introduction Meeting',
              'Initial Deck Presentation',
              'Second round Deck Presentation',
              'Sent'
            ].includes(lead.leadStatus),
            green: ['Qualified', 'Following up'].includes(lead.leadStatus),
            orange: lead.leadStatus === 'On Hold',
            red: [
              'Lead Lost - To Competitors',
              'Lead Lost - Cost',
              'Lead Lost - Idea',
              'Lead Lost - Project Cancelled'
            ].includes(lead.leadStatus)
          }"
          style="width: 250px"
        >
          {{ lead.leadStatus }}
        </p>
      </div>
    </div>
    }
  </div>
  }

  <!-- Result not Found -->
  @if (notFoundResult) {
  <div class="notFoundData">
    <img src="../../../assets/not1.png" width="400px" height="auto" />
  </div>
  } @if (!notFoundResult){
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
        <a
          class="page-link"
          href="javascript:void(0)"
          aria-label="Previous"
          (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
          >
            <path
              fill="currentColor"
              d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z"
            />
          </svg>
        </a>
      </li>
      @for(page of visiblePages; track page){
      <li
        class="page-item"
        [ngClass]="{
          active: currentPage === page,
          hidden: !isPageVisible(page)
        }"
      >
        <a
          class="page-link"
          href="javascript:void(0)"
          (click)="pageChanged(page)"
          >{{ page }}</a
        >
      </li>
      }

      <li
        class="page-item"
        [ngClass]="{ disabled: currentPage === totalPages }"
      >
        <a
          class="page-link"
          href="javascript:void(0)"
          aria-label="Next"
          (click)="
            currentPage !== totalPages ? pageChanged(currentPage + 1) : null
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
          >
            <path
              fill="currentColor"
              d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z"
            />
          </svg>
        </a>
      </li>
    </ul>
  </nav>
  }
</div>

<!-- CREATE LEADS MODAL -->
<div
  class="modal fade new-lead-banner"
  #createLeadModal
  id="editLeadModal"
  #newLeadModal
  tabindex="-1"
  role="dialog"
  aria-labelledby="editLeadModalTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered new-lead" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ isEdit ? "Edit Leads" : "New Leads" }}
        </h5>

        <button
          type="button"
          class="close lead-close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="resetClose()"
        >
          <img src="../../assets/crm/create-lead-close.svg" alt="close" />
        </button>
      </div>
      <!-- Lead Form -->
      <div class="new-leads-form modal-body">
        <form [formGroup]="CreateLeadForm">
          <div class="form-row">
            <div class="form-group col-md-3" S>
              <label for="iic">IIC Sales representative</label>
              <div class="dropdown iic-sales-rep">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="sales-rep-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  [class.invalid]="!selectedIICUser?.username && formSubmitted"
                >
                  {{ this.Name ? this.Name : "Select" }}

                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="sales-rep-button">
                  @for (user of iicUsers; track user) {
                  <a class="dropdown-item" (click)="selectUser(user)">
                    {{ user.username }}
                  </a>
                  }
                </div>
              </div>
            </div>
            <div
              class="form-group col-md-3"
              [class.invalid]="
                companyName.invalid && (companyName.touched || formSubmitted)
              "
            >
              <label for="comp-name">Company Name</label>
              <input
                type="text"
                class="form-control"
                id="comp-name"
                formControlName="companyName"
                placeholder="Enter company name"
                required
              />
            </div>

            <div
              class="form-group col-md-3"
              [class.invalid]="
                location.invalid && (location.touched || formSubmitted)
              "
            >
              <label for="location">Location</label>

              <div id="autocomplete" class="autocomplete-container"></div>
            </div>
            <div
              class="form-group col-md-3"
              [class.invalid]="
                companyIndustry.invalid &&
                (companyIndustry.touched || formSubmitted)
              "
            >
              <label for="comp-industry">Industries</label>

              <div class="dropdown iic-sales-rep">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="industry-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedIndustry }}

                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="industry-button">
                  @for (industry of iicIndusties; track industry) {
                  <a class="dropdown-item" (click)="selectIndustry(industry)">
                    {{ industry }}
                  </a>
                  }
                </div>
              </div>
            </div>
          </div>

          <div
            formArrayName="customerInformation"
            class="form-row multiple-field"
          >
            @for(index of contactForms.controls; track index; let i =$index;){
            <div [formGroupName]="i" style="display: contents">
              <div class="form-group col-md-3" style="padding-left: 7px">
                <label for="customer">Customer Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="customer"
                  placeholder="Enter Name"
                  formControlName="name"
                  placeholder="Enter customer name"
                  required
                />
              </div>
              <div class="form-group col-md-3">
                <label for="phone">Phone number</label>
                <input
                  type="tel"
                  class="form-control"
                  id="phone"
                  formControlName="phoneNo"
                  required
                  style="padding-left: 98px"
                />
                <div class="dropdown phone-no-wrapper">
                  <button
                    class="btn btn-secondary dropdown-toggle text-black-50"
                    type="button"
                    id="phone-button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style="border: none"
                  >
                    <img
                      [src]="
                        'https://flagcdn.com/16x12/' +
                        this.countryList[this.selectedCountryCode[i]] +
                        '.png'
                      "
                      id="countryFlag"
                      alt="country flag"
                      class="mr-2"
                      width="20px"
                    />
                    {{ this.selectedCountryCode[i] }}
                    <svg
                      width="11"
                      height="7"
                      viewBox="0 0 11 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.6"
                        d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                        stroke="#092C4C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="industry-button">
                    @for (country of countryKeys; track country) {
                    <a
                      class="dropdown-item"
                      (click)="selectCountry(country, i)"
                    >
                      {{ countryNames[countryList[country]] }} ({{ country }})
                    </a>
                    }
                  </div>
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmail">Email ID</label>
                <input
                  type="email"
                  pattern="[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}"
                  class="form-control"
                  id="inputEmail"
                  formControlName="emailId"
                />
              </div>
            </div>
            @if(i ===0){

            <svg
              class="add-field"
              (click)="addField()"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 32 32"
            >
              <path
                fill="#45c85a"
                d="M16 2A14.173 14.173 0 0 0 2 16a14.173 14.173 0 0 0 14 14a14.173 14.173 0 0 0 14-14A14.173 14.173 0 0 0 16 2m8 15h-7v7h-2v-7H8v-2h7V8h2v7h7Z"
              />
              <path fill="#ffffff" d="M24 17h-7v7h-2v-7H8v-2h7V8h2v7h7z" />
            </svg>
            } @if(i !==0) {
            <svg
              class="remove-field"
              (click)="removeField(i)"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 32 32"
            >
              <path
                fill="#ff5a5a"
                d="M16 2C8.4 2 2 8.4 2 16s6.4 14 14 14s14-6.4 14-14S23.6 2 16 2m8 15H8v-2h16z"
              />
              <path fill="#ffffff" d="M24 17H8v-2h16z" />
            </svg>
            } }
          </div>

          <div class="form-row">
            <div
              class="form-group col-md-3"
              [class.invalid]="
                leadType.invalid && (leadType.touched || formSubmitted)
              "
            >
              <label for="lead-type">Lead Type</label>
              <!-- <input
                type="text"
                class="form-control"
                id="lead-type"
                formControlName="lead_type"
                required
              /> -->
              <div class="dropdown lead-type-menu">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="leadTypeButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedLeadType || "Select" }}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>

                <div class="dropdown-menu" aria-labelledby="leadTypeButton">
                  <a class="dropdown-item" (click)="updateLeadType('Direct')"
                    >Direct</a
                  >
                  <a class="dropdown-item" (click)="updateLeadType('Agency')"
                    >Agency</a
                  >
                </div>
              </div>
            </div>
            <div
              class="form-group col-md-3"
              [class.invalid]="
                f.firstContactDate.invalid &&
                (f.firstContactDate.touched || formSubmitted)
              "
            >
              <label for="first-date">Date of first contact</label>
              <input
                type="date"
                id="first-contact"
                name="contact"
                formControlName="firstContactDate"
                required
              />
            </div>
            <div
              class="form-group col-md-3"
              [class.invalid]="
                leadSource.invalid && (leadSource.touched || formSubmitted)
              "
            >
              <label for="source-lead">Source of Lead</label>
              <!-- <input
                type="text"
                class="form-control"
                id="source-lead"
                formControlName="lead_source"
                required
              /> -->
              <div class="dropdown sourceLead-wrapper">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="sourceLeadButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedSourceLead || "Select" }}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>

                <div class="dropdown-menu" aria-labelledby="sourceLeadButton">
                  <a
                    class="dropdown-item"
                    (click)="updateSourceLead('Outreach')"
                    >Outreach</a
                  >
                  <a class="dropdown-item" (click)="updateSourceLead('Website')"
                    >Website</a
                  >
                  <a
                    class="dropdown-item"
                    (click)="updateSourceLead('Social Media')"
                    >Social Media</a
                  >
                  <a
                    class="dropdown-item"
                    (click)="updateSourceLead('Recommendation')"
                    >Recommendation</a
                  >
                </div>
              </div>
            </div>
            <div
              class="form-group col-md-3 lead-status-cont"
              [class.invalid]="
                leadStatus.invalid && (leadStatus.touched || formSubmitted)
              "
            >
              <label for="lead-status">Lead Status </label>
              <div class="dropdown lead-status">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="leadStatusButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <p>{{ selectedStatus || "Select" }}</p>
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>

                <div class="dropdown-menu" aria-labelledby="leadStatusButton">
                  <a
                    class="dropdown-item"
                    (click)="updateStatus('Outreach Done')"
                    >Outreach Done</a
                  >
                  <a
                    class="dropdown-item"
                    (click)="updateStatus('Introduction Meeting')"
                    >Introduction Meeting</a
                  >
                  <a
                    class="dropdown-item"
                    (click)="updateStatus('Initial Deck Presentation')"
                    >Initial Deck Presentation</a
                  >
                  <a
                    class="dropdown-item"
                    (click)="updateStatus('Second round Deck Presentation')"
                    >Second round Deck Presentation</a
                  >

                  <a
                    class="dropdown-item"
                    (click)="updateStatus('Cost Sheet Sent')"
                    >Cost Sheet Sent</a
                  >
                  <a class="dropdown-item" (click)="updateStatus('Qualified')"
                    >Qualified</a
                  >
                  <a
                    class="dropdown-item"
                    (click)="updateStatus('Following up')"
                    >Following up</a
                  >
                  <a class="dropdown-item" (click)="updateStatus('On Hold')"
                    >On Hold</a
                  >
                  <a
                    class="dropdown-item"
                    (click)="updateStatus('Lead Lost - To Competitors')"
                    >Lead Lost - To Competitors</a
                  >
                  <a
                    class="dropdown-item"
                    (click)="updateStatus('Lead Lost - Cost')"
                    >Lead Lost - Cost</a
                  >
                  <a
                    class="dropdown-item"
                    (click)="updateStatus('Lead Lost - Idea')"
                    >Lead Lost - Idea</a
                  >
                  <a
                    class="dropdown-item"
                    (click)="updateStatus('Lead Lost - Project Cancelled')"
                    >Lead Lost - Project Cancelled</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="service"> Service interested in</label>
              <div class="service-wrapper">
                <input
                  type="text"
                  placeholder="please hit enter after typing..."
                  class="form-control"
                  id="service"
                  [(ngModel)]="newService"
                  (keyup.enter)="addService()"
                  formControlName="interestedIn"
                />
                <div class="service-content">
                  @for (service of serviceInterest; track service; let i =
                  $index) {
                  <div class="add-service">
                    {{ service }}
                    <span class="remove-lead" (click)="removeServiceInt(i)">
                      <img
                        src="../../../assets/close1.png"
                        width="10px"
                        height="10px"
                        style="margin-top: -4px"
                      />
                    </span>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="category">Category of Lead</label>

              <div class="dropdown category-wrapper">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="categoryButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedCategory || "Select" }}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>

                <div class="dropdown-menu" aria-labelledby="categoryButton">
                  <a class="dropdown-item" (click)="updateCategory('Hot')"
                    >Hot</a
                  >
                  <a class="dropdown-item" (click)="updateCategory('Warm')"
                    >Warm</a
                  >
                  <a class="dropdown-item" (click)="updateCategory('Cold')"
                    >Cold</a
                  >
                </div>
              </div>
            </div>
            <div
              class="form-group col-md-3"
              [class.invalid]="
                followUpMedium.invalid &&
                (followUpMedium.touched || formSubmitted)
              "
            >
              <label for="medium">Medium of Followup</label>
              <!-- <input
                  type="medium"
                  class="form-control"
                  id="Medium-follow"
                  formControlName="follow_medium"
                  required
                /> -->
              <div class="dropdown medium-list-menu">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="mediumButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ selectedMedium || "Select" }}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.6"
                      d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                      stroke="#092C4C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>

                <div class="dropdown-menu" aria-labelledby="mediumButton">
                  <a class="dropdown-item" (click)="updateMedium('Mail')"
                    >Mail</a
                  >
                  <a class="dropdown-item" (click)="updateMedium('Whatsapp')"
                    >Whatsapp</a
                  >
                  <a class="dropdown-item" (click)="updateMedium('Phone')"
                    >Phone</a
                  >
                  <a class="dropdown-item" (click)="updateMedium('Socials')"
                    >Socials</a
                  >
                </div>
              </div>
            </div>
            <div
              class="form-group col-md-3"
              [class.invalid]="
                CreateLeadForm.get('lead_score')?.errors?.zeroValue
              "
            >
              <label for="lead-score">Lead scoring</label>
              <input
                type="number"
                class="form-control"
                id="lead-score"
                min="-10"
                max="10"
                required
                formControlName="leadScoring"
              />
            </div>
            <div
              class="form-group col-md-3"
              [class.invalid]="
                f.followUpDate.invalid &&
                (f.followUpDate.touched || formSubmitted)
              "
            >
              <label for="follow-up-date">Next follow-up date</label>
              <input
                type="date"
                class="form-control"
                id="next-follow"
                placeholder="Select date"
                formControlName="followUpDate"
                required
              />
            </div>
          </div>

          <div class="form-row">
            <div
              class="form-group col-md-6"
              [class.invalid]="
                f.notes.invalid && (f.notes.touched || formSubmitted)
              "
            >
              <label for="pre-notes">Notes from previous conversations </label>
              <input
                type="text"
                class="form-control"
                id="pre-notes"
                placeholder="Write something"
                formControlName="notes"
                style="width: 453px"
                required
              />
            </div>
            <div
              class="form-group col-md-3"
              [class.invalid]="
                f.jobTitle.invalid && (f.jobTitle.touched || formSubmitted)
              "
            >
              <label for="job">Job Title</label>
              <input
                type="text"
                class="form-control"
                id="job"
                placeholder="job title"
                formControlName="jobTitle"
                required
              />
            </div>
            <div class="form-group col-md-3 created-By">
              <label for="created-by">Created by</label>
              <span>{{ createdUsername }}</span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="edit-access">Edit Access</label>
              <div class="edit-wrapper">
                <div class="dropdown edit-access">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="editAccessButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Select
                    <svg
                      width="11"
                      height="7"
                      viewBox="0 0 11 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.6"
                        d="M10.1715 1.25913L5.85059 5.58008L1.52964 1.25913"
                        stroke="#092C4C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="editAccessButton">
                    @for (user of iicUsers; track user) {
                    <a class="dropdown-item" (click)="selectUserDisplay(user)">
                      {{ user.username }}
                    </a>
                    }
                  </div>
                </div>
                <div class="user-display">
                  @for (user of selectedUsers; track user) {
                  <p class="selected-user">
                    {{ user.username }}
                    <span class="remove-lead" (click)="removeUser(user)">
                      <img
                        src="../../../assets/close1.png"
                        width="10px"
                        height="10px"
                        style="margin-top: -4px"
                      />
                    </span>
                  </p>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<!--   LEAD DETAIL MODAL -->
<div
  class="modal fade new-lead-banner"
  #leadDetailModal
  id="leadDetailModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="leadDetailModalTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered new-lead" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Leads Details</h5>
        <div class="d-flex">
          @if (isEditable) {<button
            class="btn btn-primary create-leads"
            type="button"
            (click)="editLead()"
          >
            Edit lead
          </button>

          }

          <button
            type="button"
            class="close lead-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src="../../assets/crm/create-lead-close.svg" alt="close" />
          </button>
        </div>
      </div>
      <div class="modal-body detail-content">
        @if (singleLeads) {
        <form>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="iic">IIC Sales representative</label>
              <div class="dropdown iic-sales-rep">
                <p class="rep-name mb-0">
                  {{ singleLeads.representatorName }}
                </p>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="comp-name">Company Name</label>
              <p class="mb-0">{{ singleLeads.companyName }}</p>
            </div>

            <div class="form-group col-md-4">
              <label for="location">Location</label>
              <p class="mb-0">{{ singleLeads.location }}</p>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-4 mb-0">
              <label for="custmer">Customer Name</label>
            </div>

            <div class="form-group col-md-4 mb-0">
              <label for="phone">Phone number</label>
            </div>
            <div class="form-group col-md-4 mb-0">
              <label for="inputEmail">Email ID</label>
            </div>
          </div>

          @for(contact of singleLeads.customerInformation; track contact){
          <div class="form-row">
            <div class="form-group col-md-4">
              <p class="mb-0">{{ contact.name }}</p>
            </div>

            <div class="form-group col-md-4">
              <p class="mb-0">
                ({{ contact.countryCode }}) {{ contact.phoneNo }}
              </p>
            </div>
            <div class="form-group col-md-4">
              <p class="mb-0">{{ contact.emailId }}</p>
            </div>
          </div>
          }

          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="lead-type">Lead Type</label>
              <p>{{ singleLeads.leadType }}</p>
            </div>
            <div class="form-group col-md-3">
              <label for="first-date">Date of first contact</label>
              <p>{{ singleLeads.formattedFirstContactDate }}</p>
            </div>
            <div class="form-group col-md-3">
              <label for="source-lead">Source of Lead</label>
              <p>{{ singleLeads.leadSource }}</p>
            </div>
            <div class="form-group col-md-3 lead-status-cont">
              <label for="lead-status">Lead Status </label>
              <div class="dropdown lead-status">
                {{ singleLeads.leadStatus }}
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="service"> Service interested in</label>
              <p>{{ singleLeads.interestedIn }}</p>
            </div>
            <div class="form-group col-md-3">
              <label for="category">Category of Lead</label>
              <p>{{ singleLeads.categoryLead }}</p>
            </div>
            <div class="form-group col-md-3">
              <label for="category">Time of Creation</label>
              <p>{{ singleLeads.formattedAcquisitionDate }}</p>
            </div>
            <div class="form-group col-md-3">
              <label for="job">Job Title</label>
              <p>{{ singleLeads.jobTitle }}</p>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="lead-score">Lead scoring</label>
              <p>{{ singleLeads.leadScoring }}</p>
            </div>
            <div class="form-group col-md-3">
              <label for="follow-up-date">Next follow-up date</label>
              <p>{{ singleLeads.formattedfollowUpDate }}</p>
            </div>
            <div class="form-group col-md-3">
              <label for="medium">Medium of Followup</label>
              <p>{{ singleLeads.followUpMedium }}</p>
            </div>
            <div class="form-group col-md-3">
              <label for="comp-industry">Industry</label>
              <p>{{ singleLeads.companyIndustry }}</p>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-9">
              <label for="pre-notes">Notes from previous conversations </label>
              <p>{{ singleLeads.notes }}</p>
            </div>
            <div class="form-group col-md-3 created-By">
              <label for="created-by">Created by</label>
              <span>{{ singleLeads.creatorName }}</span>
            </div>
          </div>
        </form>
        }
      </div>
    </div>
  </div>
</div>
