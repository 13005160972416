<app-header [activeLink]=4></app-header>
<div class="container my-background">
  <div class="row">
    <div class="col">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active" (click)=onClickTabs($event)>Live Meetings</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)=onClickTabs($event)>Scheduled Meetings</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)=onClickTabs($event)>Meetings Record</a>
        </li>
      </ul>

      <div class="d-flex flex-column align-content-center justify-content-center list-grp">
        <!-- <div *ngFor="let notify of instantMeetings | paginate: { itemsPerPage: limit, currentPage: instantp,totalItems: instantTotal }" class=" list-item d-flex justify-content-between" [ngClass]="{'missed-call':missedCall(notify),'ongoing-call':!notify.ended}"> -->
        @for (notify of instantMeetings | paginate: {id:'instant', itemsPerPage: limit, currentPage: instantp }; track notify) {
          <div class=" list-item d-flex justify-content-between" [ngClass]="{'missed-call':missedCall(notify),'ongoing-call':!notify.ended}">
            <div style="    width: 100%;">
              <h4> {{notify.room_name}}</h4>
              <div class="d-flex flex-wrap">
                <p class="list-participants"><strong>{{notify.participants.length>2?'Group call: ':' Call:
                '}}</strong></p>
                @for (participant of notify.participants; track participant) {
                  <p class="list-participants">
                  {{participant.username }}</p>
                }
              </div>
            </div>
                <div style="    text-align: end;
                    width: 100%;">
              @if (!notify.ended) {
                <button class="btn btn-success" (click)="onClickJoinMeeting(notify)">
                Join</button>
              }
              @if (notify.ended) {
                <p>Meeting ended at <strong>{{convertDate(notify.updatedAt)}}</strong></p>
              }
              @if (missedCall(notify)) {
                <p style="color:red"> Missed Call</p>
              }
            </div>
          </div>
        }
        <!-- <pagination-controls (pageChange)="pageChange($event)"></pagination-controls> -->
        <pagination-controls id='instant' (pageChange)="pageChangedInstant($event)"></pagination-controls>
      </div>

      <div class="d-flex flex-column list-grp align-content-center justify-content-center hidden">
        <!-- <div *ngFor="let notify of scheduledMeetings | paginate: { itemsPerPage: 10, currentPage:scheduledp,totalItems: scheduledTotal } " class=" list-item d-flex justify-content-between"> -->
        @for (notify of scheduledMeetings  | paginate: {id:'schedule', itemsPerPage: limit, currentPage:scheduledp} ; track notify) {
          <div class=" list-item d-flex justify-content-between" [ngClass]="[!notify.ended?'ongoing-call':'']">
            <div>
              <h4>Title : {{notify.room_name}}</h4>
              <div class="d-flex flex-wrap">
                <p class="list-participants"><strong>participants:</strong></p>
                @for (participant of notify.participants; track participant) {
                  <p class="list-participants">
                  {{participant.username }}</p>
                }
              </div>
            </div>
            <div class="d-flex flex-column justify-content-around">
              @if (notify.ended) {
                <div > <strong> Start Time:</strong> {{convertDate(notify.start)}}</div>
              }
              @if (notify.ended) {
                <div > <strong>End Time:</strong> {{convertDate(notify.end)}} </div>
              }
              @if (!notify.ended) {
                <button  class="btn btn-success" (click)="onClickJoinMeeting(notify)">Join</button>
              }
            </div>
          </div>
        }
        <pagination-controls id="schedule" (pageChange)="pageChangedScheduled($event)"></pagination-controls>
      </div>

      <div class="d-flex flex-column justify-content-center align-content-center list-grp hidden">
        <!-- <p>Meetings Record</p> -->
        <img src="../../../assets/coming-soon.jpg" alt="">
      </div>



      <!-- <h3>Instant Meetings</h3>
      <div class="d-flex flex-column list-grp">
        <div *ngFor="let notify of notifications" class=" list-item d-flex justify-content-between" >
          <div>
            <h4>{{notify.room_name}}</h4>
            <ul>
              <li *ngFor="let participant of notify.participants">{{participant.username }}</li>
            </ul>
          </div>
          <div>
            <button class="btn btn-success" (click)="onClickJoinMeeting(notify)"> Join</button>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="col-lg-6 scheduled">
    <h3>Scheduled Meetings</h3>
    <div class="d-flex flex-column list-grp">
      <div *ngFor="let notify of notifications" class="list-item d-flex justify-content-between" >
        <div>
          <h4>{{notify.room_name}}</h4>
          <ul>
            <li *ngFor="let participant of notify.participants">{{participant.username }}</li>
          </ul>
          <p style="display: inline-block;text-transform: capitalize; margin-right: 2px;" *ngFor="let participant of notify.participants">{{participant.username }} </p> -->
        </div>
        <div>
          <!-- <p style="display: inline-block;">{{i.time}}</p>
          <button class="btn btn-success" (click)="onClickJoinMeeting(notify)"> Join</button>
        </div>
      </div>
    </div>
  </div> -->

</div>