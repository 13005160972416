<app-header [activeLink]="1"></app-header>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-2 white-background" id="navSideDis">
      <div class="mt-3 d-flex justify-content-between">
        <div class="d-flex justify-content-between w-100">
          @if (collectionTypeToggle) {
          <p
            id="public"
            class="colorPrivate font-weight-bold mr-3"
            [ngStyle]="selectedOption === 'public' ? dynamicStyle : {}"
            (click)="selectOption('public')"
          >
            Public
          </p>
          } @if (!collectionTypeToggle) {
          <p
            id="private"
            class="colorPrivate font-weight-bold mr-3"
            [ngStyle]="selectedOption === 'private' ? dynamicStyle : {}"
            (click)="selectOption('private')"
          >
            Private
          </p>
          } @if (!typeToggle) {
          <p
            id="company"
            class="colorPrivate font-weight-bold"
            [ngStyle]="selectedOption === 'company' ? dynamicStyle : {}"
            (click)="selectOption('company')"
          >
            Company
          </p>
          } @if (typeToggle) {
          <p
            id="reference"
            class="colorPrivate font-weight-bold"
            [ngStyle]="selectedOption === 'reference' ? dynamicStyle : {}"
            (click)="selectOption('reference')"
          >
            Reference
          </p>
          }
        </div>
      </div>
      <div class="search-container">
        <!-- <i class="fa fa-search search-icon"></i> -->
        <div class="input-wrapper">
          <input
            type="text"
            class="search-input my-background autocomplete"
            [(ngModel)]="searchTerm"
            autocomplete="off"
            placeholder="Search for files or tags"
            (focus)="workingfocus()"
            (keyup)="onSearchKeyPress($event)"
            id="search"
            #multiSearchTerm
            [ngClass]="{
              'is-invalid': searchValidation
            }"
          />
          @if (tagsSearch && tagsSearch.length > 0) {
          <svg
            (click)="tagsSearch = []; searchTerm = ''"
            class="close-search"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M5.52051 5.53027L16.0652 16.0749"
                stroke="black"
                stroke-width="1.97344"
                stroke-linecap="round"
              />
              <path
                d="M16.0654 5.53027L5.52075 16.0749"
                stroke="black"
                stroke-width="1.97344"
                stroke-linecap="round"
              />
            </g>
          </svg>
          }
        </div>
        @if (tagsSearch && tagsSearch.length > 0) {
        <ul class="multiple-tag-search">
          @for (i of tagsSearch; track i) {
          <li class="multiTagList" (click)="multiTagSearch(i)">
            {{ i }}
          </li>
          }
        </ul>
        }
      </div>
      @if (searchValidation) {
      <p class="text-danger">{{ searchValidation }}</p>
      }
      <div class="col-lg-12 white-background">
        <div class="tags searchTagss">
          @for (tag of tags; track tag; let i = $index) {
          <div class="tag-element">
            <span
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                padding-left: 5px;
              "
              >{{ tag }}
              <!-- <p class="tooltip-text">{{ tag }}</p> -->
            </span>

            <span class="remove-tag" (click)="removeTag(i)">
              <img
                src="../../../assets/closeIcon.png"
                width="12px"
                height="12px"
                style="margin-bottom: 5px"
              />
            </span>
          </div>
          }
        </div>
      </div>
      @if (searchHistory.length) {
      <div class="mt-3 mb-4">
        <h6>Search History</h6>
        <div class="borderHis mb-2"></div>
        <ul class="list-unstyled scrollableSearch">
          @for (item of searchHistory; track item) {
          <li class="font-weight-bold w-100 searchCross">
            <span
              class="cursorPointer"
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                padding-left: 5px;
              "
              (click)="selectOption(item)"
              >{{ item }}
            </span>
            <span class="remove-tag" (click)="removeFromHistory(item)">
              <img
                src="../../../assets/close1.png"
                width="10px"
                height="10px"
              />
            </span>
          </li>
          }
        </ul>
        <!-- <div *ngIf="recentFiveTags.length">
                    <h6>Recently Used Tags</h6>
                    <ul class="list-unstyled d-flex flex-wrap recentScrollable">
                      <li class="recentTags font-weight-bold mr-2 mb-2" (click)="selectOption(tag)"
                        *ngFor="let tag of recentFiveTags" style="height: fit-content; cursor: pointer">
                        {{ tag }}
                      </li>
                    </ul>
                  </div> -->
        <div class="ButtonCenter pb-4">
          <button class="clearBtn" (click)="onClearButtonClick()">
            Clear All
          </button>
        </div>
      </div>
      }
    </div>
    <div class="col-lg-10" style="text-align: center">
      <div class="col-lg-12" id="navSideDisRight">
        <div class="d-flex" style="margin-left: -21px">
          <div class="col-lg-7">
            <div class="d-flex align-items-center" style="margin-top: 10px">
              <svg
                id="back-btn"
                [ngClass]="{
                  'visible': currentTabView === 'public' || currentTabView === 'private',
                 }"
                xmlns="http://www.w3.org/2000/svg"
                width="55"
                height="30"
                fill="currentColor"
                class="bi bi-arrow-left cursorPointer fw"
                viewBox="0 0 16 16"
                (click)="goBack()"
              >
                <path
                  fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              <p class="m-0 fw" id="videoLength">{{ totalMedia }} Files</p>

              <div
                class="mx-4 d-flex align-items-center"
                [class.disabled-div]="playAccess"
              >
                <div>
                  <p
                    class="m-0 fw cursorPointer"
                    (click)="normalView('normal')"
                  >
                    Normal View
                  </p>
                </div>
                <div class="onoffswitch mx-2">
                  <input
                    type="checkbox"
                    name="onoffswitch"
                    id="projectType"
                    class="onoffswitch-checkbox"
                    (change)="GridView($event)"
                  />
                  <label for="projectType" class="onoffswitch-label">
                    <span class="onoffswitch-inner project-type-content"></span>
                    <span class="onoffswitch-switch"></span>
                  </label>
                </div>
                <div>
                  <p class="m-0 fw cursorPointer" (click)="normalView('grid')">
                    Grid View
                  </p>
                </div>
              </div>
              <div
                (click)="playListOpen()"
                [ngClass]="{
                  'public-tab-active': currentTabView === 'public',
                 }"
                class="cursorPointer public_private"
                routerLinkActive="active-tab-underline"
                [class.disabled-div]="playAccess"
              >
                <p class="m-0 fw" id="playlistDiv">
                  <span class="playPublic">Others</span>
                  <span class="icon">
                    <svg
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      focusable="false"
                    >
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM3 12c0-.7.09-1.37.24-2.02L8 14.71v.79c0 1.76 1.31 3.22 3 3.46v1.98c-4.49-.5-8-4.32-8-8.94zm8.5 6C10.12 18 9 16.88 9 15.5v-1.21l-5.43-5.4C4.84 5.46 8.13 3 12 3c1.05 0 2.06.19 3 .53V5c0 .55-.45 1-1 1h-3v2c0 .55-.45 1-1 1H8v3h6c.55 0 1 .45 1 1v4h2c.55 0 1 .45 1 1v.69C16.41 20.12 14.31 21 12 21v-3h-.5zm7.47-.31C18.82 16.73 18 16 17 16h-1v-3c0-1.1-.9-2-2-2H9v-1h1c1.10 0 2-.9 2-2V7h2c1.10 0 2-.9 2-2V3.95c2.96 1.48 5 4.53 5 8.05 0 2.16-.76 4.14-2.03 5.69z"
                      ></path>
                    </svg>
                  </span>
                </p>
              </div>
              <div
                (click)="playUsers()"
                [ngClass]="{
                  'private-tab-active': currentTabView === 'private',
                 }"
                class="ml-3 cursorPointer public_private"
                routerLinkActive="active"
                [class.disabled-div]="playAccess"
              >
                <p class="m-0 fw" id="playlistUser">
                  <span class="playPublic">Only me</span>
                  <span class="icon">
                    <svg
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      focusable="false"
                    >
                      <path
                        d="M17 8V6.63C17 4.08 14.76 2 12 2S7 4.08 7 6.63V8H4v14h16V8h-3zM8 6.63c0-2.02 1.79-3.66 4-3.66s4 1.64 4 3.66V8H8V6.63zM19 21H5V9h14v12zm-7-9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                      ></path>
                    </svg>
                  </span>
                </p>
              </div>
              <!-- *ngIf="invitationDetailslength > 0" -->
              @if (invitationDetailslength > 0) {
              <div
                (click)="getInvitation()"
                class="ml-3 cursorPointer public_private"
              >
                <div class="notification-icon" title="Request">
                  <p
                    class="m-0 fw"
                    id="playlist"
                    data-toggle="modal"
                    data-target="#requestModal"
                    [class.active]="activeTab === 'playlist'"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-bell"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                      />
                    </svg>
                    <span class="notification-count">{{
                      invitationDetailslength
                    }}</span>
                  </p>
                </div>
              </div>
              }
            </div>
          </div>
          <div class="col-lg-5 mt-25 d-flex justify-content-end sort_content">
            <div class="d-flex">
              <div class="">
                <div
                  class="text-grey mt-1 d-flex align-items-center"
                  (click)="open()"
                >
                  <span class="fw mt-1 ngx-daterangepicker-action cursorPointer"
                    >Sort By
                    <span
                      id="customDateText"
                      class="custom-date-text mt-1 pr-2"
                      (click)="open()"
                      >{{ fromMonthSelected }} {{ fromDateSelected }} -
                      {{ toMonthSelected }} {{ toDateSelected }}</span
                    ><i
                      class="fa fa-caret-down mt-1 mr-2 ngx-daterangepicker-action"
                      (click)="open()"
                    ></i
                  ></span>

                  <div class="fx-date-range-container">
                    <input
                      style="top: 69px !important; left: 186px !important"
                      #matSortBy
                      class="fx-date-range"
                      type="text"
                      matInput
                      ngxDaterangepickerMd
                      [locale]="{ applyLabel: 'ok', format: 'MM/DD/YY' }"
                      [drops]="'down'"
                      [opens]="'right'"
                      [ranges]="ranges"
                      [showCustomRangeLabel]="false"
                      [alwaysShowCalendars]="true"
                      [keepCalendarOpeningWithRange]="true"
                      [showCancel]="true"
                      autocomplete="off"
                      name="daterange"
                      (change)="onDateSelected($event)"
                      style="margin-top: 5px"
                    />
                  </div>
                </div>
              </div>

              <div class="dropdown">
                @if ( currentTabView !== 'private' && currentTabView !==
                'public' ) {
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButtonFilter"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  style="margin-top: 3px"
                  (click)="filterBaseOnUploader(currentTabView)"
                >
                  <span class="text-capitalize">{{
                    filteredName || "Filter"
                  }}</span>
                  <i class="fa fa-caret-down" style="margin-left: 29px"></i>
                </button>
                }
                <ul
                  class="dropdown-menu dis1 dropdown-menu2"
                  aria-labelledby="dropdownMenuButtonFilter"
                >
                  <li
                    class="dropdown-item cursorPointer"
                    (click)="filterItemsByExtension('all')"
                  >
                    all
                  </li>
                  <li
                    class="dropdown-item cursorPointer"
                    (click)="filterItemsByExtension('pdf')"
                  >
                    pdf
                  </li>
                  <li
                    class="dropdown-item cursorPointer"
                    (click)="filterItemsByExtension('videos')"
                  >
                    videos
                  </li>
                  <li
                    class="dropdown-item cursorPointer"
                    (click)="filterItemsByExtension('images')"
                  >
                    images
                  </li>
                  <li
                    class="dropdown-item cursorPointer"
                    (click)="filterItemsByExtension('pptx')"
                  >
                    Presentation
                  </li>
                  <li
                    class="dropdown-item cursorPointer"
                    (click)="filterItemsByExtension('docs')"
                  >
                    docs
                  </li>
                  <li
                    class="dropdown-item cursorPointer"
                    (click)="filterItemsByExtension('excel')"
                  >
                    excel
                  </li>
                </ul>
              </div>

              @if (currentTabView !== 'private') {
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButtonUpload"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  style="margin-top: 3px; min-width: 134px; display: flex"
                  (click)="filterBaseOnUploader(currentTabView)"
                >
                  <span class="text-capitalize">{{
                    nameDisplay || "Uploaded By"
                  }}</span>
                  <i class="fa fa-caret-down" style="margin-left: 29px"></i>
                </button>
                <ul
                  class="dropdown-menu dis dropdown-menu1"
                  aria-labelledby="dropdownMenuButtonUpload"
                >
                  @for (name of nameList; track name) {
                  <li
                    class="dropdown-item cursorPointer"
                    (click)="selectName(name, currentTabView)"
                  >
                    {{ name }}
                  </li>
                  }
                </ul>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      <!-- <router-outlet></router-outlet> -->

      @if (!isShimmerLoading && (currentTabView !== 'public' && currentTabView
      !== 'private' ) || (isPrevCardFolders && currentTabView === 'public') ||
      (isPrevCardFolders && currentTabView === 'private') ) {
      <div
        class="col-lg-12 pb-5 adjust-height scrollable overflow-x-hidden"
        infiniteScroll
        #infiniteScroller
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="500"
        (scrolled)="onScroll()"
        [scrollWindow]="false"
        [ngClass]="currentTabView === 'grid' ? 'four-view' : 'three-view'"
      >
        @for (card of cardData; track card; let i = $index) {
        <div class="card card-cont" (click)="working(card._id)">
          @if ( (card.ogFileUrl && card.extension == '.jpeg') || card.extension
          == '.png' || card.extension == '.jpg' ) {
          <img
            class="img-thumbnail"
            id="image"
            [src]="card.ogFileUrl"
            height="100%"
            style="object-fit: contain"
          />
          } @if (card.ogFileUrl && card.extension == '.pptx') {
          <img
            src="assets/Archive_files/pptx.png"
            height="65%"
            style="object-fit: contain; margin: auto"
            alt=""
          />
          } @if (card.ogFileUrl && card.extension == '.pdf') {
          <img
            src="assets/Archive_files/pdf.png"
            height="65%"
            style="object-fit: contain; margin: auto; padding-top: 1rem"
            alt=""
          />
          } @if ( (card.ogFileUrl && card.extension == '.doc') || card.extension
          == '.docx' ) {
          <img
            src="../../../assets/docx-img.png"
            height="65%"
            style="object-fit: contain; margin: auto; padding-top: 1rem"
            alt=""
          />
          } @if ( (card.ogFileUrl && card.extension == '.xls') || card.extension
          == '.xlsx' ) {
          <img
            src="../../../assets/Archive_files/excel-icon.png"
            height="65%"
            style="object-fit: contain; margin: auto; padding-top: 1rem"
            alt=""
          />
          } @if (card.thumbnails[0]) {
          <div
            id="videothumnail"
            class="videoThumbnail"
            style="display: grid"
            (mouseenter)="showControls(i)"
            (mouseleave)="hideControls(i)"
            (click)="toggleVideo(i)"
          >
            <div class="p-2" style="justify-self: center">
              @if ( card.gif && card.extension == '.mp4' &&
              card.selectedThumbnail ) {
              <video
                #mediaElement
                id="video"
                [src]="card.gif"
                preload="auto"
                [muted]="true"
                style="
                  width: 100%;
                  height: 223px;
                  width: auto;
                  max-width: 100%;
                  justify-self: center;
                "
                class=""
                loop
                playsinline="true"
                poster="{{ card.selectedThumbnail }}"
              >
                Your browser does not support the video tag.
              </video>
              } @if ( card.gif && card.extension == '.webm' &&
              card.selectedThumbnail ) {
              <video
                #mediaElement
                id="video"
                [src]="card.gif"
                preload="auto"
                [muted]="true"
                style="
                  width: 100%;
                  height: 223px;
                  width: auto;
                  max-width: 100%;
                  justify-self: center;
                "
                class=""
                loop
                playsinline="true"
                poster="{{ card.selectedThumbnail }}"
              >
                Your browser does not support the video tag.
              </video>
              } @if ( card.gif && card.extension == '.mov' &&
              card.selectedThumbnail ) {
              <video
                #mediaElement
                id="video"
                [src]="card.gif"
                preload="auto"
                [muted]="true"
                style="
                  width: 100%;
                  height: 223px;
                  width: auto;
                  max-width: 100%;
                  justify-self: center;
                "
                class=""
                loop
                playsinline="true"
                poster="{{ card.selectedThumbnail }}"
              >
                Your browser does not support the video tag.
              </video>
              } @if ( card.gif && card.extension == '.mkv' &&
              card.selectedThumbnail ) {
              <video
                #mediaElement
                id="video"
                [src]="card.gif"
                preload="auto"
                [muted]="true"
                style="
                  width: 100%;
                  height: 223px;
                  width: auto;
                  max-width: 100%;
                  justify-self: center;
                "
                class=""
                loop
                playsinline="true"
                poster="{{ card.selectedThumbnail }}"
              >
                Your browser does not support the video tag.
              </video>
              } @if ( card.gif && card.extension == '.mp4' &&
              !card.selectedThumbnail ) {
              <video
                #mediaElement
                id="video"
                [src]="card.gif"
                preload="auto"
                [muted]="true"
                style="
                  width: 100%;
                  height: 223px;
                  width: auto;
                  max-width: 100%;
                  justify-self: center;
                "
                class=""
                loop
                playsinline="true"
                poster="{{ card.thumbnails[0] }}"
              >
                Your browser does not support the video tag.
              </video>
              }
            </div>
          </div>
          }
          <div>
            @if (card.extension == '.xlsx' || card.extension == '.xls') {
            <p class="mb-0"></p>
            } @if (card.extension == '.docx' || card.extension == '.doc') {
            <p class="mb-0"></p>
            }
          </div>
          @for (res of PdfDetails; track res) {
          <div>
            @if (checkCondition(card, res)) {
            <p class="text-center text-black-50 mb-0">
              File :{{ res.filename }}
            </p>
            }
          </div>
          }
          <div class="card-body d-flex flex-column">
            <div class="d-flex justify-content-between">
              <h5
                class="card-title"
                style="
                  bottom: 35px;
                  max-width: 80%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ card.title }}
              </h5>
              <p style="font-size: 16px; font-weight: 600">
                {{ card.isPublic ? "Public" : "Private" }}
              </p>
            </div>
            <div class="d-flex align-items-center heightDetails">
              <div>
                @if (card.profileImg) {
                <img
                  src="{{ card.profileImg }}"
                  style="border-radius: 50%; height: 20px; width: 20px"
                />
                }
              </div>
              <div>
                @if (!card.profileImg) {
                <img
                  src="../../../assets/add-people.png"
                  width="20px"
                  height="20px"
                />
                }
              </div>
              <div>
                <p class="text-black m-0">{{ card.uploadedBy }}</p>
              </div>
              <div class="dot"></div>
              <div>
                <p class="text-grey m-0">{{ card.createdAt | date }}</p>
              </div>
              <div class="moreOptions">
                <div class="dropdown" (click)="handleDropdownItemClick($event)">
                  <ul
                    class="dropbtn icons btn-right showLeft"
                    (click)="showDropdown(i)"
                  >
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                  <div
                    id="myDropdown"
                    [ngClass]="{ show: isDropdownOpen === i }"
                    class="dropdown-content"
                  >
                    <!-- <a
                                          class="d-flex align-items-center gapP"
                                          (click)="download(card._id)"
                                          >
                                          <img src="../../../assets/downloadP.png" />
                                          <span>Download</span>
                                        </a> -->
                    @if ( currentTabView !== 'public' && currentTabView !==
                    'private' ) {
                    <a
                      class="d-flex align-items-center gapP"
                      (click)="savePlayList(i, card._id)"
                      data-toggle="modal"
                      data-target="#saveModal"
                    >
                      <img src="../../../assets/icons 6/save_collection.svg" />
                      <span>Save</span>
                    </a>
                    } @if (guestId === card.userId) {
                    <a
                      class="d-flex align-items-center gapP"
                      (click)="deleteMedia(card._id)"
                    >
                      <img
                        src="../../../assets/icons 6/delete_collection.svg"
                      />
                      <span>Delete</span>
                    </a>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        <p class="text-center text-capitalize" id="notFound"></p>
        @if (notFoundResult) {
        <div class="notFoundData">
          <img src="../../../assets/not1.png" width="100%" height="100%" />
        </div>
        }
      </div>
      } @if(isShimmerLoading) { @for (card of shimmerLoadingValue; track card;
      let i = $index) {
      <span class="d-flex">
        <div class="shimmer-card">
          <div class="shimmerBG media"></div>
          <div class="p-32">
            <div class="shimmerBG title-line"></div>
            <div class="shimmerBG title-line end"></div>

            <div class="shimmerBG content-line m-t-24"></div>
            <div class="shimmerBG content-line"></div>
            <div class="shimmerBG content-line"></div>
            <div class="shimmerBG content-line"></div>
            <div class="shimmerBG content-line end"></div>
          </div>
        </div>

        <div class="shimmer-card">
          <div class="shimmerBG media"></div>
          <div class="p-32">
            <div class="shimmerBG title-line"></div>
            <div class="shimmerBG title-line end"></div>

            <div class="shimmerBG content-line m-t-24"></div>
            <div class="shimmerBG content-line"></div>
            <div class="shimmerBG content-line"></div>
            <div class="shimmerBG content-line"></div>
            <div class="shimmerBG content-line end"></div>
          </div>
        </div>

        <div class="shimmer-card">
          <div class="shimmerBG media"></div>
          <div class="p-32">
            <div class="shimmerBG title-line"></div>
            <div class="shimmerBG title-line end"></div>

            <div class="shimmerBG content-line m-t-24"></div>
            <div class="shimmerBG content-line"></div>
            <div class="shimmerBG content-line"></div>
            <div class="shimmerBG content-line"></div>
            <div class="shimmerBG content-line end"></div>
          </div>
        </div>
      </span>
      } }

      <!-- Public && Private view -->
      @if (!isShimmerLoading && (currentTabView === 'public' || currentTabView
      === 'private')){

      <div
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="500"
        (scrolled)="onPublicScroll()"
        [scrollWindow]="false"
        class="col-lg-12 pb-5 adjust-height three-view scrollable overflow-x-hidden"
      >
        <div style="display: contents" id="publicData">
          @for (card of cardData; track card; let i = $index) {
          <div class="card-container">
            <div
              class="card card-cont"
              (click)="
                displayPlayList(card._id, 0, card.isPublic, card.creatorId)
              "
            >
              <img
                class="folder"
                id="image"
                src="assets/folder.jpg"
                width="100%"
                height="100%"
              />
              @for (item of card.media; track item) {
              <div class="position-relative">
                <div style="width: 100%; position: relative">
                  @if (item.thumbnail) {
                  <img
                    class="folder"
                    id="image"
                    [src]="item.thumbnail"
                    width="100%"
                    height="100%"
                  />
                  }
                  <div class="thumbnail-overlay">
                    <svg
                      class="icon"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      focusable="false"
                    >
                      <path
                        d="M22 7H2v1h20V7zm-9 5H2v-1h11v1zm0 4H2v-1h11v1zm2 3v-8l7 4-7 4z"
                      ></path>
                    </svg>
                    <span>{{ card.media.length }} Files</span>
                  </div>
                </div>
              </div>
              }
              <div
                class="card-body d-flex flex-column"
                style="border-top: none"
              >
                <h5 class="card-title d-flex fixed-width">
                  {{ card.title }}
                  <P style="font-size: 16px">{{
                    card.isPublic ? "Public" : "Private"
                  }}</P>
                </h5>
                <div class="d-flex align-items-center heightDetails">
                  <div>
                    @if (card.creatorProfileImg) {
                    <img
                      src="{{ card.creatorProfileImg }}"
                      width="30px"
                      height="30px"
                      style="border-radius: 50%"
                    />
                    }
                  </div>
                  <div>
                    @if (!card.creatorProfileImg) {
                    <img
                      src="../../../assets/add-people.png"
                      width="20px"
                      height="20px"
                    />
                    }
                  </div>
                  <p class="text-black m-0">
                    {{ card.creatorName }}
                  </p>
                  <div class="dot"></div>
                  <div>
                    <p class="text-grey m-0">{{ card.createdAt | date }}</p>
                  </div>
                  <div class="moreOptions">
                    <div
                      class="dropdown"
                      (click)="handleDropdownItemClick($event)"
                    >
                      <ul
                        class="dropbtn icons btn-right showLeft"
                        (click)="showDropdown(i)"
                      >
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                      <div
                        id="myDropdown "
                        [ngClass]="{ show: isDropdownOpen === i }"
                        class="dropdown-content playDelete"
                      >
                        <!-- <a
                                            class="d-flex align-items-center gapP"
                                            (click)="download(card._id)"
                                            >
                                            <img src="../../../assets/downloadP.png" />
                                            <span>Download</span>
                                          </a> -->
                        <!-- *ngIf="userId === guestId" -->
                        @if (guestId === card.creatorId) {
                        <a
                          class="d-flex align-items-center gapP"
                          (click)="deleteCollectionMedia(card._id)"
                        >
                          <img src="../../../assets/deleteP.png" />
                          <span>Delete PlayList</span>
                        </a>
                        }
                        <!-- <a
                                          class="d-flex align-items-center gapP"
                                          data-toggle="modal"
                                          data-target="#shareP"
                                          (click)="shareCollection(card._id)"
                                          > -->
                        <a
                          class="d-flex align-items-center gapP"
                          data-toggle="modal"
                          data-target="#shareP"
                          (click)="shareList(card._id)"
                        >
                          <img
                            src="../../../assets/Archive_files/share_collection.svg"
                          />
                          <span>Share</span>
                        </a>
                        @if (currentTabView !== 'public' && card.isPublic) {
                        <a
                          class="d-flex align-items-center gapP"
                          data-toggle="modal"
                          data-target="#collaborate"
                          (click)="collaborate(card)"
                        >
                          <img src="../../../assets/join.png" />
                          <span>Collaborate</span>
                        </a>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
        @if (notFoundResult) {
        <div class="notFoundData" style="transform: translate(85%, -25%)">
          <img src="../../../assets/not1.png" width="100%" height="100%" />
        </div>
        }
      </div>
      }
    </div>

    <div class="row"></div>
  </div>
  <!-- modals   -->
  <div
    class="saveModal modal fade"
    id="saveModal"
    tabindex="-1"
    aria-labelledby="networkErrorModal"
    aria-hidden="true"
    (click)="closeModal($event)"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Create New Collection
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" (click)="closeModal($event)">&times;</span>
          </button>
        </div>
        <div class="">
          <div class="modal-body text-left saveModal">
            <div>
              <label class="collection-title" for="collection"
                >Collection Title:</label
              >
              <input type="text" class="form-control collection-input" />
            </div>
            <div class="dropdown type">
              Type:
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="text-capitalize centerType textblack">{{
                  collectionType || "Add To Collection"
                }}</span>
                <i class="fa fa-caret-down centerType textblack"></i>
              </button>
              <div class="dropdown-menu drop-down-type">
                <div
                  class="dropdown-item cursorPointer text-capitalize"
                  (click)="addCollectionType('Private')"
                >
                  <span class="icon-text-wrapper d-flex align-items-center">
                    <div class="icon">
                      <svg
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        focusable="false"
                      >
                        <path
                          d="M17 8V6.63C17 4.08 14.76 2 12 2S7 4.08 7 6.63V8H4v14h16V8h-3zM8 6.63c0-2.02 1.79-3.66 4-3.66s4 1.64 4 3.66V8H8V6.63zM19 21H5V9h14v12zm-7-9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                        ></path>
                      </svg>
                    </div>
                    Private
                  </span>
                </div>
                <div
                  class="dropdown-item cursorPointer text-capitalize"
                  (click)="addCollectionType('Public')"
                >
                  <span class="icon-text-wrapper d-flex align-items-center">
                    <div class="icon">
                      <svg
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        focusable="false"
                      >
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM3 12c0-.7.09-1.37.24-2.02L8 14.71v.79c0 1.76 1.31 3.22 3 3.46v1.98c-4.49-.5-8-4.32-8-8.94zm8.5 6C10.12 18 9 16.88 9 15.5v-1.21l-5.43-5.4C4.84 5.46 8.13 3 12 3c1.05 0 2.06.19 3 .53V5c0 .55-.45 1-1 1h-3v2c0 .55-.45 1-1 1H8v3h6c.55 0 1 .45 1 1v4h2c.55 0 1 .45 1 1v.69C16.41 20.12 14.31 21 12 21v-3h-.5zm7.47-.31C18.82 16.73 18 16 17 16h-1v-3c0-1.1-.9-2-2-2H9v-1h1c1.10 0 2-.9 2-2V7h2c1.10 0 2-.9 2-2V3.95c2.96 1.48 5 4.53 5 8.05 0 2.16-.76 4.14-2.03 5.69z"
                        ></path>
                      </svg>
                    </div>
                    Public
                  </span>
                </div>
              </div>
            </div>

            <p class="text-center pt-2 or-txt">OR</p>
            <p class="text-center pt-2">Add to existing groups</p>
            <div>
              <!-- <select
                              class="form-select form-select-lg mb-3 w-100"
                              aria-label=".form-select-lg example"
                              [(ngModel)]="selectedCollectionIndex"
                              >
                              <option selected style="font-weight: 600; font-size: 14px">
                                Select Existing Collection
                              </option>
                              <option
                                *ngFor="let group of existingCollections; let i = index"
                                style="padding: 20px"
                                value="{{ i }}"
                                >
                                {{ group.title }}
                              </option>
                            </select> -->
              <select
                class="form-select form-select-lg mb-3 w-100 custom-select"
                aria-label=".form-select-lg example"
                [(ngModel)]="selectedCollectionIndex"
              >
                <option selected style="font-weight: 600; font-size: 14px">
                  Select Existing Collection
                </option>
                @for (group of existingCollections; track group; let i = $index)
                {
                <option style="padding: 20px" value="{{ i }}">
                  {{ group.title }}
                </option>
                }
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-warning create-btn"
            data-dismiss="modal"
            (click)="onAddCollection()"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- collaborate modal -->

  <!-- Modal -->
  <div
    class="modal fade collaborate"
    id="collaborate"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Collaborate</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="select-content">
            <div class="collaborate-container">
              <p class="caption">
                Collaborators can add videos to this playlist
              </p>

              <label class="switch">
                <input
                  type="checkbox"
                  checked
                  (click)="canAddMediaId = !canAddMediaId"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="collaborate-container">
              <p class="caption">Allow new collaborators</p>
              <label class="switch">
                <input
                  type="checkbox"
                  checked
                  (click)="canAddMembersId = !canAddMembersId"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <!-- <div
                            class="collaborate-conteiner bar style-scope yt-copy-link-renderer"
                            >
                            <input
                              id="share-url"
                              dir="ltr"
                              readonly=""
                              size="65"
                              class="style-scope yt-copy-link-renderer"
                              value="{{ inputValue }}"
                              />

                              <div class="tooltipShare">
                                <button
                                  class="btn btn-primary"
                                  (click)="myFunction('share-url')"
                                  (mouseout)="outFunc()"
                                  >
                                  <span class="tooltiptext" id="myTooltip"
                                    >Copy to clipboard</span
                                    >
                                    Copy
                                  </button>
                                </div>
                              </div> -->
          <div class="username-content">
            <div class="mt-2">
              <div>
                <p>Collaborate {{ teamslength }}</p>
              </div>

              <div class="d-flex">
                @for (user of teams; track user; let i = $index) {
                <div class="w-fitcontent">
                  <span
                    [attr.data-letters]="user.user ? user.user[0] : ''"
                  ></span>
                  <p>{{ user.user }}</p>
                </div>
                }
              </div>
            </div>
            <input
              class="form-control mt-2 mb-2"
              type="text"
              [(ngModel)]="searchText"
              placeholder="Search by username"
              (keyup)="filterUsers()"
            />

            <div class="searchUser">
              <!-- <div
                                    class="d-flex justify-content-between shareDiv mb-1 mt-1"
                                    *ngFor="let user of filteredUsers; let i = index"
                                    >
                                    <div>{{ user.username }}</div>
                                    <div>
                                      <button class="btn btn-primary" (click)="InviteFile(user)">
                                        Invite
                                      </button>
                                    </div>
                                  </div> -->
              @for (user of filteredUsers; track user; let i = $index) {
              <div class="d-flex justify-content-between shareDiv mb-1 mt-1">
                <div>{{ user.username }}</div>
                <div>
                  <button
                    class="btn btn-primary"
                    (click)="toggleInvitation(user)"
                    [ngClass]="{ 'yellow-background': user.invited }"
                  >
                    {{ user.invited ? "Invited" : "Invite" }}
                  </button>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn invite"
            data-dismiss="modal"
            aria-label="Close"
            (click)="getSelectedInvite(slelectedCardDAta)"
          >
            Invite Selected
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade collaborate collaborate1"
    id="shareP"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Share</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="url-container">
            <div
              class="collaborate-conteiner bar style-scope yt-copy-link-renderer"
            >
              <label for="link">Link</label><br />
              <div class="input-content">
                <input
                  id="share-url"
                  dir="ltr"
                  readonly=""
                  size="60"
                  class="style-scope yt-copy-link-renderer"
                  value="{{ inputValue }}"
                />
                <div class="tooltipShare">
                  <button
                    class="btn-url"
                    (click)="myFunction('share-url')"
                    (mouseout)="outFunc()"
                  >
                    <span class="tooltiptext" id="myTooltip"
                      >Copy to clipboard</span
                    >
                    <img src="../../../assets/copy-paste.svg" alt="copy" />
                  </button>
                </div>
              </div>
            </div>
            <div
              class="collaborate-conteiner bar style-scope yt-copy-link-renderer mt-2"
            >
              <label for="password">Password</label><br />
              <div class="input-content">
                <input
                  id="password-url"
                  dir="ltr"
                  readonly=""
                  size="65"
                  class="style-scope yt-copy-link-renderer"
                  value="{{ access_password }}"
                />
                <div class="tooltipShare">
                  <button
                    class="btn-password"
                    (click)="myFunction('password-url')"
                    (mouseout)="outFunc()"
                  >
                    <span class="tooltiptext" id="myTooltip"
                      >Copy to clipboard</span
                    >
                    <img src="../../../assets/copy-paste.svg" alt="copy" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <button class="btn btn-primary" (click)="onClickShareCollection()">
                                      Send
                                    </button> -->
          <div class="d-flex justify-content-between shareDiv mb-1 mt-1">
            <!-- <div>
                                      <button class="btn btn-primary" (click)="shareFile(user.id)">
                                        Send
                                      </button>
                                    </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="requestModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Requests For Join</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src="../../../assets/cross_icon.svg" alt="close" />
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Username</th>
                <th scope="col">PlayList</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              @for (play of invitationDetails; track play; let i = $index) {
              <tr>
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ play.senderName }}</td>
                <td>{{ play.collectionTitle }}</td>
                <td>
                  <button
                    (click)="
                      acceptInvitation(
                        i,
                        play.collaborationId,
                        play.collectionId
                      )
                    "
                    class="btn btn-success accept"
                  >
                    Accept
                  </button>
                  <button
                    class="btn btn-danger accept"
                    (click)="rejectInvitation(i, play.collaborationId)"
                  >
                    Reject
                  </button>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
