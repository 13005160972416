<nav class="navbar navbar-expand-md navbar-light">
  <div class="header-first">
    <a routerLink="/home" href="javascript:void(0)" class="navbar-brand">
      <img
        src="../../../assets/Group 185-copy.png"
        class="img-fluid Header-company-logo"
        alt="Header-company-logo"
        />
    </a>
  </div>
</nav>
<div class="header-line"></div>
<div
  class="col-lg-10 m-auto pb-5 adjust-height three-view scrollable overflow-x-hidden"
  >
  @if (!selectPlayList && !isFolderPreview) {
    <div style="display: contents">
      @for (card of AllPlayList; track card; let i = $index) {
        <div
          class="card card-cont folder-content"
          (click)="displayPlayList(card._id)"
          >
          @if (
            (card.ogFileUrl && card.extension == '.jpeg') ||
            card.extension == '.png' ||
            card.extension == '.jpg'
            ) {
            <img
              class="img-thumbnail"
              id="image"
              [src]="card.ogFileUrl"
              height="100%"
              style="object-fit: cover"
              />
          }
          @if (card.ogFileUrl && card.extension == '.pptx') {
            <img
              src="assets/Archive_files/pptx.png"
              height="65%"
              style="object-fit: contain; margin: auto"
              alt=""
              />
          }
          @if (card.ogFileUrl && card.extension == '.pdf') {
            <img
              src="assets/Archive_files/pdf.png"
              height="65%"
              style="object-fit: contain; margin: auto; padding-top: 1rem"
              alt=""
              />
          }
          @if (
            (card.ogFileUrl && card.extension == '.doc') ||
            card.extension == '.docx'
            ) {
            <img
              src="../../../assets/docx-img.png"
              height="65%"
              style="object-fit: contain; margin: auto; padding-top: 1rem"
              alt=""
              />
          }
          @if (
            (card.ogFileUrl && card.extension == '.xls') ||
            card.extension == '.xlsx'
            ) {
            <img
              src="../../../assets/Archive_files/excel-icon.png"
              height="65%"
              style="object-fit: contain; margin: auto; padding-top: 1rem"
              alt=""
              />
          }
          @if (card.thumbnails[0]) {
            <div
              id="videothumnail"
              class="videoThumbnail"
              style="display: grid"
              (mouseenter)="showControls(i)"
              (mouseleave)="hideControls(i)"
              >
              <div class="p-2" style="justify-self: center">
                @if (
                  card.gif && card.extension == '.mp4' && card.selectedThumbnail
                  ) {
                  <video
                    #mediaElement
                    id="video"
                    [src]="card.gif"
                    preload="auto"
                    [muted]="true"
            style="
              width: 100%;
              height: 223px;
              width: auto;
              max-width: 100%;
              justify-self: center;
            "
                    class=""
                    loop
                    playsinline="true"
                    poster="{{ card.selectedThumbnail }}"
                    >
                    Your browser does not support the video tag.
                  </video>
                }
                @if (
                  card.gif && card.extension == '.mp4' && !card.selectedThumbnail
                  ) {
                  <video
                    #mediaElement
                    id="video"
                    [src]="card.gif"
                    preload="auto"
                    [muted]="true"
            style="
              width: 100%;
              height: 223px;
              width: auto;
              max-width: 100%;
              justify-self: center;
            "
                    class=""
                    loop
                    playsinline="true"
                    poster="{{ card.thumbnails[0] }}"
                    >
                    Your browser does not support the video tag.
                  </video>
                }
              </div>
            </div>
          }
          <div>
            @if (card.extension == '.xlsx' || card.extension == '.xls') {
              <p
                class="mb-0"
              ></p>
            }
            @if (card.extension == '.docx' || card.extension == '.doc') {
              <p
                class="mb-0"
              ></p>
            }
          </div>
          @for (res of PdfDetails; track res) {
            <div>
              <p class="text-center text-black-50 mb-0">File :{{ res.filename }}</p>
            </div>
          }
          <div class="card-body d-flex flex-column">
            <div>
              <h5 class="card-title d-flex fixed-width">{{ card.title }}</h5>
            </div>
            <div class="d-flex align-items-center heightDetails">
              <!-- <div>
              <img
                src="{{ card.profileImg }}"
                width="30px"
                height="30px"
                style="border-radius: 50%"
                *ngIf="card.profileImg"
                />
            </div> -->
            <!-- <div>
            <img
              src="../../../assets/add-people.png"
              width="20px"
              height="20px"
              *ngIf="!card.profileImg"
              />
          </div> -->
          <div>
            <!-- <p class="text-black m-0">{{ card.uploadedBy }}</p> -->
          </div>
          <!-- <div class="dot"></div> -->
          <div>
            <!-- <p class="text-grey m-0">{{ card.createdAt | date }}</p> -->
          </div>
          <div class="moreOptions">
            <div class="dropdown">
              <ul class="dropbtn icons btn-right showLeft">
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <div id="myDropdown" [ngClass]="{}" class="dropdown-content">
                <a class="d-flex align-items-center gapP">
                  <img src="../../../assets/downloadP.png" />
                  <span>Download</span>
                </a>
                <a class="d-flex align-items-center gapP">
                  <img src="../../../assets/deleteP.png" />
                  <span>Delete</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="position-relative" *ngFor="let item of card.play_list">
      <img class="folder" id="image" src="../../../assets/folder.jpg" *ngIf="!item.thumbnail" width="100%"
        height="100%" />
      <img class="folder" id="image" *ngIf="item.thumbnail" [src]="item.thumbnail" width="100%"
        height="100%" />
      <div class="thumbnail-overlay">
        <svg class="icon" height="24" viewBox="0 0 24 24" width="24" focusable="false">
          <path d="M22 7H2v1h20V7zm-9 5H2v-1h11v1zm0 4H2v-1h11v1zm2 3v-8l7 4-7 4z"></path>
        </svg>
        <span>{{item.data.length}} videos</span>
      </div>
    </div> -->
    @for (item of card.play_list; track item) {
      <div class="position-relative">
        @if (!item.thumbnail) {
          <img
            class="folder"
            id="image"
            src="../../../assets/folder.jpg"
            width="100%"
            height="100%"
            />
        }
        <div style="width: 100%; height: 235px; position: relative">
          @if (item.thumbnail) {
            <img
              class="folder"
              id="image"
              [src]="item.thumbnail"
              width="100%"
              height="100%"
              />
          }
          <div class="thumbnail-overlay">
            <svg
              class="icon"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              focusable="false"
              >
              <path
                d="M22 7H2v1h20V7zm-9 5H2v-1h11v1zm0 4H2v-1h11v1zm2 3v-8l7 4-7 4z"
              ></path>
            </svg>
            <span>{{ item.data.length }} videos</span>
          </div>
        </div>
      </div>
    }
    <div class="card-body d-flex flex-column">
      @for (data of card.play_list; track data) {
        <div>
          <h5 class="card-title d-flex fixed-width">{{ data.name }}</h5>
        </div>
      }
      <div class="d-flex align-items-center heightDetails">
        <div>
          @if (card.profileImg) {
            <img
              src="{{ card.profileImg }}"
              width="30px"
              height="30px"
              style="border-radius: 50%"
              />
          }
        </div>
        <div>
          @if (!card.profileImg) {
            <img
              src="../../../assets/add-people.png"
              width="20px"
              height="20px"
              />
          }
        </div>
        @for (data of card.play_list; track data) {
          <div>
            <p class="text-black m-0">{{ data.type }}</p>
          </div>
        }
        <div class="dot"></div>
        <div>
          <p class="text-grey m-0">{{ card.createdAt | date }}</p>
        </div>
        <div class="moreOptions">
          <!-- three dot menu -->
          <div class="dropdown">
            <!-- Three dots (trigger) -->
            <ul class="dropbtn icons btn-right showLeft">
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <!-- Menu items -->
            <div id="myDropdown " class="dropdown-content playDelete">
              <a class="d-flex">
                <div
                    style="
                      width: 34%;
                      height: 100%;
                      fill: currentcolor;
                      margin-left: -10px;
                    "
                  >
                  <svg
                    height="18"
                    viewBox="0 0 24 24"
                    width="24"
                    focusable="false"
                      style="
                        pointer-events: none;
                        display: block;
                        width: 100%;
                        height: 100%;
                      "
                    >
                    <path
                      d="M17 18v1H6v-1h11zm-.5-6.6-.7-.7-3.8 3.7V4h-1v10.4l-3.8-3.8-.7.7 5 5 5-4.9z"
                    ></path>
                  </svg>
                </div>
                Download
              </a>
              <a class="d-flex" data-toggle="modal" data-target="#saveModal">
                <div
                    style="
                      width: 34%;
                      height: 100%;
                      fill: currentcolor;
                      margin-left: -10px;
                    "
                  >
                  <svg
                    height="18"
                    viewBox="0 0 24 24"
                    width="24"
                    focusable="false"
                      style="
                        pointer-events: none;
                        display: block;
                        width: 100%;
                        height: 100%;
                      "
                    >
                    <path
                      d="M22 13h-4v4h-2v-4h-4v-2h4V7h2v4h4v2zm-8-6H2v1h12V7zM2 12h8v-1H2v1zm0 4h8v-1H2v1z"
                    ></path>
                  </svg>
                </div>
                Save
              </a>
              @for (data of card.play_list; track data) {
                <a
                  class="d-flex"
                  data-toggle="modal"
                  data-target="#saveModal"
                  >
                  <div style="width: 100%; height: 100%; fill: currentcolor">
                    <svg
                      enable-background="new 0 0 24 24"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      focusable="false"
                      style="
                        pointer-events: none;
                        display: block;
                        width: 100%;
                        height: 100%;
                      "
                      >
                      <path
                        d="M11 17H9V8h2v9zm4-9h-2v9h2V8zm4-4v1h-1v16H6V5H5V4h4V3h6v1h4zm-2 1H7v15h10V5z"
                      ></path>
                    </svg>
                  </div>
                  Delete PlayList
                </a>
              }
              @for (data of card.play_list; track data) {
                <a
                  class="d-flex"
                  data-toggle="modal"
                  data-target="#saveModal"
                  >
                  <div style="width: 20%; height: 100%; fill: currentcolor">
                    <svg
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      focusable="false"
                      style="
                        pointer-events: none;
                        display: block;
                        width: 100%;
                        height: 100%;
                      "
                      >
                      <path
                        d="M15 5.63 20.66 12 15 18.37V14h-1c-3.96 0-7.14 1-9.75 3.09 1.84-4.07 5.11-6.4 9.89-7.1l.86-.13V5.63M14 3v6C6.22 10.13 3.11 15.33 2 21c2.78-3.97 6.44-6 12-6v6l8-9-8-9z"
                      ></path>
                    </svg>
                  </div>
                  Share
                </a>
              }
              @for (data of card.play_list; track data) {
                <a
                  class="d-flex"
                  data-toggle="modal"
                  data-target="#saveModal"
                  >
                  <div style="width: 20%; height: 100%; fill: currentcolor">
                    <svg
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      focusable="false"
                      style="
                        pointer-events: none;
                        display: block;
                        width: 100%;
                        height: 100%;
                      "
                      >
                      <path
                        d="M14 20c0-2.21 1.79-4 4-4s4 1.79 4 4h-8zm4-4c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm-3-8c0-2.21-1.79-4-4-4S7 5.79 7 8c0 1.96 1.42 3.59 3.28 3.93C4.77 12.21 2 15.76 2 20h10.02L12 19H3.06c.38-3.11 2.61-6.1 7.94-6.1.62 0 1.19.05 1.73.13l.84-.84c-.58-.13-1.19-.23-1.85-.26A4.004 4.004 0 0015 8zm-4 3c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"
                      ></path>
                    </svg>
                  </div>
                  collaborate
                </a>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
</div>
}

@if (selectPlayList) {
  <div style="display: contents">
    <div class="card card-cont">
      @if (
        (showPlayList.ogFileurl && showPlayList.extension == '.jpeg') ||
        showPlayList.extension == '.png' ||
        showPlayList.extension == '.jpg'
        ) {
        <img
          class="img-thumbnail"
          id="image"
          [src]="showPlayList.ogFileurl"
          height="100%"
          style="object-fit: cover"
          />
      }
      @if (showPlayList.thumbnails[0]) {
        <div
          id="videothumnail"
          class="videoThumbnail"
          style="display: grid"
          >
          <div class="p-2" style="justify-self: center">
            @if (
              showPlayList.gif &&
              showPlayList.extension == '.mp4' &&
              showPlayList.selectedThumbnail
              ) {
              <video
                #mediaElement
                id="video"
                [src]="showPlayList.gif"
                preload="auto"
                [muted]="true"
            style="
              width: 100%;
              height: 223px;
              width: auto;
              max-width: 100%;
              justify-self: center;
              background-color: black;
            "
                class=""
                loop
                playsinline="true"
                poster="{{ showPlayList.selectedThumbnail }}"
                >
                Your browser does not support the video tag.
              </video>
            }
            @if (
              showPlayList.gif &&
              showPlayList.extension == '.mp4' &&
              !showPlayList.selectedThumbnail
              ) {
              <video
                #mediaElement
                id="video"
                [src]="showPlayList.gif"
                preload="auto"
                [muted]="true"
            style="
              width: 100%;
              height: 223px;
              width: auto;
              max-width: 100%;
              justify-self: center;
              background-color: black;
            "
                class=""
                loop
                playsinline="true"
                poster="{{ showPlayList.thumbnails[0] }}"
                >
                Your browser does not support the video tag.
              </video>
            }
          </div>
        </div>
      }
      @for (res of PdfDetails; track res) {
        <div>
          @if (
            showPlayList.extension == '.pdf' &&
            showPlayList.ogFileUrl == res.orginal
            ) {
            <p
              class="mb-0"
              >
              <ngx-doc-viewer id="preview-pdf" [url]="pdfUrl" viewer="google">
              </ngx-doc-viewer>
            </p>
          }
          @if (
            showPlayList.extension == '.js' &&
            showPlayList.ogFileUrl == res.orginal
            ) {
            <p
              class="mb-0"
              >
              <ngx-doc-viewer id="preview-pdf" [url]="jsUrl" viewer="google">
              </ngx-doc-viewer>
            </p>
          }
          @if (
            showPlayList.extension == '.pptx' &&
            showPlayList.ogFileUrl == res.orginal
            ) {
            <p
              class="mb-0"
              >
              <ngx-doc-viewer id="preview-pdf" [url]="pptxUrl" viewer="google">
              </ngx-doc-viewer>
            </p>
          }
          @if (
            showPlayList.extension == '.xlsx' &&
            showPlayList.ogFileUrl == res.orginal
            ) {
            <p
              class="mb-0"
              >
              <ngx-doc-viewer
                id="preview-pdf"
                class="prev-pdf"
                [url]="excelUrl"
                viewer="google"
                >
              </ngx-doc-viewer>
              <!-- <img id="preview-pdf" class="prev-pdf" src="../../../assets/folder.jpg"> -->
            </p>
          }
          @if (
            showPlayList.extension == '.docx' &&
            showPlayList.ogFileUrl == res.orginal
            ) {
            <p
              class="mb-0"
              >
              <ngx-doc-viewer id="preview-pdf" [url]="docsUrl" viewer="google">
              </ngx-doc-viewer>
            </p>
          }
        </div>
      }
      @for (res of PdfDetails; track res) {
        <div>
          @if (
            (showPlayList.extension == '.pdf' &&
            showPlayList.ogFileUrl == res.orginal) ||
            (showPlayList.extension == '.pptx' &&
            showPlayList.ogFileUrl == res.orginal) ||
            (showPlayList.extension == '.xlsx' &&
            showPlayList.ogFileUrl == res.orginal) ||
            (showPlayList.extension == '.docx' &&
            showPlayList.ogFileUrl == res.orginal) ||
            (showPlayList.extension == '.js' &&
            showPlayList.ogFileUrl == res.orginal) ||
            (showPlayList.extension == '.mp4' &&
            showPlayList.ogFileUrl == res.orginal)
            ) {
            <p
              class="text-center text-black-50 mb-0"
              >
              File :{{ res.filename }}
            </p>
          }
        </div>
      }
      <div class="card-body d-flex flex-column">
        <div>
          <h5 class="card-title d-flex fixed-width">
            {{ showPlayList.title }}
          </h5>
        </div>
        <div class="d-flex align-items-center heightDetails">
          <div>
            @if (showPlayList.profileImg) {
              <img
                src="{{ showPlayList.profileImg }}"
                width="30px"
                height="30px"
                style="border-radius: 50%"
                />
            }
          </div>
          <div>
            @if (!showPlayList.profileImg) {
              <img
                src="../../../assets/add-people.png"
                width="20px"
                height="20px"
                />
            }
          </div>
          <div>
            <p class="text-black m-0">{{ showPlayList.uploadedBy }}</p>
          </div>
          <div class="dot"></div>
          <div>
            <p class="text-grey m-0">{{ showPlayList.createdAt | date }}</p>
          </div>
          <div class="moreOptions">
            <!-- three dot menu -->
            <div class="dropdown">
              <!-- Three dots (trigger) -->
              <ul class="dropbtn icons btn-right showLeft">
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <!-- Menu items -->
              <div id="myDropdown" class="dropdown-content">
                <a class="d-flex">
                  <div
                    style="
                      width: 34%;
                      height: 100%;
                      fill: currentcolor;
                      margin-left: -10px;
                    "
                    >
                    <svg
                      height="18"
                      viewBox="0 0 24 24"
                      width="24"
                      focusable="false"
                      style="
                        pointer-events: none;
                        display: block;
                        width: 100%;
                        height: 100%;
                      "
                      >
                      <path
                        d="M17 18v1H6v-1h11zm-.5-6.6-.7-.7-3.8 3.7V4h-1v10.4l-3.8-3.8-.7.7 5 5 5-4.9z"
                      ></path>
                    </svg>
                  </div>
                  Download
                </a>
                <a class="d-flex" data-toggle="modal" data-target="#saveModal">
                  <div
                    style="
                      width: 34%;
                      height: 100%;
                      fill: currentcolor;
                      margin-left: -10px;
                    "
                    >
                    <svg
                      height="18"
                      viewBox="0 0 24 24"
                      width="24"
                      focusable="false"
                      style="
                        pointer-events: none;
                        display: block;
                        width: 100%;
                        height: 100%;
                      "
                      >
                      <path
                        d="M22 13h-4v4h-2v-4h-4v-2h4V7h2v4h4v2zm-8-6H2v1h12V7zM2 12h8v-1H2v1zm0 4h8v-1H2v1z"
                      ></path>
                    </svg>
                  </div>
                  Save
                </a>
                <a class="d-flex" data-toggle="modal" data-target="#saveModal">
                  <div style="width: 23%; height: 100%; fill: currentcolor">
                    <svg
                      enable-background="new 0 0 24 24"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      focusable="false"
                      style="
                        pointer-events: none;
                        display: block;
                        width: 100%;
                        height: 100%;
                      "
                      >
                      <path
                        d="M11 17H9V8h2v9zm4-9h-2v9h2V8zm4-4v1h-1v16H6V5H5V4h4V3h6v1h4zm-2 1H7v15h10V5z"
                      ></path>
                    </svg>
                  </div>
                  Remove
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
<p class="text-center text-capitalize" id="notFound"></p>

@if (notFoundResult) {
  <div class="notFoundData">
    <img src="../../../assets/not1.png" width="100%" height="100%" />
  </div>
}

<button
  type="button"
  id="modalPass"
  data-toggle="modal"
  data-target="#staticBackdrop"
  class="d-none"
></button>

<!-- Modal -->
<div
  id="modalPass1"
  class="modal fade"
  id="staticBackdrop"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  >
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Shared Password</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              name="password"
              [(ngModel)]="password"
              required
              autocomplete="off"
              />
          </div>
          @if (errorMessage) {
            <div class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
          }
          <button type="submit" class="btn btn-warning text-white">
            Submit
          </button>
        </form>
      </div>
      <div class="modal-footer">
        <button
          id="closeModal"
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
@if (isFolderPreview) {
  <div
    (click)="isFolderPreview = false"
    class="col-lg-12 pb-5 adjust-height"
    >
    <div style="display: contents" id="publicData">
      <div class="card card-cont">
        <img
          class="folder"
          id="image"
          src="assets/folder.jpg"
          width="100%"
          height="100%"
          />
        @for (item of currentFolder.media; track item) {
          <div class="position-relative">
            <div style="width: 100%; position: relative">
              @if (item.thumbnail) {
                <img
                  class="folder"
                  id="image"
                  [src]="item.thumbnail"
                  width="100%"
                  height="100%"
                  />
              }
              <div class="thumbnail-overlay">
                <svg
                  class="icon"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  focusable="false"
                  >
                  <path
                    d="M22 7H2v1h20V7zm-9 5H2v-1h11v1zm0 4H2v-1h11v1zm2 3v-8l7 4-7 4z"
                  ></path>
                </svg>
                <span>{{ currentFolder.media.length }} Files</span>
              </div>
            </div>
          </div>
        }
        <div class="card-body d-flex flex-column">
          <h5 class="card-title d-flex fixed-width">
            {{ currentFolder.title }}
          </h5>
          <div class="d-flex align-items-center heightDetails">
            <div>
              @if (currentFolder.creatorProfileImg) {
                <img
                  src="{{ currentFolder.creatorProfileImg }}"
                  width="30px"
                  height="30px"
                  style="border-radius: 50%"
                  />
              }
            </div>
            <div>
              @if (!currentFolder.creatorProfileImg) {
                <img
                  src="../../../assets/add-people.png"
                  width="20px"
                  height="20px"
                  />
              }
            </div>
            <p class="text-black m-0">
              {{ currentFolder.creatorName }}
            </p>
            <div class="dot"></div>
            <div>
              <p class="text-grey m-0">{{ currentFolder.createdAt | date }}</p>
            </div>
            <div class="moreOptions">
              <div class="dropdown">
                <ul class="dropbtn icons btn-right showLeft">
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
                <div
                  id="myDropdown "
                  [ngClass]="{}"
                  class="dropdown-content playDelete"
                  >
                  <a class="d-flex align-items-center gapP">
                    <img src="../../../assets/downloadP.png" />
                    <span>Download</span>
                  </a>
                  <!-- (click)="deletePlayListId(data.name, data.type)" -->
                  <a class="d-flex align-items-center gapP">
                    <img src="../../../assets/deleteP.png" />
                    <span>Delete PlayList</span>
                  </a>
                  <!-- <a
                  class="d-flex align-items-center gapP"
                  data-toggle="modal"
                  data-target="#shareP"
                  (click)="shareCollection(card._id)"
                  > -->
                  <a
                    class="d-flex align-items-center gapP"
                    data-toggle="modal"
                    data-target="#shareP"
                    >
                    <img src="../../../assets/sharePlay.png" />
                    <span>Share</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
</div>

<router-outlet></router-outlet>
