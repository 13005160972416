import { Component } from "@angular/core";
import { HeaderComponent } from "../../header/header.component";
import { NgApexchartsModule } from "ng-apexcharts";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexYAxis,
  ApexPlotOptions,
  ApexLegend,
  ApexGrid,
} from "ng-apexcharts";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CrmService } from "src/app/service/crm.service";
import { provideNativeDateAdapter } from "@angular/material/core";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  colors: string[];
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  grid: ApexGrid;
};
@Component({
  selector: "app-dashboard",
  standalone: true,
  imports: [
    HeaderComponent,
    NgApexchartsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
  ],
  providers: [MatDatepickerModule, provideNativeDateAdapter()],
  templateUrl: "./dashboard.component.html",
  styleUrl: "./dashboard.component.css",
})
export class DashboardComponent {
  public chartOptions: Partial<ChartOptions>;

  constructor(private crmService: CrmService) {
    this.chartOptions = {
      title: {
        text: "Leads by Day",
        style: {
          color: "#2B2B2B",
          fontSize: "19px",
          fontFamily: "Raleway , sans-serif",
          fontWeight: 500,
        },
      },
      series: [
        {
          name: "My-series",
          data: [5, 7, 10, 12, 15, 17, 49, 26, 23, 35, 27, 29, 31, 13, 40],
        },
      ],
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },

      colors: [
        " #F9F0D3 ",
        "#FFE280",
        "#FDCC27",
        "#FFE280",
        "#FDCC27",
        "#F9F0D3",
        "#FDCC27",
        "#F9F0D3",
      ],
      plotOptions: {
        bar: {
          columnWidth: "70%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        strokeDashArray: 4,
        show: true,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },

      xaxis: {
        categories: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
        ],
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
    };
  }
}
