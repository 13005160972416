import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
// import { Observable } from "rxjs";
import { UserService } from "./service/user.service"; 


@Injectable({
  providedIn: "root",
})
export class AuthLoginGuard implements CanActivate {
  constructor(private router: Router, private userService:UserService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.userService.getToken()){
        this.router.navigate(['/home']);
        return false;
    }else{
        return true;
    }
  }
}
