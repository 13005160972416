<div class="alert fade show hide" role="alert" id="alert">


  <div style="display: inline-block">
    <button (click)="onClickDecline()" class="btn decline">
      <i class="fa fa-ban" aria-hidden="true"></i>
    </button>
    <button class="btn btn-success" (click)="onClickAccept()">
      <i class="fa fa-phone" aria-hidden="true"></i>
    </button>
  </div>
</div>

<!-- <button class="btn btn-primary" (click)="onReloadDoc()">refresh</button> -->
<!-- <app-header
*ngIf="router.url !== '/upload' && router.url !== '/login'"
></app-header> -->
@if (shouldShowChat()) {
  <div class="main-chat-wrapper">
    @if (showChat) {
      <app-new-group-chat style="z-index: 1" class="d-flex flex-row-reverse align-items-end z-1">
      </app-new-group-chat>
    }
    @if (showChat) {
      <app-new-single-chat style="margin-left: -1rem; z-index: 1"></app-new-single-chat>
    }
  </div>
}
<!-- <app-loader *ngIf="loader.getLoading()"></app-loader> -->
<!-- <ngx-loading-bar color="#fecc28" [includeSpinner]="false" height="5px"  ></ngx-loading-bar>  -->
<router-outlet></router-outlet>
<app-alerts></app-alerts>
<app-footer></app-footer>