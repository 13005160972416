import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "chatSort",
    standalone: true,
})
export class ChatSortPipe implements PipeTransform {
  transform(array: any[], property: string): any[] {
    console.log(array);
    console.log(property);

    if (!array || array.length === 0) {
      return array;
    }

    return array.slice().sort((a, b) => {
      const aValue = a[property]?.creationTime; // Use optional chaining to handle undefined or null
      const bValue = b[property]?.creationTime; // Use optional chaining to handle undefined or null

      console.log(aValue, bValue);

      if (aValue === undefined) {
        return 1; // Move items with undefined property to the end
      } else if (bValue === undefined) {
        return -1; // Move items with undefined property to the end
      }

      // Reverse the order to sort in descending order (most recent time on top)
      return bValue.localeCompare(aValue);
    });
  }
}
