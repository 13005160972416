import { Component, ViewChild, ElementRef, Type } from "@angular/core";
import { HeaderComponent } from "../header/header.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { provideNativeDateAdapter } from "@angular/material/core";
import { UserService } from "src/app/service/user.service";
import { CrmService } from "src/app/service/crm.service";
import { FormsModule } from "@angular/forms";
import Swal from "sweetalert2";
import { DatePipe } from "@angular/common";
import saveAs from "file-saver";
import { NgClass } from "@angular/common";


@Component({
  selector: "app-lead-track",
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    HeaderComponent,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    DatePipe,
    NgClass,
  ],
  templateUrl: "./lead-track.component.html",
  styleUrl: "./lead-track.component.css",
})
export class LeadTrackComponent {


  constructor(
    private userService: UserService,
    private crmService: CrmService,
  ) { }

  @ViewChild("shareLeadModal", { static: true }) shareModal: any;
  @ViewChild("iicContactLead", { static: true }) modal: any;

  isShimmerLoading: boolean = false;
  shimmerLoadingValue = ([] = [1, 2, 3, 4, 5, 6, 7, 8]);
  leadStatus: string[] = [
    "Got in touch",
    "Sent Introductory mail and Deck",
    "Working on the brief",
    "Agriculture",
    "Cost Sent",
    "Client appproval pending",
    "Low Budget",
    "We do not offer the service",
    "Client did not revert",
  ];
  ngOnInit() {
    this.getALLUsersList();
    this.getLeadsListingDetail();
    this.isAdminAndSales();
    // this.getLeadsForProject("V-RETAIL");
  }
  shareLeadModalInstance: any;
  iicContactUsleadDetail: any;
  ngAfterViewInit(): void {
    // this.iicContactUsleadDetail = new (window as any).bootstrap.Modal(
    //   this.modal.nativeElement
    // );
  }

  closeShareLeadmodal() {
    console.log(this.shareLeadModalInstance, "ShareLeadModal");
    this.shareLeadModalInstance.hide();
  }

  notFoundResult: boolean = false;
  iicUsers: any = [];
  isProjectShared: boolean = false;
  sharedUsers() {
    this.iicUsers.map((user) => {
      if (user.projects.includes(this.selectedProject.toUpperCase())) {
        user.checked = true;
      }
    });
    console.log(this.selectedProject, this.iicUsers);
  }

  hasSharedProjects(): boolean {
    return this.iicUsers.some(
      (user) => user.projects.includes(this.selectedProject.toUpperCase()),
      console.log(this.selectedProject, "PROJECT")
    );
  }
  // getALLUsersList() {
  //   const department = "SALES";
  //   const loggedInUsername = localStorage.getItem("username");
  //   const loggedInUserId = localStorage.getItem("guestID");
  //   this.crmService.getAnyUsers(department).subscribe((res) => {
  //     console.log(res);
  //     let data = res["data"];
  //     this.iicUsers = data.map((user) => {
  //       return {
  //         username: user.userName,
  //         id: user._id,
  //         projects: user.projects,
  //       };
  //     });
  //   });
  // }

  getALLUsersList() {
    const department = "SALES";
    const loggedInUsername = localStorage.getItem("username");
    const loggedInUserId = localStorage.getItem("guestID");
    this.crmService.getAnyUsers(department).subscribe(
      (res) => {
        let data = res["data"];
        this.iicUsers = data.map((user) => {
          // this.represetativeUsersId = user._id;
          return {
            username: user.userName,
            id: user._id,
          };
        });
        if (loggedInUsername) {
          this.iicUsers.push({
            username: loggedInUsername,
            id: loggedInUserId,
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  selectedUser: any[] = [];
  leads: any = [];
  projects: string[] = ["V-RETAIL", "V-ESTATE", "IIC-LAB"];
  headers: string[] = [];
  accessibleProjects: any[] = [];
  selectedProject: string = "V-RETAIL";
  getLeadsListingDetail(): void {
    const type = {
      project: this.selectedProject,
      name: this.searchQuery,
      startDate: this.startDate ? this.formatDate(this.startDate) : "",
      endDate: this.endDate ? this.formatDate(this.endDate) : "",
      pageNum: 1,
      limit: 20,
      projectTab: this.selectedType,
    };
    this.crmService.searchLeadList(type).subscribe(
      (response: any) => {
        this.leads = response.data.leads;
        this.totalLeads = response.data.totalLeads;

        // this.leadId = this.leads._id;

        console.log("leadID", this.leadId);
        console.log(this.leads, "leads");
        this.filterLeads();
        if (response.data.leads.length === 0) {
          this.showNoDataMessage = true;
        } else {
          this.showNoDataMessage = false;
        }
        // this.isShimmerLoading = true;
        console.log(this.projects, "project Name");
        if (this.selectedProject in response.data) {
          this.setLeads(response.data[this.selectedProject]);
        }
        this.getShareAccess();
      },
      (error) => {
        console.error("Error fetching leads data:", error);
      }
    );
  }

  //Accessible Projects
  setProject(project: string): void {
    if (!this.accessibleProjects.includes(project)) {
      return;
    }
    this.selectedProject = project;

    // Reset user checks
    this.iicUsers.forEach((user) => {
      user.checked = false;
    });

    if (this.selectedProject === "IIC-LAB") {
      this.selectedType = "Contact Us";
    } else {
      this.selectedType = "Book Demo";
    }

    this.selectedUser = [];
    this.clearLeads();
    this.getLeadsListingDetail();
    this.sharedUsers();
  }

  selectedType: string = "Book Demo";
  selectType(type: string) {
    this.filteredLeads.length = 0;
    if (this.selectedProject === "IIC-LAB") {
      this.selectedType = "Contact Us";
    } else {
      this.selectedType = type;
    }
    if (this.leads.length === 0) {
      this.showNoDataMessage = true;
    } else {
      this.showNoDataMessage = false;
    }

    console.log("LEADS", this.leads, this.leads.length);
    this.selectedUser = [];
    this.clearLeads();
    this.getLeadsListingDetail();
  }

  leadId: any;
  leadname: string;
  createdTime: string;
  updatedTime: string;
  showNoDataMessage: boolean = false;

  getLeadsForProject(project: string): void {
    const type = {
      project: this.selectedProject,
      name: this.searchQuery,
      startDate: this.startDate ? this.formatDate(this.startDate) : "",
      endDate: this.endDate ? this.formatDate(this.endDate) : "",
      pageNum: 1,
      limit: 20,
      projectTab: this.selectedType,
    };
    console.log(type, "Type");
    this.crmService.searchLeadList(type).subscribe(
      (response: any) => {
        const leadsData = response.data[project] || [];
        if (leadsData.source.length === 0) {
          this.showNoDataMessage = true;
          return;
        }
        this.setLeads(leadsData);
        console.log("leadsData", leadsData);

        leadsData.forEach((lead) => {
          this.leadId = lead._id;
          this.leadname = lead?.name;
          this.createdTime = lead?.createdAt;
          this.updatedTime = lead?.updatedAt;
          console.log("leadname", lead.name);
          console.log("Lead Id:", lead?.createdAt);
          console.log("Lead Id:", lead?.updatedAt);
        });

        const userIds = this.iicUsers.map((user) => user.id);
        console.log(userIds, "userID");
      },
      (error) => {
        console.error("Error fetching leads data:", error);
      }
    );
  }

  assignToUser(user: any, lead: any, i: number) {
    lead.assignedTo = user.username;
    this.selectedUser[i] = user.username;

    const body = {
      userId: user.id,
      userName: user.username,
      leadId: lead._id,
      project: lead.project,
    };

    this.crmService.assignLead(body).subscribe(
      (response) => {
        console.log("Lead assigned successfully", response);
        this.getLeadsListingDetail();
      },
      (error) => {
        console.error("Error assigning lead", error);
      }
    );
  }

  setLeads(leadsData: any[]): void {
    this.leads = leadsData;
    this.filterLeads();
  }

  filteredLeads: any[] = [];
  showNoSourceDataMessage: boolean = false;
  filterLeads() {
    this.filteredLeads = this.leads.filter(
      (lead) => lead.source === this.selectedType
    );
    if (this.filteredLeads.length === 0) {
      this.showNoSourceDataMessage = true;
    } else {
      this.showNoSourceDataMessage = false;
    }
    console.log(this.selectedType, "selected");
    this.filteredLeads.forEach((lead) => {
      lead.assignedToString = lead.assignedTo
        .map((assignee) => assignee.userName)
        .join(", ");
    });
  }

  shareWithUsers(): void {
    const selectedUserIds = this.iicUsers
      .filter((user) => user.checked)
      .map((user) => user.id);

    // if (selectedUserIds.length === 0) {
    //   console.log("Error: No users selected.");
    //   Swal.fire("select User First");
    //   return;
    // }
    const body = {
      userIds: selectedUserIds,
      project: this.selectedProject,
    };

    this.crmService.shareAccess(body).subscribe(
      (response) => {
        console.log("Success:", response);
        // this.closeShareLeadmodal();
        // this.shareLeadModalInstance.hide();
        // Swal.fire("Done");
      },
      (error) => {
        console.log("Error:", error);
      }
    );
  }

  toggleCheckbox(user: any): void {
    user.checked = !user.checked;
  }

  getShareAccess(): void {
    const loggedInUserId = localStorage.getItem("guestID");
    const department = localStorage.getItem("department");

    if (department === "ADMIN") {
      this.accessibleProjects = this.projects;
    } else {
      this.crmService.CheckLeadAccess(loggedInUserId).subscribe(
        (resp: any) => {
          this.accessibleProjects = resp.data.projects;
        },
        (error) => {
          console.error("Error fetching accessible projects:", error);
        }
      );
    }
  }

  isAdminShare: boolean = true;
  isAdminAndSales() {
    const department = localStorage.getItem("department");
    this.isAdminShare = department === "ADMIN";
  }

  //On search Input
  searchQuery: string;
  totalLeads: number;
  searchedLeadsResult: [];
  onSearchLeadList() {
    console.log({ searchQuery: this.searchQuery });
    const type = {
      project: this.selectedProject,
      name: this.searchQuery,
      startDate: this.startDate ? this.formatDate(this.startDate) : "",
      endDate: this.endDate ? this.formatDate(this.endDate) : "",
      pageNum: 1,
      limit: 20,
      projectTab: this.selectedType,
    };

    if (this.selectedProject !== "iic-lab") {
      type.projectTab = this.selectedType;
    } else {
      type.projectTab = "";
    }
    this.crmService.searchLeadList(type).subscribe(
      (response: any) => {
        this.searchedLeads = response.data.leads;
        this.totalLeads = response.data.totalLeads;
        this.searchedLeadsResult = response.data.leads;
        this.notFoundResult =
          this.searchedLeadsResult.length === 0 ? true : false;
        console.log("Length", this.searchedLeadsResult.length);
        console.log(response, "search results");
      },
      (error) => {
        console.error("Error searching leads:", error);
      }
    );
  }

  // Search Leads By Date Range
  startDate: Date | null = null;
  endDate: Date | null = null;
  searchedLeads: any[] = [];
  searchedFilteredLeads: any[] = [];
  startDateStr: string;
  endDateStr: string;
  searchLeadsListByDateRange() {
    if (this.startDate && this.endDate) {
      const startDateStr = this.formatDate(this.startDate);
      this.startDateStr = startDateStr;
      const endDateStr = this.formatDate(this.endDate);
      this.endDateStr = endDateStr;

      this.crmService
        .searchLeadList({
          project: this.selectedProject,
          startDate: startDateStr,
          endDate: endDateStr,
          pageNum: 1,
          limit: 20,
          projectTab: this.selectedType,
        })
        .subscribe(
          (response) => {
            console.log(response["data"], "search Items");
            // this.leads = response["data"];
            this.filteredLeads = response["data"].leads;
            this.totalLeads = response["data"].totalLeads;
            this.showNoDataMessage = response["data"].leads.length === 0;
          },
          (error) => {
            console.error("Error fetching leads:", error);
          }
        );
    }
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  clearLeads() {
    this.startDate = null;
    this.endDate = null;
    this.searchedLeads = [];
    this.showNoDataMessage = false;
    this.getLeadsListingDetail();
  }
  exportToExcel() {
    console.log("export");
    const options = {
      project: this.selectedProject,
      startDate: this.createdTime,
      endDate: this.updatedTime,
      pageNum: 1,
      limit: 20,
      projectTab: this.selectedType,
    };

    this.crmService.exportExcel(options).subscribe(
      (response: any) => {
        if (response.data.url) {
          window.open(response.data.url);
          Swal.fire({
            icon: "success",
            title: "Successfully Downloaded",
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "No Data Found",
          });
        }
      },
      (error) => {
        console.error("Error exporting leads:", error);

        Swal.fire({
          icon: "error",
          title: "No Data Found",
        });
      }
    );
  }

  currentPage = 1;
  itemsPerPage = 20;
  getPaginatedLeads() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.leads.slice(startIndex, endIndex);
  }

  // Function to handle page change
  pageChanged(page: number) {
    this.currentPage = page;
    console.log(page, "PAGE");
    const option = {
      pageNum: page,
      project: this.selectedProject,
      startDate: this.startDate ? this.formatDate(this.startDate) : "",
      endDate: this.endDate ? this.formatDate(this.endDate) : "",
      limit: 20,
      projectTab: this.selectedType,
    };
    this.crmService.searchLeadList(option).subscribe(
      (response: any) => {
        this.filteredLeads = response["data"].leads;
        this.totalLeads = response["data"].totalLeads;
        this.showNoDataMessage = response["data"].leads.length === 0;
      },
      (error) => {
        console.error("Error fetching leads data:", error);
      }
    );
    this.notFoundResult = false;
  }

  get totalPages(): number {
    return Math.ceil(this.totalLeads / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  trackByFn(index: number, item: any): any {
    return item._id;
  }

  get visiblePages(): number[] {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2)
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  }

  isPageVisible(page: number): boolean {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2)
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return page <= 3 || (page >= startPage && page <= endPage);
  }

  selectedStatus: any[] = [];
  statusSelected: any;
  statusnote: string = "";
  selectStatus(status: string, i: number, id: string) {
    this.selectedStatus[i] = status;
    this.leadId = id;
    this.statusSelected = this.selectedStatus[i];
    console.log(this.leadId, "leeeeeaaadddddId", status);
    // this.updateStatus();
    // this.getLeadsListingDetail();
  }

  updateStatus(id: string) {
    this.leadId = id;
    const body = {
      project: this.selectedProject,
      leadId: this.leadId,
      status: this.selectedStatus[0],
      description: this.statusnote,
    };
    console.log(body, this.statusnote);
    this.crmService.updateLeadStatus(body).subscribe((res) => {
      console.log("update status");
      this.getLeadsListingDetail();
    });
  }

  singleLead: any;
  singleLeadDetails(leadId: number): void {
    console.log(this.modal);
    // this.iicContactUsleadDetail.show();
    this.singleLead = this.filteredLeads.filter((res) => res._id === leadId)[0];
    this.statusnote = this.singleLead.description;
    console.log(this.singleLead, "single");
    this.filteredLeads.filter((res) => res._id === leadId);
  }
}
