import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { FooterComponent } from '../footer/footer.component';

import { HeaderComponent } from '../header/header.component';

@Component({
    selector: 'app-admin-access',
    templateUrl: './admin-access.component.html',
    styleUrls: ['./admin-access.component.css'],
    standalone: true,
    imports: [HeaderComponent, FooterComponent]
})
export class AdminAccessComponent implements OnInit {

  constructor(private userService:UserService,private router:Router) { }

  usersData;
  accesGranted;

  ngOnInit(): void {
    this.userService.getAllUsers().subscribe(resp=>{
      console.log("response",resp);
      console.log("data",resp['data']);
      if(resp["statusText"] !="Forbidden"){
        this.accesGranted=true;
        this.usersData=resp['data'];
        this.usersData=this.usersData.filter(x=>{
          return x['department'] != "admin";
        })
      }
      console.log(this.accesGranted);
      
    })
  }

  onClickUser(user){
    this.userService.selectedUserDetails=user;
    this.router.navigate(['./registration/'+user['_id']]);    
  }


  addUser(){
    this.router.navigate(['./registration/'+'-1']);
  }

  onClickUserDelete(user,i){
    this.userService.deleteUser(user._id).subscribe(resp=>{
      console.log("deleted ",resp);
      this.usersData.splice(i,1);
    })
  }

}
