import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";

import { Observable, Subject, from } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MediaService {
  media_url = environment.host + "media";
  play_list_url = environment.host + "share";
  media_upload_url = environment.host + "archive/upload";
  media_update_url = environment.host + "archive";
  additional_media_url = environment.host + "archive/additional-file";
  get_user_collection_url = environment.host + "archive/get-medias";
  get_public_collection_url = environment.host + "collections/public";
  get_private_collection_url = environment.host + "collections/private";
  add_media_collection_url = environment.host + "collections/add-media";
  get_collection_url = environment.host + "archive/collections";
  open_share_url = environment.host + "share/content/open";
  get_admin_collection_url = environment.host + "media/alltags";
  get_tags_url = environment.host + "common/tags";
  get_invite_collab_url = environment.host + "collaboration/invite";
  get_user_invite_req_url = environment.host + "collaboration";
  get_collection_delete_url = environment.host + "collections";

  constructor(private httpClient: HttpClient) {}

  mediaData;
  privateCollection: boolean;
  mediaID;
  selectedMedia;
  getUserCollection(tags, pageNum = 1, limit = 100) {
    var headers = this.get_auth_token();
    return this.httpClient.post(
      this.get_user_collection_url + `?page=${pageNum}&limit=${limit}`,
      { tags: tags },
      { ...headers }
    );
  }
  getPublicCollection(tags, pageNum = 1, limit = 100) {
    var headers = this.get_auth_token();
    return this.httpClient.get(
      this.get_public_collection_url + `?page=${pageNum}&limit=${limit}`,

      { ...headers }
    );
  }
  getPrivateCollection(tags, pageNum = 1, limit = 100) {
    var headers = this.get_auth_token();
    return this.httpClient.get(
      this.get_private_collection_url + `?page=${pageNum}&limit=${limit}`,

      { ...headers }
    );
  }

  getAdminCollection(tags = null) {
    return this.httpClient.post(this.get_admin_collection_url, { tags: tags });
  }

  addMediaCollection(body) {
    const headers = this.get_auth_token();
    return this.httpClient.post(this.add_media_collection_url, body, {
      ...headers,
    });
  }

  getCollectionGroup() {
    var headers = this.get_auth_token();
    return this.httpClient.get(this.get_collection_url, { ...headers });
  }

  getTags(tags) {
    return this.httpClient.post(this.get_tags_url, { tag: tags });
  }

  createMedia(mediaFile) {
    var formData = new FormData();
    formData.append("file", mediaFile);
    var headers = this.get_auth_token();
    return this.httpClient.post(this.media_upload_url, formData, {
      ...headers,
      reportProgress: true,
      observe: "events",
    });
  }

  editMedia(mediaId: any, mediaFile: any) {
    var formData = new FormData();
    formData.append("file", mediaFile);
    var headers = this.get_auth_token();
    return this.httpClient.put(
      this.media_update_url + "/media/" + mediaId,
      formData,
      {
        ...headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }

  createScreenShot(mediaFile) {
    var formData = new FormData();
    formData.append("file", mediaFile);
    var headers = this.get_auth_token();
    return this.httpClient.post(this.media_upload_url, formData, {
      ...headers,
    });
  }

  updateMedia(body: any) {
    if (body.selectedTags == "video") {
      body["selectedThumbnail"] = body.selected_thumbnail;
    }

    return this.httpClient.patch(this.media_update_url, body);
  }

  updateImage(body) {
    var headers = this.get_auth_token();

    return this.httpClient.post(this.additional_media_url, body, {
      ...headers,
    });
  }

  deleteImage(mediaId, idx) {
    return this.httpClient.patch(
      `${this.media_update_url}/additional-file/remove/`,
      { mediaId, fileId: idx }
    );
  }

  getOneByMediaId(mediaId) {
    var headers = this.get_auth_token();
    return this.httpClient.get(this.media_update_url + "/media/" + mediaId, {
      ...headers,
    });
  }

  deleteOnyById(mediaId: any) {
    var headers = this.get_auth_token();
    return this.httpClient.request("delete", this.media_update_url, {
      ...headers,
      body: { mediaId: mediaId },
    });
  }

  get_auth_token() {
    var token = localStorage.getItem("guestToken");
    var headers = {
      Authorization: "Bearer " + token,
    };
    return { headers: headers };
  }

  fileShare(phone: any, link: string) {
    let body = { phone, link };
    var headers = this.get_auth_token();
    return this.httpClient.post(this.media_url + "media/whatsapp-bot", body, {
      ...headers,
    });
  }

  playLists() {
    var headers = this.get_auth_token();
    let mockObs$: Observable<any> = from("");
    return mockObs$;
    return this.httpClient.get(this.play_list_url + "/" + "list", {
      ...headers,
    });
  }
  createPlayList(body: any) {
    var headers = this.get_auth_token();
    return this.httpClient.post(this.play_list_url, body, {
      ...headers,
    });
  }

  deletePlayList(body: any) {
    var headers = this.get_auth_token();
    return this.httpClient.delete(this.get_collection_delete_url, {
      ...headers,
      body: body,
    });
  }

  deleteCollectionPlaylist(collectionId: any) {
    var headers = this.get_auth_token();
    return this.httpClient.delete(this.get_collection_delete_url, {
      ...headers,
      body: { collectionId: collectionId },
    });
  }
  removeFromPlayList(body: any) {
    var headers = this.get_auth_token();
    return this.httpClient.delete(this.play_list_url + "/" + "remove", {
      ...headers,
      body: body,
    });
  }
  // &search=${search}
  sharePlay(body) {
    var headers = this.get_auth_token();
    return this.httpClient.post(this.play_list_url, body, {
      ...headers,
    });
  }

  sendDetails(id: any, body) {
    var headers = this.get_auth_token();
    return this.httpClient.post(`${this.play_list_url}/send/?id=${id}`, body, {
      ...headers,
    });
  }

  InvitePlayList(body, id) {
    var headers = this.get_auth_token();
    return this.httpClient.post(this.get_invite_collab_url, body, {
      ...headers,
    });
  }

  getUserInviteRequest() {
    var headers = this.get_auth_token();
    return this.httpClient.get(this.get_user_invite_req_url, {
      ...headers,
    });
  }

  acceptInvitation(body) {
    var headers = this.get_auth_token();
    return this.httpClient.post(
      `${this.get_user_invite_req_url}/accept-invite`,
      body,
      {
        ...headers,
      }
    );
  }

  rejectInvitation(body) {
    var headers = this.get_auth_token();
    return this.httpClient.post(
      `${this.get_user_invite_req_url}/reject-invite`,
      body,
      {
        ...headers,
      }
    );
  }
  accessPlayList(body) {
    var headers = this.get_auth_token();
    return this.httpClient.post(this.open_share_url, body, { ...headers });
  }

  guestPlaylist() {
    return this.httpClient.get(`${this.play_list_url}/guest-playlist`);
  }
}
