@if (showChatContainer) {
  <div class="single-chat">
    <img
      src="assets/single-chats.png"
      width="105px"
      alt=""
      class="img-fluid"
      (click)="closeContainer()"
      />
  </div>
}
<div class="single-chat-wrapper">
  @if (!showChatContainer) {
    <div class="single-chat-container">
      <div class="d-flex justify-content-between align-items-center">
        <div><h5 class="m-0">Chats</h5></div>
        <div
          (click)="showChatContainer = !showChatContainer"
          style="cursor: pointer"
          >
          <img style="width: 15px" src="assets/close1.png" alt="" />
        </div>
      </div>
      <hr />
      <div>
        <div>
          <input
            type="text"
            class="form-control filter-input"
            placeholder="Search in chats...."
            [(ngModel)]="searchTerm"
            (input)="updateFilteredUsers()"
            />
        </div>
        <!-- <div class="user-wrapper mt-3">
        <div *ngFor="let i of filteredUsers | chatSort : 'lastMessage'">
          <div
            class="d-flex align-items-start gap-3 seperate-chat justify-content-between"
            (click)="iniChat(i.userId)"
            >
            <div class="d-flex align-items-center">
              <img
                style="width: 40px"
                class="rounded-circle"
                [src]="i.profileImage"
                alt=""
                />
              <div class="ml-3">
                <p class="m-0 text-capitalize font-weight-bold">
                  <ng-template #noMatch>
                    {{ i.userName }}
                  </ng-template>
                  {{ i.userName }}
                </p>
                <p>{{ i?.lastMessage?.message }}</p>
              </div>
            </div>
            <div>
              <span class="recent-msg">{{
                i.lastMessage?.creationTime | date : "hh:mm a"
              }}</span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="user-wrapper mt-3">
        @for (i of searchFilter | chatSort : 'lastMessage'; track i) {
          <div>
            <div
              class="d-flex align-items-start gap-3 seperate-chat justify-content-between"
              (click)="iniChat(i.userId)"
              >
              <div class="d-flex align-items-center w-100">
                <img
                  style="width: 40px"
                  class="rounded-circle"
                  [src]="i.profileImage"
                  alt=""
                  />
                <div class="ml-3 w-100">
                  <p class="m-0 text-capitalize font-weight-bold">
                    {{ i.userName }}
                  </p>
                  <div class="m-0 recent-msg">
                    {{
                    i.lastMessage ? i.lastMessage.message : "No recent Message"
                    }}
                    @if (i.unreadCount && i.unreadCount != 0) {
                      <p
                        class="msg-count"
                        >
                        <span class="unread-msg" style="color: red">{{
                          showcount ? i.unreadCount : ""
                        }}</span>
                      </p>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div class="chat-messages-wrapper">
              @if (showUsers1) {
                <div class="w-50 bg-white rounded-2 chat-msg">
                  <div class="d-flex justify-content-between">
                    <!-- <div *ngIf="showUsers1" (click)="showUsers1 = !showUsers1">
                    <img src="assets/back-btn.png" class="img-fluid" alt="" />
                  </div> -->
                  <div>
                    <h5 class="text-capitalize m-0">{{ chatUserName }}</h5>
                  </div>
                  <div (click)="closeChat()" style="cursor: pointer">
                    <img style="width: 15px" src="assets/close1.png" alt="" />
                  </div>
                </div>
                <hr />
                <div class="chat-wrapper" #chatBody>
                  @for (message of singleChatMessages; track message) {
                    <div
                      style="display: contents"
                      >
                      @if (userId === message.sender_id) {
                        <div
                          class="message-box-holder"
                          >
                          @if (isDifferentTimestamp(message)) {
                            <div
                              class="message-time"
                              >
                              <span>{{ message.creationTime | date : "hh:mm a" }}</span>
                            </div>
                          }
                          @if (message.message) {
                            <div
                              class="message-box"
                      [style.backgroundColor]="
                        isURL(message.message) ? '#fecc28' : '#666666'
                      "
                              (click)="openUrlInNewWindow(message.message)"
                              style="cursor: pointer"
                              >
                              <span
                        [style.color]="
                          isURL(message.message) ? 'blue' : 'white'
                        "
                        [style.textDecoration]="
                          isURL(message.message) ? 'underline' : 'none'
                        "
                                >
                                {{ message.message }}
                              </span>
                            </div>
                          }
                          <!-- <ng-container *ngIf="message.fileName">
                          <div class="message-box">
                            File Name : {{ message.fileName }}
                            <div class="message-time">
                              <span>{{
                                message.creationTime | date : "hh:mm a"
                              }}</span>
                            </div>
                          </div>
                        </ng-container> -->
                        @if (message.url && isImage(message.url)) {
                          <div class="image-box-holder message-box">
                            <div class="image-box">
                              <img
                                [src]="message.url"
                                class="img-fluid"
                                alt="Image"
                                />
                            </div>
                          </div>
                        }
                        @if (message.url && isVideo(message.url)) {
                          <div class="video-box-holder message-box">
                            <div class="video-box">
                              <video
                                [src]="message.url"
                                class="embed-responsive embed-responsive-16by9"
                                controls
                              ></video>
                              <div class="message-time">
                                <span>{{
                                  message.creationTime | date : "hh:mm a"
                                }}</span>
                              </div>
                            </div>
                          </div>
                        }
                        <!-- <ng-container *ngIf="message.url && isDocument(message.url)">
                        <div class="document-box-holder message-box">
                          <div class="document-box">
                            <a [href]="message.url" target="_blank">Download Document</a>
                            <div class="message-time">
                              <span>{{ message.creationTime | date : "hh:mm a" }}</span>
                            </div>
                          </div>
                        </div>
                      </ng-container> -->
                      <!-- <ng-container *ngIf="message.url && isDocument(message.url)">
                      <div
                        class="document-box-holder message-box message-partner"
                        >
                        <div class="mess-box download-doc">
                          <p>{{ message.url }}</p>
                          <a [href]="message.url" target="_blank">
                            <img src="../../../assets/dwnload-icon.svg" />
                          </a>
                        </div>
                        <div class="message-time">
                          <span>{{ message.createdAt | date : "hh:mm a" }}</span>
                        </div>
                      </div>
                    </ng-container> -->
                    @if (message.url && isDocument(message.url)) {
                      <div class="document-box-holder message-box">
                        <div class="document-box download-doc">
                          <p>{{ message.url }}</p>
                          <a [href]="message.url" target="_blank">
                            <img
                              src="../../../assets/dwnload-icon.svg"
                              alt=""
                              />
                          </a>
                        </div>
                        <div class="message-time">
                          <span>{{
                            message.createdAt | date : "hh:mm a"
                          }}</span>
                        </div>
                      </div>
                    }
                  </div>
                }
                @if (userId !== message.sender_id) {
                  <div
                    class="receiver-message-box-holder"
                    >
                    @if (isDifferentTimestamp(message)) {
                      <div
                        class="message-time"
                        >
                        <span>{{ message.creationTime | date : "hh:mm a" }}</span>
                      </div>
                    }
                    @if (message.message) {
                      <div
                        class="receiver-box message-partner"
                        >
                        <!-- <img [src]="linkImagePreview" *ngIf="linkImagePreview" /> -->
                        @if (message.message) {
                          <div
                            class=""
                        [style.backgroundColor]="
                          isURL(message.message) ? '#fecc28' : '#ffffff'
                        "
                            (click)="openUrlInNewWindow(message.message)"
                            style="cursor: pointer"
                            >
                            <span
                          [style.color]="
                            isURL(message.message) ? 'blue' : 'black'
                          "
                          [style.textDecoration]="
                            isURL(message.message) ? 'underline' : 'none'
                          "
                              >
                              {{ message.message }}
                            </span>
                          </div>
                        }
                      </div>
                    }
                    @if (message.url && isImage(message.url)) {
                      <div class="image-box-holder receiver-box">
                        <div class="image-box">
                          <img
                            [src]="message.url"
                            class="img-fluid"
                            alt="Image"
                            />
                          <div class="message-time">
                            <span>{{
                              message.creationTime | date : "hh:mm a"
                            }}</span>
                          </div>
                        </div>
                      </div>
                    }
                    @if (message.url && isVideo(message.url)) {
                      <div class="video-box-holder receiver-box">
                        <div class="video-box">
                          <video
                            [src]="message.url"
                            class="embed-responsive embed-responsive-16by9"
                            controls
                          ></video>
                          <div class="message-time">
                            <span>{{
                              message.creationTime | date : "hh:mm a"
                            }}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <!-- <ng-container *ngIf="message.url && isDocument(message.url)">
                    <div class="document-box-holder receiver-box">
                      <div class="document-box">
                        <a [href]="message.url" target="_blank">Download Document</a>
                        <div class="message-time">
                          <span>{{ message.creationTime | date : "hh:mm a" }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container> -->
                  @if (message.url && isDocument(message.url)) {
                    <div
                      class="document-box-holder message-box message-partner"
                      >
                      <div class="mess-box download-doc">
                        <p>{{ message.url }}</p>
                        <a [href]="message.url" target="_blank">
                          <img src="../../../assets/dwnload-icon.svg" />
                        </a>
                      </div>
                      <div class="message-time">
                        <span>{{
                          message.createdAt | date : "hh:mm a"
                        }}</span>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          }
          @if (isLoading1) {
            <div
              class="d-flex justify-content-center flex-column align-items-center w-100"
              >
              <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <span class="uploading-progress">Uploading...</span>
            </div>
          }
        </div>
        <hr />
        <!-- <ul
        class="filter-data"
        *ngIf="filterDataSearch && filterDataSearchLength > 0"
        >
        <li
          *ngFor="let suggestion of filterDataSearch"
          (click)="selectSuggestion(suggestion.message)"
          >
          {{ suggestion.message }}
        </li>
      </ul> -->
      <!-- <ul class="filter-data" *ngIf="MetaDataDetails && MedataLength > 0">
      <li
        *ngFor="let suggestion of MetaDataDetails?.data; let i = index"
        (click)="selectSuggestion(suggestion.fileName)"
        >
        V - {{ i }} {{ suggestion.fileName }}
      </li>
    </ul> -->
    @if (MetaDataDetails && MedataLength > 0) {
      <ul
        class="filter-data"
        >
        @for (
          suggestion of MetaDataDetails?.data; track
          suggestion; let i = $index) {
          <li
            (click)="selectSuggestion(suggestion.fileUrl, suggestion._id)"
            >
            Main File: Version - {{ i + 1 }} {{ suggestion.fileName }}
            @if (suggestion.thread && suggestion.thread.length > 0) {
              <ul
                class="sub-file"
                >
                @for (
                  threadItem of suggestion.thread; track
                  threadItem; let j = $index) {
                  <li
                      (click)="
                        selectSuggestion(threadItem.fileUrl, suggestion._id)
                      "
                    >
                    Version - {{ i + 1 }}.{{ j + 1 }}
                    {{ threadItem.fileName }}
                  </li>
                }
              </ul>
            }
          </li>
        }
      </ul>
    }
    <div class="chat-input-holder">
      <textarea
        type="text"
        class="chat-input"
        [(ngModel)]="newMessage"
        placeholder="Type a message"
        (input)="onInputChange()"
        (keydown.enter)="sendMessage()"
        (keydown)="handelKeyDown1($event)"
      ></textarea>
      <div class="position-relative mx-3">
        <input
          type="file"
          (change)="handleFileInput($event)"
          (keydown.enter)="sendMessage()"
          /><img
          class="img-fluid img-icon"
          src="assets/upload-pic.png"
          alt=""
          />
      </div>
      <div class="cursorPointer" (click)="sendMessage()">
        <img
          src="../../../assets/sendMessage.png"
          class="message-send message-send1 m-auto d-block"
          />
      </div>
    </div>
  </div>
}
@if (showUsers2) {
  <div class="w-50 bg-white rounded-5 chat-msg">
    <div class="d-flex justify-content-between">
      <!-- <div *ngIf="showUsers1" (click)="showUsers1 = !showUsers1">
      <img src="assets/back-btn.png" class="img-fluid" alt="" />
    </div> -->
    <div>
      <h5 class="text-capitalize m-0">{{ chatUserName2 }}</h5>
    </div>
    <div (click)="closeChat2()" style="cursor: pointer">
      <img style="width: 15px" src="assets/close1.png" alt="" />
    </div>
  </div>
  <hr />
  <div class="chat-wrapper" #chatBody>
    @for (message of singleChatMessages2; track message) {
      <div
        style="display: contents"
        >
        @if (userId === message.sender_id) {
          <div
            class="message-box-holder"
            >
            @if (isDifferentTimestamp(message)) {
              <div
                class="message-time"
                >
                <span>{{ message.creationTime | date : "hh:mm a" }}</span>
              </div>
            }
            @if (message.message) {
              <div
                class="message-box"
                      [style.backgroundColor]="
                        isURL(message.message) ? '#fecc28' : '#666666'
                      "
                (click)="openUrlInNewWindow(message.message)"
                style="cursor: pointer"
                >
                <span
                        [style.color]="
                          isURL(message.message) ? 'blue' : 'white'
                        "
                        [style.textDecoration]="
                          isURL(message.message) ? 'underline' : 'none'
                        "
                  >
                  {{ message.message }}
                </span>
              </div>
            }
            <!-- <ng-container *ngIf="message.fileName">
            <div class="message-box">
              File Name : {{ message.fileName }}
              <div class="message-time">
                <span>{{
                  message.creationTime | date : "hh:mm a"
                }}</span>
              </div>
            </div>
          </ng-container> -->
          @if (message.url && isImage(message.url)) {
            <div class="image-box-holder message-box">
              <div class="image-box">
                <img
                  [src]="message.url"
                  class="img-fluid"
                  alt="Image"
                  />
              </div>
            </div>
          }
          @if (message.url && isVideo(message.url)) {
            <div class="video-box-holder message-box">
              <div class="video-box">
                <video
                  [src]="message.url"
                  class="embed-responsive embed-responsive-16by9"
                  controls
                ></video>
              </div>
            </div>
          }
          <!-- <ng-container *ngIf="message.url && isDocument(message.url)">
          <div class="document-box-holder message-box">
            <div class="document-box">
              <a [href]="message.url" target="_blank">Download Document</a>
              <div class="message-time">
                <span>{{ message.creationTime | date : "hh:mm a" }}</span>
              </div>
            </div>
          </div>
        </ng-container> -->
        <!-- <ng-container *ngIf="message.url && isDocument(message.url)">
        <div
          class="document-box-holder message-box message-partner"
          >
          <div class="mess-box download-doc">
            <p>{{ message.url }}</p>
            <a [href]="message.url" target="_blank">
              <img src="../../../assets/dwnload-icon.svg" />
            </a>
          </div>
          <div class="message-time">
            <span>{{ message.createdAt | date : "hh:mm a" }}</span>
          </div>
        </div>
      </ng-container> -->
      @if (message.url && isDocument(message.url)) {
        <div class="document-box-holder message-box">
          <div class="document-box download-doc">
            <p>{{ message.url }}</p>
            <a [href]="message.url" target="_blank">
              <img
                src="../../../assets/dwnload-icon.svg"
                alt=""
                />
            </a>
          </div>
          <div class="message-time">
            <span>{{
              message.createdAt | date : "hh:mm a"
            }}</span>
          </div>
        </div>
      }
    </div>
  }
  @if (userId !== message.sender_id) {
    <div
      class="receiver-message-box-holder"
      >
      @if (isDifferentTimestamp(message)) {
        <div class="">
          <span>{{ message.creationTime | date : "hh:mm a" }}</span>
        </div>
      }
      @if (message.message) {
        <div
          class="receiver-box message-partner"
          >
          <!-- <img [src]="linkImagePreview" *ngIf="linkImagePreview" /> -->
          @if (message.message) {
            <div
                        [style.backgroundColor]="
                          isURL(message.message) ? '#fecc28' : '#ffffff'
                        "
              (click)="openUrlInNewWindow(message.message)"
              style="cursor: pointer"
              >
              <span
                          [style.color]="
                            isURL(message.message) ? 'blue' : 'black'
                          "
                          [style.textDecoration]="
                            isURL(message.message) ? 'underline' : 'none'
                          "
                >
                {{ message.message }}
              </span>
            </div>
          }
        </div>
      }
      @if (message.url && isImage(message.url)) {
        <div class="image-box-holder receiver-box">
          <div class="image-box">
            <img
              [src]="message.url"
              class="img-fluid"
              alt="Image"
              />
          </div>
        </div>
      }
      @if (message.url && isVideo(message.url)) {
        <div class="video-box-holder receiver-box">
          <div class="video-box">
            <video
              [src]="message.url"
              class="embed-responsive embed-responsive-16by9"
              controls
            ></video>
          </div>
        </div>
      }
      <!-- <ng-container *ngIf="message.url && isDocument(message.url)">
      <div class="document-box-holder receiver-box">
        <div class="document-box">
          <a [href]="message.url" target="_blank">Download Document</a>
          <div class="message-time">
            <span>{{ message.creationTime | date : "hh:mm a" }}</span>
          </div>
        </div>
      </div>
    </ng-container> -->
    @if (message.url && isDocument(message.url)) {
      <div
        class="document-box-holder message-box message-partner"
        >
        <div class="mess-box download-doc">
          <p>{{ message.url }}</p>
          <a [href]="message.url" target="_blank">
            <img src="../../../assets/dwnload-icon.svg" />
          </a>
        </div>
        <div class="message-time">
          <span>{{
            message.createdAt | date : "hh:mm a"
          }}</span>
        </div>
      </div>
    }
  </div>
}
</div>
}
@if (isLoading2) {
  <div
    class="d-flex justify-content-center flex-column align-items-center w-100"
    >
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <span class="uploading-progress">Uploading...</span>
  </div>
}
</div>
<hr />
<!-- <ul
class="filter-data"
*ngIf="filterDataSearch && filterDataSearchLength > 0"
>
<li
  *ngFor="let suggestion of filterDataSearch"
  (click)="selectSuggestion(suggestion.message)"
  >
  {{ suggestion.message }}
</li>
</ul> -->
<!-- <ul class="filter-data" *ngIf="MetaDataDetails && MedataLength > 0">
<li
  *ngFor="let suggestion of MetaDataDetails?.data; let i = index"
  (click)="selectSuggestion(suggestion.fileName)"
  >
  V - {{ i }} {{ suggestion.fileName }}
</li>
</ul> -->
@if (MetaDataDetails && MedataLength > 0) {
  <ul
    class="filter-data"
    >
    @for (
      suggestion of MetaDataDetails?.data; track
      suggestion; let i = $index) {
      <li
        (click)="selectSuggestion(suggestion.fileName)"
        >
        Main File: Version - {{ i + 1 }} {{ suggestion.fileName }}
        @if (suggestion.thread && suggestion.thread.length > 0) {
          <ul
            class="sub-file"
            >
            @for (
              threadItem of suggestion.thread; track
              threadItem; let j = $index) {
              <li
                (click)="selectSuggestion(threadItem.fileName)"
                >
                Version - {{ i + 1 }}.{{ j + 1 }}
                {{ threadItem.fileName }}
              </li>
            }
          </ul>
        }
      </li>
    }
  </ul>
}
<div class="chat-input-holder">
  <textarea
    type="text"
    class="chat-input"
    [(ngModel)]="newMessage2"
    placeholder="Type a message"
    (keydown.enter)="sendMessage2()"
    (keydown)="handelKeyDown2($event)"
  ></textarea>
  <div class="position-relative mx-3">
    <input
      type="file"
      (change)="handleFileInput2($event)"
      (keydown.enter)="sendMessage2()"
      /><img
      class="img-fluid img-icon"
      src="assets/upload-pic.png"
      alt=""
      />
  </div>
  <div class="cursorPointer" (click)="sendMessage2()">
    <img
      src="../../../assets/sendMessage.png"
      class="message-send message-send1 m-auto d-block"
      />
  </div>
</div>
</div>
}
</div>
</div>
}
</div>
</div>
</div>
}
</div>
