import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertsService } from 'src/app/service/alerts.service';

declare var Toasty : any;
@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.css'],
    standalone: true
})
export class AlertsComponent implements OnInit {

  subscription: Subscription;
  options = {
    autoClose: true,
    progressBar: true,
    enableSounds: true,
    sounds: {
          
    info: "https://res.cloudinary.com/dxfq3iotg/video/upload/v1557233294/info.mp3",
    // path to sound for successfull message:
    success: "../../../assets/message_tone.mp3",
    // path to sound for warn message:
    warning: "https://res.cloudinary.com/dxfq3iotg/video/upload/v1557233563/warning.mp3",
    // path to sound for error message:
    error: "https://res.cloudinary.com/dxfq3iotg/video/upload/v1557233574/error.mp3",
    },
    };
    
     toast = new Toasty(this.options);
     
     constructor(private alertsService:AlertsService) {
      this.subscription=this.alertsService.onAlert().subscribe(alert=>{
        if(alert){
          // this.toast.success(alert.alert);
          this.toast.success(alert.alert);
        }
      })
     }
    
  ngOnInit(): void {
    this.toast.configure(this.options);
    
    // document.getElementById("successtoast").addEventListener("click",()=>{
    //   this.toast.success("loerm50");
    
    // })
    // document.getElementById("infotoast").addEventListener("click",()=>{
    //   this.toast.info("this is a text with a bit larger length as compared to other notifications");
    // })
    // document.getElementById("warningtoast").addEventListener("click",()=>{
    //   this.toast.warning("This toast notification with sound");
    // })
    // document.getElementById("errortoast").addEventListener("click",()=>{
    //   this.toast.error("This toast notification with sound");
    // })

  }

}
