import { Injectable, HostListener } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

var url = environment.host;
@Injectable({
  providedIn: "root",
})
export class ChatService {
  userId: String;
  db: any;
  user_id = localStorage.getItem("guestID");
  chatWrapper: boolean = false;
  grpChatWrapper: boolean = false;
  mouseEvents: Subscription;
  timer: Subscription;
  main_link: any;
  preview: any;
  time: any = 0;
  globalChatRef: any;
  meetChatRef: any;
  netConnected: boolean;
  isNetworkStopped = false;
  myProfileImage = "";
  usersInCallRef: any;
  onlineUserList = [];
  InCallUserList = [];

  constructor(private http: HttpClient, private router: Router) {}

  detachInCallUsersListener() {
    console.log("inside detachInCallUsersListener() --> listener removed");
    this.usersInCallRef();
  }

  getUserStatus(user_id) {
    this.db
      .ref(`profile/${user_id}`)
      .once("value")
      .then((userDetailsAsObject) => {
        let userDetails = userDetailsAsObject.val()
          ? userDetailsAsObject.val()
          : {};
        console.log(userDetails);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  @HostListener("document:mousemove")
  @HostListener("document:keypress")
  @HostListener("document:click")
  @HostListener("document:wheel")
  getChats() {
    return this.http.get(environment.host + "chats");
  }

  getUsers() {
    return this.http.get(environment.host + "users");
  }
  // }
}
