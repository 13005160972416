<!-- <div class="container">
  <div class="row justify-content-center">
      <div class="col-lg-5 col-sm-12 col-md-5 card-container " (click)="onClickCollections()">
        <div class="card-text pl-3"> Collections </div>
        <img src="../../../../assets/Group 213.png" class="img-fluid" alt="">
      </div>
      <div class="col-lg-5 col-sm-12 col-md-5 card-container " (click)="onClickUploadFile() ">
        <div class="card-text pl-3 ">Archive a Project </div>
        <img src="../../../../assets/Group 212.png" class="img-fluid" alt="">
      </div>
      <div class="col-lg-5 col-sm-12 col-md-5 card-container disabled-div" (click)="onClickNotifications()">
        <div class="card-text pl-3"> Call Logs </div>
        <img src="../../../../assets/Group 211.png" class="img-fluid" alt="">
      </div> -->

<!-- <div class="col-lg-5 col-sm-12 col-md-5 card-container"
        (click)="onClickClients()">
        <div class="card-text"> Clients </div>
        <img src="../../../../assets/Group 210.png" class="img-fluid" alt="">
      </div> -->

<!-- <div class="col-lg-5 col-sm-12 col-md-5 card-container disabled-div" (click)="onClickCreateProject()">
        <div class="card-text pl-3">Create a live Project </div>
        <img src="../../../../assets/Group 212.png" class="img-fluid" alt="">
      </div> -->

<!-- <div class="col-lg-5 col-sm-12 col-md-5 card-container" data-toggle="modal" data-target="#meetingModal"> -->

<!-- <div class="col-lg-5 col-sm-12 col-md-5 card-container disabled-div" (click)="openMeeting(true)">
        <div class="card-text pl-3"> Instant Meeting </div>
        <img src="../../../../assets/Group 210.png" class="img-fluid" alt="">
      </div> -->

<!-- <div class="col-lg-5 col-sm-12 col-md-5 card-container disabled-div" (click)="openMeeting(false)">
        <div class="card-text pl-3"> Schedule Meeting </div>
        <img src="../../../../assets/Group 210.png" class="img-fluid" alt="">
      </div>
    </div>
  </div> -->

<app-header [activeLink]="0"></app-header>
<div class="banner">
  <div class="banner_content">
    <div class="banner_content_menu">
      <div class="banner_items" (click)="onClickCollections()">
        <p>Collections</p>
        <img src="../../../../assets/Group 213.png" class="img-fluid" alt="" />
      </div>
      <div class="banner_items" (click)="onClickUploadFile()">
        <p>Archive a Project</p>
        <img src="../../../../assets/Group 212.png" class="img-fluid" alt="" />
      </div>
    </div>
    <!-- <div class="banner_content_menu">
      <div class="banner_items disabled-div">
        <p>Call Logs</p>
        <img src="../../../../assets/Group 211.png" class="img-fluid" alt="" />
      </div> -->

    <div class="banner_content_menu">
      <div class="banner_items" routerLink="/crm/leads" [ngClass]="isAdminSales ? '' : 'pointer-event-none'">
        <p>CRM</p>
        <img src="../../../../assets/Group 210.png" class="img-fluid" alt="" />
      </div>
      <!-- [ngClass]="canAccessLeadTrack ? '' : 'pointer-event-none'" -->
      <!-- [routerLink]="canAccessLeadTrack ? '/lead-track' : ''" -->
      <div class="banner_items" routerLink="/lead-track">
        <p>Lead Tracking</p>
        <img src="../../../../assets/Group 210.png" class="img-fluid" alt="" />
      </div>
    </div>
    <div class="banner_content_menu">
      <div class="banner_items" routerLink="/inventory/dashboard">
        <p>Inventory</p>
        <img src="../../../../assets/Group 210.png" class="img-fluid" alt="" />
      </div>
      <div class="banner_items disabled-div" (click)="openMeeting(false)">
        <p>Schedule Meeting</p>
        <img src="../../../../assets/Group 210.png" class="img-fluid" alt="" />
      </div>
    </div>
  </div>
</div>

<!-- <div>Current Count: {{ count$ | async }}</div>
<button (click)="increment()">Increment</button>
<button (click)="decrement()">Decrement</button>

<button (click)="reset()">Reset Counter</button> -->

<!-- Instant Meeting Modal -->
<!-- <div style="display: none;" class="modal fade" id="instantMeetingModal" data-backdrop="static" tabindex="-1"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Start an instant meeting</h5>
        <button type="button" class="close" (click)="closeInstantMeeting()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-around autocomplete col-lg-12 flex-wrap">
          <div class="col-md-12 col-lg-6">
            <input style="width: 100%;" class="meet-title-input" id="meet-title" type="text" placeholder="Meeting Title">
          </div>
          <div class="col-md-12 col-lg-6">
            <div class="ng-autocomplete" style="width: 100%;">
              <ng-autocomplete [data]="searchData" [searchKeyword]="keyword" (selected)='selectEvent($event)'
                (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate" placeholder="Search participants">
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
        </div>

        <hr style="width:95%; color:grey; margin-bottom: 0.5rem;">





        <div class="row">
          <div class="col-md-3">
            <p style="margin-top: 1rem;"><strong>3D Department</strong></p>
            <div style="max-height: 200px;overflow: auto;">
              <div class="list-group" *ngFor="let user of threeD">
                <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                <label for="{{user.username}}"
                  [ngClass]="[checkIfUserInArray(user.username) ? 'list-group-item already-on-call' : 'list-group-item']">{{user.username}}</label>
                <!-- [ngClass]="{already-on-call':chatService.InCallUserList.includes()user.username" 
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem;"><strong>2D Department</strong></p>
            <div style="max-height: 200px;overflow: auto;">
              <div class="list-group" *ngFor="let user of twoD">
                <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                <label for="{{user.username}}"
                  [ngClass]="[checkIfUserInArray(user.username) ? 'list-group-item already-on-call' : 'list-group-item']">{{user.username}}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem;"><strong>Sales & Marketing Department</strong></p>
            <div style="max-height: 200px;overflow: auto;">
              <div class="list-group" *ngFor="let user of sales">
                <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                <label for="{{user.username}}"
                  [ngClass]="[checkIfUserInArray(user.username) ? 'list-group-item already-on-call' : 'list-group-item']">{{user.username}}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem;"><strong>Account Department</strong></p>
            <div style="max-height: 200px;overflow: auto;">
              <div class="list-group" *ngFor="let user of account">
                <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                <label for="{{user.username}}"
                  [ngClass]="[checkIfUserInArray(user.username) ? 'list-group-item already-on-call' : 'list-group-item']">{{user.username}}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem;"><strong>Human Resource Department</strong></p>
            <div style="max-height: 200px;overflow: auto;">
              <div class="list-group" *ngFor="let user of hr">
                <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                <label for="{{user.username}}"
                  [ngClass]="[checkIfUserInArray(user.username) ? 'list-group-item already-on-call' : 'list-group-item']">{{user.username}}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem;"><strong>IT Department</strong></p>
            <div style="max-height: 200px;overflow: auto;">
              <div class="list-group" *ngFor="let user of it">
                <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                <label for="{{user.username}}"
                  [ngClass]="[checkIfUserInArray(user.username) ? 'list-group-item already-on-call' : 'list-group-item']">{{user.username}}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem;"><strong>Admin Department</strong></p>
            <div style="max-height: 200px;overflow: auto;">
              <div class="list-group" *ngFor="let user of admin_dept">
                <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                <label for="{{user.username}}"
                  [ngClass]="[checkIfUserInArray(user.username) ? 'list-group-item already-on-call' : 'list-group-item']">{{user.username}}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem;"><strong>Operation Department</strong></p>
            <div style="max-height: 200px;overflow: auto;">
              <div class="list-group" *ngFor="let user of ops">
                <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                <label for="{{user.username}}"
                  [ngClass]="[checkIfUserInArray(user.username) ? 'list-group-item already-on-call' : 'list-group-item']">{{user.username}}</label>
              </div>
            </div>
          </div>

        </div>

      </div>


      <div class="modal-footer">
        <!-- <div class="d-flex">
              <div *ngFor="let user of addCallers" style="display: inline-flex; margin:auto 0.25rem"> {{user}}</div>
            </div>  
        <p id="warning-meet-input" class="hide" style="color: red;">Please fill meeting title</p>
        <p id="warning-meet-user" class="hide" style="color: red;">Please add users</p>
        <button type="button" class="btn btn-secondary" (click)="closeInstantMeeting()">Close</button>
        <button type="button" (click)="onClickStartInstantMeeting()" class="btn start-meeting-button">Start</button>
      </div>
    </div>
  </div>
</div> -->

<!-- Scheduled Meeting Modal -->
<!-- <div style="display: none;" class="modal fade" id="scheduledMeetingModal" data-backdrop="static" tabindex="-1"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Schedule a meeting</h5>
        <button type="button" class="close" (click)="closeScheduledMeeting()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="d-flex justify-content-around autocomplete col-lg-12 flex-wrap">
            <div class="col-md-12 col-lg-3">
              <input style="width: 100%;" class="meet-title-input" id="schedule-meet-title" type="text" placeholder="Meeting Title">
            </div>
            <div class="col-md-12 col-lg-3">
              <div class="ng-autocomplete" style="width: 100%;">
                <ng-autocomplete [data]="searchData" [searchKeyword]="keyword" (selected)='selectEvent($event)'
                  (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                  [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" placeholder="Search Participants">
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
            </div>
            <div class="col-md-12 col-lg-3">
              <input class="meet-title-input" name="meetingStart" [(ngModel)]="meetingStart" [dpDayPicker]="startMeetingConfig"
                theme="dp-material"  mode="daytime" placeholder="Start Time" />
              
            </div>
            <div class="col-md-12 col-lg-3">
              <input class="meet-title-input" name="meetingEnd" [(ngModel)]="meetingEnd" [dpDayPicker]="endMeetingConfig" theme="dp-material"
                 mode="daytime"  placeholder="End Time" />
            </div>
          </div>
        </div>


        <hr style="width:95%; color:grey; margin-bottom: 0.5rem;">





        <div class="row">
          <div class="col-md-3">
            <p style="margin-top: 1rem;"><strong>3D Department</strong></p>
            <div style="max-height: 200px;overflow: auto;">
              <div class="list-group" *ngFor="let user of threeD">
                <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                <label for="{{user.username}}" class= 'list-group-item'>{{user.username}}</label>

              </div>
            </div>
          </div>
            <div class="col-md-3">
              <p style="margin-top: 1rem;"><strong>2D Department</strong></p>
              <div style="max-height: 200px;overflow: auto;">
                <div class="list-group" *ngFor="let user of twoD">
                  <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                  <label for="{{user.username}}"
                  class= 'list-group-item'>{{user.username}}</label>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <p style="margin-top: 1rem;"><strong>Sales & Marketing Department</strong></p>
              <div style="max-height: 200px;overflow: auto;">
                <div class="list-group" *ngFor="let user of sales">
                  <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                  <label for="{{user.username}}"
                  class= 'list-group-item'>{{user.username}}</label>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <p style="margin-top: 1rem;"><strong>Account Department</strong></p>
              <div style="max-height: 200px;overflow: auto;">
                <div class="list-group" *ngFor="let user of account">
                  <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                  <label for="{{user.username}}"
                  class= 'list-group-item'>{{user.username}}</label>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <p style="margin-top: 1rem;"><strong>Human Resource Department</strong></p>
              <div style="max-height: 200px;overflow: auto;">
                <div class="list-group" *ngFor="let user of hr">
                  <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                  <label for="{{user.username}}"
                  class= 'list-group-item'>{{user.username}}</label>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <p style="margin-top: 1rem;"><strong>IT Department</strong></p>
              <div style="max-height: 200px;overflow: auto;">
                <div class="list-group" *ngFor="let user of it">
                  <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                  <label for="{{user.username}}"
                  class= 'list-group-item'>{{user.username}}</label>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <p style="margin-top: 1rem;"><strong>Admin Department</strong></p>
              <div style="max-height: 200px;overflow: auto;">
                <div class="list-group" *ngFor="let user of admin_dept">
                  <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                  <label for="{{user.username}}"
                  class= 'list-group-item'>{{user.username}}</label>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <p style="margin-top: 1rem;"><strong>Operation Department</strong></p>
              <div style="max-height: 200px;overflow: auto;">
                <div class="list-group" *ngFor="let user of ops">
                  <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                  <label for="{{user.username}}"
                    class= 'list-group-item'>{{user.username}}</label>
                </div>
              </div>
            </div>

          </div>
        </div>


        <div class="modal-footer">
          <!-- <div class="d-flex">
                <div *ngFor="let user of addCallers" style="display: inline-flex; margin:auto 0.25rem"> {{user}}</div>
              </div>  
          <p id="warning-meet-input" class="hide" style="color: red;">Please fill meeting title</p>
          <p id="warning-meet-user" class="hide" style="color: red;">Please add users</p>
          <button type="button" class="btn btn-secondary" (click)="closeScheduledMeeting()">Close</button>
          <button type="button" (click)="onClickStartSchduledMeeting()" class="btn start-meeting-button">Start</button>
        </div>
      </div>
    </div>
  </div> -->

<div style="display: none" class="modal fade" id="meetingModal" data-backdrop="static" tabindex="-1"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="meetingTypeInstant" class="modal-title" id="exampleModalLabel">
          Start an instant meeting
        </h5>
        <h5 *ngIf="!meetingTypeInstant" class="modal-title" id="exampleModalLabel">
          Start a scheduled meeting
        </h5>
        <button type="button" class="close" (click)="closeMeeting()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="meetingTypeInstant" class="d-flex justify-content-around autocomplete col-lg-12 flex-wrap">
          <div class="col-md-12 col-lg-6">
            <input style="width: 100%" class="meet-title-input" id="meet-title" type="text"
              placeholder="Meeting Title" />
          </div>
          <div class="col-md-12 col-lg-6">
            <div class="ng-autocomplete" style="width: 100%">
              <ng-autocomplete [data]="searchData" [searchKeyword]="keyword" (selected)="selectEvent($event)"
                (inputChanged)="onChangeSearch($event)" (inputFocused)="onFocused($event)" [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate" placeholder="Search participants">
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
        </div>

        <div *ngIf="!meetingTypeInstant" class="d-flex justify-content-around autocomplete col-lg-12 flex-wrap">
          <div class="col-md-12 col-lg-3">
            <input style="width: 100%" class="meet-title-input" id="meet-title" type="text"
              placeholder="Meeting Title" />
          </div>
          <div class="col-md-12 col-lg-3">
            <div class="ng-autocomplete" style="width: 100%">
              <ng-autocomplete [data]="searchData" [searchKeyword]="keyword" (selected)="selectEvent($event)"
                (inputChanged)="onChangeSearch($event)" (inputFocused)="onFocused($event)" [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate" placeholder="Search Participants">
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
          <div class="col-md-12 col-lg-3">
            <!-- <input class="meet-title-input" name="meetingStart" id="meetingStart" [(ngModel)]="meetingStart" [dpDayPicker]="startMeetingConfig" theme="dp-material" mode="daytime" placeholder="Start Time" (onChange)="setEndMin($event)"/> -->
            <!-- <input
              class="meet-title-input"
              name="meetingStart"
              id="meetingStart"
              [(ngModel)]="meetingStart"
              [dpDayPicker]="startMeetingConfig"
              theme="dp-material"
              mode="daytime"
              placeholder="Start Time"
            /> -->
          </div>

          <div class="col-md-12 col-lg-3">
            <select class="form-control meet-title-input" id="time-duration">
              <option value="15">15 mins</option>
              <option value="30">30 mins</option>
              <option value="45">45 mins</option>
              <option value="60">1 hr</option>
              <option value="90">1.5 hrs</option>
              <option value="120">2 hrs</option>
            </select>
          </div>

          <!-- <div class="col-md-12 col-lg-3">
            <input class="meet-title-input" name="meetingEnd" [(ngModel)]="meetingEnd" [dpDayPicker]="endMeetingConfig"
              theme="dp-material" mode="daytime" placeholder="End Time" />
          </div> -->
        </div>

        <hr style="width: 95%; color: grey; margin-bottom: 0.5rem" />

        <div class="row">
          <div class="col-md-3">
            <p style="margin-top: 1rem"><strong>3D Department</strong></p>
            <div style="max-height: 200px; overflow: auto">
              <div class="list-group" *ngFor="let user of threeD">
                <input type="checkbox" name="CheckBoxInputName" value="{{ user.username }}" id="{{ user.username }}"
                  (change)="selectCaller($event)" />
                <label for="{{ user.username }}" [ngClass]="[
                    checkIfUserInArray(user.username)
                      ? 'list-group-item already-on-call'
                      : 'list-group-item'
                  ]">{{ user.username }}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem"><strong>2D Department</strong></p>
            <div style="max-height: 200px; overflow: auto">
              <div class="list-group" *ngFor="let user of twoD">
                <input type="checkbox" name="CheckBoxInputName" value="{{ user.username }}" id="{{ user.username }}"
                  (change)="selectCaller($event)" />
                <label for="{{ user.username }}" [ngClass]="[
                    checkIfUserInArray(user.username)
                      ? 'list-group-item already-on-call'
                      : 'list-group-item'
                  ]">{{ user.username }}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem">
              <strong>Sales & Marketing Department</strong>
            </p>
            <div style="max-height: 200px; overflow: auto">
              <div class="list-group" *ngFor="let user of sales">
                <input type="checkbox" name="CheckBoxInputName" value="{{ user.username }}" id="{{ user.username }}"
                  (change)="selectCaller($event)" />
                <label for="{{ user.username }}" [ngClass]="[
                    checkIfUserInArray(user.username)
                      ? 'list-group-item already-on-call'
                      : 'list-group-item'
                  ]">{{ user.username }}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem"><strong>Account Department</strong></p>
            <div style="max-height: 200px; overflow: auto">
              <div class="list-group" *ngFor="let user of account">
                <input type="checkbox" name="CheckBoxInputName" value="{{ user.username }}" id="{{ user.username }}"
                  (change)="selectCaller($event)" />
                <label for="{{ user.username }}" [ngClass]="[
                    checkIfUserInArray(user.username)
                      ? 'list-group-item already-on-call'
                      : 'list-group-item'
                  ]">{{ user.username }}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem">
              <strong>Human Resource Department</strong>
            </p>
            <div style="max-height: 200px; overflow: auto">
              <div class="list-group" *ngFor="let user of hr">
                <input type="checkbox" name="CheckBoxInputName" value="{{ user.username }}" id="{{ user.username }}"
                  (change)="selectCaller($event)" />
                <label for="{{ user.username }}" [ngClass]="[
                    checkIfUserInArray(user.username)
                      ? 'list-group-item already-on-call'
                      : 'list-group-item'
                  ]">{{ user.username }}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem"><strong>IT Department</strong></p>
            <div style="max-height: 200px; overflow: auto">
              <div class="list-group" *ngFor="let user of it">
                <input type="checkbox" name="CheckBoxInputName" value="{{ user.username }}" id="{{ user.username }}"
                  (change)="selectCaller($event)" />
                <label for="{{ user.username }}" [ngClass]="[
                    checkIfUserInArray(user.username)
                      ? 'list-group-item already-on-call'
                      : 'list-group-item'
                  ]">{{ user.username }}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem"><strong>Admin Department</strong></p>
            <div style="max-height: 200px; overflow: auto">
              <div class="list-group" *ngFor="let user of admin_dept">
                <input type="checkbox" name="CheckBoxInputName" value="{{ user.username }}" id="{{ user.username }}"
                  (change)="selectCaller($event)" />
                <label for="{{ user.username }}" [ngClass]="[
                    checkIfUserInArray(user.username)
                      ? 'list-group-item already-on-call'
                      : 'list-group-item'
                  ]">{{ user.username }}</label>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <p style="margin-top: 1rem">
              <strong>Operation Department</strong>
            </p>
            <div style="max-height: 200px; overflow: auto">
              <div class="list-group" *ngFor="let user of ops">
                <input type="checkbox" name="CheckBoxInputName" value="{{ user.username }}" id="{{ user.username }}"
                  (change)="selectCaller($event)" />
                <label for="{{ user.username }}" [ngClass]="[
                    checkIfUserInArray(user.username)
                      ? 'list-group-item already-on-call'
                      : 'list-group-item'
                  ]">{{ user.username }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="d-flex col-md-12 col-lg-7 flex-wrap">
          <p *ngIf="addCallers.length" style="display: inline-flex; margin: auto 0.25rem">
            <strong>Participants</strong>
          </p>
          <div *ngFor="let user of addCallers" style="display: inline-flex; margin: auto 0.25rem">
            {{ user }}
          </div>
          <p id="warning-meet-input" class="hide" style="color: red">
            Please fill meeting title
          </p>
          <p id="warning-meet-user" class="hide" style="color: red">
            Please add users
          </p>
        </div>

        <button type="button" class="btn btn-secondary" (click)="closeMeeting()">
          Close
        </button>
        <button *ngIf="meetingTypeInstant" type="button" (click)="onClickStartInstantMeeting()"
          class="btn start-meeting-button">
          Start
        </button>
        <button *ngIf="!meetingTypeInstant" type="button" (click)="onClickStartScheduleMeeting()"
          class="btn start-meeting-button">
          Schedule
        </button>
      </div>
    </div>
  </div>
</div>