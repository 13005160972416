<div class="white-background">
  <div class="container-fluid">
    <!-- <div
    class="overlay"
    [class.show-overlay]="menuOpened"
    (click)="clickMenu()"
  ></div> -->
    <div
      class="row d-flex hide"
      style="padding: 1% 0% 1% 0%"
      id="headerMenu"
      [ngClass]="{ 'disable-content': menuOpened }"
    >
      <a
        routerLink="/home"
        href="javascript:void(0)"
        class="navbar-brand my-auto"
        style="
          width: 9%;
          padding-right: 0;
          margin-right: 0%;
          padding-left: 0.9%;
        "
      >
        <img
          src="../../../assets/Group 185-copy.png"
          class="img-fluid Header-company-logo"
          alt="Header-company-logo"
        />
      </a>

      <div
        class="col-sm-12 col-md-12 col-lg-2 align-content-center justify-content-center removeLine"
        [ngClass]="!edit ? '' : 'mouse-event-none'"
      >
        <p class="header-para">Collection Type</p>
        <div
          [ngClass]="!edit ? '' : 'mouse-event-none'"
          class="d-flex"
          style="
            width: fit-content;
            margin: auto;
            padding-top: 10px;
            align-items: center;
          "
        >
          <span
            [ngClass]="{
              selection: selectedCollectionType === 'Private',
              'text-black': selectedCollectionType === 'Public'
            }"
            class="pr-2 selection selectiontag"
            >Public</span
          >
          <div class="onoffswitch" [ngClass]="!edit ? '' : 'mouse-event-none'">
            <input
              type="checkbox"
              name="onoffswitch"
              class="onoffswitch-checkbox"
              id="private-public"
              (change)="onChangeCollectionType($event)"
            />
            <label class="onoffswitch-label" for="private-public">
              <span class="onoffswitch-inner collection-content"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
          <span
            [ngClass]="{
              selection: selectedCollectionType === 'Public',
              'text-black': selectedCollectionType === 'Private'
            }"
            class="pl-2 selection selectiontag1"
            >Private</span
          >
        </div>
      </div>

      <div
        [ngClass]="!edit ? '' : 'mouse-event-none'"
        class="col-sm-12 col-md-12 col-lg-2 align-content-center justify-content-center removeLine p-0"
      >
        <p class="header-para">Company</p>
        <div class="d-flex flex-column" style="font-weight: 500">
          <div class="list-group d-flex flex-row" id="list-tab" role="tablist">
            <a
              class="list-group-item list-group-item-action active mr-1"
              id="list-home-list"
              data-toggle="list"
              href="#list-home"
              role="tab"
              aria-controls="home"
              (click)="onChangeCompanyTag('square pixel')"
            >
              <span class="companyType pl-1"> Square pixel </span>
            </a>
            <a
              class="listing list-group-item list-group-item-action mr-1"
              id="list-profile-list"
              data-toggle="list"
              href="#list-profile"
              role="tab"
              aria-controls="profile"
              (click)="onChangeCompanyTag('ink in caps')"
            >
              <span class="companyType pl-2">Ink in caps</span>
            </a>
          </div>
        </div>
      </div>

      <div
        [ngClass]="!edit ? '' : 'mouse-event-none'"
        class="col-sm-12 col-md-12 col-lg-2 align-content-center justify-content-center removeLine p0"
      >
        <p class="header-para">Type</p>
        <div class="dropdown" style="margin-top: 0px">
          <button
            class="btn dropdown-toggle dropButton col-type"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-expanded="false"
            style="padding: 0rem 0.75rem"
          >
            <span class="text-capitalize centerType drag-file-type">{{
              selectedTags
            }}</span>
            <i class="fa fa-caret-down centerType"></i>
          </button>
          <ul class="dropdown-menu dis" aria-labelledby="dropdownMenuButton">
            @for (tag of companyTagsArray[1].companyTags; track tag) {
            <li
              class="dropdown-item cursorPointer text-capitalize"
              (click)="addCompanyRelatedTags(tag)"
            >
              {{ tag }}
            </li>
            }
          </ul>
        </div>
      </div>
      <div
        [ngClass]="!edit ? '' : 'mouse-event-none'"
        class="col-sm-12 col-md-12 col-lg-2 align-content-center justify-content-center removeLine p-0"
      >
        <p class="header-para">Project Type</p>
        <div
          class="d-flex"
          style="width: fit-content; margin: auto; padding-top: 10px"
        >
          <span
            [ngClass]="{
              selection: selectedProjectType === 'Reference',
              'text-black': selectedProjectType === 'Company'
            }"
            class="pr-2 selection selectiontag"
            >Company</span
          >
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              class="onoffswitch-checkbox"
              id="projectType"
              (change)="onChangeCompanyProductType($event)"
            />
            <label class="onoffswitch-label" for="projectType">
              <span class="onoffswitch-inner project-type-content"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
          <span
            [ngClass]="{
              selection: selectedProjectType === 'Company',
              'text-black ': selectedProjectType === 'Reference'
            }"
            class="pl-2 selection selectiontag1"
            >Reference</span
          >
        </div>
      </div>

      <div
        [ngClass]="!edit ? '' : 'mouse-event-none'"
        class="col-sm-12 col-md-12 col-lg-2 align-content-center justify-content-center removeLine p-0"
      >
        <p class="header-para">Agreement</p>
        <div
          class="d-flex"
          style="
            width: fit-content;
            margin: auto;
            padding-top: 10px;
            align-items: center;
          "
        >
          <span
            [ngClass]="{
              selection: selectedAgreementType === 'Non-NDA',
              'text-red': selectedAgreementType === 'NDA'
            }"
            class="pr-2 selection"
            >NDA</span
          >
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              class="onoffswitch-checkbox"
              id="nda"
              (change)="onChangeNdaType($event)"
            />
            <label class="onoffswitch-label" for="nda">
              <span class="onoffswitch-inner nda-content"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
          <span
            [ngClass]="{
              selection: selectedAgreementType === 'NDA',
              'text-red': selectedAgreementType === 'Non-NDA'
            }"
            class="pl-2 selection selectiontag1"
            >Non-NDA</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
<div class="header-line"></div>
@if (!edit) {
<div
  class="container-fluid center"
  [ngClass]="{ 'disable-content': menuOpened }"
>
  <!-- [ngClass]="{ 'disable-content d-none': menuOpened, 'd-block' :!edit}" -->
  <div class="row justify-content-around">
    <div class="col-xl-4 upload-area">
      <div class="upload">
        <img
          id="preview-image"
          #previewImage
          class="imagePreview"
          width="100%"
        />
        @if (pdfUrl) {
        <div class="doc-wrapper"></div>
        }
        <iframe
          id="pdf"
          #pdf
          [src]="pdfUrl"
          viewer="google"
          height="560px"
          width="700px"
          style="width: 700px; height: 262px"
          [style.display]="pdfDisplay"
        >
        </iframe>
        <div class="position-absolute divCenter">
          <p
            class="text-capitalize fileUploaded1"
            id="upload-name"
            #uploadname
            [style.display]="pdfDis"
          ></p>
          <p
            class="text-capitalize fileUploaded"
            id="upload-file"
            #uploadfile
            [style.display]="pdfDis"
          ></p>
          <img
            class="img-thumbnail prev-img"
            id="prev-img"
            #previmg
            (click)="openWindow(data)"
            [style.display]="pdfDis"
          />
        </div>
        <div>
          <video
            id="preview-video"
            #previewvideo
            style="
              width: 100%;
              height: 88%;
              object-fit: contain;
              background-color: black;
            "
            controls
            [src]="videoUrl"
            type="video/mp4"
          ></video>
        </div>
        <embed
          id="pdf"
          #pdf
          type="application/pdf"
          height="250px"
          width="500"
          [style.display]="pdfDisplay"
        />
        <video id="vid2" class="hide" height="200px" controls></video>
        <canvas id="pic-canvas" class="hide"></canvas>
        <img
          id="pic-photo"
          class="hide takePhoto"
          alt="The screen capture will appear in this box."
        />
        <p class="hide" id="recorder-control">
          <button id="btnStart" class="upload-button">START RECORDING</button>
          <button id="btnStop" class="upload-button hide">
            STOP RECORDING
          </button>
          <button
            id="btnKeep"
            (click)="keepRecording()"
            class="upload-button hide"
          >
            SAVE RECORDING
          </button>
        </p>
        @if (canvasDisplayImage) {
        <button
          id="startbutton"
          class="hide takePhoto btn btn-success"
          (click)="takePhoto()"
          style="margin-top: 5px"
        >
          Take photo
        </button>
        } @if (uploadSelection && !startPhoto) {
        <div
          class="file-upload d-flex flex-column justify-content-center"
          (drop)="onFileDrop($event)"
          (dragover)="onDragOver($event)"
          id="file-uploadCamera"
          #fileUploadCamera
        >
          <img
            src="../../../assets/upload_icon.svg"
            alt="upload-icon"
            id="upload-icon"
          />
          <p class="font-weight-bolder">
            Drag & drop files or <span class="browse">Browse</span>
          </p>
          <p class="supported">
            Supported formats: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT
          </p>
          <div class="upload-control d-flex justify-content-center">
            <button
              class="upload-button hide"
              (click)="onClickCamera()"
              id="camera-button"
              #cameraButton
            >
              Camera
            </button>
            <button
              class="upload-button hide"
              (click)="onClickScreenCapture()"
              id="screen-capture-button"
              #screenCaptureButton
            >
              Screen Capture
            </button>
            <button class="upload-button" (click)="onClickUpload()">
              Click to Upload
            </button>
          </div>
        </div>
        }
        <input type="file" class="hide" id="upload-input" />
        @if (barWidth == '100%') {
        <div
          class="lds-facebook mt-25"
          style="
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 68%;
          "
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        } @if (barWidth == '100%' || messageError) {
        <div
          style="
            position: absolute;
            transform: translate(-50%, -70%);
            top: 70%;
            left: 50%;
          "
        >
          <button class="btn btn-outline mt-1 pleaseWait">
            <span>Please wait</span>
            <span class="AnimatedEllipsis"></span>
          </button>
          <p>{{ messageError }}</p>
        </div>
        } @if (barWidth != '100%') {
        <div id="my-loader" #loadingBar class="hide">
          <div class="progress" style="width: 200px">
            <div class="bar" id="progressBar" #progressBar></div>
          </div>
          <br />
          <div class="load">
            <span id="prog" #prog class="text-center" style="margin-top: -20px"
              >{{ this.progress }}%</span
            >
          </div>
        </div>
        }
      </div>
      <span class="image-upload">
        <div class="add-multiple-files" [class.disabled]="uploadInProgress">
          <p class="addFiles">Additional Files</p>
          <label id="add-img" title="upload additional file">
            <div>
              <img
                src="../../../assets/icons 6/multiple_icon.svg"
                width="100%"
                height="100%"
                style="cursor: pointer"
              />
              <input
                type="file"
                id="image-input"
                (change)="onClickAddImage($event)"
                multiple
                [disabled]="uploadInProgress"
              />
              <p id="ImageId" class="d-none"></p>
            </div>
          </label>
          <!-- <div class="additionalFiles"></div> -->
          <svg
            class="additionalFiles"
            xmlns="http://www.w3.org/2000/svg"
            width="3"
            height="33"
            viewBox="0 0 3 39"
            fill="none"
          >
            <path
              opacity="0.15"
              d="M1.86816 0.52832L1.86817 38.8975"
              stroke="black"
              stroke-width="2"
            />
          </svg>
        </div>
        <p class="remove disabled">Remove</p>
        <div class="" id="deleteUpload" title="delete uploaded file">
          <img
            src="../../../assets/icons 6/delete_icon.svg"
            width="83%"
            height="100%"
            class=" "
            (click)="deleteMedia(id)"
          />
        </div>
      </span>
      <!-- *ngIf="filesUrl.length > 0" -->
      <!-- *ngIf="thumbnailSpinner || filesUrl.length > 0" -->
      @if (showThumbnail) {
      <div class="img-thumbnailsList">
        <p class="text-black ml-2">Choose a thumbnail</p>
        <div class="">
          <div class="">
            <div class="img-show mb-5 pb-3">
              <div class="sliderFixed">
                <!-- <ngx-slick-carousel class="carousel pt-2 pb-2" #slickModal="slick-carousel" [config]="slideConfig"
                  (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                  (beforeChange)="beforeChange($event)">
                  @for (file of filesUrl; track file; let i = $index) {
                  <div ngxSlickItem class="slide">
                    <div class="thumbnail-container" (mousedown)="onClickImage(file, i)">
                      <img [src]="file" class="img-row-show thumbnail img-thumbnail mr-2" width="100%" height="100%" />
                      <img class="thumbnailImg" [class.selected]="i === selectedImageIndex"
                        src="../../../assets/select_tick_icon.svg" width="25px" height="25px" />
                    </div>
                  </div>
                  }
                </ngx-slick-carousel> -->

                <div #swiperContainer class="swiper-container">
                  <div class="swiper-wrapper">
                    @for (file of filesUrl; track file; let i = $index) {
                    <div class="swiper-slide">
                      <div class="img-wrapper position-relative">
                        <img
                          [src]="file"
                          class="img-row-show thumbnail img-thumbnail mr-2"
                          width="100%"
                          height="100%"
                          (click)="onClickImage(file, i)"
                        />
                        <img
                          class="thumbnailImg position-absolute"
                          [class.selected]="i === selectedImageIndex"
                          src="../../../assets/Group 26.png"
                          width="25px"
                          height="25px"
                        />
                      </div>
                    </div>
                    }
                  </div>

                  <div
                    [ngClass]="{ 'visibility-hidden': !filesUrl?.length ,'visibility-shown': filesUrl?.length , }"
                    class="swiper-pagination"
                  ></div>
                  <div
                    [ngClass]="{ 'visibility-hidden': !filesUrl?.length ,'visibility-shown': filesUrl?.length , }"
                    class="swiper-button-next"
                  ></div>
                  <div
                    [ngClass]="{ 'visibility-hidden': !filesUrl?.length ,'visibility-shown': filesUrl?.length , }"
                    class="swiper-button-prev"
                  ></div>
                </div>
                @if (thumbnailSpinner) {
                <div
                  class="lds-facebook mt-25"
                  style="position: absolute; top: 40%; left: 28%"
                >
                  <!-- <div></div>
                                    <div></div>
                                    <div></div> -->
                  <p>
                    Your thumbnail is getting generated, till then please add
                    all the details..
                  </p>
                </div>
                }
              </div>
            </div>
            <button
              type="button"
              id="img-preview"
              class="btn btn-primary hide"
              data-toggle="modal"
              data-target="#exampleModalCenter"
            ></button>
            <!-- Modal -->
            <div
              class="modal fade"
              id="exampleModalCenter"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                      Image Preview
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body"></div>
                  <div class="m-auto w-auto d-flex">
                    <div class="m-auto w-auto">
                      <button class="btn btn-primary mb-5 mr-3">Share</button>
                    </div>
                    <div class="m-auto w-auto">
                      <button
                        class="btn btn-primary mb-5"
                        (click)="downloadZip()"
                      >
                        Download All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
    <div class="col-xl-8 upload-area">
      <div class="upload1">
        <div class="pl-5">
          <div class="media-upload d-flex flex-column justify-content-center">
            <form [formGroup]="projectForm" class="d-flex flex-column">
              <div class="d-flex">
                <div class="w-50">
                  <label class="d-flex control-text">Title</label>
                  <div class="form-group">
                    <input
                      class="form-control borderInput"
                      formControlName="title"
                      type="text"
                      placeholder="Enter title"
                    />
                  </div>
                  @if ( projectForm.get('title').value &&
                  projectForm.get('title').value.length > 200 ) {
                  <div class="text-danger d-flex">
                    Please enter Title up to 100 characters
                  </div>
                  } @if ( projectForm.get('title').invalid &&
                  projectForm.get('title').touched &&
                  !(projectForm.get('title').value.length > 200) ) {
                  <div class="text-danger d-flex">Title is too long.</div>
                  }
                </div>
                <div class="w-50">
                  <label class="d-flex control-text">Client Name</label>
                  <div class="form-group">
                    <input
                      class="form-control borderInput text-dark"
                      formControlName="client_name"
                      type="text"
                      value="name"
                      placeholder="Enter client name"
                      (input)="onInput('$event?.target.value')"
                      (click)="toggleSuggestions()"
                    />
                    @if ( projectForm.get('client_name').invalid &&
                    projectForm.get('client_name').touched ) {
                    <div class="text-danger d-flex">
                      Client name is too long.
                    </div>
                    } @if (showSuggestions) {
                    <div id="suggestions" class="suggestions">
                      @for (suggestion of filteredSuggestions; track suggestion)
                      {
                      <div
                        class="suggestion-item"
                        (click)="selectSuggestion(suggestion)"
                      >
                        {{ suggestion }}
                      </div>
                      }
                    </div>
                    }
                  </div>
                </div>
              </div>
              <label class="d-flex control-text">Description</label>
              <div class="form-group">
                <textarea
                  class="form-control borderInput text-area"
                  formControlName="description"
                  placeholder="Write Something..."
                ></textarea>
              </div>
            </form>
            <div class="multiple-file-container">
              <h4 class="control-text">Additional Files</h4>
              <div class="multiple-file">
                @if (loading) {
                <div class="lds-facebook mt-25" style="margin-top: 12px">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                }
                <div class="imgContainer mb-2">
                  <div class="img-container d-flex">
                    @for ( mediaUrl of ImagefilesResponseUrl; track mediaUrl;
                    let i = $index) {
                    <div class="mr-1 position-relative">
                      <span
                        class="close-button"
                        (click)="onClickDeleteImage(mediaUrl['_id'])"
                      >
                        <img
                          src="../../../assets/cross_icon.svg"
                          class="img-close img-thumbnail"
                        />
                      </span>
                      @if (isImage(mediaUrl.fileUrl)) {
                      <img
                        height="100px"
                        class="img-preview"
                        [src]="mediaUrl.fileUrl"
                      />
                      } @if (isDocument(mediaUrl.fileUrl)) {
                      <img
                        id="edit-doc-content"
                        height="100px"
                        class="img-preview"
                        [src]="fileTypeImages[mediaUrl.fileUrl.split('.')[5]]"
                      />
                      } @if (isVideo(mediaUrl.fileUrl)) {
                      <video class="mr-1" height="100px" controls>
                        <source [src]="mediaUrl.fileUrl" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      }
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center add-collect">
              <div>
                <span class="control-text">Add to Collection:</span>
              </div>
              <div class="tag-section d-flex flex-wrap ml-3">
                <div
                  class="tag-element3 px-3"
                  data-toggle="modal"
                  data-target="#staticBackdrop"
                >
                  <img src="../../../assets/plus_icon.svg" alt="" /> &nbsp;
                  <span>New Collection</span>
                </div>
              </div>
              <div class="dropdown" style="margin-top: 0px">
                <button
                  class="btn dropdown-toggle AddToCOdropButton"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  style="padding: 0rem 0.75rem"
                >
                  <span class="text-capitalize centerType">{{
                    selectedCollection || "Add to existing groups"
                  }}</span>
                  <i class="fa fa-caret-down centerType ml-1"></i>
                </button>
                <div class="existing-group">
                  <ul
                    class="dropdown-menu dis"
                    aria-labelledby="dropdownMenuButton"
                    style="max-height: 25vh; overflow-y: auto"
                  >
                    @for (group of existingCollections; track group; let i =
                    $index) { @if ( group.isPublic === (selectedCollectionType
                    === 'Public') ) {
                    <li
                      class="dropdown-item cursorPointer text-capitalize"
                      (click)="selectedPlayList(group)"
                    >
                      {{ group.title }}
                    </li>
                    } }
                  </ul>
                </div>
                <!-- <ul
                                    class="dropdown-menu dis"
                                    aria-labelledby="dropdownMenuButton"
                                    style="max-height: 25vh; overflow-y: auto"
                                    >
                                    <li
                                      class="dropdown-item cursorPointer text-capitalize"
                                      *ngFor="let tag of playUsersList; let i = index"
                                      (click)="selectedPlayList(i)"
                                      >
                                      {{ tag.name }}
                                    </li>
                                  </ul> -->
              </div>
              @if (customTags) {
              <div
                class="tag-element"
                style="display: none; padding: 12px 10px"
                [ngStyle]="{
                  display: collectionTags.length > 0 ? 'block' : 'none'
                }"
              >
                {{ collectionTags }}
                <span
                  class="remove-tag"
                  (click)="removeSelected(collectionTags)"
                >
                  <img
                    src="../../../assets/close1.png"
                    width="10px"
                    height="10px"
                    style="margin-top: -4px"
                  />
                </span>
              </div>
              }
            </div>
            <div>
              <div class="d-flex pt-4">
                <div class="pb-2 col-lg-5" style="margin-left: -12px">
                  <label class="d-flex control-text">Primary tags</label>
                  <div class="tag-section d-flex flex-wrap">
                    @for (tag of primaryTags; track tag; let i = $index) {
                    <div class="tag-element px-3">
                      {{ tag }}
                      @if (i > 3) {
                      <span
                        class="remove-tag p-0"
                        (click)="removePrimaryTag(i)"
                      >
                        <!-- <span>X</span> -->
                        <span class="remove-tag"
                          ><img
                            src="../../../assets/close1.png"
                            width="10px"
                            height="10px"
                        /></span>
                      </span>
                      }
                    </div>
                    }
                  </div>
                </div>
                <div class="col-lg-7">
                  <label class="d-flex control-text">Custom Tags</label>
                  <div class="customTags p-2 bg-white">
                    <input
                      type="text"
                      class="tag-input text-area1 bg-white"
                      placeholder="Write Your Tags.."
                      id="input-search"
                      (keyup)="onEnterKey($event)"
                    />
                    <div class="customTagHeight">
                      <div class="tag-sect d-flex flex-wrap">
                        @for (tag of customTags; track tag; let i = $index) {
                        <div class="tag-element tag-element1 px-3">
                          {{ tag }}
                          <span class="remove-tag" (click)="removeCustomTag(i)">
                            <img
                              src="../../../assets/close1.png"
                              width="10px"
                              height="10px"
                              style="margin-top: -4px"
                            />
                          </span>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="collection-type d-flex justify-content-between mt-4 mb-5"
              style="width: 96.7%"
            >
              <div class="w-25">
                <button
                  type="button"
                  class="save"
                  [disabled]="archiveInProgress"
                  (click)="onClickCollection()"
                >
                  Save
                </button>
                <!-- <img class="no-margin cursorPointer" src="../../../assets/savar.png" (click)="onClickCollection(false)" -->
                <!-- width="90%" style="margin-left: 3px" /> -->
              </div>
              <div class="w-25 fit-content">
                <!-- <img class="no-margin cursorPointer" src="../../../assets/compr.png" (click)="onClickCollection(true)"
                                    width="90%" height="100%" /> -->
                <!-- <button type="button" class="compress" (click)="onClickCollection(true)">Compress & Save</button> -->
              </div>
              <!-- Button trigger modal -->
              <!-- <div class="storage">
                                  <img class="no-margin cursorPointer" src="../../../assets/storage1.png" width="90%"
                                    height="100%" (click)="addCompanyRelatedTags('screenshot')">
                                  </div> -->
              <!-- Modal -->
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form (ngSubmit)="onSubmit()">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Storage Nas
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="">
                          <label for="text-input">Text Input:</label>
                          <input
                            type="text"
                            id="text-input"
                            class="form-control"
                            [ngModel]="textInput"
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </div>
                        <div class="">
                          <label for="image-input">Choose Image:</label>
                          <input
                            type="file"
                            class="form-control fileStorage"
                            (change)="onImageSelect($event)"
                          />
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="submit" class="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row"></div>
    </div>
  </div>
  <div class="row">
    <div class="col"></div>
  </div>
</div>
} @if (edit) {
<div class="container-fluid center">
  <div class="row justify-content-around">
    <div class="col-xl-5 upload-area">
      @if (!changeFile) {
      <div class="upload" style="height: 310px">
        <img
          id="preview-image"
          #previewImage
          class="imagePreview"
          width="100%"
        />
        @if (folderCheck) {
        <img
          id="preview-image1"
          class="imagePreview"
          width="100%"
          src="../../../assets/book.webp"
        />
        } @if (pdfUrl) {
        <div class="doc-wrapper"></div>
        }
        <iframe
          id="pdf"
          #pdf
          [src]="pdfUrl"
          viewer="google"
          height="560px"
          width="700px"
          style="width: 700px; height: 262px"
          [style.display]="pdfDisplay"
        >
        </iframe>
        <div class="position-absolute divCenter">
          <p
            class="text-capitalize fileUploaded1"
            id="upload-name"
            [style.display]="pdfDis"
          ></p>
          <p
            class="text-capitalize fileUploaded"
            id="upload-file"
            #uploadfile
            [style.display]="pdfDis"
          ></p>
          <img
            class="prev-img"
            id="prev-img"
            #previmg
            (click)="openWindow(data)"
          />
        </div>
        <video
          title="do you want to change video"
          id="preview-video"
          style="
            width: 100%;
            height: 88%;
            object-fit: contain;
            background-color: black;
          "
          controls
        ></video>
        <embed
          id="pdf"
          #pdf
          type="application/pdf"
          height="250px"
          width="500"
          [style.display]="pdfDisplay"
        />
        <video id="vid2" class="hide" height="200px" controls></video>
        <canvas id="pic-canvas" class="hide"></canvas>
        <img
          id="pic-photo"
          class="hide takePhoto"
          alt="The screen capture will appear in this box."
        />
        <p class="hide" id="recorder-control">
          <button id="btnStart" class="upload-button">START RECORDING</button>
          <button id="btnStop" class="upload-button hide">
            STOP RECORDING
          </button>
          <button
            id="btnKeep"
            (click)="keepRecording()"
            class="upload-button hide"
          >
            SAVE RECORDING
          </button>
        </p>
        @if (canvasDisplayImage) {
        <button
          id="startbutton"
          class="hide takePhoto btn btn-success"
          (click)="takePhoto()"
          style="margin-top: 3px"
        >
          Take photo
        </button>
        } @if (uploadSelection && !startPhoto) {
        <div
          class="file-upload d-flex flex-column justify-content-center"
          (drop)="onFileDrop($event)"
          (dragover)="onDragOver($event)"
          id="file-uploadCamera"
          #fileUploadCamera
        >
          <img
            src="../../../assets/upload_icon.svg"
            alt="upload-icon"
            id="upload-icon"
          />
          <p class="font-weight-bolder">
            Drag & drop files or <span class="browse">Browse</span>
          </p>
          <p class="supported">
            Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT
          </p>
          <div class="upload-control d-flex justify-content-center">
            <button
              class="upload-button hide"
              (click)="onClickCamera()"
              id="camera-button"
            >
              Camera
            </button>
            <button
              class="upload-button hide"
              (click)="onClickScreenCapture()"
              id="screen-capture-button"
            >
              Screen Capture
            </button>
            <button class="upload-button" (click)="onClickUpload()">
              Click to Upload
            </button>
          </div>
        </div>
        }
        <input type="file" class="hide" id="upload-input" />
        @if (barWidth == '100%') {
        <div
          class="lds-facebook mt-25"
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        } @if (barWidth == '100%' || messageError) {
        <div
          style="
            position: absolute;
            top: 70%;
            left: 50%;
            transform: translate(-50%, -70%);
          "
        >
          <button class="btn btn-outline mt-1 pleaseWait">
            <span>Please wait</span>
            <span class="AnimatedEllipsis"></span>
          </button>
          <p>{{ messageError }}</p>
        </div>
        } @if (barWidth != '100%') {
        <div id="my-loader" #loadingBar class="hide">
          <!-- *ngIf="loaderVisible && barWidth != '100%'" -->
          <span id="prog" #prog class="text-center" style="margin-top: -20px"
            >0%</span
          >
          <br />
          <div class="progress" style="width: 200px">
            <div class="bar" id="progressBar" #progressBar></div>
          </div>
        </div>
        }
      </div>
      } @if (changeFile) {
      <div class="upload">
        <img
          id="preview-image"
          #previewImage
          class="imagePreview"
          width="100%"
        />
        @if (pdfUrl) {
        <div class="doc-wrapper"></div>
        }
        <iframe
          id="pdf"
          #pdf
          [src]="pdfUrl"
          viewer="google"
          height="560px"
          width="700px"
          style="width: 700px; height: 262px"
          [style.display]="pdfDisplay"
        >
        </iframe>
        <div class="position-absolute divCenter">
          <p
            class="text-capitalize fileUploaded1"
            id="upload-name"
            [style.display]="pdfDis"
          ></p>
          <p
            class="text-capitalize fileUploaded"
            id="upload-file"
            #uploadfile
            [style.display]="pdfDis"
          ></p>
          <img
            class="img-thumbnail prev-img"
            id="prev-img"
            #previmg
            (click)="openWindow(data)"
          />
        </div>
        <video id="preview-video" style="width: 100%; height: 100%"></video>
        <embed
          id="pdf"
          #pdf
          type="application/pdf"
          height="250px"
          width="500"
          [style.display]="pdfDisplay"
        />
        <!-- <video id="vid2" class="hide" height="200px" controls></video>
                              <canvas id="pic-canvas" class="hide"></canvas>
                              <img
                                id="pic-photo"
                                class="hide takePhoto"
                                alt="The screen capture will appear in this box."
                                /> -->
        <!-- <p class="hide" id="recorder-control">
                                <button id="btnStart" class="upload-button">START RECORDING</button>
                                <button id="btnStop" class="upload-button hide">
                                  STOP RECORDING
                                </button>
                                <button
                                  id="btnKeep"
                                  (click)="keepRecording()"
                                  class="upload-button hide"
                                  >
                                  SAVE RECORDING
                                </button>
                              </p>
                              <button
                                id="startbutton"
                                class="hide takePhoto btn btn-success"
                                (click)="takePhoto()"
                                *ngIf="canvasDisplayImage"
                                >
                                Take photo
                              </button>
                              <div
                                class="file-upload d-flex flex-column justify-content-center"
                                (drop)="onFileDrop($event)"
                                (dragover)="onDragOver($event)"
                                *ngIf="uploadSelection && !startPhoto"
                                id="file-uploadCamera"
                                #fileUploadCamera
                                >
                                <img
                                  src="../../../assets/upload_icon.svg"
                                  alt="upload-icon"
                                  id="upload-icon"
                                  />
                                  <p class="font-weight-bolder">
                                    Drag & drop files or <span class="browse">Browse</span>
                                  </p>
                                  <p class="supported">
                                    Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT
                                  </p>
                                  <div class="upload-control d-flex justify-content-center">
                                    <button
                                      class="upload-button hide"
                                      (click)="onClickCamera()"
                                      id="camera-button"
                                      >
                                      Camera
                                    </button>
                                    <button
                                      class="upload-button hide"
                                      (click)="onClickScreenCapture()"
                                      id="screen-capture-button"
                                      >
                                      Screen Capture
                                    </button>
                                    <button class="upload-button" (click)="onClickUploadEdit()">
                                      Click to Upload
                                    </button>
                                  </div>
                                </div> -->
        <!-- <input type="file" class="hide" id="upload-input" />
                                <div
                                  *ngIf="barWidth == '100%'"
                                  class="lds-facebook; mt-25"
                                  style="position: absolute; top: 97px; left: 196px"
                                  >
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                                <div
                                  *ngIf="barWidth == '100%' || messageError"
                                  style="position: absolute; top: 151px; left: 180px"
                                  >
                                  <button class="btn btn-outline mt-1 pleaseWait">
                                    <span>Please wait</span>
                                    <span class="AnimatedEllipsis"></span>
                                  </button>
                                  <p>{{ messageError }}</p>
                                </div> -->
      </div>
      }
      <div class="image-upload">
        <p class="addFiles">Additional Files</p>
        <label id="add-img" title="upload additional file">
          <img src="../../../assets/Group 21.png" style="cursor: pointer" />
          <input
            type="file"
            id="image-input"
            (change)="onClickAddImageEdit($event)"
          />
          <p id="ImageId" class="d-none"></p>
        </label>
        <div class="additionalFiles"></div>
      </div>
      @if (filesUrl.length > 0 || thumbnailSpinner) {
      <div class="img-thumbnailsList">
        <p class="text-black ml-2">Choose a thumbnail</p>
        <div class="">
          <div class="">
            <div class="img-show mb-5 pb-3">
              <div class="sliderFixed">
                <!-- <ngx-slick-carousel class="carousel pt-2 pb-2" #slickModal="slick-carousel" [config]="slideConfig"
                  (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                  (beforeChange)="beforeChange($event)">
                  @for (file of filesUrl; track file; let i = $index) {
                  <div ngxSlickItem class="slide">
                    <img [src]="file" class="img-row-show thumbnail img-thumbnail mr-2" width="100%" height="100%"
                      (click)="onClickImage(file, i)" />
                    <img class="thumbnailImg" [class.selected]="i === selectedImageIndex"
                      src="../../../assets/Group 26.png" width=" 25px" height=" 25px" />
                  </div>
                  }
                </ngx-slick-carousel> -->
                <!-- <div #swiperContainer class="swiper-container">
                  <div class="swiper-wrapper">
                    @for (file of filesUrl; track file; let i = $index) {
                    <div class="swiper-slide">
                      <img [src]="file" class="img-row-show thumbnail img-thumbnail mr-2" width="100%" height="100%"
                        (click)="onClickImage(file, i)" />
                      <img class="thumbnailImg" [class.selected]="i === selectedImageIndex"
                        src="../../../assets/Group 26.png" width="25px" height="25px" />
                    </div>
                    }
                  </div>
                  <div class="swiper-pagination"></div>
                  <div class="swiper-button-next"></div>
                  <div class="swiper-button-prev"></div>
                </div> -->
                @if (thumbnailSpinner) {
                <div
                  class="lds-facebook mt-25"
                  style="position: absolute; top: 83px; left: 196px"
                >
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                }
              </div>
            </div>
            <button
              type="button"
              id="img-preview"
              class="btn btn-primary hide"
              data-toggle="modal"
              data-target="#exampleModalCenter"
            ></button>
            <!-- Modal -->
            <div
              class="modal fade"
              id="exampleModalCenter"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                      Image Preview
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body"></div>
                  <div class="m-auto w-auto d-flex">
                    <div class="m-auto w-auto">
                      <button class="btn btn-primary mb-5 mr-3">Share</button>
                    </div>
                    <div class="m-auto w-auto">
                      <button
                        class="btn btn-primary mb-5"
                        (click)="downloadZip()"
                      >
                        Download All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
    <div class="col-xl-7 upload-area">
      <div class="upload1">
        <div class="pl-5">
          <div class="media-upload d-flex flex-column justify-content-center">
            <form [formGroup]="projectForm" class="d-flex flex-column">
              <div class="d-flex">
                <div class="w-50">
                  <label class="d-flex control-text">Title</label>
                  <div class="form-group">
                    <input
                      class="form-control textColor borderInput text-black-50"
                      formControlName="title"
                      type="text"
                      placeholder="Enter title"
                    />
                  </div>
                </div>
                <div class="w-50">
                  <label class="d-flex control-text">Client Name</label>
                  <div class="form-group">
                    <input
                      class="form-control textColor borderInput text-black-50"
                      formControlName="client_name"
                      type="text"
                      placeholder="Enter client name"
                    />
                    @if ( projectForm.get('client_name').invalid &&
                    projectForm.get('client_name').touched ) {
                    <div class="text-danger d-flex">
                      Client name is required.
                    </div>
                    }
                  </div>
                </div>
              </div>
              <label class="d-flex control-text">Description</label>
              <div class="form-group">
                <textarea
                  class="form-control borderInput text-area"
                  formControlName="description"
                  placeholder="Write Something..."
                ></textarea>
              </div>
            </form>
            <div class="multiple-file-container">
              <h4 class="control-text">Additional Files</h4>
              <div class="multiple-file">
                @if (loading) {
                <div class="lds-facebook mt-25" style="margin-top: 12px">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                }
                <div class="imgContainer mb-2">
                  <div class="img-container d-flex">
                    @for ( mediaUrl of ImagefilesResponseUrl; track mediaUrl;
                    let i = $index) {
                    <div class="mr-1 position-relative">
                      <span
                        class="close-button"
                        (click)="
                          onClickDeleteImaEdited(mediaUrl._id, mediaUrl.mediaId)
                        "
                      >
                        <img
                          src="../../../assets/cross_icon.svg"
                          class="img-close img-thumbnail"
                        />
                      </span>
                      @if (isImage(mediaUrl.fileUrl)) {
                      <img
                        id="edit-content"
                        height="100px"
                        class="img-preview"
                        [src]="mediaUrl.fileUrl"
                      />
                      } @if (isDocument(mediaUrl.fileUrl)) {
                      <iframe
                        id="edit-content"
                        height="100px"
                        class="img-preview"
                        [src]="mediaUrl.fileUrl"
                      >
                      </iframe>
                      } @if (isVideo(mediaUrl.fileUrl)) {
                      <video
                        id="edit-content"
                        class="mr-1"
                        height="100px"
                        controls
                      >
                        <source [src]="mediaUrl.fileUrl" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      }
                    </div>
                    }
                    <!-- <div
                        *ngFor="
                          let mediaUrl of ImagefilesResponseUrl;
                          let i = index
                        "
                                                    class="mr-1 position-relative"
                                                    >
                                                    <span
                                                      class="close-button"
                                                      (click)="onClickDeleteImage(mediaUrl.fileUrl)"
                                                      >
                                                      <img
                                                        src="../../../assets/cross_icon.svg"
                                                        class="img-close img-thumbnail"
                                                        />
                                                      </span>
                                                      <ng-container *ngIf="isImage(mediaUrl.fileUrl)">
                                                        <img
                                                          height="100px"
                                                          class="img-preview"
                                                          [src]="mediaUrl.fileUrl"
                                                          />
                                                        </ng-container>
                                                        <ng-container *ngIf="isVideo(mediaUrl.fileUrl)">
                                                          <video class="mr-1" height="100px" controls>
                                                            <source [src]="mediaUrl.fileUrl" type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                          </video>
                                                        </ng-container>
                                                      </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div>
                <span class="control-text">Add to Collection:</span>
              </div>
              <div class="tag-section d-flex flex-wrap ml-3">
                <div
                  class="tag-element3 px-3"
                  data-toggle="modal"
                  data-target="#staticBackdrop"
                >
                  <img src="../../../assets/plus_icon.svg" alt="" />
                  <span style="padding-left: 5px">New Collection</span>
                </div>
              </div>
              <div class="dropdown" style="margin-top: 0px">
                <button
                  class="btn dropdown-toggle AddToCOdropButton"
                  type="button"
                  id="dropdownMenuButtonOpen"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  style="padding: 0rem 0.75rem"
                  (click)="addToExistingCollection()"
                >
                  <span class="text-capitalize centerType">{{
                    selectedCollection || "Add To Collection"
                  }}</span>
                  <i class="fa fa-caret-down centerType"></i>
                </button>
                <div class="existing-group">
                  <ul
                    class="dropdown-menu dis"
                    aria-labelledby="dropdownMenuButton"
                    style="max-height: 25vh; overflow-y: auto"
                  >
                    @for (group of existingCollections; track group; let i =
                    $index) {
                    <li
                      class="dropdown-item cursorPointer text-capitalize"
                      (click)="selectedPlayList(group)"
                    >
                      {{ group.title }}
                    </li>
                    }
                  </ul>
                </div>
                <ul
                  class="dropdown-menu dis"
                  aria-labelledby="dropdownMenuButtonOpen"
                  style="height: 35vh; overflow-y: auto"
                >
                  @for (tag of PlayListNames; track tag; let i = $index) {
                  <li
                    class="dropdown-item cursorPointer text-capitalize"
                    (click)="selectedPlayList(i)"
                  >
                    {{ tag.name }}
                  </li>
                  }
                </ul>
              </div>
              <div
                class="tag-element"
                style="display: none; padding: 12px 10px"
                [ngStyle]="{
                  display: collectionTags.length > 0 ? 'block' : 'none'
                }"
              >
                {{ collectionTags }}
                <span
                  class="remove-tag"
                  (click)="removeSelected(collectionTags)"
                >
                  <img
                    src="../../../assets/close1.png"
                    width="10px"
                    height="10px"
                    style="margin-top: -4px"
                  />
                </span>
              </div>
            </div>
            <div>
              <div class="d-flex pt-2">
                <div class="pb-2 col-lg-5" style="margin-left: -12px">
                  <label class="d-flex control-text">Primary tags</label>
                  <div class="tag-section d-flex flex-wrap">
                    @for (tag of primaryTags; track tag; let i = $index) {
                    <div class="tag-element px-3">
                      {{ tag }}
                      @if (i > 3) {
                      <span
                        class="remove-tag p-0"
                        (click)="removePrimaryTag(i)"
                      >
                        <span class="remove-tag"
                          ><img
                            src="../../../assets/close1.png"
                            width="10px"
                            height="10px"
                        /></span>
                      </span>
                      }
                    </div>
                    }
                  </div>
                </div>
                <div class="col-lg-7">
                  <label class="d-flex control-text">Custom Tags </label>
                  <div class="customTags p-2 bg-white">
                    <input
                      type="text"
                      class="tag-input text-area1 bg-white"
                      placeholder="Write Your Tags.."
                      id="input-search"
                      (keyup)="onEnterKey($event)"
                    />
                    <div class="customTagHeight">
                      <div class="tag-section d-flex flex-wrap">
                        @for (tag of customTags; track tag; let i = $index) {
                        <div
                          class="tag-element tag-element1 px-3"
                          [ngStyle]="{
                            display: customTags.length > 0 ? 'block' : 'none'
                          }"
                        >
                          {{ tag }}
                          <span class="remove-tag" (click)="removeCustomTag(i)">
                            <img
                              src="../../../assets/close1.png"
                              width="10px"
                              height="10px"
                              style="margin-top: -4px"
                            />
                          </span>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="collection-type d-flex mt-4 mb-5">
              <button
                class="btn btn-success no-margin mr-3"
                (click)="onClickCollectionEdit(false)"
              >
                Save
              </button>
              <!-- <button class="btn btn-dark" (click)="onClickCollectionEdit(true)">
                                                    compress & save
                                                  </button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row"></div>
    </div>
  </div>
  <div class="row">
    <div class="col"></div>
  </div>
</div>
}
<!-- Modal -->
<div
  class="modal fade"
  id="staticBackdrop"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">
          Add A New collection
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="../../../assets/cross_icon.svg" alt="close" />
        </button>
      </div>
      <div class="modal-body text-left">
        <div>
          <label for="collection" class="fw-collection-modal"
            >Collection Title:</label
          >
          <input type="text" class="form-control mb-2 collection-input" />
        </div>
        <span class="fw-collection-modal mb-2 pt-1 pb-2">Type:</span>
        <div class="dropdown mt-2">
          <button
            class="btn dropdown-toggle dropButton"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-expanded="false"
            style="padding: 0rem 0.75rem"
          >
            <span class="text-capitalize centerType">{{
              collectionType || "Add to collection"
            }}</span>
            <i class="fa fa-caret-down centerType"></i>
          </button>
          <ul
            class="dropdown-menu drop-menu"
            aria-labelledby="dropdownMenuButton"
          >
            <li
              class="dropdown-item cursorPointer text-capitalize"
              (click)="addCollectionType('Private')"
            >
              <span class="icon-text-wrapper d-flex align-items-center">
                <div class="icon">
                  <svg
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    focusable="false"
                  >
                    <path
                      d="M17 8V6.63C17 4.08 14.76 2 12 2S7 4.08 7 6.63V8H4v14h16V8h-3zM8 6.63c0-2.02 1.79-3.66 4-3.66s4 1.64 4 3.66V8H8V6.63zM19 21H5V9h14v12zm-7-9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                    ></path>
                  </svg>
                </div>
                <p class="mb-0 pl-3">Private</p>
              </span>
            </li>
            <li
              class="dropdown-item cursorPointer text-capitalize"
              (click)="addCollectionType('Public')"
            >
              <span class="icon-text-wrapper d-flex align-items-center">
                <div class="icon">
                  <svg
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    focusable="false"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM3 12c0-.7.09-1.37.24-2.02L8 14.71v.79c0 1.76 1.31 3.22 3 3.46v1.98c-4.49-.5-8-4.32-8-8.94zm8.5 6C10.12 18 9 16.88 9 15.5v-1.21l-5.43-5.4C4.84 5.46 8.13 3 12 3c1.05 0 2.06.19 3 .53V5c0 .55-.45 1-1 1h-3v2c0 .55-.45 1-1 1H8v3h6c.55 0 1 .45 1 1v4h2c.55 0 1 .45 1 1v.69C16.41 20.12 14.31 21 12 21v-3h-.5zm7.47-.31C18.82 16.73 18 16 17 16h-1v-3c0-1.1-.9-2-2-2H9v-1h1c1.10 0 2-.9 2-2V7h2c1.10 0 2-.9 2-2V3.95c2.96 1.48 5 4.53 5 8.05 0 2.16-.76 4.14-2.03 5.69z"
                    ></path>
                  </svg>
                </div>
                <p class="mb-0 pl-3">Public</p>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          (click)="createCollection()"
          type="button"
          class="btn btn-warning fw-collection-modal"
          data-dismiss="modal"
        >
          Create
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <input type="file" (change)="onFileSelected($event)" /> -->
<!-- <button (click)="onUpload()">Upload</button> -->
