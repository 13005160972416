import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { InventoryService } from "src/app/service/inventory.service";
import { FormGroup, FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatSelectModule } from '@angular/material/select';
import { CrmService } from "src/app/service/crm.service";
import { toast, NgxSonnerToaster } from 'ngx-sonner';

interface Request {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-inventory-dashboard",
  standalone: true,
  imports: [RouterLink, ReactiveFormsModule, CommonModule, MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    NgxSonnerToaster,
  ],
  templateUrl: "./inventory-dashboard.component.html",
  styleUrls: ["./inventory-dashboard.component.css", "../styles/inventory.css"],
})
export class InventoryDashboardComponent {

  viewAll: boolean = false;


  uploadAssetImageForm: FormGroup
  selectedFiles: any[] = [];
  extendRequestForm: FormGroup;
  forwardRequestForm: FormGroup;
  forwardNewRequestForm: FormGroup;
  overdueExtendForm: FormGroup;
  declineReasonForm: FormGroup;
  constructor(private inventoryService: InventoryService, private fb: FormBuilder, private crmService: CrmService) {
    this.uploadAssetImageForm = this.fb.group({
      assetImage: []
    });
    this.extendRequestForm = this.fb.group({
      extendTill: []
    });

    // Forward Request Form Group
    this.forwardRequestForm = this.fb.group({
      maximumCost: ['', Validators.required],
      comment: ['', Validators.required],
      selectedAdmin: ['', Validators.required]
    })

    this.forwardNewRequestForm = this.fb.group({
      maximumCost: ['', Validators.required],
      comment: ['', Validators.required],
      link1: ['', Validators.required],
      link2: ['', Validators.required],
      link3: ['', Validators.required],
      selectedAdmin: ['', Validators.required]
    })

    this.declineReasonForm = this.fb.group({
      rejectionReason: ['', Validators.required]
    })
  }



  defaultPendingRequestType: string = 'Inventory Request';
  userDepartment: string | null = null;
  showUserDashboard = false;

  ngOnInit() {
    this.getAdminUsersList();
    this.overdueAssetInventories();
    this.getAssignedInventoryData();
    this.onFilterPendingRequest(this.defaultPendingRequestType)
    this.onFilterApprovedRequest(this.defaultPendingRequestType)
    this.getInventoryRequest();
    this.getNewInventory();
    this.getServiceRequestData();
    this.getInventoryReturnRequest();
    this.getExtendData();
    this.inventoryOverview();
    this.assetReturnReq();
    this.userDepartment = localStorage.getItem('department');
    this.showUserDashboard = this.userDepartment === 'HR' || this.userDepartment === 'ADMIN';
  }

  assignedInventoryId: string;
  selectedInventoryIndex: number | null = null;
  openReturn(assignedInventoryId: string, index: number) {
    this.assignedInventoryId = assignedInventoryId;
    this.selectedInventoryIndex = index;

    this.uploadAssetImageForm.reset();
    this.selectedFiles = [];

    this.uploadAssetImageForm.get('assetImage').enable();
  }


  overdueAssignedInventoryId: string;
  selectedOverdueIndex: number | null = null;
  openOverdueExtend(overdueAssignedInventoryId: string, index: number) {
    this.overdueAssignedInventoryId = overdueAssignedInventoryId;
    this.selectedOverdueIndex = index;

  }

  removeFile(file: any) {
    const index = this.selectedFiles.indexOf(file);
    if (index > -1) {
      this.selectedFiles.splice(index, 1);
    }
    if (this.selectedFiles.length < 4) {
      this.uploadAssetImageForm.get('assetImage').enable();
    }
  }




  onFileSelected(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;

      if (files.length + this.selectedFiles.length > 4) {
        alert('You can only upload a maximum of 4 files.');
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.selectedFiles.push({
            file: file,
            name: file.name,
            size: (file.size / 1024).toFixed(2) + ' KB',
            preview: e.target.result
          });
        };
        reader.readAsDataURL(file);
      }

      console.log("Selected Files", this.selectedFiles);

      if (this.selectedFiles.length >= 4) {
        this.uploadAssetImageForm.get('assetImage').disable();
      }
    }
  }


  showForwardSuccess = false;
  onReturnSubmit() {
    console.log(this.uploadAssetImageForm.value);

    if (this.selectedFiles.length === 0) {
      alert('Please upload at least one file.');
      return;
    }

    const formData = new FormData();
    formData.append('assignedInventoryId', this.assignedInventoryId);

    this.selectedFiles.forEach((fileData: any, index: number) => {
      formData.append(`files[${index}]`, fileData.file, fileData.name);
    });

    this.inventoryService.addAssignedReturn(formData).subscribe(res => {
      this.showForwardSuccess = true;
      console.log("success");

      if (this.selectedInventoryIndex !== null) {
        this.inventory.splice(this.selectedInventoryIndex, 1);
        this.selectedInventoryIndex = null;
        if (this.selectedInventoryIndex !== null) {
          this.inventory.splice(this.selectedInventoryIndex, 1);
          this.selectedInventoryIndex = null;

          this.totalAssigned--;
        }
      }
    }, err => {
      console.error("API call failed", err);
    });
  }

  formatExtendDate(date: Date): { day: number; month: number; year: number } {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };
  }

  onRequestExtend() {
    const extendValue = {
      ...this.extendRequestForm.value
    }
    const extendTill = new Date(extendValue.extendTill);
    const formattedExtendDate = this.formatExtendDate(extendTill);
    const extendRequest = {
      assignedInventoryId: this.assignedInventoryId,
      extendTill: {
        day: extendTill.getDate(),
        month: extendTill.getMonth() + 1,
        year: extendTill.getFullYear()
      },
    }

    this.inventoryService.assignedExtendRequest(extendRequest).subscribe(response => {
      console.log("submitted")
    }

    )
  }

  // Overdue Extend
  onRequestOverdueExtend() {
    const extendValue = {
      ...this.extendRequestForm.value
    }
    const extendTill = new Date(extendValue.extendTill);
    const formattedExtendDate = this.formatExtendDate(extendTill);
    const extendRequest = {
      assignedInventoryId: this.assignedInventoryId,
      extendTill: {
        day: extendTill.getDate(),
        month: extendTill.getMonth() + 1,
        year: extendTill.getFullYear()
      },
    }

    this.inventoryService.overdueExtendRequest(extendRequest).subscribe(response => {
      console.log("submitted")
    }

    )

  }


  //Assigned Assets Listings
  inventory: any[] = [];
  totalAssigned: any;

  getAssignedInventoryData() {
    const field: any = {
      pageNum: 1,
      limit: 100,
    };

    this.inventoryService.getAssignInv(field).subscribe(
      (response: any) => {
        this.inventory = response['data'].records.reverse()
        this.totalAssigned = response['data'].totalCounts;
      },
      (error) => {
        console.error("Error", error);
      }
    );
  }


  requests: Request[] = [
    { value: 'Inventory Request', viewValue: 'Inventory Request' },
    { value: 'Service Request', viewValue: 'Service Request' },
    { value: 'New Inventory Request', viewValue: 'New Inventory Request' },
    { value: 'Extend Request', viewValue: 'Extend Request' },
    { value: 'Return Request', viewValue: 'Return Request' },

  ]


  showUrgency: boolean = false;
  showReturnHead: boolean = false;
  showServicePending: boolean = false;

  onRequestSelect(value: string): void {
    console.log("Selected value:", value);
    this.onFilterPendingRequest(value);
  }

  onApprovedReqSelect(value: string) {
    console.log("Selected value:", value);
    this.onFilterApprovedRequest(value);
  }
  pendingReq = [];
  totalPending: any;

  onFilterPendingRequest(requestType: string) {
    const getPendingReqValue: any = {
      requestType: requestType,

    };

    this.showUrgency = requestType === 'New Inventory Request';
    this.showReturnHead = requestType === 'Return Request';
    this.showServicePending = requestType === 'Service Request';

    this.inventoryService.getPendingRequest(getPendingReqValue).subscribe(response => {
      console.log("Pending Request");
      this.pendingReq = response['data'].records;
      this.totalPending = response['data'].totalCounts
    })
  }

  approveInv = [];
  totalApproved = [];
  showServiceApproved: boolean = false;
  showAssetType: boolean = false;
  showApprovedReturn: boolean = false;
  onFilterApprovedRequest(requestType: string) {
    const getApprovedReqValue: any = {
      requestType: requestType,
    }
    this.showAssetType = requestType === 'New Inventory Request';
    this.showServiceApproved = requestType === 'Service Request';
    this.showApprovedReturn = requestType === 'Return Request';
    this.inventoryService.getApprovedRequest(getApprovedReqValue).subscribe(response => {
      console.log("Approved Request", response);
      this.approveInv = response['data'].records;
      this.totalApproved = response['data'].totalCounts;
    })
  }


  overdueAssets = []
  totalOverdueAssets: any;
  overdueAssetInventories() {
    const field: any = {
      pageNum: 1,
      limit: 100,
    };
    this.inventoryService.getOverdueAssets(field).subscribe(response => {
      console.log(response)
      this.overdueAssets = response['data'].requests;
      this.totalOverdueAssets = response['data'].totalCounts;
    })
  }


  //ADMIN DASHBOARD

  //Inventory Request Listing 
  inventoriesReq = []
  totalInventoryRequest: any;
  getInventoryRequest() {
    console.log("loggged")
    this.inventoryService.getAllInventoryRequest().subscribe(response => {
      console.log("Response", response);
      this.inventoriesReq = response['data'].records
      this.totalInventoryRequest = response['data'].totalCounts
      console.log(this.inventoriesReq)
    })
  }


  //New Inventory Request Listing
  newInventories = []
  totalNewInventory: any;
  getNewInventory() {
    console.log("loggged")
    this.inventoryService.getNewInventoryRequest().subscribe(response => {
      console.log("Response", response);
      this.newInventories = response['data'].records
      this.totalNewInventory = response['data'].totalCounts
      console.log(this.newInventories)
    })
  }

  //Service Request Listing
  serviceRequest = []
  totalService: any;
  getServiceRequestData() {
    this.inventoryService.getServiceRequest().subscribe(res => {
      this.serviceRequest = res['data'].records
      this.totalService = res['data'].totalCounts
      console.log("Service Response", res)

    })
  }

  returnInv = [];
  totalReturn: any;
  getInventoryReturnRequest() {
    this.inventoryService.getInvReturnRequest().subscribe(response => {
      console.log("RETURN REQUEST", response)
      this.returnInv = response['data'].records;
      this.totalReturn = response['data'].totalCounts;
    })
  }

  adminUsers: { value: string, viewValue: string, id: string }[] = [];
  getAdminUsersList() {
    const department = "ADMIN";
    const loggedInUsername = localStorage.getItem("username");
    const loggedInUserId = localStorage.getItem("guestID");
    this.crmService.getAnyUsers(department).subscribe(
      (res) => {
        let data = res["data"];
        this.adminUsers = data.map((adminUser) => {
          return {
            value: adminUser.userName,
            viewValue: adminUser.userName,
            id: adminUser._id
          };
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  //Forward Request Form Submit
  selectedInventory: any = {};
  onSubmitForwardReq(serviceReq: any) {
    console.log({ serviceReq })
    console.log('REQUEST ID', serviceReq.requestId)
    console.log(this.forwardRequestForm.value)
    const forwardReqValues = {
      ...this.forwardRequestForm.value
    };


    const getForwardRequestFormValue: any = {
      maximumCost: String(forwardReqValues.maximumCost),
      comment: String(forwardReqValues.comment),
      requestId: serviceReq.requestId,
      adminId: forwardReqValues.selectedAdmin
    };
    console.log(serviceReq?.requestId, 'REQUEST ID')
    this.inventoryService.addForwardServiceReq(getForwardRequestFormValue).subscribe(response => {
      console.log('submitted ', response);
      this.showForwardSuccess = true
    });
  }


  // Forward New Inventory Request
  selectedNewInventory: any = {}
  onSubmitForwardNewInvReq(newInventory: any) {
    console.log({ newInventory })
    console.log('REQUEST ID', newInventory.requestId)
    console.log(this.forwardNewRequestForm.value)
    const forwardReqValues = {
      ...this.forwardNewRequestForm.value
    };

    const linksArray = [
      forwardReqValues.link1,
      forwardReqValues.link2,
      forwardReqValues.link3
    ].filter(link => link);


    const getForwardRequestFormValue: any = {
      maximumCost: String(forwardReqValues.maximumCost),
      comment: String(forwardReqValues.comment),
      links: linksArray,
      requestId: newInventory.requestId,
      adminId: forwardReqValues.selectedAdmin
    };
    console.log(newInventory?.requestId, 'REQUEST ID')
    this.inventoryService.forwardRequest(getForwardRequestFormValue).subscribe(response => {
      console.log('submitted ', response);
      this.showForwardSuccess = true
    });
  }


  extendInv = []
  totalExtend: any;
  getExtendData() {
    this.inventoryService.getExtendRequest().subscribe(response => {
      this.extendInv = response['data'].records;
      this.totalExtend = response['data'].totalCounts
      console.log(response, "EXTEND");
    })
  }


  totalAssets: any;
  rentalAssets: any;
  availableAsset: any;
  inventoryOverview() {
    this.inventoryService.getInventoryOverview().subscribe(response => {
      console.log("Overview");
      this.totalAssets = response['data'].totalAssets;
      this.rentalAssets = response['data'].rentalAssets;
      this.availableAsset = response['data'].availableAsset;

    })
  }
  previewReturnImg: any
  openReturnPreviewSlider(index: number) {
    this.previewReturnImg = this.returnInv[index]
  }

  // ASSET RETURN REQUEST ACCEPT
  acceptReturnRequest(requestId: any) {
    const acceptRequest = {
      action: 'Accept',
      requestId: requestId,
      requestType: 'Return Request',
    }
    this.inventoryService.adminAction(acceptRequest).subscribe(response => {
      console.log("called");
      // toast.success('Request has been accepted')
      this.getInventoryReturnRequest();
    })
  }

  // ASSET RETURN REQUEST REJECT
  rejectReturnRequest(requestId: any) {

    const rejectValue = {
      ...this.declineReasonForm.value
    }

    const getRejectValue = {
      rejectionReason: rejectValue.rejectionReason,
      action: 'Rejected',
      requestId: requestId,
      requestType: 'Return Request',
    }
    this.inventoryService.adminAction(getRejectValue).subscribe(response => {
      console.log("Decline request", response)
    })
  }

  // ACCEPT INVENTORY REQUEST 
  acceptInvRequest(requestId: any) {
    const acceptRequest = {
      action: 'Accept',
      requestId: requestId,
      requestType: 'Inventory Request',
    }
    this.inventoryService.adminAction(acceptRequest).subscribe(response => {
      console.log("called");
      // toast.success('Request has been accepted')
      this.getInventoryRequest();
    })
  }

  // REJECT INVENTORY REQUEST 
  declineInvRequest(requestId: any) {
    const declineRequest = {
      action: 'Rejected',
      requestId: requestId,
      requestType: 'Inventory Request',
    }
    this.inventoryService.adminAction(declineRequest).subscribe(response => {
      console.log("called")
      this.getInventoryRequest();
    })
  }

  // ACCEPT NEW INVENTORY REQUEST
  acceptNewInvRequest(requestId: any) {
    const acceptRequest = {
      action: 'Accept',
      requestId: requestId,
      requestType: 'New Inventory Request',
    }
    this.inventoryService.adminAction(acceptRequest).subscribe(response => {
      console.log("called");
      this.getInventoryRequest();
    })
  }

  //Show Remarks Form on decline click
  showDeclineForm: boolean = false;
  onDeclineButtonClick() {
    this.showDeclineForm = true;
  }

  // REJECT NEW INVENTORY REQUEST
  declineNewInvRequest(requestId: any) {
    const declineRequest = {
      action: 'Rejected',
      requestId: requestId,
      requestType: 'New Inventory Request',
    }
    this.inventoryService.adminAction(declineRequest).subscribe(response => {
      console.log("called")
      this.getInventoryRequest();
    })
  }

  //ACCEPT SERVICE REQUEST
  acceptNewServiceRequest(requestId: any) {
    const acceptRequest = {
      action: 'Accept',
      requestId: requestId,
      requestType: 'Service Request',
    }
    this.inventoryService.adminAction(acceptRequest).subscribe(response => {
      console.log("called");
      this.getInventoryRequest();
    })
  }


  // REJECT SERVICE REQUEST
  declineServiceRequest(requestId: any) {
    const declineRequest = {
      action: 'Rejected',
      requestId: requestId,
      requestType: 'Service Request',
    }
    this.inventoryService.adminAction(declineRequest).subscribe(response => {
      console.log("called")
      this.getInventoryRequest();
    })
  }

  assetReturnInv = [];
  totalAssetReturn: any;
  assetReturnReq() {
    const field: any = {
      pageNum: 1,
      limit: 100,
    };
    this.inventoryService.getAssetReturnRequest(field).subscribe(response => {
      console.log("ASSET Return", response);
      this.assetReturnInv = response['data'].requests;
      this.totalAssetReturn = response['data'].totalCounts;
    })
  }


}
