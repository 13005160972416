<div class="containerBanner">
  <div class="menu-container">
    @if (showServiceRequestTable) {
    <h1>Service Requests<span>({{this.totalService}})</span></h1>
    }
    @if (showForwardedReqTable) {
    <h1> Forwarded Service Requests<span>({{this.totalService}})</span></h1>
    }
    <div class="menu-content d-flex align-items-center justify-content-between">
      <div class="search-menu">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
          class="search-icon">
          <g opacity="0.4">
            <circle cx="11.0795" cy="11.3768" r="7.62168" transform="rotate(-45 11.0795 11.3768)" stroke="#092C4C"
              stroke-width="1.72802" stroke-linecap="round" />
            <path d="M16.6416 16.9393L19.8465 20.1443" stroke="#092C4C" stroke-width="1.72802" stroke-linecap="round" />
          </g>
        </svg>
        <input type="search" placeholder="Search requests.." />
      </div>
      <div class="btn-container d-flex align-items-center justify-content-between">


        <div class="month-type">
          <mat-form-field class="example-form-field" style="width:240px!important;height:45px!important;border: none;
              --mat-form-field-filled-with-label-container-padding-bottom: 0px;
              --mat-form-field-container-height: 0px;
              --mat-form-field-filled-with-label-container-padding-top: 0px;
              --mdc-filled-text-field-container-color: #fff;
              --mat-form-field-container-vertical-padding: 10px;
            ">
            <mat-date-range-input [rangePicker]="rangePicker">
              <input matStartDate placeholder="Start date" />
              <input matEndDate placeholder="End date" />
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>Reset</button>
                <button style="
                    --mdc-protected-button-container-color: #fecc28;
                    --mdc-protected-button-label-text-color: #000;
                  " mat-raised-button color="primary" matDateRangePickerApply>
                  Apply
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
        </div>

        <select id="admin" class="form-control" formControlName="admin" style="margin-left: 15px">
          <option value="admin" selected>All Admins</option>
        </select>

        <mat-form-field style="width:185px!important;height: 45px!important;">

          <mat-select placeholder="select">
            @for (request of requests; track $index) {
            <mat-option [value]="request.value" (click)="onRequestSelect(request.value)"> {{request.viewValue}}
            </mat-option>
            }
            @for (serviceRequest of allServiceReq; track $index) {
            <mat-option [value]="serviceRequest.value" (click)="getServiceRequestData()"> {{serviceRequest.viewValue}}
            </mat-option>
            }
          </mat-select>

        </mat-form-field>

        <button class="d-flex align-items-center justify-content-center new-req" type="button" data-toggle="modal"
          data-target="#serviceRequestModal" style="margin-left: 13px;" (click)="openServiceForm()">
          <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 32 32"
            style="margin-right: 8px">
            <path fill="currentColor" d="M17 15V8h-2v7H8v2h7v7h2v-7h7v-2z" />
          </svg>
          New Request
        </button>
      </div>
    </div>
  </div>

  <!-- service Request Table -->
  @if (showServiceRequestTable) {
  <div class="checked-out-inv" style="overflow-x: auto; max-height: 610px">
    <table>
      <thead>
        <tr>
          <th scope="col">ASSET NAME</th>
          <th scope="col">REQUESTED BY</th>
          <th scope="col">STATUS</th>
          <th scope="col">SERVICE TYPE</th>
          <th scope="col">DAMAGE</th>
          <th scope="col">OTHER DETAILS</th>
          <th scope="col">REQUESTED ON</th>
          <th scope="col">NEED BY</th>
          <th scope="col">URGENCY</th>
          @if(showForwardButton ){
          <th scope="col">ACTIONS</th>
          }
        </tr>
      </thead>
      <tbody>
        @for (serviceReq of serviceRequest; track $index) {
        <tr data-toggle="modal">
          <td>
            <div class="d-flex align-items-center">
              <div class="request-img">
                <img
                  [src]="serviceReq.assetImage ? serviceReq.assetImage : '../../../assets/inventories/computer-system.jpg' "
                  alt="" width="60px" height="60px" />
              </div>
              <p>{{serviceReq.assetName}}</p>
            </div>
          </td>
          <td>
            <p>{{serviceReq.requestedBy}}</p>
          </td>
          <td>
            <p class="status">Pending</p>
          </td>
          <td>
            <p>{{serviceReq.serviceType}}</p>
          </td>
          <td>
            <p>{{serviceReq.damage}}</p>
          </td>
          <td>
            <p class="other-details">
              {{serviceReq.details}}
            </p>
          </td>

          <td>
            <p>{{serviceReq.requestedOn}}</p>
          </td>
          <td>
            <p>{{serviceReq.needBy}}</p>
          </td>

          <td>
            <p [ngClass]="{
              'text-red':serviceReq.urgency === 'High',
              'text-orange':serviceReq.urgency === 'Medium',
              'text-blue':serviceReq.urgency === 'Low'
            }">{{serviceReq.urgency}}</p>
          </td>
          @if(showForwardButton ){
          <td>
            <button class="forward-req" data-toggle="modal" data-target="#forwardRequestModal"
              (click)="selectedInventory = serviceReq">
              Forward Request
            </button>
            <button class="accept" style="margin-right: 10px;"
              (click)="acceptRequest(serviceReq.requestId)">Accept</button>
            <button class="decline" (click)=" declineRequest(serviceReq.requestId)">Decline</button>
          </td>
          }
        </tr>

        <!-- <tr data-toggle="modal" data-target="#requestDetailModal">
          <td>
            <div class="d-flex align-items-center">
              <div class="inventory-img">
                <img src="../../../../assets/inventories/drone.png" alt="" />
              </div>
              <p>DJI Mini 4 Pro Fly More Combo</p>
            </div>
          </td>
          <td>
            <p>Anoop Kumar</p>
          </td>
          <td class="project-name">
            <p>Tanvi Singh</p>
          </td>
          <td>
            <p>Pending</p>
          </td>
          <td>
            <p>Cleaning</p>
          </td>
          <td>
            <p>Screen Broken</p>
          </td>
          <td>
            <p class="other-details">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
              libero, iure dolorum vitae aspernatur illo dolor a voluptatem
              doloribus impedit? Fugit nisi illum ipsum dolores?
            </p>
          </td>

          <td>
            <p>June 15 (15 D ago)</p>
          </td>
          <td>
            <p>Tomorrow</p>
          </td>

          <td>
            <p class="urgency">Medium</p>
          </td>
          <td>
            <button
              class="forward-req"
              data-toggle="modal"
              data-target="#forwardRequestModal"
            >
              Forward Request
            </button>
            <button class="decline">Decline</button>
          </td>
        </tr> -->
        }
      </tbody>
    </table>
  </div>
  }


  <!-- Forward Request Table -->

  @if (showForwardedReqTable) {
  <div class="checked-out-inv" style="overflow-x: auto; max-height: 610px">
    <table>
      <thead>
        <tr>
          <th scope="col">ASSET NAME</th>
          <th scope="col">REQUESTED BY</th>
          <th scope="col">APPROVER</th>
          <th scope="col">STATUS</th>
          <th scope="col">SERVICE TYPE</th>
          <th scope="col">DAMAGE</th>
          <th scope="col">OTHER DETAILS</th>
          <th scope="col">REQUESTED ON</th>
          <th scope="col">NEED BY</th>
          <th scope="col">URGENCY</th>
          <th scope="col">FORWARDED ON</th>
          <th scope="col">FORWARDED TO</th>
          <th scope="col">COMMENT</th>
        </tr>
      </thead>
      <tbody>
        @for (forward of forwardedReq; track $index) {
        <tr data-toggle="modal" data-target="#requestDetailModal">
          <td>
            <div class="d-flex align-items-center">
              <div class="inventory-img">
                <img [src]="forward.assetImage? forward.assetImage :  '../../../../assets/inventories/drone.png'" alt=""
                  width="60px" height="60px" />
              </div>
              <p>{{forward.assetName}}</p>
            </div>
          </td>
          <td>
            <p>{{forward.requestedBy}}</p>
          </td>
          <td class="project-name">
            <p>{{forward.approver}}</p>
          </td>
          <td>
            <p class="status-point">{{forward.status}}</p>
          </td>
          <td>
            <p>{{forward.serviceType}}</p>
          </td>
          <td>
            <p>{{forward.damage}}</p>
          </td>
          <td>
            <p class="other-details">
              {{forward.details}}
            </p>
          </td>
          <td>
            <p>{{forward.requestedOn}}</p>
          </td>
          <td>
            <p>{{forward.needBy}}</p>
          </td>
          <td>
            <p class="urgency">{{forward.urgency}}</p>
          </td>
          <td>
            <p>{{forward.forwardedOn}}</p>
          </td>
          <td>
            <p>{{forward.forwardedTo}}</p>
          </td>
          <td>
            <p class="other-details">
              {{forward.comment}}
            </p>
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
  }


  <!-- <button data-target="#allowRequestModal" data-toggle="modal">Allow</button> -->
</div>


<!-- Service Request Modal -->

<div class="modal fade" id="serviceRequestModal" tabindex="-1" role="dialog" aria-labelledby="addInventoryModalTitle"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered main-width-content" role="document">

    <div class="modal-content">
      <div class="modal-header addInventory-head">

        @if (!formSubmitted) {
        <h5 class="modal-title" id="addInventoryModalTitle">
          Create A Request Inventory
        </h5>
        }
        <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
          <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
            <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
          </svg>
        </button>
      </div>

      <div class="modal-body ">
        @if (!formSubmitted) {
        <div class="new-inventory-container">
          <form [formGroup]="serviceRequestForm" (ngSubmit)="onServiceReqSubmit()">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="assetCategory" class="form-label asterisk-lbl">Select Asset</label>

                <mat-form-field style="width: 378px!important;">
                  <div class="d-flex justify-content-between align-items-center">

                    <input type="text" placeholder="search Inventory" aria-label="Number" matInput
                      [formControl]="selectedInventoryControl" [matAutocomplete]="auto"
                      style="border: none;height: auto;border-radius:0px;padding-left:0px!important;"
                      [(ngModel)]="searchQuery" (keyup.enter)="onSearchInventory()">
                    @if(searchQuery){
                    <img class="search-close" style="cursor: pointer" src="../../../assets/closeIcon.png" alt=""
                      (click)="clearSearch()" />
                    }
                    @if(!searchQuery){
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                      <path fill="currentColor"
                        d="M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15a1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16" />
                    </svg>
                    }
                  </div>
                  <mat-autocomplete #auto="matAutocomplete" formControlName="selectedInventory" placeholder="select"
                    (optionSelected)="onInventoryChange($event)" [displayWith]="displayFn">

                    @for (inventories of inventory; track $index) {
                    <mat-option [value]="inventories">
                      <img
                        [src]="inventories.assetImage ? inventories.assetImage : '../../../assets/inventories/computer-system.jpg' "
                        alt="" style="width: 30px;height:30px;" /> {{inventories.viewValue}}
                    </mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="form-group col-md-4">
                <label for="needByDate" class="form-label asterisk-lbl">Need by Date</label>
                <input type="date" id="storage" class="form-control" id="needByDate" formControlName="needByDate" />
              </div>
              <div class="form-group col-md-4">
                <label for="damageType" class="form-label asterisk-lbl">Damage Type</label>
                <mat-form-field style="width: 378px!important;">
                  <mat-select formControlName="damageType" placeholder="select">
                    @for (damage of damages; track damage) {
                    <mat-option [value]="damage.value">{{damage.viewValue}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>

            </div>
            <div class="form-row">
              <div class="form-group col-md-4">

              </div>
              <div class="form-group col-md-8">
                <label for="detail">Mention in Details</label>
                <textarea name="" id="" rows="3" style=" width:777px" formControlName="detail"></textarea>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4"></div>
              <div class="form-group col-md-4">
                <label for="urgency" class="form-label asterisk-lbl">Urgency</label>

                <mat-form-field style="width: 378px!important;">
                  <mat-select formControlName="urgency" placeholder="select">
                    @for (urgency of urgencies; track urgency) {
                    <mat-option [value]="urgency.value">{{urgency.viewValue}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-group col-md-4">
                <label for="serviceType" class="form-label asterisk-lbl">Service Type</label>
                <mat-form-field style="width: 378px!important;">
                  <mat-select formControlName="serviceType" placeholder="select">
                    @for (service of services; track service) {
                    <mat-option [value]="service.value">{{service.viewValue}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-4"></div>
              <div class="form-group col-md-4">
                <label for="approverName" class="form-label asterisk-lbl">Select
                  Approver</label>
                <mat-form-field style="width: 378px!important;">
                  <mat-select formControlName="approverName" placeholder="select">
                    @for (user of users; track user) {
                    <mat-option [value]="user.value">{{user.viewValue}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <button type="submit" class="btn btn-warning" style="margin-top: 40px">
                Submit
              </button>
            </div>
          </form>
        </div>
        }

        @if (formSubmitted) {
        <div class="text-center" style="padding: 30px 0;">
          <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M37.7808 3.91014C46.0698 3.91014 54.0763 6.92354 60.3083 12.3889C66.5404 17.8543 70.573 25.3989 71.655 33.617C72.7369 41.8352 70.7943 50.1664 66.1892 57.0586C61.584 63.9507 54.6303 68.9336 46.6237 71.079C38.6171 73.2244 30.1035 72.3858 22.6693 68.7197C15.2351 65.0535 9.38711 58.8097 6.21502 51.1516C3.04292 43.4935 2.76302 34.9434 5.42745 27.0942C8.09189 19.245 13.5189 12.6321 20.6975 8.48759"
              stroke="#24BA61" stroke-width="6.40622" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.9683 38.0768L33.5099 46.6184L50.5932 29.5352" stroke="#24BA61" stroke-width="5.33852"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <h3 style="padding: 15px 0;font-weight: 600;">Request Submitted!</h3>
          <p style="font-weight: 600;padding-bottom: 30px;">Your request has been submitted, you’ll be
            notified when it gets<br>
            approved and for further steps
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <button class="btn btn-warning close" style="width: 210px; opacity: 1;
                  font-size: 16px;
                  height: 48px;" data-dismiss="modal" aria-label="Close">Okay</button>
          </div>
        </div>
        }
      </div>
    </div>

  </div>
</div>




<!-- Forward Service Request Modal -->
<div class="modal fade" id="forwardRequestModal" tabindex="-1" role="dialog" aria-labelledby="forwardRequestTitle"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered forward-req-width" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
          <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
            <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
          </svg>
        </button>
      </div>
      <div class="modal-body forward-req-container">
        @if (!showForwardSuccess) {
        <h1>Forward Service Request</h1>
        <div class="forward-container">
          <form [formGroup]="forwardRequestForm" (ngSubmit)="onSubmitForwardReq(selectedInventory)">
            <div class="form-row">
              <div class="form-group col-md-6 maximum-cost-input">
                <label for="maximumCost" class="form-label">Maximum Cost(₹)</label>
                <input type="number" id="maximumCost" class="form-control" formControlName="maximumCost" />
              </div>
              <div class="form-group col-md-6">
                <label for="forward" class="form-label">Forward to</label>
                <mat-form-field style="width:320px">
                  <mat-select formControlName="selectedAdmin" placeholder="select">
                    @for (adminUser of adminUsers; track adminUser) {
                    <mat-option [value]="adminUser.id">{{adminUser.viewValue}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 d-flex flex-column">
                <label for="comment">Comment</label>
                <textarea id="comment" placeholder="Add your comment" name="comment" rows="4" cols="50"
                  formControlName="comment">
                </textarea>
              </div>
            </div>

            <div class="approve-submit-button d-flex justify-content-center">
              <button type="submit" class="btn approve-submit">
                Submit For Approval
              </button>
            </div>
          </form>
        </div>
        }
        @if (showForwardSuccess) {
        <div class="sent-req-content">
          <svg class="tick-mark" width="76" height="76" viewBox="0 0 76 76" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M37.7808 3.91014C46.0698 3.91014 54.0763 6.92354 60.3083 12.3889C66.5404 17.8543 70.573 25.3989 71.655 33.617C72.7369 41.8352 70.7943 50.1664 66.1892 57.0586C61.584 63.9507 54.6303 68.9336 46.6237 71.079C38.6171 73.2244 30.1035 72.3858 22.6693 68.7197C15.2351 65.0535 9.38711 58.8097 6.21502 51.1516C3.04292 43.4935 2.76302 34.9434 5.42745 27.0942C8.09189 19.245 13.5189 12.6321 20.6975 8.48759"
              stroke="#24BA61" stroke-width="6.40622" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.9683 38.0768L33.5099 46.6184L50.5932 29.5352" stroke="#24BA61" stroke-width="5.33852"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <h3>Request Sent</h3>

          <p>
            Your request has been sent for approval, you will be <br />
            notified once it gets approved by superadmin
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <button class="btn btn-warning close" style="width: 210px; opacity: 1;
                  font-size: 16px;
                  height: 48px;" data-dismiss="modal" aria-label="Close">Okay</button>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>



<!-- Request Details Modal -->
<div class="modal fade" id="requestDetailModal" tabindex="-1" role="dialog" aria-labelledby="requestDetailTitle"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered req-dtl-width" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1>Request Details</h1>
        <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
          <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
            <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
          </svg>
        </button>
      </div>
      <div class="modal-body request-detail-container">
        <div class="req-dtl-content">
          <div class="req-top d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <div class="inventory-img">
                <img src="../../../../assets/inventories/drone.png" alt="" />
              </div>
              <p style="margin-left: 25px; width: 50%">
                DJI Mini 4 Pro Fly More Combo
              </p>
            </div>
            <div class="status">
              <h2>Status</h2>
              <p class="status-point">Forwarded for Approval</p>
            </div>
            <div class="status">
              <h2>Requested by</h2>
              <p>Anoop Kumar</p>
            </div>
            <div class="status">
              <h2>Date</h2>
              <p>June 18</p>
            </div>
            <div class="status">
              <h2>Requested to</h2>
              <p>Tanvi Singh</p>
            </div>
            <div class="status">
              <h2>Approved by</h2>
              <p>Mohd Shaizi</p>
            </div>
          </div>
          <div class="req-bottom">
            <h2>Comment by Mohd Shaizi</h2>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa
              cupiditate modi mollitia illo, explicabo ratione error odit vitae
              rem ullam. Lorem ipsum dolor sit amet consectetur, adipisicing
              elit. Voluptatem, vero consequuntur. Corrupti facere iusto quis.
            </p>
          </div>
        </div>
        <div class="approver-cont d-flex">
          <div class="approver">
            <h2>Approver</h2>
            <p>Manish Kakkar</p>
          </div>
          <div class="approver">
            <h2>Sent for Approval</h2>
            <p>June 22</p>
          </div>
          <div class="approver">
            <h2>Urgency</h2>
            <p>Urgent</p>
          </div>
          <div class="approver">
            <h2>Cost</h2>
            <p>25000</p>
          </div>
          <div class="approver">
            <h2>Status</h2>
            <p class="approver-point">Pending</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Allow Inventory Request Modal -->
<div class="modal fade" id="allowRequestModal" tabindex="-1" role="dialog" aria-labelledby="allowRequestTitle"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered forward-req-width" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 style="font-size: 26px;">Allow Inventory Request</h2>
        <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
          <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
            <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
          </svg>
        </button>
      </div>
      <div class="modal-body forward-req-container">
        <div class="sent-req-content">

          <img src="/assets/inventories/qr-code.png" alt="">

          <p>
            Please Scan the QR to continue
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Upload Asset Images Modal -->
<div class="modal fade" id="allowRequestModal" tabindex="-1" role="dialog" aria-labelledby="allowRequestTitle"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered forward-req-width" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 style="font-size: 26px;">Upload Asset Images</h2>
        <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
          <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
            <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
          </svg>
        </button>
      </div>
      <div class="modal-body forward-req-container">
        <div class="sent-req-content">

          <img src="/assets/inventories/qr-code.png" alt="">

          <p>
            Please Scan the QR to continue
          </p>
        </div>
      </div>
    </div>
  </div>
</div>