<!-- <div *ngIf="!hideHeader"> -->
<nav class="navbar navbar-expand-md navbar-light">
  <div class="header-first">
    <a routerLink="/home" class="navbar-brand">
      <img
        src="../../../assets/Group 185-copy.png"
        class="img-fluid Header-company-logo"
        alt="Header-company-logo"
      />
    </a>
    <button
      class="navbar-toggler navbar-toggler-right"
      data-toggle="collapse"
      data-target="#navbarMenu"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
  <div class="collapse navbar-collapse" id="navbarMenu">
    <ul class="navbar-nav mr-auto w-100 navbarGap">
      <li class="nav-item" [class.disabled-div]="playAccess">
        <!-- disabled-div -->

        <a
          routerLink="/collection"
          class="nav-link"
          (click)="onClickNavTab($event)"
          routerLinkActive="active-link"
          >Collections</a
        >
      </li>
      <li class="nav-item" [class.disabled-div]="playAccess">
        <a routerLink="/upload" class="nav-link" disabled>Archive</a>
      </li>
      <li class="nav-item" [class.disabled-div]="playAccess">
        <a
          [ngClass]="isAdminSales ? '' : 'pointer-event-none'"
          routerLink="/crm/leads"
          class="nav-link"
          routerLinkActive="active-link"
          disabled
          >CRM</a
        >
      </li>
      @if (username == 'admin') {
      <li class="nav-item">
        <a
          routerLink="/access"
          class="nav-link"
          (click)="onClickNavTab($event)"
          routerLinkActive="active-link"
          >Users</a
        >
      </li>
      } @if (username == 'admin') {
      <li class="nav-item">
        <a
          routerLink="/feedback"
          class="nav-link"
          (click)="onClickNavTab($event)"
          routerLinkActive="active-link"
          >Feedbacks</a
        >
      </li>
      } @if (username == 'admin') {
      <li class="nav-item">
        <a
          routerLink="/feedback"
          class="nav-link"
          (click)="onClickNavTab($event)"
          routerLinkActive="active-link"
          >Feedbacks</a
        >
      </li>
      }
    </ul>
  </div>
  @if (router.url != '/video-call') {
  <div
    class="dropdown"
    class="online-users"
    (click)="getOnlineUsers()"
    id="onlineUser"
  >
    <div
      class="dropdown-toggle"
      type="button"
      data-toggle="dropdown"
      aria-expanded="false"
    >
      Online Users :
      <span>{{ onlineUserData ? onlineUserData.length : 0 }}</span>
    </div>
    <div class="dropdown-menu">
      @for (i of onlineUserData; track i) {
      <div class="dropdown-item">
        <img
          class="rounded-circle"
          [src]="i.profileImg || 'assets/user-online.png'"
          alt=""
          style="width: 40px"
        />
        <span class="mx-3">{{ i.userName }}</span>
      </div>
      }
    </div>
  </div>
  }

  <form class="form-inline" id="formDis">
    <!-- <button class="btn btn-outline-warning my-2 my-sm-0" data-toggle="modal"
                  data-target="#exampleModal">Logout</button> -->
    <div class="btn-group">
      <img
        [src]="profileImg"
        class="dropdown-toggle"
        data-toggle="dropdown"
        style="
          height: 60px;
          width: 60px;
          border-radius: 50%;
          border: 5px solid #fecc28;
          object-fit: cover;
        "
        alt="dp"
      />
      <div class="dropdown-menu">
        <span class="dropdown-item-text" style="text-transform: capitalize"
          >Hi {{ username }}!</span
        >
        <div class="dropdown-divider"></div>
        <div class="dropdown-item">
          <button
            class="btn btn-outline-warning test"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  </form>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Do you want to sign out?
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-danger"
            data-dismiss="modal"
            (click)="onClickLogout()"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>
<div class="header-line"></div>
<!-- </div> -->

<!-- Modal -->
<div
  class="modal fade"
  id="showOnlineUserModal"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">...</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button type="button" class="btn btn-primary">Understood</button>
      </div>
    </div>
  </div>
</div>
