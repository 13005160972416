import { Component } from "@angular/core";
import Channel from "converse.svc-client";
import { ChatService } from "src/app/service/chat.service";
import Swal from "sweetalert2";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-new-group-chat",
  templateUrl: "./new-group-chat.component.html",
  styleUrls: ["./new-group-chat.component.css"],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, DatePipe],
})
export class NewGroupChatComponent {
  channel_id: any = "653a07521e843a9bec8a486e";
  user_id: any = localStorage.getItem("guestID");
  ephemeral = false;
  showChatBox = false;
  AllMessages: any = {};
  project_id: any;
  channel!: any;
  allUsersData: any;
  selectedName: any;
  chatBody: any;
  sendFile: any;
  isLoading: boolean = false;
  groupChatMessages: any[] = [];
  groupedMessages: { date: Date; messages: any[] }[] = [];
  previousTimestamp: Date | null = null;
  previousSenderName: string | null = null;
  newMessage: string = "";
  profileImageMapping = {};
  ngOnInit(): void {
    this.chatInit(0, 0);
  }

  ngAfterViewInit() {}

  constructor(private chatService: ChatService) {}

  userId: any;
  conversationStart: any;
  async chatInit(receiverId: any, index: any) {
    this.userId = localStorage.getItem("guestID");
    this.selectedName = index.firstName;
    this.conversationStart = receiverId;
    this.chatService.getUsers().subscribe((users: any) => {
      users.data.forEach((user: any) => {
        this.profileImageMapping[user._id] = user.profileImg;
      });
    });
    await this.init();
    await this.getAllChats();
  }
  async init() {
    let token = await this.initChannel();
    await this.channel.connect({
      token,
      projectId: "project_link_module",
      fcmToken: localStorage.getItem("fcmToken"),
    });
    this.inComingListnerMessages();
  }
  async initChannel() {
    this.channel = new Channel(
      (this.channel_id = "653a07521e843a9bec8a486e"),
      this.ephemeral,
      50
    );
    const tokenNew = localStorage.getItem("guestToken");

    let { token } = await this.channel.init({
      token: tokenNew,
      project_id: "project_link_module",
    });

    if (token) {
      localStorage.setItem("conversation-token", token);
    }

    return token;
  }

  // isURL(str: string): boolean {
  //   const urlRegex =
  //     /^(?:(?:(?:https?|ftp):)?\/\/)?[\w/\-?=%.]+\.[\w/\-?=%.]+$/;
  //   return urlRegex.test(str);
  // }

  async sendMessage() {
    try {
      await this.channel.send({
        message: this.newMessage,
        sender_id: this.user_id,
        createdAt: new Date(),
        meta: { videoUploaded: true },
        file: this.sendFile ? this.sendFile : "",
      });
      this.newMessage = "";
      this.sendFile = null;
      this.scrollToBottom();
    } catch (error) {
      this.isLoading = false;
      Swal.fire(
        "Server Error",
        "Please ask to backend developer to fix this",
        "error"
      );
    }
  }
  users: any[];

  showChat() {
    this.showChatBox = !this.showChatBox;

    setTimeout(() => {
      this.scrollToBottom();
    }, 10);
  }

  async getAllChats() {
    let result = await this.channel.getMessages();

    this.AllMessages["read"] = result?.msgs?.read || [];
    this.AllMessages["unread"] = result?.msgs?.unread || [];
    this.groupChatMessages = [
      ...this.AllMessages["unread"],
      ...this.AllMessages["read"],
    ];

    this.groupChatMessages.forEach((message) => {
      message["profileImage"] = this.profileImageMapping[message["sender_id"]];
    });
    this.groupChatMessages.reverse();

    this.groupMessagesByDate();
    setTimeout(() => {
      this.scrollToBottom();
    }, 10);
  }
  groupMessagesByDate(): void {
    const messageMap = new Map<string, any[]>();

    // Group messages by date
    this.groupChatMessages.forEach((message) => {
      const date = new Date(message.createdAt).toDateString();
      if (!messageMap.has(date)) {
        messageMap.set(date, []);
      }
      messageMap.get(date).push(message);
    });

    // Convert the map to an array for template rendering
    this.groupedMessages = [];
    messageMap.forEach((messages, dateString) => {
      this.groupedMessages.push({
        date: new Date(dateString),
        messages: messages,
      });
    });

    // Sort grouped messages by date
    this.groupedMessages.sort((a, b) => b.date.getTime() - a.date.getTime());

    // Reverse the grouped messages array
    this.groupedMessages.reverse();
  }
  isDifferentSender(currentMessage: any): boolean {
    if (this.previousSenderName === currentMessage.name) {
      return false;
    } else {
      // Update the previous sender's name
      this.previousSenderName = currentMessage.name;
      return true;
    }
  }
  isDifferentTimestamp(currentMessage: any): boolean {
    const currentTimestamp = new Date(currentMessage.createdAt);
    if (
      this.previousTimestamp &&
      currentTimestamp.getMinutes() === this.previousTimestamp.getMinutes()
    ) {
      return false;
    } else {
      // Update the previous timestamp
      this.previousTimestamp = currentTimestamp;
      return true;
    }
  }

  inComingListnerMessages() {
    try {
      this.channel.listen((msg) => {
        let newMessage = {
          ...msg,
          sender_id: msg.sid,
          createdAt: new Date(),
          meta: { videoUploaded: true },
          url: msg.url,
          profileImage: this.profileImageMapping[msg.sid],
        };
        if (newMessage.meta.videoUploaded === true) {
          this.isLoading = false;
          this.scrollToBottom();
        }
        this.pushRecentMessage(newMessage);
        this.scrollToBottom();
      });
    } catch (e) {
      console.log(e);
      this.isLoading = false;
      Swal.fire("Error", "Server error", "error");
    }
  }

  pushRecentMessage(newMessage: any): void {
    const newDate = new Date(newMessage.createdAt).toDateString();

    // Check if the date already exists in the grouped messages
    const existingDateIndex = this.groupedMessages.findIndex(
      (group) => group.date.toDateString() === newDate
    );

    if (existingDateIndex > -1) {
      // Add the new message to the existing group
      this.groupedMessages[existingDateIndex].messages.push(newMessage);
    } else {
      // Create a new group for the new date
      const newGroup = {
        date: new Date(newDate),
        messages: [newMessage],
      };
      this.groupedMessages.push(newGroup);
    }

    // Sort grouped messages by date
    this.groupedMessages.sort((a, b) => b.date.getTime() - a.date.getTime());

    // Reverse the grouped messages array if needed
    this.groupedMessages.reverse();

    // Scroll to the bottom after adding the new message
    this.scrollToBottom();
  }
  scrollToBottom() {
    this.chatBody = document.querySelector(".chat-messages");
    setTimeout(() => {
      try {
        // Use optional chaining to handle possible 'undefined'
        this.chatBody?.scrollTo({
          top: this.chatBody.scrollHeight,
          behavior: "instant",
        });
      } catch (err) {}
    }, 10);
  }
  isImage(url: string): boolean {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  }

  isDocument(url: string): boolean {
    return /\.(doc|docx|pdf|zip|xml|rar)$/i.test(url);
  }

  isVideo(url: string): boolean {
    return /\.(mp4|webm)$/i.test(url);
  }

  fileSizeExceedsLimit: boolean = false;
  handleFileInput(event: any): void {
    const file = event.target.files[0];
    console.log(file);
    this.scrollToBottom();
    //file size limit upto 300 mb
    const fileSizeLimit = 300 * 1024 * 1024; // 300MB in bytes
    if (file && file.size > fileSizeLimit) {
      // alert(
      //   "File size exceeds the limit of 300MB. Please choose a smaller file."
      // );
      setTimeout(() => {
        this.fileSizeExceedsLimit = false;
      }, 5000);
      return;
    }
    console.log(file);

    this.isLoading = true;

    if (file) {
      this.sendFile = file;
      const fileName = { fileName: file.name };
      const fileMessage = {
        sender_id: this.userId,
        creationTime: new Date(),
        file: file ? file : "",
        fileName: file.name, // Add the file name to the message
        meta: { videoUploaded: true },
      };
      this.groupChatMessages.push(fileMessage);
      // Push the merged messages into the messages array
      this.sendMessage();
      console.log(this.groupChatMessages);
    }
  }

  convertFileToBase64(file: File): void {
    this.isLoading = true;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const base64String = e.target.result.split(",")[1];
      // Now you can use the base64String as needed, for example, send it to your server.
      this.sendFile = base64String;
      this.isLoading = false;
    };

    reader.readAsDataURL(file);
  }

  handelKeyDown(event: KeyboardEvent): void {
    if (event.ctrlKey && event.shiftKey && event.key === "Enter") {
      // Insert new line
      this.newMessage += "\n";
    }
  }
}
