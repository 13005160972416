import { JwtResponse, UserData } from "../component/login/login.component";

export interface LoginState {
  userData: UserData | null;
  errorMessage: string | null;
  jwtResponse: JwtResponse | null;
  body: any | null;
}

export const initialLoginState: LoginState = {
  userData: null,
  errorMessage: null,
  jwtResponse: null,
  body: "0000000",
};


// leads.state.ts
export interface LeadsState {
  selectedSearchIICUser: string;
  selectedSearchUserId: string;
  selectedSearchStatus: string;
}

export const initialLeadsState: LeadsState = {
  selectedSearchIICUser: '',
  selectedSearchUserId: '',
  selectedSearchStatus: '',
};

