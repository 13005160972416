<!-- <div id="container">
<aside>
  <header>
    <input type="text" placeholder="Search" [(ngModel)]="users" (input)="filterUser()">
  </header>
  <ul class="pl-3 cursorPointer">
    <li *ngFor="let user of allUsersData, let i as index" (click)="chatInit(user._id,i)">

    </li>
  </ul>
</aside>
<main>
  <header>

    <div>
      <h2>{{selectedName}} Groups Common</h2>
      <h3>Already {{messages.length}} messages</h3>
    </div>
    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_star.png" alt="">
  </header>
  <ul id="chat">
    <li *ngFor="let message of messages"
      [ngClass]="{'you': message.sender_id === userId, 'me': message.sender_id !== userId}">

      <div class="entete">
        <span
        [ngClass]="{'status': true, 'green': message.status === 'online', 'blue': message.status === 'offline'}"></span>
        <h2 *ngIf="userId ===message.sender_id">You, </h2>
        <h2 *ngIf="userId !==message.sender_id">{{message.name}}, </h2>
        <h3 class="pl-2">{{ message.createdAt | date: 'hh:mm a' }}</h3>

      </div>
      <div class="triangle"></div>
      <div class="message">{{message.message}}</div>
    </li>
  </ul>
  <footer class="d-flex">
    <textarea placeholder="Type your message" [(ngModel)]="newMessage"></textarea>

    <button (click)="sendMessage()" class="btn btn-primary">Send</button>
  </footer>
</main>
</div> -->

<!-- <div class="chatbox-holder">
<div class="chatbox">
  <div class="chatbox-top">
    <div class="chatbox-avatar">
      <a target="_blank" href="https://www.facebook.com/mfreak"><img
      src="https://gravatar.com/avatar/2449e413ade8b0c72d0a15d153febdeb?s=512&d=https://codepen.io/assets/avatars/user-avatar-512x512-6e240cf350d2f1cc07c2bed234c3a3bb5f1b237023c204c782622e80d6b212ba.png" /></a>
    </div>
    <div class="chat-partner-name">
      <span class="status online"></span>
      <a target="_blank" href="https://www.facebook.com/mfreak">Mamun Khandaker</a>
    </div>
    <div class="chatbox-icons">
      <a href="javascript:void(0);"><i class="fa fa-minus"></i></a>
      <a href="javascript:void(0);"><i class="fa fa-close"></i></a>
    </div>
  </div>

  <div class="chat-messages">
    <div class="message-box-holder">
      <div class="message-box">
        Hello
      </div>
    </div>

    <div class="message-box-holder">
      <div class="message-sender">
        Mamun Khandaker
      </div>
      <div class="message-box message-partner">
        Hi.
      </div>
    </div>

    <div class="message-box-holder">
      <div class="message-box">
        How are you doing?
      </div>
    </div>

    <div class="message-box-holder">
      <div class="message-sender">
        Mamun Khandaker
      </div>
      <div class="message-box message-partner">
        I'm doing fine. How about you?
      </div>
    </div>

    <div class="message-box-holder">
      <div class="message-box">
        I am fine.
      </div>
    </div>

    <div class="message-box-holder">
      <div class="message-box">
        Do you know why I knocked you today?
      </div>
    </div>

    <div class="message-box-holder">
      <div class="message-box">
        There's something important I would like to share with you. Do you have some time?
      </div>
    </div>

    <div class="message-box-holder">
      <div class="message-sender">
        Mamun Khandaker
      </div>
      <div class="message-box message-partner">
        Yeah sure. Let's meet in the Einstein cafe this evening and discuss the matter.
      </div>
    </div>

    <div class="message-box-holder">
      <div class="message-sender">
        Mamun Khandaker
      </div>
      <div class="message-box message-partner">
        I thought of coming to your place and discuss about it but I had to finish my projects and I didn't
        have enough time to go out of the house.
      </div>
    </div>
  </div>

  <div class="chat-input-holder">
    <textarea class="chat-input"></textarea>
    <input type="submit" value="Send" class="message-send" />
  </div>
  <div class="attachment-panel">
    <a href="#" class="fa fa-thumbs-up"></a>
    <a href="#" class="fa fa-camera"></a>
    <a href="#" class="fa fa-video-camera"></a>
    <a href="#" class="fa fa-image"></a>
    <a href="#" class="fa fa-paperclip"></a>
    <a href="#" class="fa fa-link"></a>
    <a href="#" class="fa fa-trash-o"></a>
    <a href="#" class="fa fa-search"></a>
  </div>
</div>

<div class="chatbox group-chat">
  <div class="chatbox-top">
    <div class="chatbox-avatar">
      <a target="_blank" href="https://www.facebook.com/mfreak"><img
      src="https://avatars0.githubusercontent.com/u/7145968?v=3&s=460" /></a>
    </div>

    <div class="chat-group-name">
      <span class="status away"></span>
      Group name comes here
    </div>
    <div class="chatbox-icons">
      <label for="chkSettings"><i class="fa fa-gear"></i></label><input type="checkbox" id="chkSettings" />
      <div class="settings-popup">
        <ul>
          <li><a href="#">Group members</a></li>
          <li><a href="#">Add members</a></li>
          <li><a href="#">Delete members</a></li>
          <li><a href="#">Leave group</a></li>
        </ul>
      </div>
      <a href="javascript:void(0);"><i class="fa fa-minus"></i></a>
      <a href="javascript:void(0);"><i class="fa fa-close"></i></a>
    </div>
  </div>

  <div class="chat-messages">
    <div *ngFor="let message of messages" style="display: contents;">
      <div class="message-box-holder" *ngIf="userId === message.sender_id">
        <div class="message-box">
          {{message.message}}
        </div>
      </div>

      <div class="message-box-holder" *ngIf="userId !== message.sender_id">
        <div class="message-sender">
          <a href="#">{{message.name}}</a>
        </div>
        <div class="message-box message-partner">
          {{message.message}}
        </div>
      </div>
    </div>
  </div>
  <div class="chat-input-holder">
    <textarea class="chat-input" [(ngModel)]="newMessage"></textarea>
    <input type="submit" value="Send" class="message-send" [(ngModel)]="newMessage" (click)="sendMessage()" />
  </div>

  <div class="attachment-panel">
    <a href="#" class="fa fa-thumbs-up"></a>
    <a href="#" class="fa fa-camera"></a>
    <a href="#" class="fa fa-video-camera"></a>
    <a href="#" class="fa fa-image"></a>
    <a href="#" class="fa fa-paperclip"></a>
    <a href="#" class="fa fa-link"></a>
    <a href="#" class="fa fa-trash-o"></a>
    <a href="#" class="fa fa-search"></a>
  </div>
</div>


</div> -->


<div class="chatbox-holder">
  <div class="chatbox group-chat chatbox1">
    <div class="chatbox-top chatbox-top1">
      <div class="chat-group-name chat-group-name1">
        <!-- <span class="status away"></span> -->
        <h5>Square pixels official</h5>
      </div>
      <div class="chatbox-icons chatbox-icons1">
        <!-- <label for="chkSettings"><i class="fa fa-gear"></i></label><input type="checkbox" id="chkSettings" /> -->
        <div class="settings-popup">
          <ul>
            <li><a href="#">Group members</a></li>
            <li><a href="#">Add members</a></li>
            <li><a href="#">Delete members</a></li>
            <li><a href="#">Leave group</a></li>
          </ul>
        </div>
        <a href="javascript:void(0);"><i class="fa fa-minus"></i></a>
        <!-- <a href="javascript:void(0);"><i class="fa fa-close"></i></a> -->
        <button type="button" class="close fa fa-close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <!-- <div class="p-2">

    <hr>

      <input type="text" class="form-control" placeholder="search in chats">
    </div> -->

    <div class="chat-messages">
      @for (message of messages; track message) {
        <div style="display: contents;">
          @if (userId === message.sender_id) {
            <div class="message-box-holder">
              <div class="message-box">
                {{message.message}}
                <span>{{ message.createdAt | date: 'hh:mm a' }}</span>
              </div>
            </div>
          }
          @if (userId !== message.sender_id) {
            <div class="message-box-holder">
              <div class="message-sender">
                <a class="senderName">{{message.name}}</a>
              </div>
              <div class="message-box message-partner">
                {{message.message}}
                <span>{{ message.createdAt | date: 'hh:mm a' }}</span>
              </div>
            </div>
          }
        </div>
      }
    </div>

    <div>
      <div class="chat-input-holder">
        <textarea class="chat-input" [(ngModel)]="newMessage" placeholder="Type a message"></textarea>
        <!-- <input type="submit" value="Send" class="message-send" (click)="sendMessage()" /> -->
        <div class="cursorPointer">
          <img src="../../../assets/sendMessage.png" class="message-send message-send1"
            (click)="sendMessage()">
        </div>
      </div>
    </div>

    <!-- <div class="attachment-panel">
    <a href="#" class="fa fa-thumbs-up"></a>
    <a href="#" class="fa fa-camera"></a>
    <a href="#" class="fa fa-video-camera"></a>
    <a href="#" class="fa fa-image"></a>
    <a href="#" class="fa fa-paperclip"></a>
    <a href="#" class="fa fa-link"></a>
    <a href="#" class="fa fa-trash-o"></a>
    <a href="#" class="fa fa-search"></a>
  </div> -->

</div>


</div>