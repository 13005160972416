import { enableProdMode, importProvidersFrom } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { environment } from "./environments/environment";
import { AppComponent } from "./app/app.component";
import {
  uploadReducer,
  loginReducer,
  counterReducer,
  leadsReducer,
} from "./app/store/counter.reducer";
import { StoreModule } from "@ngrx/store";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { NgxFileDropModule } from "ngx-file-drop";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { MatDialogModule } from "@angular/material/dialog";
import { provideAnimations } from "@angular/platform-browser/animations";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app/app-routing.module";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { NativeDateAdapter } from "@angular/material/core";
import { DataFilterService } from "./app/data-filter.service";
import { ChatService } from "./app/service/chat.service";
import { AsyncPipe } from "../node_modules/@angular/common";
import { LoaderInterceptor } from "./app/service/loader.interceptor";
import { SAVER, getSaver } from "./app/saver.provider";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { AuthInterceptor } from "./app/service/auth.intercepter";
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
} from "@angular/common/http";
if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      ReactiveFormsModule,
      SlickCarouselModule,
      InfiniteScrollModule,
      FormsModule,
      NgxDaterangepickerMd.forRoot(),
      MatDialogModule,
      AutocompleteLibModule,
      NgxFileDropModule,
      AutocompleteLibModule,
      NgxDocViewerModule,
      MatProgressBarModule,
      MatButtonModule,
      MatDatepickerModule,
      MatFormFieldModule,
      StoreModule.forRoot({ body: uploadReducer }),
      StoreModule.forRoot({
        login: loginReducer,
      }),
      StoreModule.forRoot({ count: counterReducer }),
      StoreModule.forRoot({ leads: leadsReducer })
    ),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: SAVER, useFactory: getSaver },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    AsyncPipe,
    ChatService,
    DataFilterService,
    NativeDateAdapter,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
