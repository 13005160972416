import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  private subject = new Subject<any>();

  sendAlert(message: string) {
    this.subject.next({ alert: message });
}

onAlert(): Observable<any> {
  return this.subject.asObservable();
}

  constructor() { }
}
