import { Component, OnDestroy, OnInit } from "@angular/core";
import { LiveProjectService } from "src/app/service/live-project.service";
import { UserService } from "src/app/service/user.service";
import { ChatService } from "src/app/service/chat.service";
import { Router } from "@angular/router";
import { NgxPaginationModule } from "ngx-pagination";
import { NgClass } from "@angular/common";
import { HeaderComponent } from "../header/header.component";

@Component({
    selector: "app-notification",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.css"],
    standalone: true,
    imports: [
    HeaderComponent,
    NgClass,
    NgxPaginationModule
],
})
export class NotificationComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private chatService: ChatService,
    private liveProjectService: LiveProjectService,
    private userService: UserService
  ) {
    // this.liveProjectService.getInstantNotifications(this.limit,this.instantp).subscribe(resp=>{
    //   console.log("instant pagination meeting",resp);
    //   this.instantTotal=<any>resp['total'];
    //   this.instantMeetings=resp['instant'];
    // })
    //   this.liveProjectService.getScheduledNotifications().subscribe(resp=>{
    //     console.log("scheduled pagination meeting",resp);
    //     this.scheduledTotal=resp['total'];
    //     this.scheduledMeetings=resp['scheduled'];
    //   })
  }

  notifications;
  instantMeetings;
  scheduledMeetings;

  instantp: number = 1;
  instantTotal;

  scheduledp: number = 1;
  scheduledTotal;

  limit = 10;

  ngOnInit(): void {
    // var all_meetings = [];
    // all_meetings = all_meetings.concat(this.instantMeetings);
    // all_meetings = all_meetings.concat(this.scheduledMeetings);
    // this.deleteEndCallChatroom(all_meetings)

    this.liveProjectService.getNotifications().subscribe((resp) => {
      console.log(resp);
      this.instantMeetings = resp["instant"];
      this.scheduledMeetings = resp["scheduled"];

      // console.log(this.instantMeetings);
      // console.log(this.scheduledMeetings);
      var all_meetings = [];
      all_meetings = all_meetings.concat(this.instantMeetings);
      all_meetings = all_meetings.concat(this.scheduledMeetings);
    });
  }

  onClickJoinMeeting(notify) {
    console.log(notify.participants);
    console.log("notify--->", notify);
    let objArr = notify.participants.filter(
      (item) => item["_id"] == localStorage.getItem("guestID")
    );

    console.log(objArr[0]);
    // localStorage.setItem("meetingLink",objArr[0].url);

    if (notify.type == "instant") {
      let newData = { contents: objArr[0].url, ...notify };
      let participantsArr = [];
      for (let i = 0; i < notify.participants.length; i++) {
        participantsArr.push(notify.participants[i].username);
      }
      let participantsArrStr = participantsArr.join();
      newData = { usernames: participantsArrStr, ...newData };

      this.liveProjectService.activeInstantMeeting = newData;
    } else if (notify.type == "scheduled") {
      let newData = { contents: objArr[0].url, ...notify };
      let participantsArr = [];
      for (let i = 0; i < notify.participants.length; i++) {
        participantsArr.push(notify.participants[i].username);
      }
      let participantsArrStr = participantsArr.join();
      newData = { usernames: participantsArrStr, ...newData };

      this.liveProjectService.activeInstantMeeting = newData;
    }
  }

  onClickTabs(event) {
    let col = document.querySelector(".col");
    let tabs: any = col.querySelectorAll(".nav-link");
    let lists: any = col.querySelectorAll(".list-grp");

    for (let i = 0; i < tabs.length; i++) {
      if (event.target.innerText == tabs[i].innerText) {
        tabs[i].classList.add("active");
        lists[i].classList.remove("hidden");
      } else {
        tabs[i].classList.remove("active");
        lists[i].classList.add("hidden");
      }
    }
  }

  convertDate(inputDate) {
    let date = new Date(inputDate);
    return (
      date.toLocaleDateString("en-GB", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }) +
      " at " +
      date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    );
  }

  missedCall(notify) {
    let user = notify.participants.filter((x) => {
      return x._id == localStorage.getItem("guestID");
    });
    return notify.ended && !user[0].joined;
  }

  async pageChangedInstant(event) {
    console.log("event triggered pagination", event);
    this.instantp = event;
    // if(this.instantp>this.maxinstantp){
    //   for(let i=2;i<=this.instantp;i++){
    //     await this.liveProjectService.getInstantNotificationsAsync(this.limit,i).then(resp=>{
    //       console.log("async" ,resp)
    //       this.instantMeetings.push(...(<any>resp['instant']));
    //       console.log(`resp at ${i} :-`,resp,);
    //     })
    //     this.maxinstantp=this.instantp;
    // }
    // }
  }

  async pageChangedScheduled(event) {
    console.log("event triggered pagination", event);
    this.scheduledp = event;
  }

  // pageChange(event){
  //   this.p=event;

  // }

  ngOnDestroy(): void {
    console.log("Notification component destroyed");
  }
}
