// counter.selectors.ts
import { createSelector, select, Store ,createFeatureSelector } from "@ngrx/store";
import { LoginState } from "src/app/store/counter.state";
import { LeadsState } from './counter.state';


export const selectLoginState = (state: { login: LoginState }) => state.login;

export const selectUsername = createSelector(
  selectLoginState,
  (loginState: LoginState) => loginState.userData?.username
);



const getLeadsState = createFeatureSelector<LeadsState>('leads');

export const getSelectedSearchIICUser = createSelector(
  getLeadsState,
  (state: LeadsState) => state.selectedSearchIICUser
);

export const getSelectedSearchUserId = createSelector(
  getLeadsState,
  (state: LeadsState) => state.selectedSearchUserId
);

export const getSelectedSearchStatus = createSelector(
  getLeadsState,
  (state: LeadsState) => state.selectedSearchStatus
);
