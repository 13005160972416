<nav class="navbar navbar-expand-md navbar-light">
  <div class="header-first">
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="30" fill="currentColor"
      class="bi bi-arrow-left cursorPointer fw" viewBox="0 0 16 16" (click)="goback()">
      <path fill-rule="evenodd"
        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
    </svg>
    <a routerLink="/home" href="javascript:void(0)" class="navbar-brand">
      <img src="../../../assets/Group 185-copy.png" class="img-fluid Header-company-logo" alt="Header-company-logo" />
    </a>
  </div>
</nav>
<div class="header-line"></div>

<div class="container-fluid w-96">
  <div class="row">
    <div class="col-6 pb-2">
      <div class="media">
        <div class="videoHeight">
          @if (videoUrl_lg && videoImageToggle) {
          <video [src]="videoUrl_lg" class="embed-responsive embed-responsive-16by9" id="video" autoplay
            [controls]="true" style="
              width: 100%;
              height: 403px;
              object-fit: contain !important;
              background-color: black;
              object-fit: fill;
            " controlsList="nodownload" preload="auto"></video>
          }
          @if (!videoImageToggle && thumbnail[0]) {
          <img class="imgHeight" [src]="thumbnail[0]" width="100%" height="400px"
            style="object-fit: contain !important; background-color: black" />
          }
          @if (!videoImageToggle && videoUrl_lg) {
          <img (click)="videoPlay()" class="playCenter" src="../../../assets/Subtract.png" />
          }
        </div>
        @if (image_Url) {
        <img class="imgHeight" id="image" class="img-thumbnail" [src]="image_Url"
          style="height: -webkit-fill-available; object-fit: contain !important" />
        }
        <!-- <ngx-doc-viewer id="preview-pdf" [url]="pdfUrl" viewer="google" height="560px" width="700px"
        style="width: 700px;height: 350px;" [style.display]="pdfDisplay">
      </ngx-doc-viewer> -->
        <div class="default-preview" style="margin-top: 80px">
          <img [src]="defaultThumbnail" />
        </div>
        @if (videoUrl_lg) {
        <div id="download-file" (click)="removeDownload()">
          <div class="centerMedia">
            <h4>Download Media</h4>
            <div class="verticalLine"></div>
            @if (videoUrl_lg && !videoUrl_md) {
            <div class="d-flex justify-content-between">
              @if (videoUrl_lg) {
              <div class="cursorPointer m-auto" (click)="handleResolution('High', 'highDiv')" [ngStyle]="{
                  'background-color': activeDiv === 'highDiv' ? '#ffc107' : '',
                  height: '46px'
                }" #highDiv>
                <p class="high">Original Resolution</p>
              </div>
              }
            </div>
            }
            @if (videoUrl_md) {
            <div class="d-flex justify-content-around">
              <!-- <div class="cursorPointer" (click)="handleResolution('Whatsapp','medDiv')"
                [ngStyle]="{'background-color': activeDiv === 'medDiv' ? '#ffc107' : '','height': '46px'}"
                #medDiv>
                <p class="low">Low Resolution</p>
              </div> -->
              <div class="cursorPointer" (click)="handleResolution('High', 'highDiv')" [ngStyle]="{
                  'background-color': activeDiv === 'highDiv' ? '#ffc107' : '',
                  height: '46px'
                }" #highDiv>
                <p class="high">Original Resolution</p>
              </div>
              <div class="cursorPointer" (click)="handleResolution('Low', 'lowDiv')" [ngStyle]="{
                  'background-color': activeDiv === 'lowDiv' ? '#ffc107' : '',
                  height: '46px'
                }" #lowDiv>
                <p class="whatsapp">Medium Resolution</p>
              </div>
            </div>
            }
          </div>
        </div>
        }
        <div class="d-flex justify-content-between">
          <!-- <p class="pt-3 d-flex fileTruncate">{{ fileNameBased }}</p> -->
          @if (isResolutionVisible) {
          <div class="d-flex mt-2">
            <div class="cursorPointer" (click)="watermark()" [ngStyle]="{
                'background-color':
                  activediv === 'watermarkDiv' ? '#ffc107' : '',
                height: activediv === 'watermarkDiv' ? '43px' : ''
              }" #watermarkDiv>
              <p class="watermark">Watermark</p>
            </div>
            <div class="cursorPointer" (click)="Withoutwatermark()" #withoutWatermarkDiv>
              <p class="Withoutwatermark ml-2" [ngStyle]="{
                  'background-color':
                    activediv === 'withoutWatermarkDiv' ? '#ffc107' : '',
                  height: activediv === 'withoutWatermarkDiv' ? '43px' : ''
                }">
                Without Watermark
              </p>
            </div>
          </div>
          }
        </div>
      </div>
      @if (isDownLoad) {
      <progress id="downloadProgress" value="0" max="100" style="width: inherit; margin-top: 20px"></progress>
      }

      <!-- <div id="progress-bar" style="display: none">
  <div class="progress-bar"></div>
</div>
<div *ngIf="isDownloading">
  <p>Download in progress...</p>
  <div class="progress">
    <div
      id="progress-bar"
      class="progress-bar progress-bar-striped progress-bar-animated"
      [style.width]="downloadProgress + '%'"
      >
      {{ downloadProgress }}%
    </div>
  </div>
</div> -->

      <div class="pt-2 pb-3">
        <div class="float-right">
          <!-- <img
    title="download files"
    *ngIf="videoUrl_lg"
    class="cursorPointer"
    src="../../../assets/Group 55/Group 55.png"
    (click)="downloadOption('video')"
    /> -->
          <!-- <img
    title="download files"
    *ngIf="!videoUrl_lg && cardData.extension == '.xlsx'"
    class="cursorPointer"
    src="../../../assets/Group 55/Group 55.png"
    (click)="downloadOption('excel')"
    />
  <img
    title="download files"
    *ngIf="!videoUrl_lg && cardData.extension == '.pdf'"
    class="cursorPointer"
    src="../../../assets/Group 55/Group 55.png"
    (click)="downloadOption('pdf')"
    />
  <img
    title="download files"
    *ngIf="!videoUrl_lg && cardData.extension == '.pptx'"
    class="cursorPointer"
    src="../../../assets/Group 55/Group 55.png"
    (click)="downloadOption('pptx')"
    />
  <img
    title="download files"
            *ngIf="
              (!videoUrl_lg && cardData.extension == '.png') ||
              cardData.extension == '.jpeg' ||
              cardData.extension == '.jpg'
            "
    class="cursorPointer"
    src="../../../assets/Group 55/Group 55.png"
    (click)="downloadOption('image')"
    /> -->
        </div>
        <!-- <div class="float-right d-none" id="float-right">

  <button type="button" class="btn  savePlaylist" data-toggle="modal"
    data-target="#exampleModalCenter">
  save playlist
</button>


<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">
          Create new collection
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div>
        <div class="modal-body text-left">
          <div>
            <label for="collection">Collection Title:</label>
            <input type="text" class="form-control" [(ngModel)]=" collectionTags" />
          </div>
          <div class="dropdown" style="margin-top: 1.5rem">
            Type:
            <button class="btn dropdown-toggle dropButton" type="button"
              id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false"
              style="padding: 0rem 0.75rem">
              <span class="text-capitalize centerType">{{
                collectionType || "Add To Collection"
              }}</span>

            </button>
            <ul class="dropdown-menu drop-menu" aria-labelledby="dropdownMenuButton">
              <li class="dropdown-item cursorPointer text-capitalize"
                (click)="addCollectionType('Private')">
                <span class="icon-text-wrapper d-flex align-items-center">
                  <div class="icon">
                    <svg height="24" viewBox="0 0 24 24" width="24"
                      focusable="false">
                      <path
                        d="M17 8V6.63C17 4.08 14.76 2 12 2S7 4.08 7 6.63V8H4v14h16V8h-3zM8 6.63c0-2.02 1.79-3.66 4-3.66s4 1.64 4 3.66V8H8V6.63zM19 21H5V9h14v12zm-7-9c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z">
                      </path>
                    </svg>
                  </div>
                  <p class="mb-0 pl-3">Private</p>
                </span>
              </li>
              <li class="dropdown-item cursorPointer text-capitalize"
                (click)="addCollectionType('Public')">
                <span class="icon-text-wrapper d-flex align-items-center">
                  <div class="icon">
                    <svg height="24" viewBox="0 0 24 24" width="24"
                      focusable="false">
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM3 12c0-.7.09-1.37.24-2.02L8 14.71v.79c0 1.76 1.31 3.22 3 3.46v1.98c-4.49-.5-8-4.32-8-8.94zm8.5 6C10.12 18 9 16.88 9 15.5v-1.21l-5.43-5.4C4.84 5.46 8.13 3 12 3c1.05 0 2.06.19 3 .53V5c0 .55-.45 1-1 1h-3v2c0 .55-.45 1-1 1H8v3h6c.55 0 1 .45 1 1v4h2c.55 0 1 .45 1 1v.69C16.41 20.12 14.31 21 12 21v-3h-.5zm7.47-.31C18.82 16.73 18 16 17 16h-1v-3c0-1.1-.9-2-2-2H9v-1h1c1.10 0 2-.9 2-2V7h2c1.10 0 2-.9 2-2V3.95c2.96 1.48 5 4.53 5 8.05 0 2.16-.76 4.14-2.03 5.69z">
                      </path>
                    </svg>
                  </div>
                  <p class="mb-0 pl-3">Public</p>
                </span>
              </li>
            </ul>
          </div>
          <p class="text-center pt-2">OR</p>
          <p class="text-center pt-2">Add to existing groups</p>
          <div>
            <select class="form-select form-select-lg mb-3 w-100 custom-dropdown"
              aria-label=".form-select-lg example" style="padding: 10px;"
              [(ngModel)]="collectionTags" (change)="selectedPlayList()">

              <option [value]="card.name"
                *ngFor="let card of PlayListNames;let i = index"
                style="padding: 20px;">
                <span class="p-5">{{ card.name }}</span>
              </option>
            </select>

          </div>

        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-warning" data-dismiss="modal"
          (click)="createCollection()">
          Create
        </button>
      </div>
    </div>
  </div>
</div>
<span class="cursorPointer">
  <img src="../../../assets/edit.png" (click)="edit()">
</span>
<img title="share file" class="cursorPointer" src="../../../assets/Group 55/Group 53.png"
  data-toggle="modal" data-target="#exampleModal12">
<img title="download files" *ngIf="videoUrl_lg" class="cursorPointer"
  src="../../../assets/Group 55/Group 55.png" (click)="downloadOption('video')">
  <img title="download files" *ngIf="!videoUrl_lg && cardData.extension=='.xlsx'"
    class="cursorPointer" src="../../../assets/Group 55/Group 55.png"
    (click)="downloadOption('excel')">
  <img title="download files" *ngIf="!videoUrl_lg && cardData.extension=='.pdf'" class="cursorPointer"
    src="../../../assets/Group 55/Group 55.png" (click)="downloadOption('pdf')">
    <img title="download files" *ngIf="!videoUrl_lg && cardData.extension=='.pptx'"
      class="cursorPointer" src="../../../assets/Group 55/Group 55.png"
      (click)="downloadOption('pptx')">
    <img title="download files"
      *ngIf="!videoUrl_lg && cardData.extension=='.png' || cardData.extension=='.jpeg' || cardData.extension=='.jpg'"
      class="cursorPointer" src="../../../assets/Group 55/Group 55.png"
      (click)="downloadOption('image')">

    <img title="delete media file" class="cursorPointer" src="../../../assets/icons 6/Group 24.png"
      (click)="deleteMedia(id)">
  </div> -->
        <!-- <mat-progress-bar
  *ngIf="download$ | async as download"
  [mode]="download.state == 'PENDING' ? 'buffer' : 'determinate'"
  [value]="download.progress"
  >
</mat-progress-bar> -->
      </div>
    </div>
    <div class="col-6">
      <div class="detail-area">
        <div class="white-background p-3 pt-4">
          <div class="playlist-title d-flex justify-content-between text-work">
            <h3 [title]="title">{{ title }}</h3>
            <p class="d-flex">
              <span class="description">Client:</span>
              <span [title]="client_name" class="text-bold pl-1 client-text">
                {{ client_name }}
              </span>
            </p>
          </div>
          <p class="description">{{ description }}</p>
        </div>
        <div class="heightTags">
          <!-- <div class="tags d-flex flex-wrap">
      <div class="tag-element px-2" *ngFor="let tag of tags"><span class="px-3">{{tag}} </span> </div>
    </div> -->
          <!-- <div class="col-12 img-show mb-5 pb-3" *ngIf="filesUrlLen"> -->
          <!-- <div class="swiper-container1 carousel pt-2 pb-2">
    <div class="swiper-wrapper swiper-wrapper1">
      <div *ngFor="let file of filesUrl; let i = index" class="swiper-slide slide">
        <img src="{{ file }}" *ngIf="isImage(file)"
          class="img-row-show thumbnail img-thumbnail mx-2"
          (click)="onClickImage(file, i)" width="100%" height="100%" data-toggle="modal"
          data-target="#exampleModalCenter">
        <ng-container *ngIf="isVideo(file)">
          <video id="vidoeCheck" class="mx-2 cursorPointer" height="100%" width="100%"
            [controls]="true" preload="none" [muted]="true" controls muted
            (click)="onClickImage(file, i)" data-toggle="modal"
            data-target="#exampleModalCenter">
            <source [src]="file" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </ng-container>
      </div>
    </div>
    <div class="swiper-pagination"></div>

    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div> -->
          <!-- </div> -->
        </div>
        <div class="BottomDetails">
          <div class="pb-2">
            <div class="d-flex align-items-center">
              <div>
                <!-- <p class="pt-3 mr-2 description11">In Collections: </p> -->
              </div>
              <div class="tag-section d-flex flex-wrap">
                <!-- <div class="tag-elementCollection px-4"
        *ngFor="let tag of ['Public Collection','Works 2023'];let i=index">
        {{tag}}
      </div> -->
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <p class="pr-2 description11">Uploaded By:</p>
              <!-- <img class="uploadedBy" src="../../../assets/Group 212.png"> -->
              @if (profileImg) {
              <img class="uploadedBy" src="{{ profileImg }}" />
              }
              <!-- <img
    *ngIf="!cardData?.profileImg"
    src="../../../assets/add-people.png"
    width="20px"
    height="20px"
    /> -->

              <p class="text-bold pl-2" style="font-size: 14.54px">
                {{ name }}
              </p>
            </div>
            <div class="d-flex align-content-center">
              <span class="pt-0 pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                  class="bi bi-clock colorCLock" viewBox="0 0 16 16">
                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                </svg>
              </span>
              <p class="description21 align-baseline pt-1">
                {{ createdAt | date }}
              </p>
            </div>
          </div>
        </div>
      </div>
      @if (imageUrls.length > 0) {
      <div class="downloadZip">
        <!-- <button class="downloadALL float-right" (click)="downloadZip()">
    Download All
  </button> -->
      </div>
      }
    </div>
  </div>
  <div class="row justify-content-center">
    @if (filesUrlLen) {
    <div class="col-12 img-show mb-5 pb-3" style="max-width: 95%">
      <div class="swiper-container1 carousel pt-2 pb-2">
        <div class="swiper-wrapper swiper-wrapper1">
          @for (file of additionalFiles; track file; let i = $index) {
          <div class="swiper-slide slide">
            @if (isImage(file.fileUrl)) {
            <img src="{{ file.fileUrl }}" class="img-row-show thumbnail img-thumbnail mx-2"
              (click)="onClickImage(file.fileUrl, i)" width="100%" height="100%" data-toggle="modal"
              data-target="#exampleModalCenter" />
            }
            @if (isVideo(file.fileUrl)) {
            <video id="vidoeCheck" class="mx-2 cursorPointer" height="100%" width="100%" [controls]="true"
              preload="none" [muted]="true" controls muted (click)="onClickImage(file.fileUrl, i)" data-toggle="modal"
              data-target="#exampleModalCenter">
              <source [src]="file.fileUrl" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            }
          </div>
          }
        </div>
        <span class="d-block position-relative">
          <div class="swiper-pagination"></div>
        </span>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    }
    <!-- Modal -->
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backtrack="static">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Image Preview
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" (click)="pauseVideo()">&times;</span>
            </button>
          </div>
          <div class="modal-body modalImage p-0">
            @if (imageUrls.length > 0) {
            <div class="swiper-container carousel-container">
              <div class="swiper-wrapper">
                @for (url of imageUrls; track url; let i = $index) {
                <div class="swiper-slide slide slider1">
                  @if (isImage(url)) {
                  <div>
                    <img id="imageAll" src="{{ url }}" class="modal-image img-thumbnail" width="100%" height="100%" />
                  </div>
                  }
                  @if (isVideo(url)) {
                  <div>
                    <video id="video" class="ratio ratio-16x9" id="videoSelected" height="100%" width="100%"
                      [controls]="true" [muted]="true" (click)="playVideo()">
                      <source [src]="url" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  }
                </div>
                }
              </div>
            </div>
            }
          </div>
          <div class="d-flex justify-content-between">
            <div class="pt4">
              <p>{{ fileName }}</p>
            </div>
            <div class="d-flex">
              <!-- <span class="cursorPointer"><img src="../../../assets/Group 55/Group 53.png"></span> -->
              @if (selectedImageUrl) {
              <p class="d-none">{{ selectedImageUrl }}</p>
              @if (isImage(selectedImageUrl)) {
              <span class="cursorPointer" (click)="download('image')"><img
                  src="../../../assets/download_icon.svg" /></span>
              }
              @if (isVideo(selectedImageUrl)) {
              <span class="cursorPointer" (click)="download('video')">
                <img src="../../../assets/Group 55/Group 55.png" />
              </span>
              }
              }
            </div>

            <!-- <div class="m-auto w-auto">
          <button class="btn btn-primary mb-5" (click)="downloadZip()">Download All</button>
        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="shareButton">
<button type="button" class="btn btn-danger  mb-5" (click)="deleteMedia(id)">Delete</button>
<button type="button" class="btn btn-primary  mb-5">Share link </button>
</div> -->
</div>

<!-- Modal -->
<div class="modal fade exampleModal12" id="exampleModal12" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        @for (user of allUsers; track user; let i = $index) {
        <div class="d-flex justify-content-between shareDiv mb-1">
          <div>{{ i }}</div>
          <div>{{ user.username }}</div>
          <div>{{ user.phone }}</div>
          <div>
            @if (videoUrl_lg) {
            <button class="btn btn-primary" (click)="shareFile(user.phone, videoUrl_lg)">
              Send
            </button>
            }
            @if (!videoUrl_lg && cardData.extension == '.xlsx') {
            <button class="btn btn-primary" (click)="shareFile(user.phone, cardData.og_file_url)">
              Send
            </button>
            }
            @if (!videoUrl_lg && cardData.extension == '.pdf') {
            <button class="btn btn-primary" (click)="shareFile(user.phone, cardData.og_file_url)">
              Send
            </button>
            }
            @if (!videoUrl_lg && cardData.extension == '.pptx') {
            <button class="btn btn-primary" (click)="shareFile(user.phone, cardData.og_file_url)">
              Send
            </button>
            }
            @if (!videoUrl_lg && cardData.extension == '.png') {
            <button class="btn btn-primary" (click)="shareFile(user.phone, cardData.og_file_url)">
              Send
            </button>
            }
          </div>
        </div>
        }
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>