import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DataFilterService {
  private dataSubject = new BehaviorSubject<any[]>([]);
  public data$ = this.dataSubject.asObservable();

  public sortBy = new Subject<any>();
  public filterD = new Subject<any>();
  public uploadBy = new Subject<any>();
  public privateLength = new Subject<any>();
  public disableUser = new Subject<any>();
  public store = new Subject<any>();
  public searchData = new Subject<any>();
  public selectOption = new Subject<any>();
  public removeItem = new Subject<any>();

  constructor() {}

  updateData(data: any[]) {
    this.dataSubject.next(data);
    console.log("next");
  }

  filterDataByExtension(extension: string) {
    console.log("extension", extension);
    this.dataSubject.pipe(
      map((data) => data.filter((item) => item.extension === extension))
    );
  }

  filterSortBy(startDate, endDate) {
    this.sortBy.next({ startDate, endDate });
  }

  filterExt(data) {
    this.filterD.next(data);
  }
  filterUploadBy(data) {
    this.uploadBy.next(data);
  }

  passPrivate(data) {
    this.privateLength.next(data);
  }

  guestUserDisable(data) {
    console.log("play list user login + =>", data);
    this.disableUser.next({ data });
  }

  dataPass(data) {
    console.log("play list pass + =>", data);
    this.store.next({ data });
  }

  searchFilterPass(data: any, event: any) {
    this.searchData.next({ data, event });
  }

  selectAndOption(data) {
    console.log("select and option", data);
    this.selectOption.next(data);
  }

  removeFromItem(data) {
    this.removeItem.next(data);
  }

  public clearButton = new Subject<any>();

  sendButton(data) {
    this.clearButton.next(data);
  }

  public removeTags = new Subject<any>();
  removeTagFunction(data) {
    console.log("remove index + based after data + =>", data);
    this.removeTags.next(data);
  }

  public numberOfVideos = new Subject<any>();

  numberOfVideosDisplay(data) {
    this.numberOfVideos.next(data);
  }

  public clearDateData = new Subject<any>();
  clearDate(data) {
    console.log("clear date data", data);
    this.clearDateData.next(data);
  }
  public clearDa = new Subject<any>();

  clearDateDetails(data) {
    this.clearDa.next(data);
  }
}
