import { createAction, props } from "@ngrx/store";
import { UserData } from "../component/login/login.component";
import { JwtResponse } from "../component/login/login.component";

export const uploadAction = createAction(
  "uploadFormInfo",
  props<{ body: any }>()
);

export const loginSuccess = createAction(
  "[Login] Login Success",
  props<{ body: any }>()
);
export const loginFailure = createAction(
  "[Login] Login Failure",
  props<{ errorMessage: string }>()
);

export const increment = createAction("[Counter Component] Increment");
export const decrement = createAction("[Counter Component] Decrement");
export const reset = createAction("[Counter Component] Reset");



export const updateLeadSearchUser = createAction(
  'UPDATE_SEARCH_USER',
  props<{ user: string | { username: string; id: string } }>()
);

export const updateLeadSearchStatus = createAction(
  'UPDATE_SEARCH_STATUS',
  props<{ searchStatus: string }>()
);

