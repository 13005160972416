import { Component } from "@angular/core";
import { FormGroup, FormControl, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { UserService } from "src/app/service/user.service";
import { LoaderService } from "src/app/service/loader.service";
import { Store } from "@ngrx/store";
import { loginFailure, loginSuccess } from "src/app/store/counter.actions";
import { LoginState } from "src/app/store/counter.state";

export interface UserData {
  department: string;
  username: string;
  userId: string;
  userEmail: string;
  profileImg: string;
}

export interface JwtResponse {
  data?: UserData;
  token?: string;
  message: string;
}
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  standalone: true,
  imports: [ReactiveFormsModule],
})
export class LoginComponent {
  errorMessage: string = "";

  constructor(
    private router: Router,
    private userService: UserService,
    private loaderService: LoaderService,
    private store: Store<{ login: LoginState }>
  ) {}

  ngOnInit(): void {}

  loginForm = new FormGroup({
    username: new FormControl(""),
    password: new FormControl(""),
  });

  handleLoginSuccess(jwt_resp: JwtResponse): void {
    let allUserInfo: any;
    console.log({ jwt_resp });
    if (jwt_resp && jwt_resp?.data) {
      const data: UserData = jwt_resp.data;
      allUserInfo = {
        guestToken: data["token"],
        department: data["department"],
        username: data["userName"],
        guestID: data["userId"],
        email: data["userEmail"],
        profileImg: data["profileImg"],
      };
      console.log({ allUserInfo });
      localStorage.setItem("guestToken", data["token"]);
      localStorage.setItem("department", data.department);
      localStorage.setItem("username", data["userName"]);
      localStorage.setItem("guestID", data.userId);
      localStorage.setItem("email", data.userEmail);
      localStorage.setItem("profileImg", data.profileImg);
      this.router.navigate(["/home"]);
    }

    // this.store.dispatch(loginSuccess({ jwtResponse: allUserInfo }));
  }

  handleLoginError(error: any): void {
    this.errorMessage = "Invalid username or password";
    console.log("error", this.errorMessage);
    this.store.dispatch(loginFailure({ errorMessage: this.errorMessage }));
    // this.loginForm.reset();
  }

  onInputClick(): void {
    this.errorMessage = "";
  }
  onLogin(): void {
    const loginData = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
    };
    this.userService.login(loginData).subscribe(
      (jwt_resp: JwtResponse) => {
        console.log("resp on login", jwt_resp);
        this.router.navigate(["/home"]);
        if (!jwt_resp.data) {
          this.handleLoginError(null);
          this.loaderService.setLoading(false);
        } else {
          this.handleLoginSuccess(jwt_resp);
        }
      },
      (error) => {
        this.handleLoginError(error);
        this.loaderService.setLoading(false);
      }
    );
  }
}
