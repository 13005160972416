import { Component, OnInit } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';
import {
  ReactiveFormsModule,
  FormGroup,
  FormsModule,
  Validators,
  FormBuilder,
  FormControl,
  FormArray,
} from "@angular/forms";
import { InventoryService } from 'src/app/service/inventory.service';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { alertModalWithoutConfirm } from '../../helpers/alert';
import { monitorEventLoopDelay } from 'perf_hooks';
import { clippingParents } from '@popperjs/core';


interface userList {
  id: string;
  viewValue: string;
}


@Component({
  selector: 'app-workstation',
  standalone: true,
  imports: [MatInputModule, MatSelectModule, MatFormFieldModule, ReactiveFormsModule, FormsModule, NgClass, NgStyle],
  templateUrl: './workstation.component.html',
  styleUrls: ['./workstation.component.css', '../styles/inventory.css']
})
export class WorkstationComponent implements OnInit {

  currentStep = 1;
  selectedImage: File | null = null;
  selectedImages: File[] = [];
  previewImageUrl: string | ArrayBuffer | null = null;
  previewImagesUrl: string[] = [];
  maxImages: number = 5;
  showWorkstationButton = false;
  userDepartment: string | null = null;

  users: { value: string, viewValue: string, id: string }[] = [];

  configurationForm: FormGroup;
  accessoriesForm: FormGroup;

  constructor(private fb: FormBuilder,
    private inventoryService: InventoryService,
  ) {

    this.configurationForm = this.fb.group({
      workstationName: ['', [Validators.required, Validators.maxLength(50)]],
      processor: ['', Validators.required],
      graphicCard: ['', Validators.required],
      isLicensed: ['', Validators.required],
      assignTo: ['',],
      ramValue: ['', Validators.required],
      version: ['', Validators.required],
      File: []
    })

    this.accessoriesForm = this.fb.group({
      monitor1: ['', Validators.maxLength(50)],
      monitor2: ['', Validators.maxLength(50)],
      monitor3: ['', Validators.maxLength(50)],
      monitor4: ['', Validators.maxLength(50)],
      monitor5: ['', Validators.maxLength(50)],
      monitor6: ['', Validators.maxLength(50)],
      headPhone: ['', Validators.maxLength(50)],
      keyboard: ['', Validators.maxLength(50)],
      mouse: ['', Validators.maxLength(50)],
      camera: ['', Validators.maxLength(50)],
      speakers: ['', Validators.maxLength(50)]
    });

  }


  ngOnInit(): void {
    this.getAllUsers();
    this.getWorkstation();
    this.userDepartment = localStorage.getItem('department');
    this.showWorkstationButton = this.userDepartment === 'HR';
  }



  getAllUsers(): void {
    this.inventoryService.getInvUsers().subscribe(res => {
      let data = res["data"];
      this.users = data.map((user: any) => ({
        value: user.userName,
        viewValue: user.userName,
        id: user._id
      }));
      console.log("users", this.users);

    });
  }


  navigateToStep(step: number): void {
    this.currentStep = step;
  }

  resetClose() {
    this.configurationForm.reset();
    this.accessoriesForm.reset();
    this.currentStep = 1;
    this.selectedImages = [];
  }

  previewInventory: any
  openPreviewSlider(index: number) {
    this.previewInventory = this.workstation[index]
  }


  isEditMode: boolean;
  previewProductImg: any
  workstationDetail: any
  openWorkstationDetail(index: number) {
    this.isEditMode = true;
    this.workstationDetail = this.workstation[index]
    console.log({ Detail: this.workstationDetail })
  }

  // Configuration Image Upload
  onSelectImage(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const selectedImage = event.target.files[0];
      this.selectedImages = [selectedImage];
      this.updateImagePreviews();
      console.log("selected images", this.selectedImages)
      this.configurationForm.patchValue({
        File: this.selectedImages
      });
    }
    console.log(this.selectedImage)
  }

  // Configuration Multiple Image Upload
  onSelectMultipleImages(event: any): void {

    if (event.target.files && event.target.files.length > 0) {
      const filesArray: File[] = Array.from(event.target.files) as File[];
      const totalImages = this.selectedImages.length + filesArray.length;

      if (totalImages > this.maxImages) {
        return alertModalWithoutConfirm(
          "warning",
          `You can only upload a maximum of ${this.maxImages} images.`
        );
      }


      this.selectedImages = [...this.selectedImages, ...filesArray];
      console.log("selected images", this.selectedImages)

      this.updateImagePreviews();
      this.configurationForm.patchValue({
        File: this.selectedImages
      });

    }
    console.log(this.selectedImage)
  }

  updateImagePreviews(): void {
    this.previewImagesUrl = [];
    this.selectedImages.forEach(file => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.previewImagesUrl.push(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  // Remove multiple uploaded Image
  removeImage(index: number): void {
    this.selectedImages.splice(index, 1);
    this.updateImagePreviews();
    this.configurationForm.patchValue({
      productFile: this.selectedImages
    });
  }

  submitted = false;
  //Configuration Form Submit
  onSubmitConfiguration() {
    console.log(this.configurationForm)
    this.submitted = true;
    if (this.configurationForm.valid) {
      this.currentStep = 2;
      const formData = new FormData();
      Object.keys(this.configurationForm.value).forEach(key => {
        formData.append(key, this.configurationForm.value[key]);
      });

      if (this.selectedImages.length > 0) {
        this.selectedImages.forEach((image, index) => {
          formData.append(`files[${index}]`, image);
        });
      }
    }
    else if (this.configurationForm.invalid) {
      console.log('invalid')
      return alertModalWithoutConfirm(
        "warning",
        "Please fill in all required fields before submitting."
      );
    }

  }


  onSubmitAccessories() {
    console.log(this.accessoriesForm);

    if (this.configurationForm.valid) {
      const workstationFormValue = {
        ...this.configurationForm.value,
        ...this.accessoriesForm.value
      };
      const assignee = this.users.find(user => user.value === workstationFormValue.assignTo);
      console.log('Assignee:', assignee);
      const assigneeId = assignee ? assignee.id : "null";
      console.log({ assigneeId });

      const monitorArray = [
        workstationFormValue.monitor1,
        workstationFormValue.monitor2,
        workstationFormValue.monitor3,
        workstationFormValue.monitor4,
        workstationFormValue.monitor5,
        workstationFormValue.monitor6
      ];

      const getFormValue: any = {
        name: String(workstationFormValue.workstationName),
        assigneeName: workstationFormValue.assignTo,
        processor: workstationFormValue.processor,
        ram: workstationFormValue.ramValue,
        graphicsCard: workstationFormValue.graphicCard,
        windowsVersion: workstationFormValue.version,
        windowsLicensed: Boolean(workstationFormValue.isLicensed === 'yes' ? true : false),
        headPhone: workstationFormValue.headPhone,
        keyboard: workstationFormValue.keyboard,
        mouse: workstationFormValue.mouse,
        monitors: monitorArray,

      };

      const formData = new FormData();

      for (const key in getFormValue) {
        if (getFormValue.hasOwnProperty(key)) {
          if (Array.isArray(getFormValue[key])) {
            getFormValue[key].forEach((item, index) => {
              formData.append(`monitors[${index}]`, item);
            });
          } else {
            formData.append(key, getFormValue[key]);
          }
        }
      }

      if (workstationFormValue.assignTo) {
        getFormValue.assigneeId = assigneeId;
      }
      const selectedImages = this.configurationForm.get('File')?.value;
      if (selectedImages && Array.isArray(selectedImages)) {
        selectedImages.forEach((image: File, index: number) => {
          formData.append(`files[${index}]`, image, image.name);
        });
      }
      this.inventoryService.addWorkstation(formData).subscribe(
        response => {
          this.currentStep = 3
          console.log(response, "response");
          this.getWorkstation();
        },
        error => {
          console.error('error:', error);
        }
      );
    } else {
      console.log('One or more forms are invalid');
      if (this.configurationForm.invalid) {
        return alertModalWithoutConfirm(
          "warning",
          "Please fill in all required fields before submitting."
        );
      }
    }
  }


  workstation: any[] = [];
  totalWorkstation: number;
  getWorkstation() {
    const field: any = {
      pageNum: 1,
      limit: 500,
    };

    this.inventoryService.getWorkstation(field).subscribe((response: any) => {
      this.workstation = response.data.records.reverse();
      this.totalWorkstation = response.data.totalCounts
      console.log("Response", response)
    })
  }




}






