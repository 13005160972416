import { createChannel } from "converse.svc-client";
import Channel from "converse.svc-client";
// @ts-ignore
import Project from "converse.svc-client/src/project";
import { Component, ViewChild, ElementRef, Renderer2 } from "@angular/core";
import { single } from "rxjs";
import { UserService } from "src/app/service/user.service";
import { send } from "process";
import { channel } from "diagnostics_channel";
import { clippingParents } from "@popperjs/core";
import { HttpClient } from "@angular/common/http";
import { OnlineUsersService } from "src/app/service/online-users.service";
import { ChatService } from "src/app/service/chat.service";
import { ChatSortPipe } from "../../pipe/chat-sort.pipe";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-new-single-chat",
  templateUrl: "./new-single-chat.component.html",
  styleUrls: ["./new-single-chat.component.css"],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, DatePipe, ChatSortPipe],
})
export class NewSingleChatComponent {
  // @ViewChild('chatBody', { static: true }) chatBody!: ElementRef;
  constructor(
    private api: UserService,
    private renderer: Renderer2,
    private httpClient: HttpClient,
    private OnlineUsersService: OnlineUsersService,
    private chatService: ChatService
  ) {}
  UserData: any[];
  singleUser: any;
  showUsers1: boolean = false;
  showUsers2: boolean = false;
  showChatContainer: boolean = true;
  showcount: boolean = true;
  token: string;
  projectId = "project_link_module";
  channel: any;
  newMessage: string = "";
  singleChatMessages: any;
  singleChatMessages2: any;
  userId: any;
  showFileName: string;
  channelCache: any[] = [];
  isLoading1: boolean = false;
  isLoading2: boolean = false;
  chatBody: any;
  chatUserName: string;
  chatUserName2: string;
  getfilediv: any;
  AllMessages: any = {};
  lastMessage: any = {};
  searchTerm: string = "";
  filteredUsers: any[] = [];
  fileId: any;
  sendfile1: any;
  sendfile2: any;
  globalProject: any;
  chatUserId: any;
  newMessage2: string = "";
  previousTimestamp: Date | null = null;
  ngOnInit(): void {
    // Initialize user-related data
    this.userId = localStorage.getItem("guestID");
    this.token = localStorage.getItem("guestToken");
    this.projectId = "project_link_module";

    // Fetch chat users
    this.getChatAndUsers();
    this.converseGlobalSocket();
  }

  newReceiver: any;
  newUserName: any;

  async iniChat(id: string) {
    this.chatUserId = id;
    this.singleUser = this.filteredUsers.find((item) => item.userId == id);

    this.newReceiver = this.singleUser.userId;
    this.newUserName = this.singleUser.userName;

    this.scrollToBottom();
    this.showcount = false;
    if (!this.singleUser.channelId) {
      const sendData = {
        username: localStorage.getItem("username"),
        userId: this.userId,
        profilePic: localStorage.getItem("profileImg") || "",
      };

      const body = {
        members: [
          {
            userId: this.singleUser.userId,
            userName: this.singleUser.userName,
            profileImage: this.singleUser.profileImage,
            fcmToken: this.singleUser.fcmToken,
          },
        ],
        type: "single",
      };

      this.api.sendSingleChatUserData(body).subscribe((res: any) => {
        const channelId = res.data.converseChannelId;
        this.singleUser.channelId = channelId;
        this.showUsers1 = true;
        // this.showcount = true;
        this.initiatChat(this.singleUser, channelId);
      });
    } else {
      if (this.channelCache.length < 2) {
        this.initiatChat(this.singleUser, this.singleUser.channelId);
      } else {
        if (
          this.singleUser.channelId != this.channelCache[0].channel._id ||
          this.singleUser.channelId != this.channelCache[1].channel._id
        ) {
          this.initiatChat(this.singleUser, this.singleUser.channelId);
          return;
        }
      }
    }
  }

  getChatAndUsers() {
    this.chatService.getChats().subscribe((resp: any) => {
      this.filteredUsers = resp.data;

      this.searchFilter = this.filteredUsers;
      this.globalProject.listenNewMessage(({ message: msg }) => {
        if (msg) {
          const newArr = [];
          this.searchFilter.forEach((message) => {
            if (message.userId === msg.sid) {
              const obj = { ...message };
              obj.lastMessage = {
                creationTime: new Date().toISOString(),
                message: msg.message,
                sender_id: msg.sid,
                url: "",
                og_msg: msg.message,
                name: msg.name,
                meta: msg.meta,
              };
              obj.unreadCount = (message.unreadCount || 0) + 1;
              newArr.push(obj);
            } else {
              newArr.push(message);
            }
            this.searchFilter = newArr;
          });
        }
      });
    });
  }

  async initiatChat(singleUser: any, channelId: string) {
    this.channel = new Channel(channelId);
    const channelInit = await this.channel.init({
      token: this.token,
      project_id: this.projectId,
    });

    const channelConnection = await this.channel.connect({
      token: channelInit.token,
      projectId: this.projectId,
      fcmToken: localStorage.getItem("fcmToken"),
    });
    // if (this.channelCache.length) {
    //   this.channelCache.forEach((ch) => {
    //     ch.close();
    //   });
    //   this.channelCache = [];
    // }

    if (channelConnection === 0) {
      this.channelCache.push(this.channel);
    }
    if (this.channelCache.length === 1) {
      const channelInfo = this.channelCache[0];
      this.chatUserName = singleUser.userName;
      await this.getAllMsg1(channelInfo);
      this.showUsers1 = true;
    } else if (this.channelCache.length === 2) {
      this.showUsers2 = true;
      this.chatUserName2 = singleUser.userName;
      const channelInfo = this.channelCache[1];
      await this.getAllMsg2(channelInfo);
    } else {
      this.channelCache[0].close();
      this.channelCache.shift();
      this.chatUserName = singleUser.userName;
      const channelInfo = this.channel;
      await this.getAllMsg1(channelInfo);
    }
  }

  // isURL(str: string): boolean {
  //   const urlRegex =
  //     /^(?:(?:(?:https?|ftp):)?\/\/)?[\w/\-?=%.]+\.[\w/\-?=%.]+$/;
  //   return urlRegex.test(str);
  // }

  isURL(str: string): boolean {
    const urlRegex = /^(?:(?:https?|ftp):)?\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(str);
  }

  filterDataSearch: any;
  filterDataSearchLength: any;
  private isFirstTextTyping: boolean = true;
  skipApi: boolean = false;
  MetaDataDetails: any;
  MedataLength: any;
  onInputChange(): void {
    // Checks if the input is empty
    if (!this.newMessage) {
      // Resets the suggestion data
      this.filterDataSearch = [];
      this.filterDataSearchLength = 0;
      this.isFirstTextTyping = true;
      return;
    }

    if (this.newMessage.split("")[0] == "/") {
      let newText = this.newMessage.slice(1);
      if (newText) {
        let text = newText?.toLowerCase();

        let filterData = this.singleChatMessages.filter((msg: any) => {
          return msg.message?.toLowerCase()?.includes(text);
        });

        this.filterDataSearch = filterData;
        this.filterDataSearchLength = filterData.length;
      }
    }

    let result = this.isURL(this.newMessage);

    window.addEventListener("keydown", (event) => {
      if (event.keyCode === 8 && !this.searchTerm) {
        this.skipApi = true;
        return;
      }
    });

    window.addEventListener("paste", (event) => {});

    if (result && !this.skipApi) {
      let body = { url: this.newMessage, receiverId: this.newReceiver };
      this.OnlineUsersService.isUrlValid(body).subscribe((res: any) => {
        this.MetaDataDetails = res;
        this.MedataLength = this.MetaDataDetails?.data?.length;
      });
      this.isFirstTextTyping = false;
    }
  }
  onSuggestionSelectedAction(selectedSuggestion: string): void {
    console.log("this is selected suggestions", selectedSuggestion);
  }
  selectSuggestion(selectedSuggestion: string, fileId?: any): void {
    // Assigning the selected suggestion to the input field
    this.newMessage = selectedSuggestion;
    this.fileId = fileId;
    // Clearing suggestions after selection
    this.filterDataSearch = [];
    this.filterDataSearchLength = 0;
    this.onSuggestionSelectedAction(selectedSuggestion);
  }

  openUrlInNewWindow(url: string): void {
    if (this.isURL(url)) {
      window.open(url, "_blank");
    }
  }
  linkImagePreview: any;

  async sendMessage() {
    // Reset suggestion data if newMessage is not empty and contains a URL
    if (this.newMessage) {
      let result = this.isURL(this.newMessage);

      if (result) {
        let body = {
          url: this.newMessage,
          receiverId: this.newReceiver,
          receiverName: this.chatUserName,
          meta: { videoReceived1: true },
        };
        if (this.fileId) {
          body["fileId"] = this.fileId;
          this.OnlineUsersService.isUrlValidSave(body).subscribe((res) => {});
        } else {
          this.OnlineUsersService.isUrlValidSave(body).subscribe((res) => {});
        }
      }
    }

    await this.channelCache[0].send({
      message: this.newMessage,
      sender_id: this.userId,
      creationTime: new Date(),
      file: this.sendfile1 ? this.sendfile1 : "",
      meta: { videoReceived1: true },
    });
    await this.globalProject.notifyPeople({
      msg: {
        message: this.newMessage,
        sender_id: this.userId,
        creationTime: new Date(),
        file: this.sendfile1 ? this.sendfile1 : "",
      },
      users: [this.chatUserId],
    });

    // Clear suggestion data after sending the message
    this.resetSuggestionData();

    this.newMessage = "";
    this.sendfile1 = null;
    this.showFileName = "";
    this.scrollToBottom();
  }
  async sendMessage2() {
    // Reset suggestion data if newMessage is not empty and contains a URL
    if (this.newMessage2) {
      let result = this.isURL(this.newMessage);
      if (result) {
        let body = {
          url: this.newMessage2,
          fileId: this.fileId || "",
          receiverId: this.newReceiver,
          receiverName: this.chatUserName,
          meta: { videoReceived2: true },
        };
        this.OnlineUsersService.isUrlValidSave(body).subscribe((res) => {});
      }
    }

    await this.channelCache[1].send({
      message: this.newMessage2,
      sender_id: this.userId,
      creationTime: new Date(),
      file: this.sendfile2 ? this.sendfile2 : "",
      meta: { videoReceived2: true },
    });
    await this.globalProject.notifyPeople({
      msg: {
        message: this.newMessage2,
        sender_id: this.userId,
        creationTime: new Date(),
        file: this.sendfile2 ? this.sendfile2 : "",
      },
      users: [this.chatUserId],
    });

    // Clear suggestion data after sending the message
    this.resetSuggestionData();

    this.newMessage2 = "";
    this.sendfile2 = null;
    this.showFileName = "";
    this.scrollToBottom();
  }
  resetSuggestionData(): void {
    this.MetaDataDetails = null;
    this.MedataLength = null;
  }

  scrollToBottom() {
    setTimeout(() => {
      document.querySelectorAll(".chat-wrapper").forEach((val) => {
        val.scrollTo(0, 12313);
      });
    }, 100);
  }

  //Previous
  async getChats(id: string) {
    const channel = new Channel(id);
    await channel.init({
      token: this.token,
      project_id: this.projectId,
    });

    let messages = await channel.getMessages();

    if (messages.unread && messages.unread.length) {
      this.lastMessage = messages.msgs.unread[messages.msgs.unread.length - 1];
    } else {
      this.lastMessage = messages.msgs.read[messages.msgs.read.length - 1];
    }

    return this.lastMessage;
  }

  //Previous
  async getAllMsg1(channel: any) {
    let messages = await channel.getMessages();
    this.AllMessages["read"] = messages.msgs.read;
    this.AllMessages["unread"] = messages.msgs.unread;

    this.singleChatMessages = [
      ...this.AllMessages["read"],
      ...this.AllMessages["unread"],
    ];

    setTimeout(() => {
      this.scrollToBottom();
    }, 1000);

    this.channelCache[0].listen((msg) => {
      let newMessage = {
        ...msg,
        sender_id: msg.sid,
        creationTime: new Date(),
        url: msg.url,
      };

      this.singleChatMessages.push(newMessage);
      if (msg.meta.videoReceived1 === true) {
        this.isLoading1 = false;
      }
    });
    setTimeout(() => {
      document.querySelectorAll(".chat-wrapper").forEach((val) => {
        val.scrollTo(0, 12313);
      });
    }, 1000);
  }
  async getAllMsg2(channel: any) {
    let messages = await channel.getMessages();

    this.AllMessages["read"] = messages.msgs.read;
    this.AllMessages["unread"] = messages.msgs.unread;

    this.singleChatMessages2 = [
      ...this.AllMessages["read"],
      ...this.AllMessages["unread"],
    ];

    setTimeout(() => {
      this.scrollToBottom();
    }, 100);

    this.channelCache[1].listen((msg) => {
      let newMessage = {
        ...msg,
        sender_id: msg.sid,
        creationTime: new Date(),
        url: msg.url,
      };
      this.singleChatMessages2.push(newMessage);
      if (msg.meta.videoReceived2 === true) {
        this.isLoading2 = false;
      }
    });
    setTimeout(() => {
      document.querySelectorAll(".chat-wrapper").forEach((val) => {
        val.scrollTo(0, 12313);
      });
    }, 10);
  }

  handleFileInput(event: any): void {
    const file = event.target.files[0];
    this.isLoading1 = true;

    if (file) {
      // this.convertFileToBase64(file);
      this.sendfile1 = file;

      const fileName = { fileName: file.name };

      const fileMessage = {
        sender_id: this.userId,
        creationTime: new Date(),
        file: file ? file : "",
        fileName: file.name,
      };
      this.singleChatMessages.push(fileMessage);

      this.sendMessage();
    }
  }
  handleFileInput2(event: any): void {
    const file = event.target.files[0];
    this.isLoading2 = true;

    if (file) {
      // this.convertFileToBase64(file);
      this.sendfile2 = file;

      const fileName = { fileName: file.name };

      const fileMessage = {
        sender_id: this.userId,
        creationTime: new Date(),
        file: file ? file : "",
        fileName: file.name,
      };
      this.singleChatMessages2.push(fileMessage);

      this.sendMessage2();
    }
  }
  isDifferentTimestamp(currentMessage: any): boolean {
    const currentTimestamp = new Date(currentMessage.creationTime);
    if (
      this.previousTimestamp &&
      currentTimestamp.getMinutes() === this.previousTimestamp.getMinutes()
    ) {
      return false;
    } else {
      // Update the previous timestamp
      this.previousTimestamp = currentTimestamp;
      return true;
    }
  }

  isImage(url: string): boolean {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  }

  isDocument(url: string): boolean {
    return /\.(doc|docx|pdf|zip|xml|rar)$/i.test(url);
  }

  isVideo(url: string): boolean {
    return /\.(mp4|webm)$/i.test(url);
  }

  closeChat() {
    this.showUsers1 = !this.showUsers1;
    this.channelCache[0].close();

    this.channelCache.shift();
  }
  closeChat2() {
    this.showUsers2 = !this.showUsers2;
    this.channelCache[1].close();
    this.channelCache.pop();
  }

  ngOnDestroy() {
    if (this.channelCache.length) {
      this.channelCache.forEach((ch) => {
        ch.close();
      });
      this.channelCache = [];
    }
  }
  searchFilter: any = this.filteredUsers;
  updateFilteredUsers() {
    if (this.filteredUsers) {
      this.searchFilter = this.filteredUsers.filter((user) =>
        user.userName.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  async converseGlobalSocket() {
    this.globalProject = new Project(this.projectId);
    const { token: converToken } = await this.globalProject.init({
      token: this.token,
      project_id: this.projectId,
    });
    const conver = await this.globalProject.connect({
      token: converToken,
    });
  }
  handelKeyDown1(event: KeyboardEvent): void {
    if (event.ctrlKey && event.shiftKey && event.key === "Enter") {
      // Insert new line
      this.newMessage += "\n";
    }
  }
  handelKeyDown2(event: KeyboardEvent): void {
    if (event.ctrlKey && event.shiftKey && event.key === "Enter") {
      // Insert new line
      this.newMessage2 += "\n";
    }
  }
  closeContainer() {
    this.showChatContainer = !this.showChatContainer;
    this.showUsers1 = false;
    this.showUsers2 = false;
    this.channelCache = [];
  }
}
