<!-- <app-header></app-header> -->
<div class="container-fluid my-background">
  <div class="row" >
    <div class="col-10">
      <input type="text" placeholder="Search">
      <div class="card-area d-flex flex-wrap">
        @for (card of cards; track card) {
          <div class="card">
            <!-- <i class="far fa-star" (click)=select></i> -->
            <img src="{{card.imgSrc}}" alt="">
            <p>{{card.clientName}} </p>
          </div>
        }
      </div>
    </div>
    <div class="col-2">

    </div>
  </div>
</div>
<app-footer></app-footer>
