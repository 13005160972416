import { Component } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatSelectModule } from '@angular/material/select';
import { InventoryService } from 'src/app/service/inventory.service';




interface Request {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-admin-request',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [MatButtonModule, MatDatepickerModule, MatInputModule, MatFormFieldModule, MatSelectModule],
  templateUrl: './admin-request.component.html',
  styleUrls: ['./admin-request.component.css', '../styles/inventory.css']
})
export class AdminRequestComponent {


  constructor(private inventoryService: InventoryService) { }

  defaultPendingRequestType: string = 'Service-Request';
  ngOnInit() {
    this.allAdminRequest(this.defaultPendingRequestType);
  }


  forwardedReq = []


  requests: Request[] = [
    { value: 'Service-Request', viewValue: 'Service Request' },
    { value: 'New-Inventory', viewValue: 'New Inventory Request' },
  ]

  onRequestSelect(value: string): void {
    console.log("Selected value:", value);
    this.allAdminRequest(value)
  }

  adminRequest = []
  totalForwarded: any;
  showNewInv: boolean = false;
  showService: boolean = false;
  allAdminRequest(requestType: string) {
    const adminValue = {
      requestType: requestType,

      startDate: {
        day: 1,
        month: 1,
        year: 2021
      },
      endDate: {
        day: 1,
        month: 1,
        year: 2025
      },
    }
    this.showNewInv = requestType === 'New-Inventory';
    this.showService = requestType === 'Service-Request';
    this.inventoryService.getAdminRequest(adminValue).subscribe(response => {
      console.log("Forward Response", response);
      this.adminRequest = response['data'].records;
      this.totalForwarded = response['data'].totalCounts;
    });
  }
}
