import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  Output,
} from "@angular/core";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  RouterLinkActive,
} from "@angular/router";
import { MediaService } from "src/app/service/media.service";
import { SearchHistoryService } from "../../service/search-history.service";
import * as moment from "moment";
import {
  DaterangepickerDirective,
  NgxDaterangepickerMd,
} from "ngx-daterangepicker-material";
import { saveAs } from "file-saver";
import { UserService } from "../../service/user.service";
import { DataFilterService } from "../../data-filter.service";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import {
  alertModalWithoutConfirm,
  alertModal,
  alertModalDelete,
} from "../helpers/alert";
import { takeUntil } from "rxjs/operators";
import { fromEvent, interval, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { clippingParents } from "@popperjs/core";
import { log } from "console";
import { PaginationService } from "src/app/service/pagination.service";
import { FormControl, ReactiveFormsModule, FormsModule } from "@angular/forms";
import EventEmitter from "events";
import { BidiModule } from "@angular/cdk/bidi";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgStyle, NgClass, DatePipe } from "@angular/common";
import { HeaderComponent } from "../header/header.component";

declare var $: any;

@Component({
  selector: "app-collection",
  templateUrl: "./collection.component.html",
  styleUrls: ["./collection.component.css"],
  standalone: true,
  imports: [
    HeaderComponent,
    NgStyle,
    ReactiveFormsModule,
    FormsModule,
    NgClass,
    RouterLinkActive,
    NgxDaterangepickerMd,
    InfiniteScrollModule,
    BidiModule,
    DatePipe,
  ],
})
export class CollectionComponent implements AfterViewInit {
  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild(DaterangepickerDirective, { static: true })
  picker: DaterangepickerDirective;
  @ViewChild("matSortBy") private matSortBy: ElementRef;
  @ViewChild("mediaElement") mediaElement: ElementRef;
  @ViewChild("infiniteScroller") infiniteScroller: ElementRef;
  @ViewChild("multiSearchTerm") multiSearchTerm: ElementRef;

  selected: { startDate: moment.Moment; endDate: moment.Moment };
  name = "Angular";
  open() {
    this.picker.open();
  }
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };
  pdfDisplay: string = "none";
  pdfUrl: string = "";
  excelUrl: string = "";
  pptxUrl: string = "";
  docsUrl: string = "";
  jsUrl: string = "";
  PdfDetails = [];
  isHovered = false;
  searchTerm: string;
  tagsSearch: any;
  filteredName: string;
  searchHistory: string[];
  mostRecentSearch: string | null;
  recentFiveTags: string[];
  client_names: any[];
  uploader_names = [];
  completePath: any;
  currentPath: any = "normal-view";
  mediaId: string;
  selectedDateRange: { startDate: moment.Moment; endDate: moment.Moment };
  newCollection = true;
  allDataForFilter = [];
  constructor(
    private mediaService: MediaService,
    private router: Router,
    private searchHistoryService: SearchHistoryService,
    private renderer: Renderer2,
    private UserService: UserService,
    private dataFilterService: DataFilterService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private paginationService: PaginationService
  ) {
    this.searchHistory = this.searchHistoryService.getSearchHistory();
    this.mostRecentSearch = this.searchHistoryService.getMostRecentSearch();
    this.recentFiveTags = this.searchHistoryService.getRecentFiveTags();
    this.selectedDateRange1 = { startDate: null, endDate: null };
    window.addEventListener("click", this.closeDropdown.bind(this));
    window.addEventListener("click", this.closeDropdown1.bind(this));

    this.route.url.subscribe((segments) => {
      this.completePath =
        "/" + segments.map((segment) => segment.path).join("/");
    });
  }

  selectedCount = 0;
  selectedDateRange1: any;
  @Output() renameRequest = new EventEmitter();
  searchControl = new FormControl();
  ngAfterViewInit() {
    let view = localStorage.getItem("backView");
    const rightCalendar = document.getElementsByClassName("calendar right");
    if (rightCalendar.item(0)) {
      rightCalendar.item(0).remove();
    }

    let top1 = document.getElementsByClassName(".md-drppicker");
    let buttonContainer = document.querySelector(".md-drppicker .ranges ul");

    if (buttonContainer) {
      let buttons = buttonContainer.querySelectorAll("li button");

      buttons.forEach((button, index) => {
        if (index === 0) {
          button.classList.add("active");
          (button as HTMLElement).style.backgroundColor = "#ffc107";
          (button as HTMLElement).style.color = "#000";
        }
        button.addEventListener("click", function () {
          buttons.forEach((btn) => btn.classList.remove("active"));

          button.classList.add("active");

          buttons.forEach((btn) => {
            if (btn.classList.contains("active")) {
              (btn as HTMLElement).style.backgroundColor = "#ffc107";
              (btn as HTMLElement).style.color = "#000";
            } else {
              (btn as HTMLElement).style.backgroundColor = "";
              (btn as HTMLElement).style.color = "";
            }
          });
        });
      });
    }

    let customStyle = document.createElement("style");
    document.head.appendChild(customStyle);

    customStyle.sheet.insertRule(
      ".md-drppicker td.active, .md-drppicker td.active:hover { background-color: #ffc107 !important; border-color: transparent; color: #000 !important; }",
      0
    );

    let secondButton = document.querySelector(
      ".buttons_input button:nth-child(2)"
    ) as HTMLButtonElement;

    if (secondButton) {
      secondButton.style.backgroundColor = "#ffc107";
      (secondButton.style.color = "black"),
        (secondButton.style.padding = "0px 20px 0px 20px"),
        (secondButton.style.marginLeft = "9px");
    }

    let firstButton = document.querySelector(
      ".buttons_input button:nth-child(1)"
    ) as HTMLButtonElement;

    if (firstButton) {
      firstButton.style.marginRight = "230px";
    }

    // Create the new button element
    const newButton = document.createElement("button");
    newButton.textContent = "clear";
    newButton.style.marginLeft = "-98px";
    newButton.classList.add("btn", "btn-default");
    newButton.id = "clearButton";
    // Find the "OK" button
    const okButton = document.querySelector(
      ".buttons_input button:nth-child(1)"
    );

    // Insert the new button after the "OK" button
    if (okButton) {
      okButton.parentNode.insertBefore(newButton, okButton.nextSibling);
    }

    const clearCalendarButton = document.getElementById("clearButton");
    if (clearCalendarButton) {
      clearCalendarButton.addEventListener(
        "click",
        this.clearSelectedDate.bind(this)
      );
    }

    let customStyle2 = document.createElement("style");
    document.head.appendChild(customStyle2);
    customStyle2.sheet.insertRule(
      ".md-drppicker.shown.drops-down-right {top: 59px !important;left: 185px !important}",
      0
    );

    if (top1.length > 0) {
      let firstElement = top1[0] as HTMLElement;
      firstElement.style.top = "160px ";
      firstElement.style.backgroundColor = "";
    }

    this.detectPortrait();

    const hash = window.location.hash;
    const segments = hash.substring(1).split("/");

    let path = segments[2];

    if (path == "private") {
      this.activeTab = "playlistUser";
    }

    if (path == "public") {
      this.activeTab = "playlistDiv";
    }

    // Added a sick debounceTime for checking the email validation yay
    fromEvent(this.multiSearchTerm.nativeElement, "input")
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.mediaService
          .getTags(this.searchTerm)
          .subscribe((tags: { data: any }) => {
            this.tagsSearch = tags.data;
            setTimeout(() => {}, 40000);
          });
      });
  }

  multiTagSearch(tagsFilter: string) {
    if (this.tags.includes(tagsFilter)) return;
    this.tags.push(tagsFilter);
    this.searchHistoryService.addToHistory(this.searchTerm);
    this.mediaService.getUserCollection(this.tags).subscribe((resp) => {
      if (resp["operation"] == false) {
        document.getElementById("notFound").innerHTML = "";
        this.notFoundResult = true;
      } else {
        document.getElementById("notFound").innerHTML = "";
        this.notFoundResult = false;
      }
      if (resp["operation"] == true) {
        document.getElementById("notFound").innerHTML = "";
        this.notFoundResult = false;
      }
      if (resp["data"].medias == undefined || resp["data"].medias.length == 0) {
        this.mediaService.mediaData = resp["data"].medias;
        this.cardData = [];
        this.notFoundResult = true;
      }
      if (resp["data"].medias.length > 0) {
        this.cardData = resp["data"].medias;
        this.totalMedia = this.cardData.length;
        // let videoLength = document.getElementById("videoLength");
        // videoLength.textContent = `${this.cardData.length} Files`;
      }
    });
  }
  detectPortrait() {
    return;
    const media = this.mediaElement.nativeElement;

    if (media instanceof HTMLVideoElement) {
      if (media.videoWidth > media.videoHeight) {
        this.renderer.addClass(media, "landscape-video");
      } else {
        this.renderer.addClass(media, "portrait-video");
      }
    }
  }

  isPortrait(mediaElement: HTMLElement): boolean {
    return mediaElement.classList.contains("portrait-video");
  }

  cardData;
  cardDataPermanent;
  tags = [];
  searchTags = [];
  searchTagsDisplay = [];
  imageDis: boolean = false;
  videoDis: boolean = false;
  orginalFileName: string = "";
  orginal: boolean = false;
  searchValidation: string = "";
  collectionTypeToggle: boolean = false;
  typeToggle: boolean = false;
  currentPathPlay: any;
  playAccess: boolean = false;
  membername: string;
  memberId: string;
  hasRouteChanged: boolean = true;
  isShimmerLoading: boolean = true;
  shimmerLoadingValue = ([] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  ngOnInit(): void {
    // refetching the previous routed data
    this.route.queryParams.subscribe((params: any) => {
      if (params?.type == "public") {
        this.playListOpen(false);
      } else if (params?.type == "private") {
        this.playUsers(false);
      } else {
        this.dataFilterService.disableUser.subscribe((res) => {
          if (res.data == "playlist-user-login") {
            this.playAccess = true;
            let side = document.getElementById("navSideDis");
            side.style.setProperty("display", "none", "important");
            let sideRight = document.getElementById("navSideDisRight");
            sideRight.style.setProperty("display", "none", "important");
            let element = document.querySelector(".col-lg-10") as HTMLElement;
            element.style.transform = "translate(10%, 8%)";
          }
        });
        this.tabViewHandler("normal");
      }
    });

    this.ngZone.run(() => {
      this.dataFilterService.searchData.pipe(take(5)).subscribe((res) => {
        if (res.event.key == "Enter") {
          this.onSearchKeyPress(res.event);
        }
      });
    });

    this.getInvitation();
    this.getOneUserDetails();
    this.loadData();
  }

  getCurrentEndPath(path) {
    const segments = path.split("/");

    return segments[2];
  }

  sortByDate() {
    this.open();
  }
  working(id) {
    var selectedCard = document.getElementById("card-" + id.toString());
    this.mediaService.selectedMedia = this.cardData[id];
    this.router.navigate(["single", id]);
  }

  handleDropdownItemClick(event: Event) {
    event.stopPropagation();
  }

  workingfocus() {
    if ((<HTMLInputElement>document.getElementById("search")).value != "") {
    } else {
    }
  }
  guestId = localStorage.getItem("guestID");
  userId: string;
  dynamicStyle: any = {};
  selectedOptions: any;
  selectedOption: string;
  notFoundResult1: boolean = false;
  selectOption(option) {
    this.selectedOption = option;
    this.dataFilterService.selectAndOption(option);
    if (option === "public") {
      this.collectionTypeToggle = !this.collectionTypeToggle;
      this.selectedOptions = option;
      // this.notFoundResult = true;
      if (this.tags.includes("private")) {
        this.tags = this.tags.filter((tag) => tag !== "private");
      }
      if (!this.tags.includes("public")) {
        this.tags.push(option);
      }
      if (this.tags.length === 0) {
        delete this.dynamicStyle["background-color"];
        this.notFoundResult = true;
      }
      this.dynamicStyle = {
        "background-color": "#FECC28",
      };
    } else if (option === "private") {
      this.collectionTypeToggle = !this.collectionTypeToggle;
      this.dynamicStyle["background-color"] = "lightgreen";
      this.selectedOptions = option;
      this.notFoundResult = false;

      if (this.tags.includes("public")) {
        this.tags = this.tags.filter((tag) => tag !== "public");
      }
      if (!this.tags.includes("private")) {
        this.tags.push(option);
      }
      if (this.tags.length === 0) {
        delete this.dynamicStyle["background-color"];
        this.notFoundResult = true;
      }
      this.dynamicStyle = {
        "background-color": "#FECC28",
      };
    } else if (option === "company") {
      this.typeToggle = !this.typeToggle;
      if (this.tags.includes("reference")) {
        this.tags = this.tags.filter((tag) => tag !== "reference");
      }
      if (!this.tags.includes("company")) {
        this.tags.push(option);
      }

      if (this.tags.length === 0) {
        delete this.dynamicStyle["background-color"];
        this.notFoundResult = true;
      }
      this.dynamicStyle = {
        //
        "background-color": "#FECC28",
      };
    } else if (option === "reference") {
      this.typeToggle = !this.typeToggle;

      if (this.tags.includes("company")) {
        this.tags = this.tags.filter((tag) => tag !== "company");
      }
      if (!this.tags.includes("reference")) {
        this.tags.push(option);
      }
      if (this.tags.length === 0) {
        delete this.dynamicStyle["background-color"];
        this.notFoundResult = true;
      }
      this.dynamicStyle = {
        "background-color": "#FECC28",
      };
    } else if (option === "public" && this.selectedView == 5) {
      //  public ki
      this.AllPlayList = this.AllPlayList.map((item) => {
        const filteredPlayList = item.play_list.filter(
          (data) => data.type === "public"
        );

        item.play_list = filteredPlayList;

        return item;
      });
      this.AllPlayList = this.AllPlayList.filter((item) => {
        return item.play_list.length > 0;
      });
    }
    if (option === "private" && this.selectedView == 5) {
      this.AllPlayList = this.AllPlayList.map((item) => {
        const filteredPlayList = item.play_list.filter(
          (data) => data.type === "private"
        );

        item.play_list = filteredPlayList;

        return item;
      });

      this.AllPlayList = this.AllPlayList.filter((item) => {
        return item.play_list.length > 0;
      });
    } else if (this.selectedView != 5) {
      this.tags.push(option);
    }
    this.tags = Array.from(new Set(this.tags));
    this.searchTagsDisplay = [];
    if (localStorage.getItem("department") == "admin") {
      this.mediaService.getAdminCollection(this.tags).subscribe((resp) => {
        if (resp["operation"] == false) {
          document.getElementById("notFound").innerHTML =
            "No data found for this department";
        } else {
          document.getElementById("notFound").innerHTML = "";
        }
        this.mediaService.mediaData = resp["data"];
        if (resp["data"] == undefined) {
          this.cardData = this.mediaService.mediaData || [];
        }

        if (resp["operation"] == false) {
          document.getElementById("notFound").innerHTML = "";
          this.notFoundResult = true;
        }
        if (resp["operation"] == true) {
          document.getElementById("notFound").innerHTML = "";
          this.notFoundResult = false;
        }

        if (this.mediaService.mediaData.length > 0) {
          if (resp["operation"] == true) {
            document.getElementById("notFound").innerHTML = "";
            this.notFoundResult = false;
          }
          this.cardData = this.mediaService.mediaData;
          this.notFoundResult = false;
        }
        (<HTMLInputElement>document.getElementById("search")).value = "";
      });
    } else {
      this.mediaService.getUserCollection(this.tags).subscribe((resp) => {
        this.totalMedia = resp["data"].totalMedias;
        if (
          resp.hasOwnProperty("operation") &&
          resp["operation"] == false &&
          this.selectedView != 5
        ) {
          this.notFoundResult = true;
        }
        if (resp["operation"] == false && this.selectedView != 5) {
          document.getElementById("notFound").innerHTML = "";
          this.notFoundResult = true;
        } else {
          document.getElementById("notFound").innerHTML = "";
        }
        this.mediaService.mediaData = resp["data"]["medias"];
        this.cardData = this.mediaService.mediaData;
        if (resp["data"] == undefined) {
          this.cardData = this.mediaService.mediaData || [];
        }

        if (this.mediaService.mediaData.length > 0) {
          this.cardData = resp["data"]["medias"];
          this.notFoundResult = false;

          if (resp["operation"] == true) {
            document.getElementById("notFound").innerHTML = "";
            this.notFoundResult = false;
          }

          this.notFoundResult = false;
        }
        (<HTMLInputElement>document.getElementById("search")).value = "";
      });
    }
  }

  filterSearch(elementEvent) {
    const searchTerm: string = elementEvent.target.value.trim();
    this.dataFilterService.searchFilterPass(searchTerm, elementEvent);
  }

  onSearchKeyPress(elementEvent) {
    const searchTerm: string = elementEvent.target.value.trim();
    if (searchTerm === "" || this.tags.includes(searchTerm)) {
      return;
    }
    if (
      (searchTerm == "public" && this.tags.includes("private")) ||
      (searchTerm == "private" && this.tags.includes("public"))
    ) {
      this.searchValidation = "Invalid Search";
      return;
    }
    if (elementEvent.key == "Enter" || elementEvent.key == "click") {
      var val = (<HTMLInputElement>document.getElementById("search")).value;
      (<HTMLInputElement>document.getElementById("search")).value = "";
      this.searchTagsDisplay = [];

      if (val != "") {
        this.tags.push(val);
        this.dataFilterService.searchFilterPass(searchTerm, elementEvent);

        this.searchHistoryService.addToHistory(searchTerm);

        if (localStorage.getItem("department") == "admin") {
          this.mediaService.getAdminCollection(this.tags).subscribe((resp) => {
            this.autocomplete(
              <HTMLInputElement>document.getElementById("search"),
              []
            );
            if (resp["operation"] == false) {
              document.getElementById("notFound").innerHTML = "";
              this.notFoundResult = true;
            } else {
              document.getElementById("notFound").innerHTML = "";
              this.notFoundResult = false;
            }
            if (resp["operation"] == true) {
              document.getElementById("notFound").innerHTML = "";
              this.notFoundResult = false;
            }
            if (resp["data"] == undefined) {
              this.mediaService.mediaData = resp["data"];
              this.cardData = [];
            }
            if (resp["data"].length > 0) {
              this.cardData = resp["data"];
            }
          });
        } else {
          this.mediaService.getUserCollection(this.tags).subscribe((resp) => {
            if (resp["operation"] == false) {
              document.getElementById("notFound").innerHTML = "";
              this.notFoundResult = true;
            } else {
              document.getElementById("notFound").innerHTML = "";
              this.notFoundResult = false;
            }
            if (resp["operation"] == true) {
              document.getElementById("notFound").innerHTML = "";
              this.notFoundResult = false;
            }
            if (
              resp["data"].medias == undefined ||
              resp["data"].medias.length == 0
            ) {
              this.mediaService.mediaData = resp["data"].medias;
              this.cardData = [];
              this.notFoundResult = true;
            }
            if (resp["data"].medias.length > 0) {
              this.cardData = resp["data"].medias;
              this.totalMedia = this.cardData.length;
              this.notFoundResult = false;
              // let videoLength = document.getElementById("videoLength");
              // videoLength.textContent = `${this.cardData.length} Files`;
            }

            (<HTMLInputElement>document.getElementById("search")).value = "";
            this.autocomplete(
              <HTMLInputElement>document.getElementById("search"),
              []
            );
          });
        }
      }
    } else {
      if ((<HTMLInputElement>document.getElementById("search")).value != "") {
        this.searchTagsDisplay = this.searchTags.filter((word) => {
          return word.includes(
            (<HTMLInputElement>document.getElementById("search")).value
          );
        });

        let unique = [];
        for (const tag of this.searchTagsDisplay) {
          if (!unique.includes(tag)) {
            unique.push(tag);
          }
        }

        const uniqueArray = Array.from(
          new Set(this.searchTagsDisplay.map((str) => str.toLowerCase()))
        );
        this.searchTagsDisplay = Array.from(new Set(this.searchTagsDisplay));

        this.dataFilterService.searchFilterPass(searchTerm, elementEvent);
      } else {
        this.searchTagsDisplay = [];
        this.dataFilterService.searchFilterPass(searchTerm, elementEvent);
      }
    }
    this.searchHistory = this.searchHistoryService.getSearchHistory();
    this.searchHistory = Array.from(new Set(this.searchHistory));
    this.mostRecentSearch = this.searchHistoryService.getMostRecentSearch();
    this.recentFiveTags = this.searchHistoryService.getRecentFiveTags();
    this.recentFiveTags = Array.from(new Set(this.recentFiveTags));
  }

  removeTag(idx) {
    this.tags.splice(idx, 1);
    this.dataFilterService.removeTagFunction(idx);
    // this.searchTerm = "";
    if (this.tags.length === 0) {
      this.selectedOption = "";
      this.dynamicStyle = {};
    }

    if (this.currentTabView === "public") {
      this.mediaService
        .getPublicCollection(
          [],
          this.currentPublicPage,
          this.selectedPublicLimit
        )
        .subscribe((resp) => {
          this.mediaService.mediaData = resp["data"].collections;
          this.cardData = this.mediaService.mediaData.filter((card: any) => {
            return card.creatorId != localStorage.getItem("guestID");
          });
          this.totalMedia = resp["data"].totalCollections;
          this.cardData.map((card) => {
            this.uploader_names.push(card);
            try {
              this.handlePuplicFileView(card);
            } catch (error) {}
          });
          this.isShimmerLoading = false;
          this.route.queryParams.subscribe((params: any) => {
            if (params?.id) {
              this.cardData.map((card) => {
                if (card._id == params.id) {
                  this.displayPlayList(
                    card._id,
                    0,
                    card.isPublic,
                    card.creatorId,
                    false
                  );
                }
              });
            }
          });

          this.PdfDetails = this.PdfDetails.filter(
            (detail, index, self) =>
              index === self.findIndex((d) => d.filename === detail.filename)
          );
        });
    } else if (this.currentTabView === "private") {
      this.mediaService
        .getPrivateCollection(
          [],
          this.currentPublicPage,
          this.selectedPublicLimit
        )
        .subscribe((resp) => {
          this.mediaService.mediaData = resp["data"].collections;
          this.cardData = this.mediaService.mediaData;
          this.totalMedia = resp["data"].totalCollections;
          this.cardDataPermanent = this.mediaService.mediaData;
          this.cardData.map((card) => {
            this.uploader_names.push(card);
            this.handlePuplicFileView(card);
          });
          this.route.queryParams.subscribe((params: any) => {
            if (params?.id) {
              this.cardData.map((card) => {
                if (card._id == params.id) {
                  this.displayPlayList(
                    card._id,
                    0,
                    card.isPublic,
                    card.creatorId,
                    false
                  );
                }
              });
            }
          });

          this.isShimmerLoading = false;

          this.PdfDetails = this.PdfDetails.filter(
            (detail, index, self) =>
              index === self.findIndex((d) => d.filename === detail.filename)
          );
        });
    } else {
      if (localStorage.getItem("department") == "admin") {
        this.mediaService.getAdminCollection(this.tags).subscribe((resp) => {
          this.mediaService.mediaData = resp["data"]["medias"];
          this.cardData = this.mediaService.mediaData;

          if (resp["data"].length > 0) {
            this.notFoundResult = false;
            document.getElementById("notFound").innerHTML = "";
            document.getElementById("notFound").style.display = "none";
          }
        });
      } else {
        this.mediaService.getUserCollection(this.tags).subscribe((resp) => {
          this.mediaService.mediaData = resp["data"]["medias"];
          console.log(" yo");
          if (this.mediaService.mediaData) {
            this.cardData = this.mediaService.mediaData;

            if (this.cardData.length) {
              this.notFoundResult = false;
            } else {
              this.notFoundResult = true;
            }
            console.log("sdd");
            this.totalMedia = resp["data"].totalMedias;
          }
          // if (resp["operation"] == false && this.selectedView !== 5) {
          //   // document.getElementById("notFound").innerHTML = "";
          //   this.notFoundResult = true;
          // } else {
          //   // document.getElementById("notFound").innerHTML = "";
          //   this.notFoundResult = false;
          // }
        });
      }
    }
  }

  autocomplete(inp, arr) {
    var currentFocus;
    inp.addEventListener("input", (e) => {
      let el = e.target;
      var a,
        b,
        i,
        val = el.value;

      closeAllLists();
      if (!val) {
        return false;
      }
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", el.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      el.parentNode.appendChild(a);

      let unique = [];
      for (const tag of arr) {
        if (!unique.includes(tag.toLowerCase())) {
          unique.push(tag.trim());
        }
      }
      arr = unique;

      for (i = 0; i < arr.length; i++) {
        if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
          b = document.createElement("DIV");
          b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
          b.innerHTML += arr[i].substr(val.length);
          b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
          b.addEventListener("click", (e) => {
            inp.value = e.target.getElementsByTagName("input")[0].value;

            this.onSearchKeyPress({
              target: { value: inp.value },
              key: "Enter",
            });
            inp.value = "";
            closeAllLists();
          });
          a.appendChild(b);
        }
      }
    });
    inp.addEventListener("keydown", function (e) {
      var x: any = document.getElementById(this.id + "autocomplete-list");
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode == 40) {
        currentFocus++;
        addActive(x);
      } else if (e.keyCode == 38) {
        currentFocus--;
        addActive(x);
      } else if (e.keyCode == 13) {
        e.preventDefault();
        if (currentFocus > -1) {
          if (x) x[currentFocus].click();
        }
      }
    });
    function addActive(x) {
      if (!x) return false;
      removeActive(x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = x.length - 1;
      x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
      }
    }
    function closeAllLists(elmnt = null) {
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }
    document.addEventListener("click", function (e) {
      const target = e.target as HTMLInputElement;
      closeAllLists(e.target);
    });
  }

  public showVideo: boolean = false;

  activeVideoIndex: number = -1;
  showControls(i) {
    this.activeVideoIndex = i;
    const videoElement = document.getElementsByTagName("video");
    const videoElements = document.getElementsByTagName("video");
    for (let i = 0; i < videoElements.length; i++) {
      const videoElement = videoElements[i];
      videoElement.addEventListener("mouseenter", function () {
        videoElement.play();
        const videoIndex = i;
      });
    }
    if (videoElement && this.activeVideoIndex == i) {
      // videoElement.play();
    }
  }

  hideControls(i) {
    this.activeVideoIndex = -1;
    const videoElement = document.getElementsByTagName("video");
    const videoElements = document.getElementsByTagName("video");
    for (let i = 0; i < videoElements.length; i++) {
      const videoElement = videoElements[i];
      videoElement.addEventListener("mouseout", function () {
        videoElement.pause();
        const videoIndex = i;
      });
    }

    if (videoElement && this.activeVideoIndex !== i) {
      // videoElement.pause();
    }
  }

  replayGif(event: MouseEvent): void {
    const imgElement = event.currentTarget as HTMLImageElement;
    const originalSrc = imgElement.src;

    // Resetting the src attribute by removing and re-adding the element from the DOM
    imgElement.parentNode?.replaceChild(imgElement.cloneNode(true), imgElement);
    setTimeout(() => {
      imgElement.src = originalSrc;
    }, 0);
  }

  playVideo(i: number) {
    this.activeVideoIndex = i;
    const videoElement: HTMLVideoElement = this.getVideoElement(i);
    if (videoElement) {
      videoElement.play();
    }
  }

  pauseVideo(i: number) {
    this.activeVideoIndex = -1;
    const videoElement: HTMLVideoElement = this.getVideoElement(i);
    if (videoElement) {
      videoElement.pause();
    }
  }

  private getVideoElement(i: number): HTMLVideoElement | null {
    const videoElements: NodeListOf<HTMLVideoElement> =
      document.querySelectorAll("video");
    if (videoElements.length > i) {
      return videoElements[i];
    }
    return null;
  }

  toggleVideo(index: number): void {
    setTimeout(() => {
      if (this.activeVideoIndex === index) {
        this.activeVideoIndex = -1;
      } else {
        this.activeVideoIndex = index;
      }
    }, 5000);
  }

  onClearButtonClick(): void {
    this.dataFilterService.sendButton(this.onClearButtonClick);
    this.searchHistoryService.clearHistory();
    this.searchHistory = [];
    this.mostRecentSearch = null;
    this.recentFiveTags = [];
  }

  removeFromHistory(item: string): void {
    this.dataFilterService.removeFromItem(item);
    this.searchHistory = this.searchHistory.filter(
      (historyItem) => historyItem !== item
    );
    this.searchHistoryService.updateHistory(this.searchHistory);
    this.recentFiveTags = this.searchHistoryService.getRecentFiveTags();
  }
  uploadNew() {
    this.router.navigate(["/upload"]);
  }

  filterData(type: string) {
    if (type === "user") {
    }
  }

  gridSelection(num: number) {}

  selectedOption1: string = "public";
  toggleOption() {
    this.selectedOption1 =
      this.selectedOption1 === "public" ? "private" : "public";
  }

  selectedDays: number = 1;
  cardDataFor10Days;

  activeGifIndex: number = -1;
  playVideoOnHover(index: number) {
    if (this.activeGifIndex !== index) {
      this.activeGifIndex = index;
      const video = document.getElementById(
        `video${index}`
      ) as HTMLVideoElement;
      if (video) {
        video.play();
      }
    }
  }

  stopVideoOnHover(index: number) {
    if (this.activeGifIndex === index) {
      const video = document.getElementById(
        `video${index}`
      ) as HTMLVideoElement;
      if (video) {
        video.pause();
      }
      this.activeGifIndex = -1;
    }
  }

  nameList: any[] = [];

  // filterBaseOnUploader() {
  //   this.cardData = this.cardData;
  //   const uniqueNamesSet = new Set();
  //   this.uploader_names.forEach((name) => {
  //     if (name !== undefined) {
  //       uniqueNamesSet.add(name.uploadedBy);
  //     }
  //   });

  //   this.nameList = Array.from(uniqueNamesSet);
  //   this.nameList.unshift("all");
  //   this.nameList = this.nameList.filter((name) => {
  //     return name !== undefined && name !== "";
  //   });
  // }

  // nameDisplay: any;
  // selectName(selectedName: string) {
  //   let filteredData: any[];
  //   this.nameDisplay = selectedName;
  //   this.dataFilterService.filterUploadBy(selectedName);
  //   if (selectedName === "all") {
  //     filteredData = this.mediaService.mediaData;
  //   } else {
  //     filteredData = this.mediaService.mediaData.filter(
  //       (data: any) => data.uploadedBy === selectedName
  //     );
  //   }

  //   this.cardData = [...filteredData];
  // }

  filterBaseOnUploader(tab: string) {
    const uniqueNamesSet = new Set<string>();
    if (tab === "public") {
      this.uploader_names.forEach((name) => {
        if (
          name !== undefined &&
          name.creatorName !== undefined &&
          name.creatorName.trim() !== ""
        ) {
          uniqueNamesSet.add(name.creatorName.trim());
        }
      });
    } else {
      this.uploader_names.forEach((name) => {
        if (
          name !== undefined &&
          name.uploadedBy !== undefined &&
          name.uploadedBy.trim() !== ""
        ) {
          uniqueNamesSet.add(name.uploadedBy.trim());
        }
      });
    }
    this.nameList = ["all", ...Array.from(uniqueNamesSet)];
  }

  nameDisplay: string = "Uploaded By";
  selectName(selectedName: string, tab) {
    this.nameDisplay = selectedName;
    let filteredData: any[] = [];
    this.notFoundResult = false;

    if (selectedName === "all") {
      filteredData = this.mediaService.mediaData;
      this.notFoundResult = false;
    } else {
      filteredData = this.mediaService.mediaData.filter((data) => {
        if (tab === "public") {
          return data.creatorName === selectedName;
        } else {
          return data.uploadedBy === selectedName;
        }
      });
    }

    this.cardData = [...filteredData];

    if (this.cardData.length === 0) {
      this.notFoundResult = true;
    }
    this.totalMedia = this.cardData.length;
  }

  selectedView: any = 3;
  gridview: boolean = false;
  GridView(event) {
    this.selectPlayList = false;
    this.selectPlayListPlay = false;
    this.selectPlayListReq = false;
    this.activeTab = "";
    this.currentPageNume = 1;
    if (event.target.checked) {
      this.selectedView = 4;
      let normal = document.querySelector(".onoffswitch-switch") as HTMLElement;
      normal.style.left = "";
      normal.style.right = "0px";
      var playlistDiv = document.getElementById("playlistDiv");

      if (playlistDiv.classList.contains("active")) {
        playlistDiv.classList.remove("active"); // Remove the 'active' class if playlist is closed
      }

      // this.router.navigate(["/collection/grid-view"]);
      this.tabViewHandler("grid");
    } else {
      this.selectedView = 3;
      let normal = document.querySelector(".onoffswitch-switch") as HTMLElement;
      normal.style.left = "0px";
      normal.style.right = "";
      this.tabViewHandler("normal");

      if (playlistDiv.classList.contains("active")) {
        playlistDiv.classList.remove("active"); // Remove the 'active' class if playlist is closed
      }
    }
  }

  selectedDate: string;
  dataItems = [];
  notFoundResult: boolean = false;
  onDateSelected(event: any) {
    let startDate = event.startDate.$d;
    let endDate = event.endDate.$d;
    this.filterDataByDate(startDate, endDate);
    this.dataFilterService.filterSortBy(startDate, endDate);
  }
  fromDateSelected: any;
  fromMonthSelected: any;
  toDateSelected: any;
  toMonthSelected: any;

  filterDataByDate(startDate, endDate) {
    const momentStartDate = moment(startDate, "YYYY-MM-DD");
    const momentEndDate = moment(endDate, "YYYY-MM-DD");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let fromDate = new Date(startDate);
    let fromDay = fromDate.getDate();
    this.fromDateSelected = fromDay;
    let fromMonth = monthNames[fromDate.getMonth()];
    this.fromMonthSelected = fromMonth;

    let toDate = new Date(endDate);
    let toDay = toDate.getDate();
    this.toDateSelected = toDay;
    let toMonth = monthNames[toDate.getMonth()];
    this.toMonthSelected = toMonth;

    this.cardData = this.filterMediaByDays(
      this.mediaService.mediaData,
      momentStartDate,
      momentEndDate
    );

    if (this.cardData.length === 0) {
      this.notFoundResult = true;
    } else {
      this.notFoundResult = false;
    }
    this.totalMedia = this.cardData.length;
  }

  filterMediaByDays(
    mediaData: any[],
    startDate: moment.Moment,
    endDate: moment.Moment
  ) {
    return mediaData.filter((media) => {
      const createdAtDate = moment(media.createdAt);
      return createdAtDate.isBetween(startDate, endDate, null, "[]");
    });
  }

  clearDateRange() {
    this.selectedDateRange = { startDate: null, endDate: null };
  }

  filterItemsByExtension(extension: string) {
    this.notFoundResult = false;
    this.filteredName = extension;

    this.dataFilterService.filterExt(extension);

    if (extension === "all") {
      this.cardData = this.allDataForFilter;
    } else if (extension === "images") {
      this.cardData = this.allDataForFilter.filter((media) => {
        const fileExtension = media.extension.toLowerCase();
        return [".jpeg", ".jpg", ".png"].includes(fileExtension);
      });
    } else if (extension === "pdf") {
      this.cardData = this.allDataForFilter.filter(
        (media) => media.extension === ".pdf"
      );
    } else if (extension === "excel") {
      this.cardData = this.allDataForFilter.filter((media) => {
        const fileExtension = media.extension.toLowerCase();
        return [".xlsx", ".xls"].includes(fileExtension);
      });
    } else if (extension === "docs") {
      this.cardData = this.allDataForFilter.filter((media) => {
        const fileExtension = media.extension.toLowerCase();
        return [".doc", ".docx"].includes(fileExtension);
      });
    } else if (extension === "videos") {
      this.cardData = this.allDataForFilter.filter((media) => {
        const fileExtension = media.extension.toLowerCase();
        return [".mp4", ".webm", ".mov", ".mkv"].includes(fileExtension);
      });
    }
    if (extension === "pptx") {
      this.cardData = this.allDataForFilter.filter((media) => {
        const fileExtension = media.extension.toLowerCase();
        return [".pptx"].includes(fileExtension);
      });
    }

    if (this.cardData.length === 0) {
      this.notFoundResult = true;
    }
    this.totalMedia = this.cardData.length;
  }

  ngOnDestroy() {
    this.muteVideoOnScroll();
    this.savePlayList(0, null);
    this.shareList(null);
    $(".modal-backdrop").remove();
    this.destroy$.next();
    this.destroy$.complete();
  }
  muteVideoOnScroll() {
    const video = document.getElementById("video") as HTMLVideoElement;
    if (video) {
      video.muted = true;
    }
  }

  isDropdownOpen: number | null = null;
  isDropdownOpen1: number | null = null;

  showDropdown(index: number) {
    index = index;
    if (this.isDropdownOpen === index) {
      // Clicked on the same dropdown, close it
      this.isDropdownOpen = null;
    } else {
      // Clicked on a different dropdown, open it
      this.isDropdownOpen = index;
    }
  }

  closeDropdown(event: Event) {
    if (
      !(event.target instanceof HTMLElement) ||
      !event.target.matches(".dropbtn")
    ) {
      this.isDropdownOpen = null;
    }
  }

  closeDropdown1(event: Event) {
    if (
      !(event.target instanceof HTMLElement) ||
      !event.target.matches(".dropbtn")
    ) {
      this.isDropdownOpen1 = null;
    }
  }

  getOne(id) {
    this.mediaService.getOneByMediaId(id).subscribe((res: any) => {
      if ([".mp4", ".webm", ".mov", ".mkv"].includes(res["data"].extension)) {
        fetch(res["data"].lg)
          .then((response) => response.blob())
          .then((blob) => {
            saveAs(blob, `${res["data"].title}.mp4`);
          })
          .catch((error) => {
            console.error("Error downloading the file:", error);
          });
      }

      if ([".png", ".jpg", ".jpeg"].includes(res["data"].extension)) {
        const imageUrl = res["data"].ogFileUrl;
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = imageUrl;
        a.click();
      }
    });
  }

  download(id) {
    this.getOne(id);
  }
  modalVisible = false;
  OneCardDetails: any;
  selectedTagId: any;
  selectedCollectionIndex: number;
  savePlayList(index: number, id: any) {
    this.OneCardDetails = this.cardData.filter((item) => item._id == id);
    this.collectionType = "";
    this.collectionTags = "";
    this.modalVisible = true;
    this.mediaId = id;
    if (this.isDropdownOpen1 === index) {
      this.isDropdownOpen1 = null;
    } else {
      this.isDropdownOpen1 = index;
    }

    let saveModalEl = document.querySelector("#saveModal");
    let saveModal = new (window as any).bootstrap.Modal(saveModalEl);
    saveModal.show();
    this.addToExistingCollection();
  }
  playListview: boolean = false;

  closeModal(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      this.modalVisible = false;

      let saveModalEl = document.querySelector("#saveModal");
      let saveModal = new (window as any).bootstrap.Modal(saveModalEl);

      saveModalEl.addEventListener("hidden.bs.modal", () => {
        this.removeModalElement(saveModalEl);
      });
      saveModal.hide();
    }
  }

  removeModalElement(modalElement: Element) {
    if (modalElement && modalElement.parentElement) {
      modalElement.parentElement.removeChild(modalElement);
    }
  }
  activeTab: string = "";
  playListOpen(route: boolean = true) {
    this.activeTab = "playlistDiv";
    this.selectedView = 5;
    this.playListview = !this.playListview;
    this.isPrevCardFolders = false;
    this.tabViewHandler("public");
    this.lastViewedCollection = "public";
    if (route) {
      this.router.navigate(["/collection"], {
        queryParams: { type: "public" },
      });
    }
  }

  AllPlayList: any;
  PlayListNames: any = [];
  collectionTags: string = "";
  collectionType: string = "Public";
  playList = [];

  addCollectionType(string: any) {
    this.collectionType = string;
  }

  createPlayLists(body) {
    let name = body.name;
    let type = body.type;

    body = {
      play_list: { name: name, type: type, data: this.OneCardDetails[0] },
    };
    this.mediaService.createPlayList(body).subscribe(
      (res) => {},
      (err) => [console.log(err)]
    );
  }

  createCollection() {
    if (this.collectionTags && this.collectionType) {
      this.playList.push({
        name: this.collectionTags,
        type: this.collectionType,
      });
    }

    this.createPlayLists(this.playList[0]);
  }
  selectPlayList: boolean = false;
  selectPlayListPlay: boolean = false;
  selectPlayListReq: boolean = false;
  showPlayList: any = [];
  isPrevCardFolders: boolean = false;
  showPublicFolder = true;
  lastViewedCollection: string = "public";
  displayPlayList(
    id: any,
    index: any,
    type: boolean,
    userId: string,
    route: boolean = true
  ) {
    if (route) {
      this.hasRouteChanged = false;
      this.router.navigate([], {
        queryParams: { type: this.lastViewedCollection, id },
      });
    }

    this.backView = this.lastViewedCollection;
    this.cardData.map((cards, idx) => {
      if (cards._id === id) {
        this.mediaService.mediaData = cards.media;
        this.cardData = this.mediaService.mediaData;
        this.allDataForFilter = this.mediaService.mediaData;
        this.cardDataPermanent = this.mediaService.mediaData;
        this.cardData.map((card) => {
          this.totalMedia = this.cardData.length;
          this.uploader_names.push(card);

          if (
            card.extension === ".pptx" ||
            card.extension === ".pdf" ||
            card.extension === ".docx" ||
            card.extension === ".doc" ||
            card.extension === ".xlsx" ||
            card.extension === ".xls" ||
            card.extension == ".js" ||
            card.extension == ".git" ||
            card.extension == ".html"
          ) {
            let url = card.ogFileUrl;

            var filename = url.substring(url.lastIndexOf("/") + 1);
            this.orginal = true;
            this.orginalFileName = filename;

            let excel = document.getElementById("thumbnail");

            this.PdfDetails.push({
              filename: filename,
              orginal: card.ogFileUrl,
            });

            if (card.extension === ".xlsx" || card.extension === ".xls") {
              this.excelUrl = card.ogFileUrl.replace(/^https?:\/\//, "");
            }
            if (card.extension === ".pptx") {
              this.pptxUrl = card.ogFileUrl;
            }
            if (card.extension === ".pdf") {
              this.pdfUrl = card.ogFileUrl;
            }
            if (card.extension === ".docx" || card.extension === ".doc") {
              this.docsUrl = card.ogFileUrl;
            }
            if (card.extension === ".js") {
              this.jsUrl = card.ogFileUrl.replace(/^https?:\/\//, "");
            }
            this.pdfDisplay = this.pdfDisplay === "none" ? "inline" : "none";
          }
        });
        this.PdfDetails = this.PdfDetails.filter(
          (detail, index, self) =>
            index === self.findIndex((d) => d.filename === detail.filename)
        );
      }
    });
    this.isPrevCardFolders = true;
  }
  selectedCollection = "";

  selectedPlayList() {
    let data = this.onlyUserPlaylist.filter(
      (item) => item.name == this.collectionTags
    );
    this.collectionType = data[0].type;
    this.collectionTags = data[0].name;
    this.selectedCollection = this.collectionTags;
    if (this.currentPathPlay.split("/")[1] == "collection") {
      this.selectedView = 6;
    }
  }

  removeOne(id: any, name: string, type: string) {
    let body = { id, name, type };
    this.mediaService.removeFromPlayList(body).subscribe(
      (res) => {},
      (err) => {}
    );
  }

  deletePlayListId(name: string, type: string) {
    let body = { name, type };
    this.mediaService.deletePlayList(body).subscribe(
      (res) => {},
      (err) => {}
    );
  }

  filterPlayList() {
    if (this.selectPlayList) {
    }
  }
  inputValue: string = "";
  play_list_id: "";
  access_password: any;
  createPasword() {
    const chars =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let password = "";

    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }

    return password;
  }
  selectedSahreFolder: any;
  shareList(id: any) {
    this.selectedSahreFolder = this.cardData.filter((card) => card._id === id);
    this.access_password = this.createPasword();
    this.play_list_id = id;
    this.inputValue = `${window.location.origin}/#/collection/play-list?id=${id}&shareId=${this.access_password}`;
    let saveModalEl = document.querySelector("#shareP");
    let saveModal = new (window as any).bootstrap.Modal(saveModalEl);
    saveModal.show();
    this.getAllUserList();

    var body: any = {
      shareId: this.access_password,
      contentId: id,
      contentType: "COLLECTION",
      sharedUrl: this.inputValue,
      password: this.access_password,
    };
    this.mediaService.sharePlay(body).subscribe(
      (res) => {
        // let input = document.getElementById("share-url") as HTMLInputElement;
        // this.inputValue = res["link"];
        // this.access_password = res["password"];
        // input.value = res["link"];
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  onClickShareCollection() {}

  //Fetching Users on collab dropdown button click
  teams: any;
  teamslength: any;
  canAddMediaId: boolean = true;
  canAddMembersId: boolean = true;
  slelectedCardDAta: any;
  collaborate(cardData: any) {
    this.play_list_id = cardData._id;
    const membersArr = [];
    cardData.members.forEach((crd, idx) => {
      membersArr.push({
        ...crd,
        canAddMedia: this.canAddMediaId,
        canAddMembers: this.canAddMembersId,
      });
    });
    let saveModalEl = document.querySelector("#collaborate");
    let saveModal = new (window as any).bootstrap.Modal(saveModalEl);
    saveModal.show();
    let body = {
      collectionId: cardData._id,
      members: membersArr,
    };
    this.slelectedCardDAta = body;
    this.getAllUserList();
  }

  //Calling API on Invite selected Button
  getSelectedInvite(cardData: any) {
    this.play_list_id = cardData._id;
    const membersArr = [];
    cardData.members.forEach((crd, idx) => {
      membersArr.push({
        memberId: this.memberId,
        memberName: this.membername,
        memberProfileImg: crd.memberProfileImg,
        canAddMedia: this.canAddMediaId,
        canAddMembers: this.canAddMembersId,
      });
    });
    let body = {
      collectionId: cardData.collectionId,
      members: this.member,
    };
    this.mediaService.InvitePlayList(body, cardData).subscribe(
      (res) => {
        this.teams = res["data"].play_list[0].team;
        const uniqueSet = new Set();
        const uniqueArray = this.teams.filter((entry) => {
          const key = `${entry.user}-${entry.id}`;
          if (!uniqueSet.has(key)) {
            uniqueSet.add(key);
            return true;
          }
          return false;
        });

        this.teams = uniqueArray;
        this.teamslength = this.teams.length;
      },
      (err) => {}
    );
  }

  //Inviting Users To collab
  member: any[] = [];
  toggleInvitation(user: any) {
    if (user.invited) {
      // Remove the user from the list of invited members
      const index = this.member.findIndex(
        (member) => member.memberId === user.id
      );
      if (index !== -1) {
        this.member.splice(index, 1);
      }
    } else {
      // list of invited members
      this.member.push({
        memberId: user.id,
        memberName: user.username,
        memberProfileImg: user.profile,
        canAddMedia: this.canAddMediaId,
        canAddMembers: this.canAddMembersId,
      });
    }
    user.invited = !user.invited;
  }

  //Getting Username List in Collab Modal
  allUsers: any = [];
  getAllUserList() {
    this.UserService.guestList().subscribe(
      (res) => {
        let data = res["data"];
        data.map((user) => {
          this.allUsers.push({
            username: user.userName,
            id: user._id,
            profile: user.profileImg,
          });
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy to clipboard";
  }

  shareFile(id: any) {
    let body = { user_id: id };
    this.mediaService.sendDetails(this.play_list_id, body).subscribe(
      (res) => {
        alertModalWithoutConfirm("success", "send successfully");
        const modal = document.querySelector("#shareP") as HTMLElement;

        modal.classList.remove("show");

        modal.style.display = "none";
        const backdrop = document.querySelector(
          ".modal-backdrop.show"
        ) as HTMLElement;

        backdrop.style.opacity = "0";
      },
      (err) => {
        alertModal("warning", err.message);
      }
    );
  }

  //Copy the Url on tooltip click in share Modal
  myFunction(id: string) {
    var copyText = document.getElementById(id) as HTMLInputElement;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: ";
  }

  //Filtering Username in collab Modal
  searchText: string = "";
  filteredUsers: any[] = this.allUsers;
  filterUsers() {
    this.filteredUsers = this.allUsers.filter((user) => {
      return user.username
        .toLowerCase()
        .includes(this.searchText.toLowerCase());
    });
  }

  invitationDetails: any[] = [];
  invitationDetailslength: number = 0;
  sendername: any;
  getInvitation() {
    let id = localStorage.getItem("guestID");
    this.activeTab = "playlist";

    this.mediaService.getUserInviteRequest().subscribe(
      (res) => {
        let data = res["data"];
        this.invitationDetails = data;
        this.invitationDetailslength = this.invitationDetails.length;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // acceptInvitation(i) {
  //   let data = this.invitationDetails[i];
  //   data.select = true;
  //   let body = data;
  //   this.mediaService.acceptInvitation(body).subscribe(
  //     (res) => {},
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  acceptInvitation(i: number, collaborationId: string, collectionId: string) {
    let data = this.invitationDetails[i];
    data.select = true;
    const body: any = {
      collaborationId: collaborationId,
      collectionId: collectionId,
    };
    body.collaborationId = this.invitationDetails[i]._id;
    this.mediaService.acceptInvitation(body).subscribe(
      (res) => {
        this.invitationDetails.splice(i, 1);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  rejectInvitation(i, collaborationId: string) {
    let data = this.invitationDetails[i];
    const body: any = {
      collaborationId: collaborationId,
    };
    body.collaborationId = this.invitationDetails[i]._id;
    this.mediaService.rejectInvitation(body).subscribe(
      (res) => {
        this.invitationDetails.splice(i, 1);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  playUsers(route: boolean = true) {
    this.selectedView = 6;
    this.selectPlayListPlay = false;
    this.getInvitation();
    this.activeTab = "playlistUser";
    this.isPrevCardFolders = false;
    this.tabViewHandler("private");
    this.lastViewedCollection = "private";
    if (route) {
      this.router.navigate([], {
        queryParams: { type: "private" },
      });
    }
  }

  // goBack() {
  //   window.history.back();
  // }

  goBack() {
    // this.tabViewHandler(this.backView[this.backView.length - 2]);
    if (this.backView === "public") {
      history.back();
    } else if (this.backView === "private") {
      history.back();
    } else {
      this.tabViewHandler(this.backView);
    }
  }

  sendData(data: any[]) {
    this.dataFilterService.updateData(data);
  }

  items: string[] = [];
  isLoading = false;

  toggleLoading = () => (this.isLoading = !this.isLoading);
  loadData = () => {
    this.toggleLoading();
    this.paginationService
      .getItems(this.selectedLimit, this.selectedLimit)
      .subscribe({
        next: (response) => (this.items = response),
        error: (err) => console.log(err),
        complete: () => this.toggleLoading(),
      });
  };

  // this method will be called on scrolling the page
  appendData = () => {
    this.toggleLoading();
    this.mediaService
      .getUserCollection([], this.currentPageNume, this.selectedLimit)
      .subscribe({
        next: (response) => (
          (this.cardData = [...this.cardData, ...response["data"].medias]),
          (this.allDataForFilter = [...this.cardData])
        ),
        error: (err) => console.log(err),
        complete: () => this.toggleLoading(),
      });
  };
  appendPublicCardData = () => {
    this.toggleLoading();
    this.mediaService
      .getPublicCollection([], this.currentPublicPage, this.selectedPublicLimit)
      .subscribe({
        next: (response) =>
          (this.cardData = [...this.cardData, ...response["data"].collections]),
        error: (err) => console.log(err),
        complete: () => this.toggleLoading(),
      });
  };

  onScroll = () => {
    if (this.cardData.length === 100) {
      this.currentPageNume++;
      this.appendData();
    }
  };
  onPublicScroll = () => {
    if (this.cardData.length === 100) {
      this.currentPublicPage++;
      this.appendPublicCardData();
    }
  };

  PublicItems: string[] = [];
  // isPublicLoading = false;

  togglePublicLoading = () => (this.isLoading = !this.isLoading);
  loadPublicData = () => {
    this.toggleLoading();
    this.paginationService
      .getItems(this.selectedLimit, this.selectedLimit)
      .subscribe({
        next: (response) => (this.items = response),
        error: (err) => console.log(err),
        complete: () => this.toggleLoading(),
      });
  };

  // this method will be called on scrolling the page
  appendPublicData = () => {
    this.toggleLoading();
    this.mediaService
      .getPublicCollection([], this.currentPublicPage, this.selectedPublicLimit)
      .subscribe({
        next: (response) =>
          (this.cardData = [...this.cardData, ...response["data"].medias]),
        error: (err) => console.log(err),
        complete: () => this.toggleLoading(),
      });
  };

  currentTabView: string = "normal";
  currentPageNume: number = 1;
  selectedLimit: number = 100;
  totalMedia: number = 0;
  currentPublicPage: number = 1;
  selectedPublicLimit: number = 100;
  backView: string = "normal";
  tabViewHandler(view: string) {
    console.log(view);

    this.cardData = [];
    if (view === "grid" || view === "normal") {
      this.isShimmerLoading = true;

      this.currentTabView = view;

      this.mediaService
        .getUserCollection([], this.currentPageNume, this.selectedLimit)
        .subscribe((resp) => {
          this.mediaService.mediaData = resp["data"].medias;
          this.cardData = this.mediaService.mediaData;
          this.allDataForFilter = this.cardData;
          this.totalMedia = resp["data"].totalMedias;
          this.cardDataPermanent = this.mediaService.mediaData;

          this.cardData.map((card) => {
            this.uploader_names.push(card);

            if (
              card.extension === ".pptx" ||
              card.extension === ".pdf" ||
              card.extension === ".docx" ||
              card.extension === ".doc" ||
              card.extension === ".xlsx" ||
              card.extension === ".xls" ||
              card.extension == ".js" ||
              card.extension == ".git" ||
              card.extension == ".html"
            ) {
              let url = card.ogFileUrl;

              var filename = url.substring(url.lastIndexOf("/") + 1);
              this.orginal = true;
              this.orginalFileName = filename;

              let excel = document.getElementById("thumbnail");

              this.PdfDetails.push({
                filename: filename,
                orginal: card.ogFileUrl,
              });

              if (card.extension === ".xlsx" || card.extension === ".xls") {
                this.excelUrl = card.ogFileUrl.replace(/^https?:\/\//, "");
              }
              if (card.extension === ".pptx") {
                this.pptxUrl = card.ogFileUrl;
              }
              if (card.extension === ".pdf") {
                this.pdfUrl = card.ogFileUrl;
              }
              if (card.extension === ".docx" || card.extension === ".doc") {
                this.docsUrl = card.ogFileUrl;
              }
              if (card.extension === ".js") {
                this.jsUrl = card.ogFileUrl.replace(/^https?:\/\//, "");
              }
              this.pdfDisplay = this.pdfDisplay === "none" ? "inline" : "none";
            }
          });
          this.isShimmerLoading = false;

          // this.route.queryParams.subscribe((params: any) => {
          //   if (params?.id) {
          //     console.log(params.id);
          //     this.cardData.map((card) => {
          //       if (card._id == params.id) {
          //         this.displayPlayList(
          //           card._id,
          //           0,
          //           card.isPublic,
          //           card.creatorId,
          //           false
          //         );
          //       }
          //     });
          //   }
          // });

          this.PdfDetails = this.PdfDetails.filter(
            (detail, index, self) =>
              index === self.findIndex((d) => d.filename === detail.filename)
          );
        });
    }
    if (view === "public") {
      this.currentTabView = view;
      this.isShimmerLoading = true;

      this.mediaService
        .getPublicCollection(
          [],
          this.currentPublicPage,
          this.selectedPublicLimit
        )
        .subscribe((resp) => {
          this.mediaService.mediaData = resp["data"].collections;
          this.cardData = this.mediaService.mediaData.filter((card: any) => {
            return card.creatorId != localStorage.getItem("guestID");
          });
          this.totalMedia = resp["data"].totalCollections;
          this.cardData.map((card) => {
            this.uploader_names.push(card);
            try {
              this.handlePuplicFileView(card);
            } catch (error) {}
          });
          this.isShimmerLoading = false;
          this.route.queryParams.subscribe((params: any) => {
            if (params?.id) {
              this.cardData.map((card) => {
                if (card._id == params.id) {
                  this.displayPlayList(
                    card._id,
                    0,
                    card.isPublic,
                    card.creatorId,
                    false
                  );
                }
              });
            }
          });

          this.PdfDetails = this.PdfDetails.filter(
            (detail, index, self) =>
              index === self.findIndex((d) => d.filename === detail.filename)
          );
        });
    }
    if (view === "private") {
      this.currentTabView = view;
      this.isShimmerLoading = true;

      this.mediaService
        .getPrivateCollection(
          [],
          this.currentPublicPage,
          this.selectedPublicLimit
        )
        .subscribe((resp) => {
          this.mediaService.mediaData = resp["data"].collections;
          this.cardData = this.mediaService.mediaData;
          this.totalMedia = resp["data"].totalCollections;
          this.cardDataPermanent = this.mediaService.mediaData;
          this.cardData.map((card) => {
            this.uploader_names.push(card);
            this.handlePuplicFileView(card);
          });
          this.route.queryParams.subscribe((params: any) => {
            if (params?.id) {
              this.cardData.map((card) => {
                if (card._id == params.id) {
                  this.displayPlayList(
                    card._id,
                    0,
                    card.isPublic,
                    card.creatorId,
                    false
                  );
                }
              });
            }
          });

          this.isShimmerLoading = false;

          this.PdfDetails = this.PdfDetails.filter(
            (detail, index, self) =>
              index === self.findIndex((d) => d.filename === detail.filename)
          );
        });
    }
  }

  handlePuplicFileView(card) {
    if (card.media.length > 0) {
      card.media.map((mediaData) => {
        if (
          mediaData.extension === ".pptx" ||
          mediaData.extension === ".pdf" ||
          mediaData.extension === ".docx" ||
          mediaData.extension === ".doc" ||
          mediaData.extension === ".xlsx" ||
          mediaData.extension === ".xls" ||
          mediaData.extension === ".js" ||
          mediaData.extension === ".git" ||
          mediaData.extension === ".html"
        ) {
          let url = mediaData.ogFileUrl;

          var filename = url.substring(url.lastIndexOf("/") + 1);
          this.orginal = true;
          this.orginalFileName = filename;

          let excel = document.getElementById("thumbnail");

          this.PdfDetails.push({
            filename: filename,
            orginal: mediaData.ogFileUrl,
          });

          if (
            mediaData.extension === ".xlsx" ||
            mediaData.extension === ".xls"
          ) {
            this.excelUrl = mediaData.ogFileUrl.replace(/^https?:\/\//, "");
          }
          if (mediaData.extension === ".pptx") {
            this.pptxUrl = mediaData.ogFileUrl;
          }
          if (mediaData.extension === ".pdf") {
            this.pdfUrl = mediaData.ogFileUrl;
          }
          if (
            mediaData.extension === ".docx" ||
            mediaData.extension === ".doc"
          ) {
            this.docsUrl = mediaData.ogFileUrl;
          }
          if (mediaData.extension === ".js") {
            this.jsUrl = mediaData.ogFileUrl.replace(/^https?:\/\//, "");
          }
          this.pdfDisplay = this.pdfDisplay === "none" ? "inline" : "none";
        }
      });
    }
  }

  normalView(view: "grid" | "normal") {
    let normal = document.querySelector(".onoffswitch-switch") as HTMLElement;
    let removeBox = document.querySelector(".active-tab-underline");
    removeBox?.classList.remove("active-tab-underline");
    this.currentPageNume = 1;
    if (view === "normal") {
      normal.style.left = "0px";
      normal.style.right = "";
      this.tabViewHandler("normal");
    }

    if (view === "grid") {
      normal.style.right = "0px";
      normal.style.left = "";
      this.tabViewHandler("grid");
    }
  }

  onlyUserPlaylist: any;
  playUsersList: any;
  getOneUserDetails() {
    let id = localStorage.getItem("guestID");

    this.UserService.getOneUser(id).subscribe(
      (res) => {
        let data = res["data"];
        this.AllPlayList = data.user.play_list;
        this.playUsersList = data.user.play_list;

        const nameAndTypeArray = this.AllPlayList.map((item) => {
          return {
            name: item.play_list[0].name,
            type: item.play_list[0].type,
          };
        });

        this.onlyUserPlaylist = nameAndTypeArray;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  clearSelectedDate() {
    this.tabViewHandler("normal");
    const customDateText = document.getElementById("customDateText");
    const dateCells = document.querySelectorAll("td.available");

    dateCells.forEach((cell) => {
      cell.classList.remove(
        "start-date",
        "in-range",
        "end-date",
        "today",
        "active"
      );
    });

    let startDate = null;
    let endDate = null;

    // this.filterDataByDate(startDate, endDate);
    if (customDateText) {
      customDateText.textContent = "";
      if (typeof this.dataFilterService !== "undefined") {
        this.dataFilterService.clearDateDetails("clear");
      }
    }

    if (this.dataFilterService) {
    }
    let firstButton = document.querySelector(
      ".buttons_input button:nth-child(1)"
    ) as HTMLButtonElement;

    firstButton.click();

    if (typeof this.dataFilterService !== "undefined") {
      this.dataFilterService.clearDate({ startDate, endDate });
    }

    const matSortBy = document.querySelector(
      ".fx-date-range"
    ) as HTMLInputElement;
    if (matSortBy) {
      matSortBy.value = "";
    }
  }

  checkCondition(card, res) {
    return (
      (card.extension == ".pdf" && card.ogFileUrl == res.orginal) ||
      (card.extension == ".pptx" && card.ogFileUrl == res.orginal) ||
      (card.extension == ".xlsx" && card.ogFileUrl == res.orginal) ||
      (card.extension == ".xls" && card.ogFileUrl == res.orginal) ||
      (card.extension == ".docx" && card.ogFileUrl == res.orginal) ||
      (card.extension == ".doc" && card.ogFileUrl == res.orginal) ||
      (card.extension == ".js" && card.ogFileUrl == res.orginal) ||
      (card.extension == ".mp4" && card.ogFileUrl == res.orginal) ||
      (card.extension == ".webm" && card.ogFileUrl == res.orginal) ||
      (card.extension == ".mov" && card.ogFileUrl == res.orginal) ||
      (card.extension == ".mkv" && card.ogFileUrl == res.orginal)
    );
  }
  deleteMedia(id: string) {
    alertModalDelete("You won't be able to revert this!").then(
      (isConfirmed) => {
        if (isConfirmed) {
          this.mediaService.deleteOnyById(id).subscribe(
            (res) => {
              window.location.reload();
            },
            (error) => {
              console.error(error);
              const message = error.error.message || "Something went wrong!";
              alertModalDelete(message);
            }
          );
        } else {
          alertModalDelete("Deletion cancelled");
        }
      }
    );
  }

  deleteCollectionMedia(id: string) {
    alertModalDelete("You won't be able to revert this!").then(
      (isConfirmed) => {
        if (isConfirmed) {
          this.mediaService.deleteCollectionPlaylist(id).subscribe(
            (res) => {
              window.location.reload();
            },
            (error) => {
              console.error(error);
              const message = error.error.message || "Something went wrong!";
              alertModalDelete(message);
            }
          );
        } else {
          alertModalDelete("Deletion cancelled");
        }
      }
    );
  }
  existingCollections: any;
  addToExistingCollection() {
    this.mediaService.getCollectionGroup().subscribe((res) => {
      this.existingCollections = res["data"];
    });
  }

  onAddCollection() {
    var collectionTag = document.querySelector(
      ".collection-input"
    ) as HTMLInputElement;
    this.collectionTags = collectionTag.value;
    var body: any = {
      mediaId: this.mediaId,
    };
    if (this.collectionTags) {
      body.collectionData = {
        title: this.collectionTags,
        isPublic: this.collectionType === "Public" ? true : false,
      };
    } else {
      body.collectionId =
        this.existingCollections[this.selectedCollectionIndex]?._id;
    }
    this.mediaService.addMediaCollection(body).subscribe(
      (res: any) => {
        this.mediaId = res["data"]._id;
      },
      (error: any) => {
        console.error("Error creating collection:", error);
      }
    );
  }
}
