import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  login_url = environment.host + "auth/login";

  post_register_url = environment.host + "users";
  get_all_users_url = environment.host + "users";
  update_token_url = environment.host + "users/token";
  remove_token_url = environment.host + "users/removetoken";
  update_user_status_url = environment.host + "update";
  update_user_status_scheduled_url = environment.host + "updateSked";
  singleChatUserApi = environment.host + "chats";

  add_feedback_url = environment.host + "feedbacks";

  selectedUserDetails;

  allUsersData;

  public getToken(): string {
    return localStorage.getItem("guestToken");
  }

  login(cred: { username: string; password: string }) {
    console.log("cred", cred);
    // const httpHeaders= new HttpHeaders();
    // httpHeaders.append('Content-Type','application/x-www-form-urlencoded');
    return this.httpClient.post(this.login_url, {
      userName: cred.username,
      password: cred.password,
    });
  }

  signUp(formData) {
    console.log("form data in service", formData);
    return this.httpClient.post(this.post_register_url, formData);
  }

  updateUser(id, bodyData) {
    return this.httpClient.put(this.post_register_url + "/" + id, bodyData);
  }

  deleteUser(id) {
    return this.httpClient.delete(this.post_register_url + "/" + id);
  }

  getAllUsers() {
    //API Blocked For Now
    let mockObs$: Observable<any> = from("");
    return mockObs$;
    return this.httpClient.get(this.get_all_users_url);
  }

  getSingleUser(id) {
    return this.httpClient.get(this.get_all_users_url + "/" + id);
  }

  profileImage(formData) {
    console.log(formData);
    return this.httpClient.post(
      this.post_register_url + "/" + "fileUpload",
      formData
    );
  }

  guestList() {
    var headers = this.get_auth_token();
    return this.httpClient.get(this.get_all_users_url, headers);
  }

  get_auth_token() {
    var token = localStorage.getItem("guestToken");
    var headers = new HttpHeaders();
    headers.append("Authorization", "Bearer " + token);
    return { headers: headers };
  }

  updateToken(bodyData: { id: string; token: string }) {
    return this.httpClient.post(this.update_token_url, bodyData);
  }

  removeToken(bodyData: { id: string; token: string }) {
    return this.httpClient.post(this.remove_token_url, bodyData);
  }

  updateUserStatus(meetingId) {
    var body = {
      id: meetingId,
      joined: true,
    };
    return this.httpClient.post(this.update_user_status_url, body);
  }

  updateUserScheduledStatus(meetingId) {
    var body = {
      id: meetingId,
      joined: true,
    };
    return this.httpClient.post(this.update_user_status_scheduled_url, body);
  }

  createfeedback(formData) {
    return this.httpClient.post(this.add_feedback_url, formData);
  }

  getAllFeedbacks() {
    return this.httpClient.get(this.add_feedback_url);
  }

  deleteFeedback(id) {
    return this.httpClient.delete(this.add_feedback_url + "/" + id);
  }

  getOneUser(id: any) {
    var headers = this.get_auth_token();
    //API Blocked Currently
    let mockObs$: Observable<any> = from("");
    return mockObs$;
    return this.httpClient.get(this.get_all_users_url + "/" + id, headers);
  }

  getSingleChatUser() {
    var headers = this.get_auth_token();
    //API Blocked for Now
    let mockObs$: Observable<any> = from("");
    return mockObs$;
    return this.httpClient.get(this.singleChatUserApi, headers);
  }
  sendSingleChatUserData(body: any) {
    var headers = this.get_auth_token();
    //API Blocked for Now
    //  let mockObs$ : Observable<any> = from('')
    //  return mockObs$
    return this.httpClient.post(this.singleChatUserApi, body, headers);
  }

  clearToken() {
    localStorage.removeItem("guestToken");
  }
}
