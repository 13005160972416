<div class="containerBanner">
    <div class="menu-container">

        <h1>Admin Requests<span>(34)</span></h1>
        <div class="menu-content d-flex align-items-center justify-content-between">
            <div class="search-menu">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="search-icon">
                    <g opacity="0.4">
                        <circle cx="11.0795" cy="11.3768" r="7.62168" transform="rotate(-45 11.0795 11.3768)"
                            stroke="#092C4C" stroke-width="1.72802" stroke-linecap="round" />
                        <path d="M16.6416 16.9393L19.8465 20.1443" stroke="#092C4C" stroke-width="1.72802"
                            stroke-linecap="round" />
                    </g>
                </svg>
                <input type="search" placeholder="Search requests.." />
            </div>
            <div class="btn-container d-flex align-items-center justify-content-between">


                <select id="admin" class="form-control" formControlName="admin" style="margin-left: 15px">
                    <option value="admin" selected>All Admins</option>
                </select>

                <mat-form-field style="width:185px!important;height: 45px!important;">

                    <mat-select placeholder="select">
                        @for (request of requests; track $index) {
                        <mat-option [value]="request.value" (click)="onRequestSelect(request.value)">
                            {{request.viewValue}}
                        </mat-option>
                        }
                    </mat-select>

                </mat-form-field>

            </div>
        </div>
    </div>

    <!-- service Request Table -->
    <div class="checked-out-inv" style="overflow-x: auto;">
        <table>
            <thead>
                <tr>
                    <th scope="col">ASSET NAME</th>
                    <th scope="col">REQUESTED BY</th>
                    <th scope="col">STATUS</th>
                    @if(showNewInv){
                    <th scope="col">LINKS</th>
                    }
                    @if(showService){
                    <th scope="col">SERVICE TYPE</th>
                    <th scope="col">DAMAGE</th>
                    <th scope="col">OTHER DETAILS</th>
                    }
                    <th scope="col">REQUESTED ON</th>
                    <th scope="col">NEED BY</th>
                    <th scope="col">URGENCY</th>
                    <th scope="col">FORWARDED ON</th>
                    <th scope="col">FORWARDED BY</th>
                    <th scope="col">MAXIMUM COST(₹)</th>
                    <th scope="col">ACTIONS</th>

                </tr>
            </thead>
            <tbody>
                @for (adminReq of adminRequest; track $index) {
                <tr>
                    <td>
                        <div class="d-flex align-items-center">
                            <div class="request-img">
                                <img src="../../../assets/inventories/computer-system.jpg" alt="" width="60px"
                                    height="60px" />
                            </div>
                            <p>{{adminReq.assetName}}</p>
                        </div>
                    </td>
                    <td>
                        <p>{{adminReq.requestedBy}}</p>
                    </td>
                    <td>
                        <p class="status">{{adminReq.status}}</p>
                    </td>
                    @if(showNewInv){
                    <td>
                        <p class="link"><a [href]="adminReq.links[0]">{{adminReq.links[0]!== "null" ?
                                adminReq.links[0] : '-' }}</a></p>
                        <p class="link"><a [href]="adminReq.links[1]">{{adminReq.links[1]!== "null" ?
                                adminReq.links[1] : ''}}</a></p>
                        <p class="link"><a [href]="adminReq.links[2]">{{adminReq.links[2]!== "null" ?
                                adminReq.links[2] : ''}}</a></p>
                    </td>
                    }
                    @if(showService){
                    <td>
                        <p>{{adminReq.serviceType}}</p>
                    </td>
                    <td>
                        <p>{{adminReq.damage}}</p>
                    </td>
                    <td>
                        <p class="other-details">
                            {{adminReq.details}}
                        </p>
                    </td>
                    }
                    <td>
                        <p>{{adminReq.requestedOn}}</p>
                    </td>
                    <td>
                        <p>{{adminReq.needBy}}</p>
                    </td>

                    <td>
                        <p>{{adminReq.urgency}}</p>
                    </td>
                    <td>
                        <p>{{adminReq.forwardedOn}}</p>
                    </td>
                    <td>
                        <p>{{adminReq.forwardedBy}}</p>
                    </td>

                    <td>
                        <p>₹{{adminReq.maximumCost}}</p>
                    </td>


                    <td>
                        <button class="forward-req" data-toggle="modal" data-target="#acceptRequestConfirm"
                            style="width: 140px;">
                            Accept
                        </button>
                        <button class="on-hold" style="margin-right: 10px;">Put on Hold</button>
                        <button class="decline" style="width: 140px;">Decline</button>
                    </td>
                </tr>
                }
            </tbody>
        </table>
    </div>


    <!-- Accept Request Modal -->
    <div class="modal fade" id="acceptRequestConfirm" tabindex="-1" role="dialog"
        aria-labelledby="acceptRequestConfirmTitle" aria-hidden="true" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered accept-width-content" role="document">
            <div class="modal-content">
                <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
                    <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313"
                            stroke-linecap="round" />
                        <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313"
                            stroke-linecap="round" />
                    </svg>
                </button>
                <div class="modal-body" style="padding: 90px 20px 20px 20px;">
                    <h1 class="text-center" style="font-size: 28px;font-weight: 600;">Accept Request?</h1>
                    <p class="text-center" style="font-size: 16px;font-weight: 600;opacity:0.5;">Are you sure you
                        want to approve
                        the request?</p>
                    <button type="button" class="confirm">Confirm</button>
                </div>

            </div>
        </div>
    </div>



</div>