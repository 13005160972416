<app-header [activeLink]=3></app-header>
<div class="container-fluid">
  <div class="row justify-content-center align-content-center">
    <div class="col-lg-6 col-md-12">
      <form [formGroup]="projectForm">
        <div class="form-group">
          <label for="projectName">Project Name</label>
          <input type="text" class="form-control" formControlName="title" id="projectName">
        </div>
        <div class="form-group">
          <label for="projectDescription">Project Description</label>
          <input type="text" class="form-control" formControlName="description" id="projectDescription">
        </div>
        <div class="form-group">
          <label for="notion">Notion Link</label>
          <input type="text" class="form-control" formControlName="notion" id="notion">
        </div>
        <div class="form-group">
          <label for="slack">Slack Links</label>
          <input type="text" class="form-control" formControlName="slack" id="slack">
        </div>
        @if (!editMode) {
          <button class="create-button" (click)="onClickCreateButton()">Create project</button>
        }
        @if (editMode) {
          <button class="create-button" (click)="onClickSaveButton()"> Save Edits</button>
        }
        @if (editMode) {
          <button class="create-button" type="button" data-toggle="modal"
          data-target="#meetingModal">Schedule Meeting</button>
        }
        @if (editMode && meetings.length>0) {
          <div style="max-height: 250px;overflow: auto;">
            @if (editMode && meetings.length>0) {
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <!-- <th scope="col">Date</th> -->
                    <th scope="col">Start Time</th>
                    <th scope="col">End Time</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  @for (meet of meetings; track meet; let i = $index) {
                    <tr>
                      <th scope="row"> {{i+1}} </th>
                      <!-- <td>{{meet.date}}</td> -->
                      <td>{{meet.start}}</td>
                      <td>{{meet.end}}</td>
                      <td><button class="btn btn-secondary">Edit</button></td>
                      <td><button class="btn btn-warning">Delete</button></td>
                    </tr>
                  }
                </tbody>
              </table>
            }
          </div>
        }
        <!-- <table *ngIf="editMode && meetings.length>0" class="table table-bordered table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date</th>
            <th scope="col">Start Time</th>
            <th scope="col">End Time</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let meet of meetings;let i=index">
            <th scope="row"> {{i+1}} </th>
            <td>{{meet.date}}</td>
            <td>{{meet.start}}</td>
            <td>{{meet.end}}</td>
            <td><button class="btn btn-secondary">Edit</button></td>
            <td><button class="btn btn-warning">Delete</button></td>
          </tr>
        </tbody>
      </table> -->



      <!-- Modal -->


      <div class="modal fade" id="meetingModal" tabindex="-1" aria-labelledby="meetingeModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="meetingModalLabel">Set up a meeting</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="meet-title">Meeting title: </label><input id="meet-title" type="text" (change)="startTimeChange($event)">
              </div>
              <div class="form-group">
                <label for="start-date">Starting date: </label><input id="start-date" type="date" (change)="startTimeChange($event)">


                <label for="start-time">Starting time: </label><input id="start-time" type="time" (change)="startTimeChange($event)">
              </div>
              <div class="form-group">
                <label for="end-time">Ending date: </label><input id="end-date" type="date" (change)="endTimeChange($event)">
                <label for="end-time">Ending time: </label><input id="end-time" type="time" (change)="endTimeChange($event)">
              </div>
              <div class="form-group">
                <label for="meet-description">Description: </label><textarea  id="meet-description" cols="30" rows="2"></textarea>
              </div>
              <p>Participants:</p>
              <div class="row">
                <div class="col-md-3">
                  <p style="margin-top: 1rem;"><strong>3D Department</strong></p>
                  <div style="max-height: 200px;overflow: auto;">
                    @for (user of threeD; track user) {
                      <div class="list-group">
                        <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                        <label class="list-group-item" for="{{user.username}}">{{user.username}}</label>
                      </div>
                    }
                  </div>
                </div>

                <div class="col-md-3">
                  <p style="margin-top: 1rem;" ><strong>2D Department</strong></p>
                  <div style="max-height: 200px;overflow: auto;">
                    @for (user of twoD; track user) {
                      <div class="list-group">
                        <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                        <label class="list-group-item" for="{{user.username}}">{{user.username}}</label>
                      </div>
                    }
                  </div>
                </div>

                <div class="col-md-3">
                  <p style="margin-top: 1rem;" ><strong>Sales & Marketing Department</strong></p>
                  <div style="max-height: 200px;overflow: auto;">
                    @for (user of sales; track user) {
                      <div class="list-group">
                        <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                        <label class="list-group-item" for="{{user.username}}">{{user.username}}</label>
                      </div>
                    }
                  </div>
                </div>

                <div class="col-md-3">
                  <p style="margin-top: 1rem;" ><strong>Account Department</strong></p>
                  <div style="max-height: 200px;overflow: auto;">
                    @for (user of account; track user) {
                      <div class="list-group">
                        <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                        <label class="list-group-item" for="{{user.username}}">{{user.username}}</label>
                      </div>
                    }
                  </div>
                </div>

                <div class="col-md-3">
                  <p style="margin-top: 1rem;" ><strong>Human Resource Department</strong></p>
                  <div style="max-height: 200px;overflow: auto;">
                    @for (user of hr; track user) {
                      <div class="list-group">
                        <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                        <label class="list-group-item" for="{{user.username}}">{{user.username}}</label>
                      </div>
                    }
                  </div>
                </div>

                <div class="col-md-3">
                  <p style="margin-top: 1rem;" ><strong>IT Department</strong></p>
                  <div style="max-height: 200px;overflow: auto;">
                    @for (user of it; track user) {
                      <div class="list-group">
                        <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                        <label class="list-group-item" for="{{user.username}}">{{user.username}}</label>
                      </div>
                    }
                  </div>
                </div>

                <div class="col-md-3">
                  <p style="margin-top: 1rem;" ><strong>Admin Department</strong></p>
                  <div style="max-height: 200px;overflow: auto;">
                    @for (user of admin_dept; track user) {
                      <div class="list-group">
                        <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                        <label class="list-group-item" for="{{user.username}}">{{user.username}}</label>
                      </div>
                    }
                  </div>
                </div>

                <div class="col-md-3">
                  <p style="margin-top: 1rem;" ><strong>Operation Department</strong></p>
                  <div style="max-height: 200px;overflow: auto;">
                    @for (user of ops; track user) {
                      <div class="list-group">
                        <input type="checkbox" name="CheckBoxInputName" value="{{user.username}}" id="{{user.username}}">
                        <label class="list-group-item" for="{{user.username}}">{{user.username}}</label>
                      </div>
                    }
                  </div>
                </div>

              </div>









              <!-- <p *ngFor="let user of allUsers"> {{user.username}}</p> -->
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="create-button" data-dismiss="modal" (click)="onClickScheduleButton()">Schedule</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
</div>
<app-footer></app-footer>