import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';


@Component({
    selector: 'app-data',
    templateUrl: './data.component.html',
    styleUrls: ['./data.component.css'],
    standalone: true,
    imports: [FooterComponent]
})
export class DataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  cards=[
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
    {imgSrc:"../../../assets/Group 190.png",clientName:"Client Name"},
  ];

}
