<app-header></app-header>
<div class="container">
  <div class="row">
    <div class="col">
      <div class="d-flex justify-content-around" style="margin:1rem">
        <h2>Users</h2>
        <button class="btn btn-warning" (click)="addUser()">Add User</button>
      </div>


      @if (accesGranted) {
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Username</th>
              <th scope="col">Email</th>
              <th scope="col">Department</th>
              <!-- <th scope="col">Revoked</th> -->
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            @for (user of usersData; track user; let i = $index) {
              <tr>
                <th scope="row"> {{i+1}} </th>
                <td>{{user.username}}</td>
                <td>{{user.email}}</td>
                <td>{{user.department}}</td>
                <!-- <td>{{user.revoked}}</td>    -->
                <td><button class="btn btn-outline-warning" (click)="onClickUser(user)"> Edit</button></td>
                <td><button class="btn btn-outline-danger" (click)="onClickUserDelete(user ,i)"> Delete</button></td>
              </tr>
            }
          </tbody>
        </table>
      }

      @if (!accesGranted) {
        <p>You are not allowed to view this page</p>
      }

    </div>
  </div>
</div>
<app-footer></app-footer>