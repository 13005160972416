import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { ChatService } from "src/app/service/chat.service";
import { LiveProjectService } from "src/app/service/live-project.service";
import { UserService } from "src/app/service/user.service";
import { environment } from "src/environments/environment";
declare var $: any;
import * as moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { MediaService } from "src/app/service/media.service";
import { ReactiveFormsModule } from "@angular/forms";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { HeaderComponent } from "../header/header.component";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
  increment,
  decrement,
  reset,
  loginSuccess,
} from "src/app/store/counter.actions";
import { CommonModule } from "@angular/common";
import { LeadTrackComponent } from "../lead-track/lead-track.component";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  standalone: true,
  imports: [
    HeaderComponent,
    RouterLink,
    NgIf,
    AutocompleteLibModule,
    ReactiveFormsModule,
    NgFor,
    NgClass,
    CommonModule,
  ],
  providers: [LeadTrackComponent],
})
export class HomeComponent implements OnInit {
  allUsers = [];

  usersList = [];
  message = "";

  addCallers = [];

  threeD = [];
  twoD = [];
  sales = [];
  account = [];
  hr = [];
  it = [];
  admin_dept = [];
  ops = [];

  keyword = "name";
  searchData = [];

  meetingStart = "";
  meetingEnd;

  startMeetingConfig = {
    min: moment(),
  };

  // endMeetingConfig={
  //   min:moment()
  // }

  meetingTypeInstant = false;

  selectEvent(item) {
    console.log("selected item", item);
    (<any>document.getElementById(item.name)).checked = true;
    this.selectCaller(new Event("click"));
    // (<any>document.querySelector("ng-autocomplete")).setAttribute("ng-reflect-model",null);
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
    console.log("on focus", e);
  }

  cardData = [
    { text: "Private Collection", img: "../../../../assets/Group 215.png" },
    { text: "Public Collection", img: "../../../../assets/Group 213.png" },
    { text: "Upload File", img: "../../../../assets/Group 212.png" },
    { text: "Notifications", img: "../../../../assets/Group 211.png" },
    { text: "Client Data", img: "../../../../assets/Group 210.png" },
  ];
  count$: Observable<number>;
  login$: Observable<any>;
  constructor(
    private router: Router,
    private mediaService: MediaService,
    private userService: UserService,
    private liveProjectService: LiveProjectService,
    private leadTrackComponent: LeadTrackComponent,
    private chatService: ChatService,
    private store: Store<{ count: number; login: any }>
  ) {
    this.count$ = store.select("count");
    this.login$ = store.select("login");
  }

  increment() {
    this.store.dispatch(increment());
    this.store.dispatch(loginSuccess({ body: "lskjd" }));

    this.count$.subscribe((data) => {
      console.log({ data });
    });
  }

  decrement() {
    this.store.dispatch(decrement());
    this.login$.subscribe((loginData) => {
      console.log({ loginData });
    });
  }

  reset() {
    this.store.dispatch(reset());
  }

  ngOnInit(): void {
    this.checkSharedProjects();
    $("#meetingStart").on("change", function (event) {
      console.log($("#meetingStart").attr("ng-reflect-model"));
    });

    while ($(".modal-backdrop.fade.show").length) {
      $(".modal-backdrop.fade.show").remove();
    }

    this.userService.getAllUsers().subscribe((resp) => {
      this.userService.allUsersData = resp["data"];
      this.allUsers = this.userService.allUsersData;
      this.usersList = [];
      this.allUsers = this.allUsers.filter(
        (x) => x._id != localStorage.getItem("guestID") && x.username != "admin"
      );
      this.allUsers.sort(this.sortFunction);

      for (let i = 0; i < this.allUsers.length; i++) {
        this.searchData.push({ id: i + 1, name: this.allUsers[i].username });
        switch (this.allUsers[i].department) {
          case "3D Department":
            this.threeD.push(this.allUsers[i]);
            break;
          case "2D Department":
            this.twoD.push(this.allUsers[i]);
            break;
          case "Sales & Marketing Department":
            this.sales.push(this.allUsers[i]);
            break;
          case "Account Department":
            this.account.push(this.allUsers[i]);
            break;
          case "Human Resource Department":
            this.hr.push(this.allUsers[i]);
            break;
          case "IT Department":
            this.it.push(this.allUsers[i]);
            break;
          case "Admin Department":
            this.admin_dept.push(this.allUsers[i]);
            break;
          case "Operation Department":
            this.ops.push(this.allUsers[i]);
            break;
        }
      }

      setTimeout(() => {
        $(".selectpicker").selectpicker("refresh");
      }, 150);

      setTimeout(() => {
        $(function () {
          $(".list-group.checked-list-box .list-group-item").each(function () {
            // Settings
            var $widget = $(this),
              $checkbox = $(
                '<input  type="checkbox" (click)="selectCaller()" class="hidden" />'
              ),
              color = $widget.data("color") ? $widget.data("color") : "primary",
              style =
                $widget.data("style") == "button" ? "btn-" : "list-group-item-",
              settings = {
                on: {
                  icon: "glyphicon glyphicon-check",
                },
                off: {
                  icon: "glyphicon glyphicon-unchecked",
                },
              };

            $widget.css("cursor", "pointer");
            $widget.append($checkbox);

            // Event Handlers
            $widget.on("click", function () {
              $checkbox.prop("checked", !$checkbox.is(":checked"));
              $checkbox.triggerHandler("change");
              updateDisplay();
            });
            $checkbox.on("change", function () {
              updateDisplay();
            });

            // Actions
            function updateDisplay() {
              var isChecked = $checkbox.is(":checked");

              // Set the button's state
              $widget.data("state", isChecked ? "on" : "off");

              // Set the button's icon
              $widget
                .find(".state-icon")
                .removeClass()
                .addClass("state-icon " + settings[$widget.data("state")].icon);

              // Update the button's color
              if (isChecked) {
                $widget.addClass(style + color + " active");
              } else {
                $widget.removeClass(style + color + " active");
              }
            }

            // Initialization
            function init() {
              if ($widget.data("checked") == true) {
                $checkbox.prop("checked", !$checkbox.is(":checked"));
              }

              updateDisplay();

              // Inject the icon if applicable
              if ($widget.find(".state-icon").length == 0) {
                $widget.prepend(
                  '<span class="state-icon ' +
                    settings[$widget.data("state")].icon +
                    '"></span>'
                );
              }
            }
            init();
          });

          // $('#get-checked-data').on('click', (event)=> {
          //     event.preventDefault();
          //     var checkedItems = [], counter = 0;
          //     $(".list-group input[type='checkbox']:checked").each(function(idx, li) {
          //         // checkedItems[counter] = $(li).text();
          //         checkedItems[counter] = $(this).val();
          //         counter++;
          //     });
          //     // console.log(checkedItems);
          //     this.addCallers=checkedItems;
          //     console.log(this.addCallers);
          // });
        });
      }, 150);
    });

    $("#meetingModal").on("hide.bs.modal", (event) => {
      $.each($("input[type='checkbox']:checked"), function () {
        $(this).prop("checked", false);
      });
      this.usersList = [];
      this.addCallers = [];

      (<any>document.getElementById("meet-title")).value = null;
      this.meetingStart = null;
      this.meetingEnd = null;
      (<any>document.querySelector("ng-autocomplete")).value = null;
      console.log("modal clossed");
    });
  }

  selectCaller(event) {
    event.preventDefault();
    var checkedItems = [],
      counter = 0;
    $(".list-group input[type='checkbox']:checked").each(function (idx, li) {
      checkedItems[counter] = $(this).val();
      counter++;
    });
    this.addCallers = checkedItems;
    console.log(this.addCallers);
  }

  onClickCollections() {
    this.router.navigate(["/collection"]);
  }

  onClickNotifications() {
    this.router.navigate(["/notification"]);
  }

  onClickClients() {}

  onClickUploadFile() {
    this.router.navigate(["/upload"]);
  }

  onClickCreateProject() {
    // this.router.navigate(["/create-project/-1"]);
  }

  async openMeeting(bool) {
    this.meetingTypeInstant = bool;
    $("#meetingModal").modal("show");
  }

  closeMeeting() {
    $("#meetingModal").modal("hide");
  }

  onClickStartInstantMeeting() {
    var values = [];

    let inputEl = <any>document.getElementById("meet-title");

    if (!inputEl.value) {
      document.getElementById("warning-meet-input").classList.remove("hide");
      document.getElementById("warning-meet-user").classList.add("hide");
      return;
    }

    $.each($("input[type='checkbox']:checked"), function () {
      values.push($(this).val());
    });
    console.log(values);

    if (values.length) {
      let arrtest = [];
      let intersection = this.allUsers.filter((x) =>
        values.includes(x.username)
      );
      for (let i = 0; i < intersection.length; i++) {
        arrtest.push(intersection[i]._id);
      }
      arrtest.push(localStorage.getItem("guestID"));
      var title = (<any>document.getElementById("meet-title")).value;
      console.log("body", {
        room_name: title,
        room_uid: "123456",
        users: arrtest,
      });

      this.liveProjectService
        .createInstantMeeting({
          creator: localStorage.getItem("username"),
          room_name: title,
          room_uid: uuidv4(),
          users: arrtest,
          meetingType: "instant",
        })
        .subscribe((resp) => {
          console.log("start meeting", resp);
          console.log("meeting module", resp["meet"]);
          let arr = resp["meet"]["participants"];
          console.log("response array id+url", arr);
          let content;
          let usernames = [];
          for (let i = 0; i < arr.length; i++) {
            usernames.push(arr[i].username);
            if (arr[i]._id == localStorage.getItem("guestID")) {
              content = arr[i].url;
              console.log(arr[i].url);
              break;
            }
          }
          let usernamesStr = usernames.join();

          this.userService
            .updateUserStatus(resp["meet"]["_id"])
            .subscribe((response) => {
              console.log("update on creating meet", response);
              let meetNewObject = { contents: content, ...resp["meet"] };
              meetNewObject = { usernames: usernamesStr, ...meetNewObject };
              this.liveProjectService.activeInstantMeeting = meetNewObject;

              this.router.navigate(["./video-call"]);
            });

          //   let el:any =document.querySelectorAll('input[name]');
          //   for(let i=0;i<el.length;i++){
          //     el[i].checked=false;
          //   }
          //   (<any>document.getElementById("meet-title")).value="";
          // $('#meetingModal').modal('hide');
        });
    } else {
      document.getElementById("warning-meet-input").classList.add("hide");
      document.getElementById("warning-meet-user").classList.remove("hide");
    }
  }

  sortFunction(a, b) {
    let nameA = a.username.toUpperCase();
    let nameB = b.username.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }

  // sortSearchData(a, b) {
  //   let nameA = a.name.toUpperCase();
  //   let nameB = b.name.toUpperCase();
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }
  //   // names must be equal
  //   return 0;
  // }

  checkIfUserInArray(username) {
    var found = false;
    for (var i = 0; i < this.chatService.InCallUserList.length; i++) {
      // console.log("in the checkArray ",this.chatService.InCallUserList[i].username);
      if (this.chatService.InCallUserList[i].username == username) {
        found = true;
        break;
      }
    }
    return found;
  }

  onClickStartScheduleMeeting() {
    let start = this.meetingStart.split(" ");
    // let end=this.meetingEnd.split(" ");

    console.log(
      "starting time",
      moment
        .parseZone(moment(start, "DD MM YYYY hh:mm:ss").format())
        .utc()
        .format()
    );
    // console.log("ending time",moment.parseZone(moment(end,"DD MM YYYY hh:mm:ss").format()).utc().format());

    let participants = [];
    $.each($("input[type='checkbox']:checked"), function () {
      participants.push($(this).val());
    });
    console.log(participants);
    let arrtest = [];
    let intersection = this.allUsers.filter((x) =>
      participants.includes(x.username)
    );
    for (let i = 0; i < intersection.length; i++) {
      arrtest.push(intersection[i]._id);
    }
    arrtest.push(localStorage.getItem("guestID"));

    let meet = {
      room_name: (<any>document.getElementById("meet-title")).value,
      room_uid: uuidv4(),
      usersids: arrtest,
      meetingType: "scheduled",
      creator: localStorage.getItem("username"),
      description: "description",
      st: moment
        .parseZone(moment(start, "DD MM YYYY hh:mm:ss").format())
        .utc()
        .format(),
      // ed:moment.parseZone(moment(end,"DD MM YYYY hh:mm:ss").format()).utc().format()
      ed: moment
        .parseZone(
          moment(start, "DD MM YYYY hh:mm:ss")
            .add((<any>document.getElementById("time-duration")).value, "m")
            .format()
        )
        .utc()
        .format(),
    };

    console.log(meet);
    this.liveProjectService.createScheduledMeeting(meet).subscribe((resp) => {
      console.log("scheduled meeting ", resp);
      this.closeMeeting();
    });
  }

  // setEndMin(eve){
  //   this.endMeetingConfig={
  //     min:eve
  //   }

  // }

  ngOnDestroy(): void {
    $(".modal-backdrop").remove();
  }
  ngAfterViewInit() {
    this.isAdminAndSales();
    this.checkAdminStatus();
  }

  isAdminSales: boolean = true;
  isAdminAndSales() {
    const department = localStorage.getItem("department");
    if (department == "TESTER" || department == "EVENTS") {
      this.isAdminSales = false;
    } else {
      this.isAdminSales = true;
    }
  }
  hasSharedProjects: boolean = false;
  checkSharedProjects() {
    this.leadTrackComponent.getShareAccess();
    this.hasSharedProjects = this.leadTrackComponent.hasSharedProjects();
    console.log(this.hasSharedProjects, "check project");
  }

  isAdmin: boolean = false;
  checkAdminStatus() {
    const department = localStorage.getItem("department");
    this.isAdmin = department === "ADMIN";
  }

  get canAccessLeadTrack(): boolean {
    return this.isAdmin || this.hasSharedProjects;
  }
}
